import React from 'react'
import { Span } from 'components/common'
import './style.scss'

interface propsType{
  children?: any;
  image?: any;
  onClick?: any;
  className?: any;
  href?: any;
  ariaLabel?: string;
  type?: string;
  onBlur?: any
}

const OdsImage = (props: propsType) =>{
  const { children, image, className, href, onClick, ...rest } = props

  const _image = image || <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
    <use href={`#${href}`}></use>
  </svg>
  
  const pps = {className: `img-button ${className} ${href}`, onClick, ...rest}

  return (onClick ? <Span role='button' {...pps}>{_image}{children}</Span>
  :
  <span {...pps}>{_image}{children}</span>)
}
export default OdsImage