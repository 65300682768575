import { combineReducers } from 'redux'
import { containerReducer as container} from './containers/reducer'
import { cacheReducer as cache} from './cache/reducer'
import { valuelistReducer as valuelist} from './valuelist/reducer'
import { auditReducer as audit} from './audit/reducer'
//import { importReducer as winImport} from './win_import/reducer'
import { importReducer as winImportWin} from './win_import_win/reducer'
import { userReducer as user} from './user/reducer'
import { extractReducer as extract} from './data_extract/reducer'
import { indMgmtReducer as indMgmt} from './ind_mgmt/reducer'
import { unmatchedWinsReducer as unmatchedWins } from './win_ad_mapping/reducer'
import { orgPrioritiesReducer as orgPriorities } from './org_priorities/reducer'
import { minMappingReducer as minMapping } from './min_mapping/reducer'
import { ratingReducer as rating } from './rating/reducer'
import { sendGridReducer as sendGrid} from './send_grid/reducer'
import { accessLogReducer as access} from './access/reducer'
import { reportReducer as report} from './report/reducer'
import { succReducer as succLists } from './succession_planning/reducer'
import { processLogReducer as processLog } from './process_log/reducer'
import { announcementReducer as announcement } from './announcement/reducer'
export const adminReducer = combineReducers({
  container,
  cache,
  valuelist,
  audit,
  // winImport,
  winImportWin,
  user,
  extract,
  indMgmt,
  unmatchedWins,
  orgPriorities,
  minMapping,
  rating,
  sendGrid,
  report,
  access,
  succLists,
  processLog,
  announcement
})