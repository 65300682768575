import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import './item.scss'

// export const OverlayItem = (props: any) => {
//   const { children } = props;

//   const style = {
//     width: "100%",    
//     height: '100%', 
//     border: "1px solid grey",
//     margin: "5px 0 0 0",
//     background: "white"
//   };

//   return <div style={style}>{children}</div>;
// }

export const SortableItem = (props: any) => {
  const { id, children } = props
  const {
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id })

  const style: any = {
    transform: CSS.Transform.toString(transform),
    transition,   
  }

  return (
    <div style={style} className='sortable-item' ref={setNodeRef} role='application' aria-disabled='false' aria-roledescription='sortable' {...listeners} tabIndex={0}>
      {children}
    </div>
  )
}
