import React from 'react'
import { connect } from 'react-redux'
import { getInprogress } from '..'
import { getLock, getRemainTime } from './selector'
import './style.scss'

const LockStatus = (props: any) => {
  const { lockBody, remainTime, inprogress } = props

  return remainTime <=0 || inprogress ? <span/> :
    <div className='simple-row' style={{justifyContent: 'flex-end'}}>
      { 
      lockBody.acquireSuccess === 1 ? 
      <span>Locked in {remainTime}s</span>
      : 
      lockBody.acquireSuccess === 0 ?
      <span className='red'>Locked by {lockBody.lockBy?.displayName}</span>
      :
      <span/>
      }
    </div>
}

const mapStateToProps = (state: any, ownProps: any) =>({
	lockBody: getLock(state),
  remainTime: getRemainTime(state),
  inprogress: getInprogress(state)
})
export default connect(mapStateToProps, {})(LockStatus)