import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { connect } from 'react-redux'
import { Modal, Scrollbar } from 'components/common'
import sessionTimeout from 'services/time-out'
import { refresh_sse_status1, refresh_sse_inpro } from './actions'
import { getSseStatus, getSseInProgress } from './selectors'
import './style.scss'
import { toLocalDateTime } from 'utils'

let intervalHandler: any={}
const inverval = 5
const InProgress = forwardRef((props: any, ref) => {
  const { sseStatus, sseInProgress, header='', onHide=()=>{} } = props
  const [ funcs, setFuncs ] = useState<any>({})
  const modalRef: any = useRef({}), showAreaRef: any = useRef({})

  const api = () => ({
    show: (fs: any) =>{
      refresh_sse_status1()
      const _funcs = {...fs}
      fs.start((data: any)=>{
        _funcs.key = data.key
        setFuncs(_funcs)
        watch(_funcs)
      })
      modalRef.current.show()
    }
  })
  useImperativeHandle(ref, api)

  const watch = (fs: any) => {
    refresh_sse_inpro()    
    intervalHandler = setInterval(() => { 
      refresh_sse_inpro(inverval)
      fs.watch({key: fs.key}, (data: any)=>onData(data, fs))
      sessionTimeout.globalReset()
    }, inverval * 1000)

    const onData = (data: any, fs: any) => {
      if(data.filter((e: any)=>e.log === 'LOG OVER').length > 0){
        addData(data)
        fs.successcallback && fs.successcallback()
        cleanUp()
      }else if(data.filter((e: any)=>e.log && e.log.indexOf('LOG ERR') > -1).length > 0){
        console.log(data)
        addData(data)
        fs.failureCallbackure && fs.failureCallbackure()
        cleanUp()
      }else{
        addData(data)
      }
    }
  }
  
  const addData = (data: any) => {
    refresh_sse_status1(data)
    showAreaRef.current?.scrollIntoView && showAreaRef.current.scrollIntoView(false)
  }

  const cleanUp = () => {
    clearInterval(intervalHandler)
    intervalHandler = 0
  }

  const onClickHide = () => {    
    modalRef.current.hide()
  }

  const _onHide = () => {
    refresh_sse_inpro()
    cleanUp()
    if(funcs.clean){
      funcs.clean({key: funcs.key})
    }
    onHide()
  }
  return (
    <Modal ref={modalRef} onHide={_onHide} className='in-progress-modal'
      header={<div className='simple-row'><div className='col-sm-12'>{header}</div></div>}          
      footer={
      <div className='simple-row'><div className='col-sm-12'>
        <button className='tms-button delete' onClick={onClickHide}>Close</button>
      </div></div>
       }>
        
      <div className='in-progress-modal-container'>
        <div className='simple-row'>{sseInProgress} s</div>
        <Scrollbar>
        <div ref={showAreaRef}>
          {sseStatus.sort((a: any,b: any)=>a.timestamp - b.timestamp).map((m: any, i: number)=><div key={i} className='simple-row'>{toLocalDateTime(m.timestamp, 'HH:mm:ss')}: {m.log}</div>)}
        </div>
        </Scrollbar>
      </div>
    </Modal>
  )
})
const mapStateToProps = (state: any, ownProps: any) => {
  return ({
    sseStatus: getSseStatus(state),
    sseInProgress: getSseInProgress(state)
  })
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(InProgress)