import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react'
import { Key, trim, focusOnRefs, newId } from 'utils'
import { Div, HTMLDiv } from './elements'
import AnimateInput from './animate-input'
import Textarea from './textarea'
import More from './more'
import './content-editable.scss'

const ContentEditable = forwardRef((props: any, ref) =>  {
  const { content, className, label, labelledBy, describedBy='', minHeight=100, maxLength=0, onValueChange, onCancelChange=()=>{}, singleLine = false, placeHolder, readonly = false, required=false, onEditing=()=>{}, styles, pencilStyle, xss=true, ...rest } = props
  const [status, setStatus] = useState({value: '', editable: false})
  const [contentId, setContentId] = useState('')
  const [focused, setFocused] = useState(false)
  const contentRef: any = useRef({}), inputRef: any = useRef({}), wrapperRef: any = useRef({}), editBtnRef = useRef({})

  useEffect(() => setContentId(newId()), [])
  useImperativeHandle(ref, () => ({
		focus: ()=> focusOnRefs([editBtnRef]),
    focusInput: () =>{
      setStatus({...status, editable: true})
      focusOnRefs([inputRef])
    }
  }))
  
  const onLostFocus = useCallback((newValue: any, restoreFocus=true) => {
    if(!enableToOk()){
      return
    }
    setStatus({...status, editable: false})
    onEditing(false)
    if(content !== newValue){ 
      if(!required){
        onValueChange(newValue)
      }else if(trim(newValue)){
        onValueChange(newValue)
      }
    }
    if (restoreFocus) {
      focusOnRefs([editBtnRef])
    }
    if(!trim(content)){
      onCancelChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValueChange, content])

  const leaveEditingArea = useCallback((target: any) => {
    if (!inputRef || !inputRef.current || inputRef.current.contains(target)){
      return
    }
    if (!wrapperRef || !wrapperRef.current || wrapperRef.current.contains(target)){
      if(target.className ==='fa fa-times' || (target.firstChild && target.firstChild.className ==='fa fa-times' )
        || target.className==='fa fa-check' || (target.firstChild && target.firstChild.className==='fa fa-check')){
        return
      }
    }
    onLostFocus(inputRef.current.value, false)
  }, [onLostFocus])
  
  const handleClickOutside = useCallback((event: any) => {
    leaveEditingArea(event.target)
  }, [leaveEditingArea])

  const handleKeypress = useCallback((event: any) => {
    if(event.keyCode === Key.ESCAPE){
      // do nothing!
    }else if(event.keyCode === Key.TAB){
      setTimeout(()=>leaveEditingArea(document.activeElement), 100)
    }else{
      handleClickOutside(event)
    }
  }, [leaveEditingArea, handleClickOutside])

  useEffect(() => {
    if(status.editable){
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleKeypress)
    }
    return function cleanup(){
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeypress)
    }
  }, [status.editable, handleClickOutside, handleKeypress])

  const handleChange = (event: any) => setStatus({...status, value: event.target.value})
  
  const onClick = () => {
    setStatus({...status, value: content, editable: true})
    onEditing(true)
    focusOnRefs([inputRef]) 
  }

  const onKeyDown = (event: any) => event.keyCode === Key.ESCAPE && onCancel()

  const enableToOk = () => {
    if(maxLength && (inputRef.current?.value || '').length > maxLength){
      return false
    }
    return true
  }

  const onOk = () => {    
    onLostFocus(inputRef.current.value)
  }
  const onCancel = () => {
    inputRef.current.value = content
    onLostFocus(content)
    onCancelChange()
  }

  const getLabel = () => {
    if (!!maxLength) {
      return `${label} max is ${maxLength} characters` 
    }
    return label
  }

  return <div ref={wrapperRef} className={`content-editable ${className}`}>
    {
    readonly?
    <div className='content-container' title={content}>
      <More minHeight={minHeight}>
        <HTMLDiv className='content' style={{border: '0px'}} content={content} xss={xss} placeHolder={placeHolder}/>
      </More>
    </div>
    :
    !!!status.editable ? <>
      <div ref={contentRef} className={`content-container editable ${focused ? 'focused': ''}`}  onClick={onClick} title={singleLine ? content: ''}>
        <Div role='button' ref={editBtnRef} noOutline={true} onFocus={()=> setFocused(true)} onBlur={()=>setFocused(false)} 
          {...(labelledBy ? {'aria-labelledby': labelledBy}: {'aria-label': `Edit ${label}`})}
          aria-describedby={describedBy ? describedBy: contentId}>   
          <span aria-hidden='true' className='fa fa-pencil' style={pencilStyle}></span>
        </Div>
        <More minHeight={minHeight}>
          <HTMLDiv id={contentId} className='content' content={content} xss={xss} placeHolder={placeHolder}/>
        </More>
      </div>
    </>
    :
    <div className='editing'>{
      !!!singleLine ?
      <Textarea {...rest} aria-describedby={describedBy} aria-required={required} maxLength={maxLength} label={getLabel()} style={styles} ref={inputRef} value={status.value} placeholder={placeHolder} onChange={handleChange} onKeyDown={onKeyDown}/>
      :
      <AnimateInput {...rest} label={getLabel()} style={styles} ref={inputRef} maxLength={maxLength} value={status.value} placeHolder={placeHolder} onChange={handleChange} onKeyDown={onKeyDown}/>
      }
      <div style={{float: 'right'}}>
        <button disabled={!enableToOk()} onClick={onOk} type='submit' aria-label='Save' title='Save'><span aria-hidden={true} className='fa fa-check'/></button>
        <button onClick={onCancel} aria-label="Cancel" title='Cancel'><span aria-hidden={true} className='fa fa-times'/></button>
      </div>
    </div>
    }
  </div>
})
export default ContentEditable