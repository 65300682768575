import React, { useState } from 'react'
import { Button, OdsImage } from 'components/common'
import './confirm-banner.scss'

const ConfirmBanner = (props: any) => {
  const { children, header, check, close, ods=false } = props
  const [show, setShow]=useState(true)
  return (!show ? <div/> :
    <div className='simple-row confirm-banner'>
      { !check ? <div/> :
        ods ? <OdsImage href='ontario-icon-alert-success'/>
        :
        <span aria-hidden='true' className='fa fa-check-circle'/>
        
      }
      <div role='alert' aria-live='assertive' className='confirm-banner-content'>
        {!header ? <span/> : <h2 className='simple-row strong big' >{header}</h2>}
        <div className='simple-row'>{children}</div>
      </div>

      {!close ? <div/> :
        <span className='banner-close' style={{float: 'right'}}>
          <Button className='button' onClick={() =>setShow(false)} aria-label='Close Message'>
            <span aria-hidden='true' className='fa fa-close'></span>
          </Button>
        </span>
      }
    </div>
  )
}

export default ConfirmBanner