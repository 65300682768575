import { getGoalTypeValues, getPPlanAckValues, toDate, tryParseJSON, onBehalf, displayUserName } from 'utils'

export const buildYearTree = years => {
  const tree = {}
  years.map(s=>({...tryParseJSON(s.jsonData), ...s}))
  .sort((a,b)=>b.displayOrder - a.displayOrder)
  .map(s =>{
    const yearId = s.type
    if(tree[yearId]){
      const _plan = tree[yearId].plans.filter(p=>p.id === s.id)[0]
      if(_plan){
        _plan.positions.push(s)
      }else{
        tree[yearId].plans.push({...s, positions: [s]})
      }
    }else{
      tree[yearId]={
        ...s,
        plans:[{...s, positions: [s]}]
      }
    }
    return tree
  })
  return Object.keys(tree).map(e=>tree[e]).sort((a,b)=>b.type - a.type)
}

export const buildAcksTree = (acks) => {
  const _acknowledges = []
  const ackValues = getPPlanAckValues()
  ackValues.map(e=>{
    const _ack = acks.filter(a=>a.ack.id === e.id)[0]
    if(_ack){
      _acknowledges.push({..._ack, checked: true})
    }else{
      _acknowledges.push({id: 0, ack: e, checked: false})
    }
    return e
  })
  return _acknowledges
}
export const buildPrioritiesTree = (priorities) => {
  const tree = []
  priorities.map(p =>{
    const path = p.priority.org.path.join('>')
    const active = p.priority.org.active
    const _tree = tree.filter(t=>t.path === path)[0]
    if(_tree){
      _tree.children.push(p)
    }else{
      tree.push({ path, active, children:[p] })
    }
    return tree
  })
  return Object.keys(tree).map(e=>tree[e])
}
export const buildGoalTree = goals => {
  const tree = [...getGoalTypeValues()]
  tree.map(t=>t.goals=goals.filter(g=>g.type.id === t.id)) //.sort((a,b)=>a.name.localeCompare(b.displayOrder))
  return Object.keys(tree).map(e=>tree[e])
}

export const buildUpdateTree = updates => {
  const _updates = updates.filter(e=>e.replyToId === 0 ).sort((a,b)=>b.createAt - a.createAt)
  const _replies = updates.filter(e=>e.replyToId !== 0 )
  _updates.map(e=>e.replies=_replies.filter(r=>r.replyToId === e.id))
  return _updates
}

export const filterGoals = (goals, filter) => {
  const filter_t = e => filter.type.some(t=>t.id === e.type.id || t.id === 0)
  const filter_s = e => filter.status.some(s => s.id === 0 || (s.type === 'GOAL_LIB_DEPLOY_TYPE' ? s.code === e.deployType : s.id === e.status.id))
  const filter_e = e => !filter.endorse || e.endorses.length > 0
  const sortFun = filter.sorting?.alphbeta ? (a,b)=>a.name.localeCompare(b.name) : (a,b)=>b.createAt - a.createAt
  return (filter.all ? goals : goals.filter(filter_t).filter(filter_s).filter(filter_e)).sort(sortFun)
}

export const getEndorseHint = (endorses=[]) => {
  if(endorses.length === 0 ){
    return ''
  }
  const endorse = endorses[0]
  return 'By ' + onBehalf(endorse.onBehalf, endorse.manager?.userFullName || endorse.manager?.displayName) + ' on ' + toDate(endorse.at, 'MMM DD YYYY [at] hh:mmA')
}


export const getUpdateUser = update => {
  return update.onBehalf && update.ownByUser ? `Admin (on behalf of ${displayUserName(update.ownByUser)})`
  :
  update.ownBy === update.updateBy.id ? displayUserName(update.updateBy)
  :
  'Admin'
}

export const getFeedbackUser = feed => {
  return feed.onBehalf && feed.ownByUser ? `Admin (on behalf of ${displayUserName(feed.ownByUser)})`
  :
  feed.feedbackOwnerId === feed.requestBy?.id ? displayUserName(feed.requestBy)
  :
  'Admin'
}

export const getFeedReminder = (feed, user, plan) => {
  return feed.owner.adId  === user.userId ? 1 : feed.requestBy.adId === user.userId ? 1 : 
    ((!!user.minAdmin || !!user.corpAdmin) && (plan.manager.id !== user.id )) ? 1
     :  0
}

// export const getFeedReminder = (feed, user) => feed.requestBy.adId === user.userId

export const getProposedBy = detail => {
  return !detail.onBehalf ? displayUserName(detail.lastUpdate?.updateBy)
  : 
  `Admin (on behalf of ${displayUserName(detail.submitBy)})`
}
export const getAssessedBy = review => {
  return !review.lastUpdate.onBehalf ? displayUserName(review.submitBy)
  : 
  `Admin (on behalf of ${displayUserName(review.submitBy)})`
}

export const getStatusHint = (goal={}) => {
  const comment = goal.statusComment
  let hint = goal.statusReason ? goal.statusReason.label : ''

  if(hint && comment){
    hint += '\n'
  }

  if(comment){
    hint += comment
  }

  return hint
}

export const defaultInvide = v =>`Hi,
Please provide feedback on ${v} goal.
`