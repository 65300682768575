import React from 'react'
import './warning-red.scss'

const WarningRed = (props: any) => {
  const { children } = props
  
  return (
    <div className='talent-warning-red simple-row small'>
      <span className='fa fa-info-circle'/>
      <div style={{width: 'calc(100% - 34px)'}}>
        <div className='simple-row talent-row'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default WarningRed