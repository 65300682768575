import { 
  AUTH_STARTING,
  AUTH_FIRST_ACCESS,
  AUTH_REGISTER_ACK,
  AUTH_REGISTER_SUCCESS,
	AUTH_USER_PROFILE,
  AUTH_FAILURE,
  AUTH_NONE,
  AUTH_LOGOUT,
  AUTH_CATAGORY,
  AUTH_INITIALLED,
  AUTH_VERSION_REFRESH,
  AUTH_ENV,
  AUTH_CLEAN_USER,
  AUTH_NO_PERMISSION, 
  AUTH_NO_ACCESS_BPS, 
  AUTH_GO_LANDING_PAGE,
  AUTH_NO_ACCESS_MAINTAIN,
  AUTH_TIMEOUT, 
  AUTH_ADAL_TIMEOUT,
  AUTH_PUBLIC_VL_REFRESH
} from 'services/action-types'
import { dispatchSimple, dispatchFetch, getCatagory, getState } from 'store'
import { isActionAsProd, cleanSettings, clearOnBehalf, buildFailHandler } from 'utils'
import { getMaintainMsg } from 'utils/value-util'
import { prompt } from 'components/common/actions'
import { getCachedToken, msalLogout } from 'services/msal-config'

// export const auth_progress = () => fetch_user_profile(auth_first_access)
// export const user_register = (value: any) => handle_auth_success({
//   ...getState().ui.auth.user,
//   winReged: 1,
//   // acknowledge: 0
// })

export const auth_progress = () => fetch_user_profile(handle_auth_success)
export const user_register = (value: any, failureCallback: any) => dispatchFetch({
  spinner: true,
  path: `api/auth/regist/${value}`,
  method: 'GET',
  success: data=>handle_auth_success({...data, winReged: 1}),
  ...buildFailHandler(failureCallback, ['E6004', 'E8010'])
})

// export const user_ack = () => handle_auth_success({
//   ...getState().ui.auth.user,
//   acknowledge: 1
// })
export const user_ack = (value: any) => dispatchFetch({
  spinner: true,
  path: `/api/tms/user/${value.indId}/ack`,
  method: 'POST',
  success: data=>handle_auth_success({...data, winReged: value.winReged})
})
export const fetch_user_profile = (callback: any) => {
  clearOnBehalf()
  dispatchFetch({
    spinner: true,
    path: `api/${getCatagory()||'auth'}/user-profile`,
    method: 'GET',
    //success: data=>callback({...data, extendManager: 1}),
    success: data=>{
      callback(data)
      resetAccessToken()
    },
    failure: auth_failure
  })
}


const resetAccessToken = () =>getCachedToken((resp: any) => dispatchFetch({
  spinner: true,
  path: `api/auth`,
  method: 'POST',
  body: `${resp.accessToken}`,
  success: ()=>{console.log('Access code is set.')}
}))

export const clean_user_profile = () => dispatchSimple({type: AUTH_CLEAN_USER})
export const env_refresh = (value: any) => dispatchSimple({type: AUTH_ENV, value})
export const fetch_env = () => dispatchFetch({
  spinner: true,
  path: `public/env`,
  method: 'GET',
  success: env_refresh
})

export const public_vl_refresh = (value: any) => dispatchSimple({type: AUTH_PUBLIC_VL_REFRESH, value})
export const fetch_public_vl = () => dispatchFetch({
  spinner: true,
  path: `public/valuelist`,
  method: 'GET',
  success: public_vl_refresh
})
export const auth_start = () => dispatchSimple({type: AUTH_STARTING})

export const auth_first_access = (value: any) => dispatchSimple({type: AUTH_FIRST_ACCESS, value})
export const auth_register_ack = (value: any) => dispatchSimple({type: AUTH_REGISTER_ACK, value})

export const auth_register_success = (value: any) => dispatchSimple({type: AUTH_REGISTER_SUCCESS, value})

export const handle_auth_success = (value: any) => {  
  if(value.firstTimeAccess){
    auth_first_access(value)
  }else if(!value.acknowledge){
  // }else if(value.acknowledge){
    auth_register_ack(value)
  }else if(value.winReged){
    auth_register_success(value)
  }else{    
    auth_success(value)
    request_value_list()    
  }
}
export const auth_success = (value: any) => dispatchSimple({type: AUTH_USER_PROFILE, value})

export const auth_failure = (value: any) => {
  cleanSettings()
  dispatchSimple({type: AUTH_FAILURE, value})
}
export const auth_catagory = (value: any) => dispatchSimple({type: AUTH_CATAGORY, value})

export const handle_auth = (catagory: any) => {
  fetch_env()
  fetch_public_vl()
  auth_catagory(catagory)
  auth_start()
  auth_progress()
}

export const clear_auth = () => {
  dispatchSimple({type: AUTH_NONE})
}
export const logout_auth = () => dispatchSimple({type: AUTH_LOGOUT})

const logout_msal = () => {
  cleanSettings()
  msalLogout()
}

const logout0 = (callback=()=>{}) => {
  clearOnBehalf()
  dispatchFetch({
    spinner: true,
    path: `api/auth/logout`,
    method: 'GET',
    success: callback,
    failure: callback
  })
}

export const logout = (callback=()=>{}) => logout0(()=>{
  callback()
  clearAndLogout()
})

export const logoutAndReload = () => logout0(()=>{
  clearAndLogout()
})

export const logoutAdalAndReload = (callback=()=>{}) => {
  clear_auth()
  logout_msal()
  if(!isActionAsProd()){
    callback()
    window.location.reload()
  }
}

export const reload = () => {
  clear_auth()
  window.location.reload()
}

export const logoutAndClose = (value: any) => {
  if(value){
    logout0(()=>{
      clearAndLogout()
    })
  }else{
    clearAndLogout()
  }
}

const clearAndLogout = ()=>{
  clear_auth()
  logout_auth()
  logout_msal()
  if(!isActionAsProd()){
    window.location.reload()
  }
}

export const init_value_list = (value: any) => dispatchSimple({type: AUTH_INITIALLED, value})
export const request_value_list = (callback: any=()=>{}) => dispatchFetch({
  spinner: true,
  path: `api/${getCatagory()||'tms'}/valuelist`,
  method: 'GET',
  success: data=>{
    init_value_list(data)
    callback(data)
  }
})
export const verify_manager_role = (callback=()=>{}) => dispatchFetch({
  spinner: true,
  path: `api/client/iswinmgr`,
  method: 'GET',
  success: callback
})

export const log_error_to_server = (value: any, callback:any=()=>{}) => dispatchFetch({
  spinner: true,
  path: `/public/upload-ui-log`, 
  method: 'POST',
  body: value,
  success: callback
})

export const refresh_latest_version = (value: any) => dispatchSimple({type: AUTH_VERSION_REFRESH, value})

export const handle_global_timeout = (value: any) => dispatchSimple({type: AUTH_TIMEOUT, value})
export const handle_msal_timeout = (value: any) => dispatchSimple({type: AUTH_ADAL_TIMEOUT, value})
export const handle_no_permission = (value: any) => dispatchSimple({type: AUTH_NO_PERMISSION, value})
export const handle_no_access_bps = (value: any) => dispatchSimple({type: AUTH_NO_ACCESS_BPS, value})
export const handle_go_landing_page = (value: any) => dispatchSimple({type: AUTH_GO_LANDING_PAGE, value})

export const handle_no_access_maintain = (value: any) => {
  const user = getState().ui.auth.user
  if(user.userId){
    prompt.error(getMaintainMsg())
  }else{
    dispatchSimple({type: AUTH_NO_ACCESS_MAINTAIN, value})
  }
}