import { createSelector } from 'reselect'

export const getOrgTree = createSelector(
   state => state.ui.search.orgTree, 
   data => data
)
export const getIndUsers = createSelector(
   state => state.ui.search.indUsers, 
   data => data
)
export const getAdUsers = createSelector(
   (state, props) => !props.vacant ? state.ui.search.adUsers : state.ui.search.adAllUsers, 
   data => data
)
export const getWinMins = createSelector(
   state => state.ui.search.winMins, 
   data => data
)
export const getSearchGoals = createSelector(
   state => state.ui.search.goals, 
   data => data
   // data => {
   //    return data.sort((a,b)=>(b.displayYear).localeCompare(a.displayYear) || (a.name).localeCompare(b.name))
   // }
)