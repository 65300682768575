import {dispatchFetch, getCatagory} from 'store'
import { buildFailHandler } from 'utils'
const thumbnail: number[] = []
const DEFAULT_THUMBNAIL = './img/default-thumbnail.jpg'

export default (()=>{
  const getThumbnail = (id: any, callback: any) =>{
    const url = `api/${getCatagory()||'tms'}/user/${id}/photo`
    if(thumbnail[id]){
      callback({id: id, image: thumbnail[id]})
    }else{
      dispatchFetch({
        path: url,
        method: 'GET',
        success: data=>{
          thumbnail[id] = data.image
          callback({id: id, image: data.image})
        },
        failure: ()=>{
          callback({id: id, image: DEFAULT_THUMBNAIL})
        }
      })
    }
  }

  const updateThumbnail = (value: any, callback: any) =>{
    dispatchFetch({
      spinner: true,
      path: `https://graph.microsoft.com/v1.0/users/${value.email}/photo/$value`,
      method: 'GET',
      type: 'GRAGH',
      success: data=>{
        thumbnail[value.id] = data
        callback({id: value.id, image: data})
        pushThumbnail(value.id, data)
      },
      ...buildFailHandler((data: any)=>console.log(data), [404])
    })    
  }

  const pushThumbnail = (id: any, value: any) =>{
    const url = `api/${getCatagory()||'tms'}/user/${id}/photo`
    dispatchFetch({
      path: url,
      method: 'PUT',
      body: value,
      success: data=>data
    })}
  return ({
    getThumbnail,
    updateThumbnail
  })
})()