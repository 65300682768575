import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { MsalProvider } from "@azure/msal-react"
import * as serviceWorker from './serviceWorker'
import 'utils/polyfill'
import Init from './init'
import { msalSetup } from 'services/msal-config'
import { setTmsStartPage } from 'utils/routing-util'
import { fetch_msal_config } from './action'
import { Config } from 'types'

const render = () => {
    try {
        setTmsStartPage()

        const container = document.getElementById('root') as HTMLElement
        const root = createRoot(container)

        const errorHandler = (error: any)=>root.render(<Init error={error}/>)
        const mockAppHandler = (data: Config) =>root.render(<Init config={data}/>)
        const prodAppHandler = (data: Config) =>msalSetup(data).then((instance:any)=>root.render(
            <MsalProvider instance={instance}><Init config={data}/></MsalProvider>
        ), errorHandler)

        fetch_msal_config((data: Config)=> !data.actionAs ? mockAppHandler(data) : prodAppHandler(data), errorHandler)        
    } catch (err) {
        console.error(err)
    }
}

render()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
