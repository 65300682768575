import {
  AUTH_USER_PROFILE,
  AUTH_ENV,
  NAV_CATA,
  NAV_SKIP_TO,
  AUTH_CLEAN_USER,
  EXTEND_MANAGER_WIZARD_REFRESH,
  TALENT_RSLT_WIZARD_REFRESH,
  PERFORMANCE_NEW_PLAN_REFRESH
} from 'services/action-types'
// import { setOnBehalf } from 'utils'
// import { PATH_ID_PROFILE } from 'services/constants';
const init_user = {
  minScope: [],
  // recruiter: 0
}
const initialState = {
  user: init_user,
  id: '',
  skipTo: [],
  env: ''
}

export const navReducer =  (state = initialState, action) => {
  switch (action.type) {
  case AUTH_USER_PROFILE:
    return {
      ...state, 
      user: {
        ...action.value, 
        // recruiter: action.value.minScope.some(e=>e.roleName==='RECRUITER'),
        // minAdmin: action.value.corpAdmin === 0 && action.value.minScope.length > 0
      }
    }
  case AUTH_ENV:
    return { ...state, 
      env: action.value
    }    
  case NAV_CATA:
    return {
      ...state, 
      id: action.value
    }
  case NAV_SKIP_TO:
    return {
      ...state, 
      skipTo: action.value
    }
  case AUTH_CLEAN_USER:
    return { ...state, user: init_user}
  case EXTEND_MANAGER_WIZARD_REFRESH:
    return { 
      ...state, 
      user: {...state.user, extTeamWizard: action.value}
    }
  case TALENT_RSLT_WIZARD_REFRESH:
    return { 
      ...state, 
      user: {...state.user, rsListWizard: action.value}
    }
  case PERFORMANCE_NEW_PLAN_REFRESH:
    return { 
      ...state, 
      user: {...state.user, praplanNewUIAck: action.value}
    }
  default:
    return state
  }
}