import { DOWNLOAD_TOGGLE_REFRESH, DOWNLOAD_WAITING_REFRESH, DOWNLOAD_TRIGGER_REFRESH, DOWNLOAD_START_REFRESH, DOWNLOAD_CHECK_REFRESH, 
  DOWNLOAD_PROCESS_REFRESH, DOWNLOAD_DELETE_REFRESH, DOWNLOAD_REMOVE_REFRESH, DOWNLOAD_BRANDNEW_REFRESH, DOWNLOAD_FINISHED_REFRESH } from 'services/action-types'
import { saveFileAs } from 'utils'
import { STATUS_START, STATUS_END, STATUS_FAILED, STATUS_TIMEOUT, getMaxTimes } from './services'

const initialState = {
  waitingList: [],
  failedList: [],
  current: {},
  toggle: false,
  brandNew: {},
  finished: {}
}

export const download_reducer= (state = initialState, action: any) => {
  switch (action.type) {
  case DOWNLOAD_TOGGLE_REFRESH:
    return { 
			...state, 
			toggle: !state.toggle
		}
  case DOWNLOAD_WAITING_REFRESH:
    return { 
			...state, 
      waitingList: [action.value, ...state.waitingList],
      brandNew: action.value
		} 
  case DOWNLOAD_BRANDNEW_REFRESH:
    return { 
			...state, 
      brandNew: action.value
		} 
  case DOWNLOAD_FINISHED_REFRESH:
    return { 
      ...state, 
      finished: action.value
    }    
  case DOWNLOAD_REMOVE_REFRESH:
    return { 
      ...state, 
      waitingList: [...state.waitingList].filter((r: any)=>r.id !== action.value.id),
      failedList: [...state.failedList].filter((r: any)=>r.id !== action.value.id)
    }
  case DOWNLOAD_TRIGGER_REFRESH:
    return addToDownload(state)
  case DOWNLOAD_START_REFRESH:
    return { 
			...state, 
      current: {
        ...state.current, 
        progress: 0,
        reportId: action.value.reportId,
        status: STATUS_START,  
      }
		}
	case DOWNLOAD_CHECK_REFRESH:
		return updateStatus(state, action)
	case DOWNLOAD_PROCESS_REFRESH:
		saveFileAs(action.value.data, action.value.filename)
		return { 
			...state, 
      current: {
        ...state.current, 
        status: STATUS_END,  
      }
    }
  case DOWNLOAD_DELETE_REFRESH:
    return { 
      ...state, 
      current: {}
    }
  default:
    return state
  }
}

const addToDownload = (state: any) => {
  const _state = {...state}
  if(!_state.current.id && _state.waitingList.length > 0){
    _state.current = {
      ..._state.waitingList.pop(),
      status: '',
      progress: 0
    }
  }
  return _state
}

const updateStatus = (state: any, action: any) => 
  state.current.progress >= getMaxTimes() ?
  { 
    ...state, 
    current: {},
    finished: { ...state.current, status: STATUS_FAILED },
    failedList: [{ ...state.current, progress: 0, status: STATUS_FAILED }, ...state.failedList]
  }
  :
  action.value.status === STATUS_FAILED || action.value.status === STATUS_TIMEOUT ?
  { 
    ...state, 
    current: {},
    finished: { ...state.current, status: action.value.status },
    failedList: [{ ...state.current, progress: 0, status: action.value.status }, ...state.failedList]
  }
  :
  { 
    ...state, 
    current: { ...state.current, progress: state.current.progress + 1, status: action.value.status }
  }
  