import { getEmployeeGroupValues } from 'utils'

export const buildPlansTree = plans => {
  const tree = {}
  plans.map(s =>{
    const indId = s.indId
    if(tree[indId]){
      const _position = tree[indId].plans.filter(p=>p.planId === s.planId)[0]
      if(_position){
        _position.positions.push(s)
      }else{
        tree[indId].plans.push({...s, positions: [s]})
      }      
    }else{
      tree[indId]={ ...s, plans:[{...s, positions: [s]}] }
    }
    return tree
  })
  return Object.keys(tree).map(e=>tree[e]).sort((a,b)=>(a.firstName+a.lastName).localeCompare(b.firstName+b.lastName))
}

export const getGroupValues = () => [{label: 'All', code: '0'}, ...getEmployeeGroupValues()]