import { createSelector } from 'reselect'
import { getAccessMode, getNavUser } from 'components/selectors'
import { 
   PLAN_STATUS_OPEN, PLAN_STATUS_DRAFT, PLAN_STATUS_MGR_RATE, PLAN_STATUS_MIN_RATE, PLAN_STATUS_CORP_RATE, PLAN_STATUS_MGR_COMM, 
   PLAN_STATUS_COMPLETE, PLAN_STATUS_YEAR_CLOSE, PLAN_STATUS_INCOMP, 
   RATING_STEP_IND_INIT, RATING_STEP_MGR_START, RATING_STEP_MGR_FACTOR, RATING_STEP_MGR_SHARE } from 'services/constants'
import { getPlan, getGoals, getPlanType } from '../performance/selectors'
import { sumGoals, enableToNext } from './util'

export const getRatingStep = createSelector(
   state => state.ui.rating.step, 
   data => data
)
export const getRatingDetail = createSelector(
   [
      state => state.ui.rating.detail,
      state => getPlan(state),
      state => getPlanStage(state)
   ], 
   (data, plan, stage) => {
      const rd = {...data, formula: plan.formula}
      if(plan.type?.code === 'NO_GOAL' && stage.yearClose){
         rd.details = []
         // rd.finalThought = ''
         rd.onBehalf = 3
      }
      return rd
   }
)

export const getRatingCalRate = createSelector(
   [
      state => state.ui.rating.detail.calRate,
      state => getPlan(state),
      state => getPlanStage(state)
   ], 
   (data, plan, stage) => {
      return plan.type?.code === 'NO_GOAL' && stage.yearClose ? {code: 'SELECT'} : data
   }
)

export const getRatingDetailOrigin = createSelector(
   [
      state => state.ui.rating.detailO, 
      state => getPlan(state)
   ],
   (data, plan) => ({...data, formula: plan.formula})
)

export const getRatingList = createSelector(
   state => getPlan(state), 
   data => data.planRatingList
)

// beforeRatingSeason: 1
// startRatingSeason: 0       Flip#1
// minFrozenRateData: 0       Flip#2
// minStartCalibration: 0     Flip#3
// minFinalizeCalibration: 0  Flip#4
// corpFinalizeCalibration: 0 Flip#5
// corpPublishRating: 0       Flip#6
// yearClose: 0               Flip#7
export const getPlanStage= createSelector(
   state => getPlan(state), 
   data => data.stageStatus
)
export const getRatingStarted = createSelector(
   [
      state => getAccessMode(state),
      state => getPlan(state),
      state => getRatingStep(state),
      state => getRateReject(state)
   ],
   (accessMode, plan, step, rateReject) => {
      if(plan.type.code !== 'RATING'){
         return false
      }
      if(step > RATING_STEP_IND_INIT){
         return true
      }

      const status = plan.status.code
      if(!status){
         return false
      }
      if(accessMode.ownerRating){
         if(status !==PLAN_STATUS_OPEN && status !==PLAN_STATUS_DRAFT){
            return true
         }
         if(plan.planRatingList.length > 0){
            return true
         }
         if(rateReject && status ===PLAN_STATUS_OPEN){
            return true
         }
      }
      
      if(accessMode.managerRating){ 
         if(status !==PLAN_STATUS_OPEN && status !==PLAN_STATUS_DRAFT){
            return true
         }
         if(rateReject){
            return true
         }
      }

      return false
   }
)
export const getIndRating= createSelector(
   state => getRatingList(state), 
   data => data.filter(e=>e.type.code === 'EMPL')[0] || {
      submitBy: {}
   },
)

export const getRateReject= createSelector(
   state => getRatingList(state), 
   data => (data.filter(e=>e.rateReject)[0] || {}).rateReject,
)
export const getGoalSummary = createSelector(
   state => getGoals(state), 
   data => sumGoals(data),
)
export const getGoalUpdates = createSelector(
   state => state.ui.rating.updates, 
   data => data,
)
export const getRatingEdit = createSelector(
   state => state.ui.rating.edit, 
   data => data,
)
export const getRatingAudit = createSelector(
   state => state.ui.rating.audit, 
   data => data,
)

export const getStepEnable = createSelector(
   [
      state => getPlan(state),
      state => getRatingDetailOrigin(state),
      state => getRatingDetail(state)
   ],
   (plan, detailO, detail) => {
      const status = plan.status.code
      const stepEnable={}
      stepEnable[RATING_STEP_MGR_START] = true
      
      if(status === PLAN_STATUS_MIN_RATE || status === PLAN_STATUS_CORP_RATE || status === PLAN_STATUS_MGR_COMM || status === PLAN_STATUS_COMPLETE
         || status === PLAN_STATUS_YEAR_CLOSE || status === PLAN_STATUS_INCOMP){
         stepEnable[RATING_STEP_MGR_FACTOR] = true
         stepEnable[RATING_STEP_MGR_SHARE] = true
      }else if(status === PLAN_STATUS_MGR_RATE){
         stepEnable[RATING_STEP_MGR_FACTOR] = enableToNext(detail, RATING_STEP_MGR_START)
         stepEnable[RATING_STEP_MGR_SHARE] = enableToNext(detail, RATING_STEP_MGR_START) && enableToNext(detail, RATING_STEP_MGR_FACTOR)
      }
      return stepEnable
   }
)
export const getSelf = createSelector(
   [
      state => getPlan(state),
      state => getNavUser(state)
   ],
   (plan, user) => plan.ind.id === user.userPK
)

export const getPlanMinFinal = createSelector(
   [
      state => getAccessMode(state),
      state => getPlanStage(state),
      state => getPlanType(state)
   ],
   (accessMode, stage, type) => {
      if(stage?.yearClose){
         return true
      }
      if(type.code ==='REVIEW'){
         return false
      }
      return accessMode.min && stage?.minFinalizeCalibration
   }
)