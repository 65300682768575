import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { message3_hide_refresh } from './actions'
import InlineMessage from './inline'
import { MentorServicePops} from './pops'
import { getMessage3 } from './selector'

export enum MTServiceMsg{
  init = 'init',
  unavailable = 'unavailable',
}
class propsType{
  content?: any;
  pop?: boolean;
}
const Message3 = (props: propsType) =>{
  const { content, pop } = props
  const modalRef: any = useRef({})
  useEffect(() => {
    if(pop){
      if(content?.pop){
        modalRef.current.show(content)
      }else{
        modalRef.current.hide()
      }
    }
  }, [content, pop])

  const onClose = () => {
    modalRef.current.hide()
    message3_hide_refresh(content)
  }

  return (<>
    {!!!content.msgCode || content?.pop || pop ? <span/>:<InlineMessage content={content}/>}
    <MentorServicePops ref={modalRef} onClose={onClose}/>
  </>)
}
const mapStateToProps = (state: any, ownProps: any) =>({
	content: getMessage3(state)
})

export default connect(mapStateToProps, {})(Message3)