import React from 'react'
import { DropDownMenu, DropDownItem } from 'components/common'
import './style.scss'

const CriteriaSort = (props: any) =>{
  const { sortValue, sortValues, onSort} = props

  const selected = sortValues.filter((s: any)=>s.sortLabel === sortValue.sortLabel)[0] || sortValues[0]
  return (
    <DropDownMenu menuRole='listbox' pullRight className='list-sort' display={<div className='display'>
        <span className='label'>Sort: {selected.sortLabel}</span>
        <span className='fa fa-angle-down'/>
      </div>}>
      {sortValues.map((s: any,i: number)=>
        <DropDownItem key={i} selected={selected.sortLabel===s.sortLabel} onClick={()=>onSort(s)}>{s.sortLabel}</DropDownItem>)}
    </DropDownMenu>)
}
export default CriteriaSort