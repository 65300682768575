import React, { useState } from 'react'
import { ShowMore } from '.'
import MoreBase from './more-base'

const More2 = (props: any) =>{
  const { minHeight = 75, children } = props
  const [ expanded, setExpanded ] = useState(false)
  return (
    <MoreBase minHeight={minHeight} expanded={expanded} content={children}>
      <ShowMore onClick={setExpanded}></ShowMore>
    </MoreBase>
  )
};

export default More2;