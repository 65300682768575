import React, { useState, useRef } from 'react'
import { Span } from '.'
import MoreBase from './more-base'
import './more.scss'

const More = (props: any) =>{
  const { minHeight = 75, children } = props
  const [ expanded, setExpanded ] = useState(false)
  const wrapRef: any = useRef()

  const onClick = (event: any) =>{
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    const _expanded = !expanded
    setExpanded(_expanded)
    if(!_expanded){
      setTimeout(() => {
        wrapRef.current?.scrollIntoView && wrapRef.current.scrollIntoView(false)
      }, 200)
    }
  }
  
  return (
    <MoreBase minHeight = {minHeight} expanded={expanded} content={children}>
      <Span aria-hidden='true' ref={wrapRef} className={`more-label ${expanded ? 'less':''}`} onClick={onClick}>
        {`Show ${!expanded ? 'more' : 'less'}`}
      </Span>
    </MoreBase>
  )
};

export default More;