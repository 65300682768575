import {
    ADMIN_UNMATCHED_WIN_LIST_REFRESH,
    ADMIN_UNMATCHED_WIN_REFRESH,
    ADMIN_UNMATCHED_WIN_EMAIL_REFRESH
} from 'services/action-types'

const initialState = {
    winList: []
}

export const unmatchedWinsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_UNMATCHED_WIN_LIST_REFRESH:
            return state = {
                ...state,
                winList: action.value
            }
        case ADMIN_UNMATCHED_WIN_REFRESH:
            return state = {
                ...state,
                winList: {
                    ...state.winList,
                    items: state.winList.items.map(e => e.indId == action.value.indId ? {...e, person:action.value.person}: e)
                }
            }
        case ADMIN_UNMATCHED_WIN_EMAIL_REFRESH:
            return state = {
                ...state,
                winList: {
                    ...state.winList,
                    items: state.winList.items.map(e => e.indId == action.value.indId ? {...e, email:action.value.email}: e)
                }
            }
        default:
            return state
    }
}