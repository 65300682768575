import { 
    ADMIN_VALUE_LIST_TYPES_REFRESH,
    ADMIN_VALUE_LIST_VALUES_REFRESH,
    ADMIN_VALUE_LIST_VALUE_REFRESH,
    ADMIN_VALUE_LIST_DETAIL_VALUES_REFRESH
  } from 'services/action-types'

const initialState = {
    types: [],
    values: [],
    value: {},
    detailValues: []
}

export const valuelistReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_VALUE_LIST_TYPES_REFRESH:
    return state = { 
      ...state, 
      types: action.value,
      values: [],
      value: {}
    }
  case ADMIN_VALUE_LIST_VALUES_REFRESH:
    return state = { 
      ...state, 
      values: action.value,
      value: {}
    }
  case ADMIN_VALUE_LIST_VALUE_REFRESH:
    const values = [...state.values]
    const index = values.map(g=>g.id).indexOf(action.value.id)
    index>=0 && (values[index] = action.value)
    return state = { 
      ...state, 
      values,
      value: action.value,
      detailValues: []
    }
  case ADMIN_VALUE_LIST_DETAIL_VALUES_REFRESH:
    return state = { 
      ...state, 
      detailValues: action.value
    }
  default:
      return state
  }
}