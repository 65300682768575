import { createSelector } from 'reselect'
import { VALUE_PLAN_TYPE_NO_FINAL } from 'services/constants'
import { getPlanStatusOpen } from 'utils'
import { buildYearTree, buildAcksTree } from './util'
import { getPlanStatus, getPlan } from './plan/selectors'

export const getPlanYears = createSelector(
   state => state.ui.performance.years, 
   years => buildYearTree(years)
)
export const getPlanYearsInd = createSelector(
   state => state.ui.performance.yearsInd, 
   data => data
)
export const getSearchPlanYears = createSelector(
   state => state.ui.performance.searchYears, 
   searchYears => buildYearTree(searchYears)
)

export const getAssMaps = createSelector(
   state => state.ui.performance.plan.assMaps,
   data => data
)
export const getAcks = createSelector(
   state => state.ui.performance.plan.acks, 
   data => buildAcksTree(data)
)
export const getPlanType = createSelector(
   state => state.ui.performance.plan.type, 
   data => data
)
export const getExpanded = createSelector(
   state => state.ui.performance.expanded, 
   data => data
)

export const getAccessMode = createSelector(
   [
      state => state.ui.performance.accessMode,
      state => getPlan(state)
   ], 
   (mode, plan) => {
      return {
         ...mode, 
         ownerRating: (mode.owner || mode.corp || mode.min) && !mode.adminRating && !mode.manager,
         managerRating: mode.manager || mode.adminRating,
         noGoal: plan.type?.code === 'NO_GOAL',
         noFinal: plan.type?.code === VALUE_PLAN_TYPE_NO_FINAL,
      }
   }
)
export const getManager = createSelector(
   state => state.ui.performance.plan.manager, 
   data => data
)
export const getActiveTab = createSelector(
   state => state.ui.performance.activeTab, 
   data => data
)
export const getPlanLoading = createSelector(
   state => state.ui.performance.planLoading, 
   data => data
)
export const getGoalFilter = createSelector(
   state => state.ui.performance.filter, 
   data => data
)
const getToOpenPlanYears = createSelector(
   state => state.ui.performance.searchYears, 
   data => data.filter(y=>getPlanStatusOpen().some(s=>`${s.id}` === `${y.value}`))
)

export const getToPlanYears = createSelector(
   state => getToOpenPlanYears(state), 
   toYears => buildYearTree(toYears)
)
export const getStageStatus = createSelector(
   state => state.ui.performance.plan.stageStatus, 
   data => data
)
export const getEnableAck = createSelector(
   [
      state => getPlanStatus(state),
      state => getPlanType(state),
      state => getAcks(state),
      state => getAccessMode(state),
      state => getStageStatus(state)
   ], 
   (status, type, acks, accessMode, stage) => {
      if(stage?.yearClose === 1){
         return false
      }
      if(status.code === 'DRAFT'){
         return true
      } 
      if(type.code ==='REVIEW' 
         && acks.filter(a=>!a.checked).length > 0 
         && !accessMode.hra && !accessMode.manager){
         return true
      }
      return false
   }
)
export { getPlanStatus, getPlan, getGoals } from './plan/selectors'

export const getPopGoal = createSelector(
   state => state.ui.performance.popGoal, 
   data => data
)
export const getPopShadowGoal = createSelector(
   state => state.ui.performance.popShadowGoal, 
   data => data
)
export const getGoalStep = createSelector(
   state => state.ui.performance.goalStep, 
   data => data
)

export const getOriginalGoal = createSelector(
   state => state.ui.performance.originalGoal, 
   data => data
)
export const getGoalVerifies = createSelector(
   state => state.ui.performance.verifies, 
   data => data
)
export const getGoalBanners = createSelector(
   state => state.ui.performance.banners, 
   data => data
)
export const getUnsaveCheck = createSelector(
   state => state.ui.performance.unsaveCheck, 
   data => data
)
export const getPopErrors = createSelector(
   state => state.ui.performance.popErrors, 
   data => data
)
export const getReplyAdding = createSelector(
   state => state.ui.performance.replyAdding, 
   data => data
)
