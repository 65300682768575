import { 
  RESOURCE_LOCK_APPLIED, 
  RESOURCE_LOCK_RELEASE,
  RESOURCE_LOCK_REFRESH, 
  RESOURCE_LOCK_TIMEOUT_START, 
  RESOURCE_LOCK_RESET,
  RESOURCE_LOCK_KEEPING_CONFIRM,
  RESOURCE_LOCK_KEEPING_REFRESH,
  RESOURCE_LOCK_TIMEOUT_END,
  RESOURCE_LOCK_TIMEOUT_CLOSE,
  RESOURCE_LOCK_INVALID 
} from 'services/action-types'
import { LOCK_KEEP_TIME } from 'services/constants'
import { getValueByCode } from 'utils'

const initialState = {
  body: {},
  remain: 1,
  showTimeout: undefined,
  acquireSuccess: undefined,
  keepTime: LOCK_KEEP_TIME,
  timeoutFunc: ()=>{}
}

export const lock_reducer= (state = initialState, action: any) => {
  switch (action.type) {
	case RESOURCE_LOCK_APPLIED:    
		return { 
			...state, 
			body: action.value,
      acquireSuccess: action.value.acquireSuccess,
      timeoutFunc: action.value.timeoutFunc
		}
  case RESOURCE_LOCK_RELEASE:
    return { 
      ...state,
      acquireSuccess: undefined
    }
  case RESOURCE_LOCK_REFRESH:
    return { 
      ...state, 
      remain: state.remain > 1 ? state.remain - 1 : 0 
    }
  case RESOURCE_LOCK_RESET:
    return { 
      ...state, 
      remain: getLockTimeOut()
    }
  case RESOURCE_LOCK_KEEPING_REFRESH:
    return { 
      ...state, 
      keepTime: state.keepTime - 1,
    } 
  case RESOURCE_LOCK_KEEPING_CONFIRM:
    return { 
      ...state,
      showTimeout: undefined,
      keepTime: LOCK_KEEP_TIME
    }
  case RESOURCE_LOCK_TIMEOUT_START:
    return { 
      ...state, 
      showTimeout: state.acquireSuccess,
    }
  case RESOURCE_LOCK_TIMEOUT_END:
    return { 
      ...state,
      keepTime: LOCK_KEEP_TIME
    }
  case RESOURCE_LOCK_TIMEOUT_CLOSE:
    return { 
      ...state, 
      showTimeout: undefined,
      acquireSuccess: undefined
    }
  case RESOURCE_LOCK_INVALID:
    return { 
      ...state, 
      acquireSuccess: 0
    }
  default:
    return state
  }
}

const getLockTimeOut = () => (getValueByCode('APP_ADMIN', 'RES_LOCK_TIMEOUT')?.value || 300) - 0