import React from 'react'
import { OdsImage } from 'components/common'
import './style.scss'

interface propsType{
  onClick?: any;
  inEditing?: boolean;
  onBlur?: any;
}

const EditButton = (props: propsType) =>{
  const { onClick, inEditing, onBlur} = props

  return (inEditing ?
    <OdsImage onBlur={onBlur} image={<span aria-hidden='true' className='fa fa-check'></span>} aria-label='Click to save' onClick={onClick} className='edit-button'>
      <div className='resizer-boder'/>
    </OdsImage>
    :
    <OdsImage onBlur={onBlur} href='ontario-icon-edit' aria-label='Click to edit' onClick={onClick} className='edit-button'>
      <div className='resizer-boder'/>
    </OdsImage>
  )
}
export default EditButton