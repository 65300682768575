import React, { useState, useEffect, forwardRef, useRef } from 'react'
// @ts-ignore
import autosize from 'autosize'
import newId from 'utils/new-id'
import CharCounter from './character-count'
import Scrollbar from './scroll-bar'
import ResizeDetector from './resize-detector'

import './textarea.scss'
import { Key } from 'utils'

export default forwardRef((props:any, ref) =>  {
  const {children, onChange=()=>{}, value, label, labelledBy, required, maxLength, describedBy, style, onBlur=()=>{}, onKeyDown=()=>{}, 
    // overlimit=false, 
    ...rest} = props
    const overlimit=false
  const {maxHeight, height, ...restStyle } = style
  const [ id, setId ] = useState('')
  const [ focused, setFocused ] = useState(false)
  const [ maxLengthId, setMaxLengthId ] = useState('')
  const __ref = useRef({})
  const defaultRef:any = ref|| __ref, refcc:any = useRef({})
  
  useEffect(() => { 
    setTimeout(() => {
      autosize(defaultRef.current)
    }, 100)
  }, [])
  
  useEffect(() => { 
    setTimeout(() => {
      autosize.update(defaultRef.current)
    }, 100)
  }, [value])
  

  useEffect(() => { 
    setId(newId())
    setMaxLengthId(newId())
  }, [defaultRef])

  useEffect(() => {
    return () => {
      autosize.destroy(defaultRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFocus = () => {
    setFocused(true)
    refcc.current.focus()
  }
  
  const handleBlur = (event:any) => {
    onBlur(event)
    setFocused(false)
  }

  const handlePaste = () => {
    setTimeout(()=> {
      autosize.update(defaultRef.current)
    })
  }

  const onResize = () => autosize.update(defaultRef.current)

  const onClick = () => !focused && defaultRef.current.focus()

  const _onKeyDown = (event:any) => {
    if(event.keyCode !== Key.ESCAPE){
      event.stopPropagation()
    }
    onKeyDown(event)
  }
  
  return <>
    {!!label && <label className='sr-only' htmlFor={id}>{label}</label>}
    <div className={`autosize-textarea ${focused ? 'focused': ''}`} style={{height: maxHeight || height, backgroundColor: style.backgroundColor}} onClick={onClick}>
      <Scrollbar hidearrow='true'>
        <span className='sr-only' id={maxLengthId}>{maxLength ? `max is ${maxLength} characters`: ''}</span>
        <textarea aria-labelledby={`${labelledBy} ${maxLengthId}`} aria-required={required} 
          value={value} onFocus={onFocus} onKeyDown={_onKeyDown} onBlur={handleBlur} onChange={onChange} 
          {...(describedBy && {'aria-describedby': describedBy})}
          {...rest} onPaste={handlePaste} {...restStyle} ref={defaultRef} id={id} maxLength={overlimit ? 0 : maxLength}>
          {children}   
        </textarea>
        <ResizeDetector onResize={onResize}/>   
      </Scrollbar>
    </div>
    <CharCounter ref={refcc} focused={focused} size={maxLength} value={props.value}/>
  </>
})