import {
  ACTION_IND_REFRESH,
  ACTION_AS_IND_REFRESH
} from 'services/action-types'

const initialState = {
  status: 'starting',
  actionInd: {},
  actionAsInd: {}
}

export const homeReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ACTION_IND_REFRESH:
    return {
      ...state, 
      actionInd: action.value
    }
  case ACTION_AS_IND_REFRESH:
    return {
      ...state, 
      actionAsInd: action.value
    }
  default:
    return state
  }
}