
export enum MessageSeverity{ 
    INFO = 0,
    WARNING = 1,
    ERROR = 2,
}
export class Message{
    id: number = 0;
    icon?: any;
    severity: MessageSeverity = MessageSeverity.INFO;
    code?: string;
    content?: string;
}