import './toggle-button.scss'

interface propsType{
  children?: any;
  image?: any;
  onClick?: any;
  className?: any;
  href?: any;
  ariaLabel?: string;
}

const ToggleButton = (props: propsType) =>{
  const { children, image, className, href, ...rest } = props

  const _image = image || <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
    <use href={`#${href}`}></use>
  </svg>
  
  const pps = {className: `ontario-button toggle-button ${className} ${href}`, ...rest}

  return (<button {...pps}>{_image}{children}</button>)
}
export default ToggleButton