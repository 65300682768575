import { combineReducers } from 'redux'
import {commonReducer as common} from './common/reducer'
import {profileReducer as profile} from './profile/reducer'
import {mentorReducer as mentor} from './mentor/reducer'
import {performanceReducer as performance} from './performance/reducer'
import {ratingReducer as rating} from './rating/reducer'
import {reviewReducer as review} from './review/reducer'
import {teamReducer as team} from './team/reducer'
import {extendReducer as extend} from './extend/reducer'
import {goalLibReducer as goallib} from './goallib/reducer'

import {talentReducer as talent} from './talent/reducer'
import {feedbackReducer as feedback} from './feedback/reducer'
import {adminReducer as admin} from './admin/reducer'
import {navReducer as nav} from './navbar/reducer'
import {homeReducer as home} from './home/reducer'
import {authReducer as auth} from './auth/reducer'
import {searchReducer as search} from './search/reducer'
import {successorReducer as successor} from './successor/reducer'
export const componentsReducer = combineReducers({
  home,
  nav,
  auth,
  profile,
  mentor,
  performance,
  rating,
  review,
  team,
  extend,
  goallib,
  talent,
  feedback,
  admin,
  common,
  search,
  successor
})
