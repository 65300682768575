import {
    VALUE_TYPE_SKILL_CATE,
    VALUE_TYPE_SKILL,
    VALUE_TYPE_NEXTROLE_STATUS,
    VALUE_TYPE_NEXTROLE_GROUP,
    VALUE_TYPE_NEXTROLE_INTEREST,
    VALUE_TYPE_CERT_NAME,
    VALUE_TYPE_CERT_ORG,
    VALUE_TYPE_LANG,
    VALUE_TYPE_LANG_PROF,
    VALUE_TYPE_GOAL_TYPE,
    VALUE_TYPE_IMPROVE_GOAL_REASON,
    VALUE_TYPE_IMPROVE_GOAL_CONV,
    VALUE_TYPE_GOAL_STATUS,
    VALUE_TYPE_PPLAN_ACK,
    VALUE_TYPE_ORG_TYPE,
    VALUE_TYPE_UPDATE_TYPE,
    VALUE_TYPE_UPDATE_STATUS,
    VALUE_TYPE_UPDATE_TAG,
    VALUE_TYPE_GOAL_LEARN_ACT,
    VALUE_TYPE_GOAL_LEARN_ROLE,
    VALUE_TYPE_GOAL_FEEDBACK_REL,
    VALUE_TYPE_GOAL_CLS_REASON,
    VALUE_TYPE_IMP_GOAL_CLS_REASON,
    VALUE_TYPE_ROLE,
    VALUE_TYPE_NEXTROLE_JLEVEL,
    VALUE_TYPE_NEXTROLE_JCLAZ,
    VALUE_TYPE_FEEDBACK_MGR_REL,
    VALUE_TYPE_FEEDBACK_EMP_REL,
    VALUE_TYPE_MODAL_TOOLTIP,
    VALUE_TYPE_EXP_OPS_MIN,
    VALUE_TYPE_RATING_CONTINUE,
    VALUE_TYPE_RATING_ACK,
    VALUE_TYPE_RATING_NOCOV_REASON,
    VALUE_TYPE_RATING_FACTOR,
    VALUE_TYPE_RATING_CALIBRATION,
    VALUE_RATING_FACTOR_NORMAL,
    VALUE_RATING_FACTOR_CAL_CALIB,
    VALUE_RATING_FACTOR_OVR_CALIB,
    VALUE_TYPE_RATING,
    VALUE_TYPE_PLAN_TYPE,
    VALUE_CURRENT_YEAR,
    VALUE_TYPE_PLAN_STATUS,
    VALUE_TYPE_NO_GOAL_REASON,
    VALUE_TYPE_ADMIN_REPORT,
    VALUE_TYPE_RS_SEARCH_AUTOCOMP,
    VALUE_TYPE_RS_SEARCH,
    VALUE_TYPE_RS_LIST_ACCESS,
    VALUE_TYPE_RS_ACCESS_LEVEL,
    VALUE_TYPE_RS_LIST_TYPE,
    VALUE_TYPE_RS_MEMB_ASSM,
    VALUE_TYPE_RS_MEMB_INFORM,
    VALUE_TYPE_RS_MEMB_ACCEPT,
    VALUE_TYPE_RS_EXTRS_FTYPE,
    VALUE_TYPE_REVIEW_TYPE,
    VALUE_TYPE_REVIEW_CONTINUE,
    VALUE_TYPE_COMPENSATION_GROUP,
    VALUE_TYPE_BEN_STATUS,
    VALUE_TYPE_EMPLOYEE_GROUP,
    VALUE_TYPE_UI_BLOCK,
    VALUE_TYPE_PLAN_REVIEW_STATUS,
    VALUE_TYPE_GOAL_LIB_DEPLOY_TYPE,
    VALUE_TYPE_MENTOR_TYPE,
    VALUE_TYPE_MENTOR_ACK,
    VALUE_TYPE_MT_SEARCH_AUTOCOMP,
    VALUE_TYPE_MT_SEARCH,
    VALUE_TYPE_GL_RESULT
} from 'services/constants'
import { tryParseJSON } from './json-util'
import { stringFormat } from './text-util'

const valueList = []
const valueList_public = []
const valueList_private = []
export const initValueList = values => {
    valueList.length = 0
    valueList_public.length = 0
    valueList_public.push(...values.sort((a,b)=>a.displayOrder - b.displayOrder))
    valueList.push(...valueList_public)
}
export const appendValueList = values => {
    valueList.length = 0
    valueList_private.length = 0
    valueList_private.push(...values.sort((a,b)=>a.displayOrder - b.displayOrder))    
    valueList.push(...valueList_public, ...valueList_private)
}
export const getValueList = () => valueList
export const getMessage = msgCode => (valueList.messages.filter(e=>e.code===msgCode)[0] || {message: 'Invalid message'}).message
export const getValuesByType = type => valueList.filter(e=>e.type===type)
export const getValuesByTypes = types => {
    const values = []
    types.map(t=>values.push(...getValuesByType(t)))
    return values
}
export const getSkillCatagoryValues = () => getValuesByType(VALUE_TYPE_SKILL_CATE)
export const getSkillValues = () => getValuesByType(VALUE_TYPE_SKILL)
export const getNextRoleStatusValues = () => getValuesByType(VALUE_TYPE_NEXTROLE_STATUS)
export const getNextRoleGroupValues = () => getValuesByType(VALUE_TYPE_NEXTROLE_GROUP)
export const getNextRoleInterestValues = () => getValuesByType(VALUE_TYPE_NEXTROLE_INTEREST)
export const getCertificateNameValues = () => getValuesByType(VALUE_TYPE_CERT_NAME)
export const getCertificateOrgValues = () => getValuesByType(VALUE_TYPE_CERT_ORG)

export const getLanguageValues = () => getValuesByType(VALUE_TYPE_LANG)
export const getLanguageProValues = () => getValuesByType(VALUE_TYPE_LANG_PROF)
export const getGoalTypeValues = () => getValuesByType(VALUE_TYPE_GOAL_TYPE)
export const getGoalStatusValues = () => getValuesByType(VALUE_TYPE_GOAL_STATUS)
export const getGoalImpReasonValues = () => getValuesByType(VALUE_TYPE_IMPROVE_GOAL_REASON)
export const getGoalImpConvValues = () => getValuesByType(VALUE_TYPE_IMPROVE_GOAL_CONV)

export const getPPlanAckValues = () => getValuesByType(VALUE_TYPE_PPLAN_ACK)
export const getOrgTypeValues = () => getValuesByType(VALUE_TYPE_ORG_TYPE)

export const getUpdateTypeValues = () => getValuesByType(VALUE_TYPE_UPDATE_TYPE)
export const getUpdateStatusValues = () => getValuesByType(VALUE_TYPE_UPDATE_STATUS)
export const getUpdateTagValues = (parentId, formula) => {
    const fcode = getFormulaCode(formula)
    return getValuesByType(VALUE_TYPE_UPDATE_TAG).filter(t=>t.parentId === parentId && JSON.parse(t.jsonData)?.formula===fcode)
    .sort((a,b)=>a.displayOrder-b.displayOrder)
}
export const getGoalLearnActValues = () => getValuesByType(VALUE_TYPE_GOAL_LEARN_ACT)
export const getGoalLearnRoleValues = () => getValuesByType(VALUE_TYPE_GOAL_LEARN_ROLE)
export const getGoalFeedbackRelValues = () => getValuesByType(VALUE_TYPE_GOAL_FEEDBACK_REL)
export const getGoalCloseReasonValues = () => getValuesByType(VALUE_TYPE_GOAL_CLS_REASON)
export const getImpGoalCloseReasonValues = () => getValuesByType(VALUE_TYPE_IMP_GOAL_CLS_REASON)

export const getRoleValues = () => getValuesByType(VALUE_TYPE_ROLE)
export const getNextRoleJobLevel = () => getValuesByType(VALUE_TYPE_NEXTROLE_JLEVEL)
export const getNextRoleJobClass = () => getValuesByType(VALUE_TYPE_NEXTROLE_JCLAZ)
export const getFeedbackMgrRel = () => getValuesByType(VALUE_TYPE_FEEDBACK_MGR_REL)
export const getFeedbackEmpRel = () => getValuesByType(VALUE_TYPE_FEEDBACK_EMP_REL)
export const getModalTooltip = () => getValuesByType(VALUE_TYPE_MODAL_TOOLTIP)
export const getExpOpsMinValues = () => getValuesByType(VALUE_TYPE_EXP_OPS_MIN).sort((a,b)=>a.label.localeCompare(b.label))
export const getRatingContinueValues = () => getValuesByType(VALUE_TYPE_RATING_CONTINUE)

export const getRatingAckValues = () => getValuesByType(VALUE_TYPE_RATING_ACK)
export const getRatingValues = () => getValuesByType(VALUE_TYPE_RATING)
export const getPlanTypeValues = () => getValuesByType(VALUE_TYPE_PLAN_TYPE)
export const getNoGoalReasonValues = () => getValuesByType(VALUE_TYPE_NO_GOAL_REASON)
export const getAdminReportValues = () => getValuesByType(VALUE_TYPE_ADMIN_REPORT)
export const getRsSearchAutoCompValues = () => getValuesByType(VALUE_TYPE_RS_SEARCH_AUTOCOMP)
export const getMsSearchAutoCompValues = () => getValuesByType(VALUE_TYPE_MT_SEARCH_AUTOCOMP)
export const getRsListAccessValues = () => getValuesByType(VALUE_TYPE_RS_LIST_ACCESS)
export const getRsAccessLevelValues = () => getValuesByType(VALUE_TYPE_RS_ACCESS_LEVEL)
export const getRsSearcListValues = () => getValuesByType(VALUE_TYPE_RS_LIST_TYPE)
export const getRsMemberAssmValues = () => getValuesByType(VALUE_TYPE_RS_MEMB_ASSM)
export const getRsMemeberInformValues = () => getValuesByType(VALUE_TYPE_RS_MEMB_INFORM)
export const getRsMemeberAcceptValues = () => getValuesByType(VALUE_TYPE_RS_MEMB_ACCEPT)
export const getRsExtrsFTypeValues = () => getValuesByType(VALUE_TYPE_RS_EXTRS_FTYPE)
export const getReviewTypeValues = () => getValuesByType(VALUE_TYPE_REVIEW_TYPE)
export const getReviewReasonValues = () => getValuesByType(VALUE_TYPE_REVIEW_CONTINUE)

export const getCompensationGroupValues = () => getValuesByType(VALUE_TYPE_COMPENSATION_GROUP)
export const getBenStatusValues = () => getValuesByType(VALUE_TYPE_BEN_STATUS)
export const getEmployeeGroupValues = () => getValuesByType(VALUE_TYPE_EMPLOYEE_GROUP)
export const getUIBlockValues = () => getValuesByType(VALUE_TYPE_UI_BLOCK)
export const getGoalLibResultValues = () => getValuesByType(VALUE_TYPE_GL_RESULT)

export const getRatingNoCovReasonValues = () => getValuesByType(VALUE_TYPE_RATING_NOCOV_REASON)
export const getGoalLibDeployTypeValues = () => getValuesByType(VALUE_TYPE_GOAL_LIB_DEPLOY_TYPE)
export const getMentorTypeValues = () => getValuesByType(VALUE_TYPE_MENTOR_TYPE)
export const getMentorAckValues = () => getValuesByType(VALUE_TYPE_MENTOR_ACK)
export const getMentorMaxPeer = () =>getValueByCode('MT_APP_ADMIN', 'MAX_PEER') || {value: '4'}

const getFormulaCode = formula => (formula && formula.code) || 'V1'
const getFormulaKey = (formula, value) => getFormulaCode(formula) + '_' + value

export const getRatingCalibValues = formula => {
    const key = getFormulaKey(formula, VALUE_TYPE_RATING_CALIBRATION)
    return getValuesByType(key).map(v=>({...v, jsonData: JSON.parse(v.jsonData)}))
}
export const getRatingFactorValues = formula => {
    const key = getFormulaKey(formula, VALUE_TYPE_RATING_FACTOR)
    return getValuesByType(key).sort((a,b)=>a.displayOrder-b.displayOrder)
        .filter(e=>e.value === VALUE_RATING_FACTOR_NORMAL ).map(e=>({...e, jsonData: tryParseJSON(e.jsonData)}))
}
export const getRatingFactorOverCalibValue = formula => {
    const key = getFormulaKey(formula, VALUE_TYPE_RATING_FACTOR)
    return getValuesByType(key).filter(e=>e.value===VALUE_RATING_FACTOR_OVR_CALIB)[0]
}

export const getRatingFactorCalibValue = formula => {
    const key = getFormulaKey(formula, VALUE_TYPE_RATING_FACTOR)
    return getValuesByType(key).filter(e=>e.value===VALUE_RATING_FACTOR_CAL_CALIB)[0]
}

export const getPlanStatusValues = () => getValuesByType(VALUE_TYPE_PLAN_STATUS).sort((a,b)=>a.displayOrder-b.displayOrder)
export const getPlanReviewStatusValues = () => getValuesByType(VALUE_TYPE_PLAN_REVIEW_STATUS).sort((a,b)=>a.displayOrder-b.displayOrder)

export const getPlanStatusOpen = () => [...getPlanStatusValues(), ...getPlanReviewStatusValues()].filter(e=>e.code === 'OPEN')

export const getCandidateValues = (value, parents, parents2) => {
    let candidateValues = []
    JSON.parse(value.jsonData).candidateType.map(e=>candidateValues.push(...getValuesByType(e)))
    if(!parents && !parents2){
        return candidateValues
    }else if(parents.length === 0 && parents2.length === 0 ){
        return []
    }

    if(parents.length > 0){
        candidateValues = candidateValues.filter(e=>parents.some(p=>p.id === e.parentId))
    }
    if(parents2.length > 0){
        candidateValues = candidateValues.filter(e=>parents2.some(p=>p.code === e.value))
    }
    return candidateValues
}

export const getValue = id => valueList.filter(e=>e.id === id)[0]
export const getValueByCode = (type, code) => {
    const value = valueList.filter(e=>e.type===type && e.code === code)[0]
    return value ? {...value, jsonData: JSON.parse(value.jsonData)} : value
}
export const getValueByLabel = (type, label) => {
    const value = valueList.filter(e=>e.type===type && e.label === label)[0]
    return value ? {...value, jsonData: JSON.parse(value.jsonData)} : value
}

export const getCurrentYear = () =>getValueByCode(VALUE_TYPE_RATING, VALUE_CURRENT_YEAR)
export const getCountPerPage = () => 10
export const getTalentSearchPageSize = () => getValueByCode(VALUE_TYPE_RS_SEARCH, 'PAGE_SIZE') || { value: 20 }
export const getTalentSearchKeywordsSize = () => getValueByCode(VALUE_TYPE_RS_SEARCH, 'KEYWORDS_SIZE') || { value: 5 }
export const getDefaultListSize = () => getValueByCode(VALUE_TYPE_RS_SEARCH, 'DEF_LIST_SIZE') || { value: 50 }
export const getTalentEmailListSize = () => getValueByCode(VALUE_TYPE_RS_SEARCH, 'EMAIL_LIST_SIZE') || { value: 0 }

export const getMentorSearchPageSize = () => getValueByCode(VALUE_TYPE_MT_SEARCH, 'PAGE_SIZE') || { value: 30 }

export const getDefaultEmployeeGroup = () => getValueByCode(VALUE_TYPE_EMPLOYEE_GROUP, 'MCP')
export const getBargainingEmployeeGroup = () => getValueByCode(VALUE_TYPE_EMPLOYEE_GROUP, 'BARG')
export const getBargainingUnits = () => {
    let parentId = getValueByCode(VALUE_TYPE_EMPLOYEE_GROUP, 'BARG').id
    return getCompensationGroupValues().filter(e => e.parentId === parentId)
}

export const onBehalf = (onBehalf, display) => onBehalf===1 || onBehalf===2 ? `Admin` : display
export const displayYear = year => {
    const years = year.split('/')
    return years[0] + '-20' + years[1]
}
// export const filterAutoCompValues = keys => getRsSearchAutoCompValues().filter(r=>keys.indexOf(r.code) > -1)
export const filterAutoCompValues = keys => {
    const _values = []
    keys.map(r=>{
        const value = getValueByCode(VALUE_TYPE_RS_SEARCH_AUTOCOMP, r.customCode) || {}
        _values.push({...value, ...r})
        return r
    })
    return _values
}

export const filterMtAutoCompValues = keys => {
    const _values = []
    keys.map(r=>{
        const value = getValueByCode(VALUE_TYPE_MT_SEARCH_AUTOCOMP, r.customCode) || {}
        _values.push({...value, ...r})
        return r
    })
    return _values
}

export const isUIBlock = code => (getUIBlockValues().filter(v=>v.code === code)[0] || {value: '0'}).value === '1'

export const getMaxFileSize = () => {
    const size = getValueByCode('FILE_VALID', 'MAX_FILE_SIZE') || {value: '0'}
    return (size.value - 0) || 104857600
}

export const getMaintainMsg = () => {
    const msg = getValueByCode('APP_ADMIN', 'MAINTAIN_MSG') || {
        value: '1 hour',
        description: 'FORTE is going through a scheduled update to give you the best experience!<br/> Hang in tight. We will be back in {0}'
    }
    return stringFormat(msg.description, [msg.value])
}