import useFeatureFlags from "."
import { getFlags } from "./parseToggles";

interface propsType{
  children?: any; 
  featureId: string;
}

const Flagged = (props: propsType) =>{
  const { children, featureId } = props
  const flags = useFeatureFlags<any>()

  if (flags[featureId]) {
    return <>{children}</>
  }

  return flags[featureId] ? <>{children}</> : <></>

  // return <>{children}</>
}

export default Flagged

export const flagged = (featureId: string, children=()=>{}) =>{
  
  const flags: any = getFlags()

  if (flags[featureId]) {
    children()
    return true
  }

  return false
}