import { dispatchFetch } from 'store'

export const fetch_all_feature_flag = (callback: any=()=>{}) => {
  dispatchFetch({
    spinner: false,
    path: `api/feature/all`,
    method: 'GET',
    success: callback
  })
}
export const fetch_feature_flag = (value: any, callback: any=()=>{}) => {
  dispatchFetch({
    spinner: false,
    path: `api/feature/${value.componentId}`,
    method: 'GET',
    success: callback
  })
}