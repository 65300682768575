import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Div, DropDownMenu, AnimateInput } from '.'
import { debounce, newId } from 'utils'
import { loggerInfo } from 'utils'
import './typeahead.scss'

export default forwardRef((props: any, ref) => {
    const { label, labelledBy, describedBy, value, values = [], extra, options = [], placeholder, onChange = () => { }, menuStyle, styles, pullRight, showTitle=false, blockMsg='' } = props

    const [menuId, setMenuId] = useState({})
    const [menuExpanded, setMenuExpanded] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState<any>({})
    const [init, setInit] = useState(true)
    const [showBlockMsg, setShowBlockMsg] = useState(false)

    const inputRef: any = useRef({}), ddlRef: any = useRef({}), tmpTextRef = useRef('')

    useEffect(() => setMenuId(newId()), [])
    useEffect(() => {
        setFilteredOptions(options)
        if (!!value) {
            setSelectedOption(value)
        }
    }, [value, options])

    const api = () => ({
        setValue: (v: any) => {
            inputRef.current.setValue && inputRef.current.setValue(v.label)
            setSelectedOption(v)
        },
        getValue: () => selectedOption,
        isToggleOn: () =>ddlRef.current.isToggleOn(),
        onToggle :() => onToggle(),
        hideDropMenu: () =>ddlRef.current.hideDropMenu(),
        showDropMenu: () =>ddlRef.current.showDropMenu(),
        clear: () => {
            tmpTextRef.current = ''
            inputRef.current.value = ''
        }
    })
    useImperativeHandle(ref, api)

    const onSearchStrChange = debounce((v: any) => {
        setFilteredOptions(options.filter((o: any) => o.label.toLowerCase().indexOf(v.trim().toLowerCase()) !== -1))
        setInit(false)
    }, 200)

    const onToggle = () => {
        if (ddlRef.current.isToggleOn()) {
            ddlRef.current.hideDropMenu()
        } else {
            ddlRef.current.showDropMenu()
        }
    }

    const onSelect = (option: any) => {
        setSelectedOption(option)
        tmpTextRef.current = ''
        ddlRef.current.hideDropMenu()
        onChange(option)
    }

    const onInput = (event: any) => {
        onChange({})
        setShowBlockMsg(false)
        setInit(true)
        tmpTextRef.current = event.target.value
        const _value = event.target.value
      
        if (_value.trim()) {
            onSearchStrChange(_value)
        } else {
            setFilteredOptions(options)
        }
        if (!!!event.target.value) {
            ddlRef.current.hideDropMenu()
            return
        }
        ddlRef.current.showDropMenu()
    }

    const onClick = (event: any) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation();
    }
    const onBlur = () =>{      
        setTimeout(()=>{
            if (tmpTextRef.current && !selectedOption && !ddlRef.current.contains(document.activeElement)) {
                inputRef.current.focus()
                setShowBlockMsg(true)
            }
        })     
    }

    const onDropMenu = (event: any) => setMenuExpanded(event)

    loggerInfo("typeahead render")
    return (
        <div className='typeahead'>
            <div className='simple-row'>
                <DropDownMenu id={menuId} onBlur={onBlur} onDropMenu={onDropMenu} autoFocus={false} pullRight={pullRight} scrollable 
                    menuRole="listbox" focusable={true} menuLabelledBy={labelledBy} ref={ddlRef} style={{ width: '100%' }} 
                    menuStyle={{ maxHeight: '300px', width: '150%', top: '95%', ...menuStyle }}
                    display={<>
                        <AnimateInput ref={inputRef} aria-controls={menuId} aria-haspopup={true} aria-expanded={menuExpanded} 
                            labelledBy={labelledBy} describedBy={describedBy} title={showTitle ? (selectedOption.label || '') : ''} 
                            value={selectedOption.label || tmpTextRef.current || ''} style={{width: 'calc(100% - 20px)', ...styles}}
                            label={label} onClick={onClick} onChange={onInput} placeholder={placeholder}/>
                            {!extra ? <span aria-hidden='true' className='fa fa-caret-down' style={{ margin: '10px 0 0 -20px', top: '-5px', color: '#98a3a6' }} onClick={onToggle} />  : extra}
                    </>}>
                    <div>
                        {
                            filteredOptions.length === 0 ? 'No data match'
                            :
                            filteredOptions.map((o: any, i) => values.some((v: any)=>v?.id=== o.id) ?
                            <div key={i} className='dropdown-item auto-wrap item-label disabled'>{o.label}</div>                
                            :
                            <Div key={i} role="option" aria-selected={o.id===selectedOption.id} title ={showTitle ? o.label : ''} className='dropdown-item' onClick={() => onSelect(o)}>{o.label}</Div>)
                        }
                        <div className="sr-only" role="status" aria-live="assertive" aria-atomic={true}>
                            {!init && <span>{filteredOptions.length} records found for {tmpTextRef.current}</span>}
                        </div>
                        {showBlockMsg && blockMsg && <span className='sr-only' role="alert">{blockMsg}</span>}
                    </div>
                </DropDownMenu>
            </div>
        </div>
    )
})
