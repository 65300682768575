import {
  FEEDBACK_REFRESH,
} from 'services/action-types'

const initialState = {
  requestBy: {},
  goalDetail: {
    manager: {}
  },
  owner: {},
  status: {}
}

export const feedbackReducer =  (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK_REFRESH:
      return action.value
    default:
      return state
    }
}