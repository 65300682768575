import React, { useRef, forwardRef, useImperativeHandle} from 'react'
import DropDownBase from './dropdown-base'
import { Key, focusOnSelectors2 } from 'utils'
import './dropdown.scss'

export default forwardRef((props: any, ref) =>  {
  const {children, autoDrop=true, onKeyDown=()=>{}, onDropMenu=()=>{}, ...rest}=props
  const wrapperRef: any = useRef({})

  const api = () => ({
    showDropMenu: (e: any) => wrapperRef.current.showDropMenu(e),
    hideDropMenu: (e: any) => wrapperRef.current.hideDropMenu(e),
    isToggleOn: () => wrapperRef.current.isToggleOn()
  })
  useImperativeHandle(ref, api)

  const _onKeyDown = (e: any) => {
    if((e.keyCode === Key.ENTER || e.keyCode === Key.SPACE) && !wrapperRef.current.isToggleOn()){
      e.preventDefault()
      e.stopPropagation()
      onClick()
    }
    onKeyDown(e)
  } 

  const focusOnFirst = (ref: any) => setTimeout(()=>focusOnSelectors2(ref, [
    "input, [href], select, textarea, [tabindex]:not([tabindex='-1']",
    "button.tms-button:not([disabled]), button.flat-button"
  ]))

  const onClick = () =>{
    if(!wrapperRef.current.isToggleOn() && autoDrop){
      wrapperRef.current.showDropMenu()

      // auto focus on 1st focusable element
      wrapperRef.current.focusOnFirst()
    }
  }

  return (
    <DropDownBase ref={wrapperRef} {...rest} onKeyDown={_onKeyDown} onClick={onClick} onDropMenu={onDropMenu} focusOnFirst={focusOnFirst}>
      {children}
    </DropDownBase>
  )
})