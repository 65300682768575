import React from 'react'
import { dispatchSimple } from 'store'
import { MESSAGE_SHOW, MESSAGE_HIDE } from 'services/action-types'
import { MessageSeverity } from './types'

let nextNotificationId = 0

const promptInfo = (message: any) =>{
  const id = nextNotificationId++
  dispatchSimple({
    type: MESSAGE_SHOW, 
    value: { severity: MessageSeverity.INFO, ...message, id: id }
  })
  setTimeout(() => dispatchSimple({type: MESSAGE_HIDE, value: id}), 10000 )
}

const promptWarning = (message: any) => dispatchSimple({
  type: MESSAGE_SHOW, 
  value: { severity: MessageSeverity.WARNING, ...message, id: nextNotificationId++ }
})

const promptError =  (message: any) => dispatchSimple({
  type: MESSAGE_SHOW, 
  value: { severity: MessageSeverity.ERROR, ...message, id: nextNotificationId++ }
})

export const hide_message = (value: any) => dispatchSimple({type: MESSAGE_HIDE, value})

const promptInfoCheck = (m: any) => promptInfo({
  icon: <span className='fa fa-check-circle icon' style={{color: 'RGB(17, 136, 71)', fontSize: '20px'}} aria-hidden='true'/>,
  content: m
})
const promptInfoTrash = (content: any) =>promptInfo({
  icon: <span className='fa fa-trash icon' aria-hidden='true'
    style={{ 
      color: '#595959',
      fontSize: '15px',
      opacity: '1',
      marginTop: '2px',
      background: '#ccc',
      borderRadius: '50%',
      width: '20px',
      padding: '4px'
    }}/>,
  content
})
export const prompt = {
    info: (m: any)=>promptInfo({content: m}),
    warning: (m: any)=>promptWarning({content: m}),
    error: (m: any)=>promptError({content: m}),

    errorCast: (m: any)=>promptError(m),
    infoCheck: (m: any)=>promptInfoCheck(m),
    infoTrash: (m: any)=>promptInfoTrash(m)
}