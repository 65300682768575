import React, { useState, useEffect, useRef,forwardRef, useImperativeHandle } from 'react'
import { DayPicker } from 'react-day-picker'
import { addMonths } from 'date-fns';

// import { Span, Div } from './elements'
import { AnimateInput } from '.'
import { toDate, isValidDate, isValidDateStr, toMonth, createDate } from 'utils/date-util'
import { loggerInfo } from 'utils'
import 'react-day-picker/dist/style.css'
import './date-picker.scss'
import { getValidDateInputKeys, formatDateText } from 'utils/text-util'
const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export default forwardRef((props: any, ref) =>  {
  const { id, value, labelledBy, onDayChange=()=>{}, format, placeHolder, dayPickerProps, onShow=()=>{} } = props
  const [ show, setShow ] = useState(false)
  const [ message, setMessage] = useState('')
  const [ selectedDay, setSelectedDay ] = useState()
  // const [ month, setMonth ] = useState(toMonth(createDate()))

  const nextMonth = addMonths(new Date(), 1);
  const [month, setMonth] = useState(nextMonth);

  const [ valid, setValid ] = useState(true)  
  const inputRef: any = useRef({}), wrapperRef: any = useRef({})
  const dateKeyFilter = getValidDateInputKeys()

  useEffect(() => { inputRef.current.value = value || '' }, [value])

  const api = () => ({
    getValue: () => {
      showPicker(false)
      return inputRef.current.value
    },
    setValue: (value: any) => {
      inputRef.current.value = value
      verifyDate(value)
    },
    isShow: () => show,
    toggleShow: (value: any) => showPicker(value),
    isValid: () => valid,
    setMessage: (value: any) => setMessage(value),
    getMessage: () => message,
    focus: () => inputRef.current.focus()
  })

  useImperativeHandle(ref, api)

  const onDayClick = (day: any, modifiers: any = {}) => {
    if (modifiers.disabled) {
      return
    }
    showPicker(false)
    setSelectedDay(day)
    onDayChange(day)
    inputRef.current.value = toDate(day, format)
    inputRef.current.focus()
  }
  
  const onClickShow = () => {
    let _date: any = createDate(value || '')
    if(!isValidDate(_date)){
      _date = createDate()
    }
    setSelectedDay(_date)
    setMonth(toMonth(_date))
    showPicker(!show)
  }

  const onBlur = (event: any) => verifyDate(event.target.value)

  const verifyDate = (value: any) => {
    const _date = createDate(value)
    let _valid = true

    if(isValidDateStr(value) && isValidDate(_date)){ 
      onDayChange(_date)
      setMessage('')
    }else{
      if(value){
        setMessage("Invalid date format.")    
        _valid = false
        inputRef.current.focus()
        setTimeout(() => showPicker(false), 200)
      }
      onDayChange('')
    }
    setValid(_valid)
  }

  const onFocus = (event: any) => {
    const _date = createDate(event.target.value)
    let _valid = true

    if((!isValidDate(_date) || !isValidDateStr(event.target.value)) && event.target.value){
      _valid = false
    }
    setValid(_valid)
  }
  
  const onChange = (event: any) => {
    if(!event.target.value){
      setValid(true)
      setMessage('')
      onDayChange(null)
    }else{
      showPicker(false)
      if (event.target.value.length === 10) {
        verifyDate(event.target.value)
      }
    }
  }

  const handleClickOutside = (event: any) =>  {
    if (!wrapperRef || !wrapperRef.current || wrapperRef.current.contains(event.target)) return
    showPicker(false)
  }

  const showPicker = (value: any) =>{
    setShow(value)
    onShow(value)
    if (value) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }
  const onDayKeyDown = (day: any, modifiers: any, event: any) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation();
  }

  const onKeyDown = (e: any) => {
    if(dateKeyFilter.indexOf(e.keyCode) < 0){
      e.preventDefault();
      return false;
    }  
  }

  const onKeyUp = (e: any) => {
    let formatted = formatDateText(e.target.value);
    inputRef.current.value = formatted;
  }
  
  loggerInfo('datePicker.render')
  return <div className='date-picker' ref={wrapperRef}>
    <AnimateInput className={`date-picker-input ${!valid ? 'invalid': ''}`} 
      id={id} labelledBy={labelledBy} ref={ inputRef } placeholder={ placeHolder || format } maxLength={10}
      onChange={onChange}
      onClick={onClickShow}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      onFocus={onFocus} 
      extra={<button aria-hidden='true' className='fa fa-calendar' onClick={ onClickShow }/>}
      // countable={false}
      />
    {!!message && <span role="alert" style={{fontSize: '12px', color: 'red'}}>{message}</span>}
    { show && <DayPicker tabIndex={0}
      onDayKeyDown={onDayKeyDown} 
      onDayClick={onDayClick} 
      month={month} 
      onMonthChange={setMonth}
      mode={'single'}
      autoFocus
      auto-open-disabled
      {...dayPickerProps} 
      // selectedDays={selectedDay}
      selected={selectedDay}
      onSelect={setSelectedDay}
      // modifiersClassNames={{
      //   selected: 'my-selected',
      //   today: 'my-today'
      // }}
      weekdaysShort={WEEKDAYS_SHORT}/>}
  </div>
})