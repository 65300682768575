import {
  PROFILE_REFRESH,
  PROFILE_REFRESH_PERSON,
  PROFILE_REFRESH_THUMBNAIL,
  PROFILE_REFRESH_SUMMARY,
  PROFILE_REFRESH_NEXTNOTE,
  PROFILE_REFRESH_EXPERIENCE,
  PROFILE_TOGGLE_SUPPRESSED,
  PROFILE_REFRESH_EDUCATION,
  PROFILE_REFRESH_CERTIFICATION,
  PROFILE_REFRESH_SKILL,
  PROFILE_REFRESH_PROJECT,
  PROFILE_REFRESH_HONOR,
  PROFILE_REFRESH_LANGUAGE,
  PROFILE_REFRESH_LANGUAGE_CERT,
  PROFILE_REFRESH_INTEREST,
  PROFILE_REFRESH_LAST_UPDATE,
  PROFILE_EDIT_MODE,
  PROFILE_ACCESS_MODE,
  PROFILE_REFRESH_SUCCESSION,
  PROFILE_LANG_CERT_REMOVED
} from 'services/action-types'

const initialState = {
  id: 0,  
  person: {},
  summary:'',
  nextRoleNote: '',
  experience: [],
  education: [],
  certificates: [],
  skills: [],
  projects: [],
  languages:[],
  langCerts:[],
  honors:[],
  nextRole: [],
  editMode: true,
  accessMode: {},
  ver: 0,
  lastUpdate: {},
  showSuppressed: false,  //experience status: 0: draft, 1: pushlish, 2: suppressed
  successionRole: [],
  langCertRemoved: [],
  langCertVisit: []
}

export const profileReducer =  (state = initialState, action) => {
  switch (action.type) {
  case PROFILE_REFRESH:
    return state = { 
      ...state, 
      ...action.value,
      // experience: sortExperience(action.value.experience)
    }
  case PROFILE_REFRESH_PERSON:
    return state = { 
      ...state, 
      person: { ...action.value }
    }
  case PROFILE_REFRESH_THUMBNAIL:
    return state = { 
      ...state, 
      person: { ...state.person, thumbnail: action.value.image }
    }
  case PROFILE_REFRESH_SUMMARY:
    return state = { 
      ...state, 
      summary: action.value.content,
      ver: action.value.ver
    }
  case PROFILE_REFRESH_EXPERIENCE:
    return state = { 
      ...state, 
      experience: action.value//sortExperience([ ...action.value ])
    }
  case PROFILE_TOGGLE_SUPPRESSED:
    return state = { 
      ...state, 
      showSuppressed: action.value
    }    
  case PROFILE_REFRESH_EDUCATION:
    return state = { 
      ...state, 
      education: [ ...action.value ]
    }
  case PROFILE_REFRESH_CERTIFICATION:
    return state = { 
      ...state, 
      certificates: [ ...action.value ]
    }
  case PROFILE_REFRESH_SKILL:
    return state = { 
      ...state, 
      skills: [ ...action.value ]
    }
  case PROFILE_REFRESH_PROJECT:
    return state = { 
      ...state, 
      projects: [ ...action.value ]
    }    
  case PROFILE_REFRESH_HONOR:
    return state = { 
      ...state, 
      honors: [...action.value ]
    }
  case PROFILE_REFRESH_LANGUAGE:
    return state = { 
      ...state, 
      languages: [...action.value ]
    }
  case PROFILE_REFRESH_LANGUAGE_CERT:
    return state = { 
      ...state, 
      langCerts: [...action.value ],
    }
  case PROFILE_REFRESH_INTEREST:
    return state = { 
      ...state, 
      nextRole: [ ...action.value ]
    }
  case PROFILE_REFRESH_NEXTNOTE:
    return state = { 
      ...state, 
      nextRoleNote: action.value.content,
      ver: action.value.ver
    }
  case PROFILE_REFRESH_LAST_UPDATE:
    return state = { 
      ...state, 
      lastUpdate: action.value
    }
  case PROFILE_EDIT_MODE:
    return state = {
      ...state,
      editMode: action.value
    }
  case PROFILE_ACCESS_MODE:
    return state = {
      ...state,
      accessMode: action.value
    }
  case PROFILE_REFRESH_SUCCESSION:
    return state = { 
      ...state, 
      successionRole: updateSuccessorRoles(state.successionRole, action.value)
    }
  case PROFILE_LANG_CERT_REMOVED:
    return state = { 
      ...state, 
      langCertRemoved: action.value
    }
  default:
    return state
  }
}
const updateSuccessorRoles = (roles, data) => {
  return [...roles].map(r=> r.uuid === data.uuid ? data : r)
}
// const sortExperience  = value => value.sort((a,b)=> a.to - b.to)