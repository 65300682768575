export const debounce = (func, wait, immediate) =>{
	let timeout;
	return function() {
		const context = this, args = arguments;
		const later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		var callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

export const debounceAsync = (func, wait) => {
	let timerId, latestResolve
  
	return async function () {
		const context = this, args = arguments;
	  	if ( !latestResolve ) {
			return await new Promise( ( resolve, reject ) => {
		  		latestResolve = resolve
				timerId = setTimeout( invokeAtTrailing.bind( context, args, resolve, reject ), wait )
			})
	  	}
  
	  	return await new Promise( ( resolve, reject ) => {
			latestResolve = resolve
			timerId = setTimeout( invokeAtTrailing.bind( context, args, resolve, reject ), wait )
	  	})
	}
  
	async function invokeAtTrailing( args, resolve, reject ) {
		await func.apply( this, args ).then( resolve ).catch( reject )
		clearTimeout( timerId )
		timerId = latestResolve = null
	}
}