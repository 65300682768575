import { useRef, forwardRef, useImperativeHandle} from 'react'
import DropDownBase from './dropdown-base'
import { Key } from 'utils'
import './dropdown.scss'
export default forwardRef((props: any, ref) =>  {
  const {children, id, menuRole, spaceDown=true, hasGroup=false, autoFocus=true, closeOnSelect=true, onKeyDown=()=>{}, onDropMenu=()=>{}, onBlur=()=>{}, ...rest}=props
  const wrapperRef: any = useRef({})

  useImperativeHandle(ref, () => ({
		showDropMenu(e: any){ wrapperRef.current.showDropMenu(e) },
    hideDropMenu(e: any){ wrapperRef.current.hideDropMenu(e) },
    contains: (e: any) => {return (wrapperRef.current.contains(e))},
    isToggleOn: () => wrapperRef.current.isToggleOn(),
    focusOnFirst: () => wrapperRef.current.focusOnFirst(),
    clear: () => wrapperRef.current.clear(),
  }))
  
  const focusOnFirst = (menuRef: any) => {
      setTimeout(()=> {
        let items = menuRef.current.querySelectorAll('.dropdown-item') || []
        if (items.length > 0) {
          items[0].focus()
        }
      })
    }

  const clear = (menuRef: any) => {}
  
  const onClick = (e: any) => {
    if(!wrapperRef.current.isToggleOn()){
      wrapperRef.current.showDropMenu(e)
    }else if (wrapperRef.current.isToggleOn() && !closeOnSelect ){
      e.stopPropagation()      
    }else if (closeOnSelect && (e.target.tabIndex>=-1 || e.target.parentNode.tabIndex>=-1 || e.target.parentNode.parentNode.tabIndex>=-1)){//TODO: find ancestor by common way
      wrapperRef.current.hideDropMenu(true, true, e)
    }
  }

  const _onKeyDown = (e: any) => {
    if (e.keyCode === Key.ENTER || e.keyCode === Key.SPACE){
      if(!wrapperRef.current.isToggleOn()){
        // wrapperRef.current.showDropMenu(e)
        wrapperRef.current.click()
      }else{
        // wrapperRef.current.hideDropMenu(true, true, e)
        wrapperRef.current.click()
      }
    }else if (e.keyCode === Key.DOWN_ARROW && wrapperRef.current.isToggleOn && !(e.target.className.indexOf('dropdown-item') >-1)) {   
      e.preventDefault()
      e.stopPropagation()
      let children = e.target.closest('.dropdown').querySelectorAll('.dropdown-item:not(.disabled)')
      if (children && children.length > 0) {
        return children[0].focus()
      }
    }
    onKeyDown(e)
  }
  
  const _onDropMenu = (isShow: any, event: any) => {
    !isShow && wrapperRef.current.clear && wrapperRef.current.clear()
    onDropMenu(isShow, event)
  }

  return (
    <DropDownBase ref={wrapperRef} id={id} menuRole={menuRole} {...rest} onKeyDown={_onKeyDown} 
      onClick={onClick} onBlur={onBlur} onDropMenu={_onDropMenu} focusOnFirst={focusOnFirst} clear={clear}>
      {children}
    </DropDownBase>
  )
})