import { 
  ADMIN_CACHE_NAMES_REFRESH,
  ADMIN_CACHE_KEYS_REFRESH,
  ADMIN_CACHE_DETAIL_REFRESH
} from 'services/action-types'

const initialState = {
    names: [],
    keys: [],
    detail: {}
}

export const cacheReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_CACHE_NAMES_REFRESH:
    return state = { 
      ...state, 
      names: action.value,
      keys: [],
      detail: {}
    }
  case ADMIN_CACHE_KEYS_REFRESH:
    return state = { 
      ...state, 
      keys: action.value,
      detail: {}
    }
  case ADMIN_CACHE_DETAIL_REFRESH:
    return state = { 
      ...state,
      detail: action.value
    }
  default:
      return state
  }
}