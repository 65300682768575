import { getValueByCode } from 'utils'

export const STATUS_START = 'START'
export const STATUS_PENDING = 'PENDING'
export const STATUS_READY = 'READY'
export const STATUS_END = 'END'
export const STATUS_FAILED = 'FAILED'
export const STATUS_TIMEOUT = 'TIMEOUT'

export const CHECK_INTERVAL = 10000

export const getMaxTimes = () => {
    const time = getValueByCode('APP_ADMIN_BIZ', 'ASYNC_RPT_TIMEOUT') || {value: '600'}
    return (time.value - 0) * 1000/CHECK_INTERVAL

    // return 3
}