import { MESSAGE5_SHOW, MESSAGE5_HIDE } from 'services/action-types'

const initialState = {
  showed: false,
  show: false,
  remaining: 0
}

export const message5_reducer= (state = initialState, action: any) => {
  switch (action.type) {
  case MESSAGE5_SHOW:
    return { ...state,
      show: action.value.show ? true: !state.showed && action.value.remaining > 0 ? true: state.show,
      remaining: action.value.remaining
    }
  case MESSAGE5_HIDE:
    return { 
      ...state, 
      show: false, 
      showed: state.show ? true: state.showed}
  default:
    return state
  }
}