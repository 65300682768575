import React, { useEffect, forwardRef, useRef, InputHTMLAttributes } from 'react'
import CharCount from '../../character-count'
import newId from 'utils/new-id'
import './style.scss'

class propsType implements InputHTMLAttributes<HTMLInputElement> {
    label?: any;
    desc?: any;
    value?: string;
    className?: string;
    extra?: any;    
    maxLength?: number | undefined;
    required?: any;
    id?: any;
    title?: string;
    placeholder?: string;
    style?:any;
    readOnly?: boolean;
    onChange?: any;
    onFocus?: any;
    onBlur?: any;
    onValueChange?: any;
    charCount?: boolean = true;
}
const OdsInput = forwardRef((props: propsType, ref: any) => {
    const { label, desc, value, className, extra, required, maxLength, charCount=true, onValueChange=()=>{}, onBlur=()=>{}, ...rest } = props

    const __ref = useRef({})
    const _ref = ref || __ref

    useEffect(() => { 
        if (_ref.current) {
            _ref.current.value = value || ''
        } 
    }, [value, _ref])

    const handleBlur = (event: any) => {
        // if(value !== event.target.value){
            onValueChange(event.target.value)
        // }
        onBlur(event)
    }

    const labelledBy = newId(), describedBy = newId(), inputId = newId()

    const __rest = {
        ...rest, value, autoComplete: 'off', maxLength, id: inputId, type: 'text', 
        'aria-labelledby':labelledBy, 'aria-describedby': describedBy, 'aria-required': required,
        onBlur: handleBlur
    }

    return (
    <div className='ontario-form-group input3 input4'>
        {label && <label role='dialog' aria-live='polite' className='ontario-label' htmlFor={inputId}><span id={labelledBy}>{label}</span>
                {desc && <div className='simple-row ontario-label__flag' id={describedBy}>{desc}</div>}
            </label>
        }
        <div className='simple-row'>
            <input {...__rest} ref={_ref} className={`ontario-input ${className}`} />
            {extra}
        </div>
        {/* <input autoComplete='off' {...rest} ref={_ref} aria-labelledby={labelledBy} {...(describedBy && {'aria-describedby': describedBy})} id={id} onFocus={handleFocus} onBlur={handleBlur} 
                maxLength={overlimit ? undefined : maxLength} readOnly={readOnly}
            /> {extra} */}
        { !!!props.readOnly && charCount && <CharCount size={maxLength} value={value}/> }
    </div>
    )
})

export default OdsInput