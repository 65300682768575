import { createSelector } from 'reselect'

export const getUINotifications = createSelector(
   (state: any) => state.ui.common.notification.events, 
   data => data
)
export const getUINotifyFetching = createSelector(
   (state: any) => state.ui.common.notification.fetching, 
   data => data
)
