export const tryParseJSON = jsonString =>{
  try {
    var o = JSON.parse(jsonString)
    if (o && typeof o === "object") {
        return o
    }
  }
  catch (e) { }

  return false
}

export const stringifyJSON = (jsonObj, replacer = null, space = 0) =>{
  if (jsonObj && typeof jsonObj === "object") {
    return JSON.stringify(jsonObj, replacer, space)
  }else{
    return jsonObj || ''
  }
}

export const tryParseNestJSON = json =>{
  try {
    var o = typeof json === "object" ? json : JSON.parse(json)
    if (o && typeof o === "object") {
      for (let key in o) {
        if((o[key]+'').indexOf('{') > -1 ||  typeof o[key] === "object"){
          o[key] = tryParseNestJSON(o[key])
        }
      }
      return o
    }
  }
  catch (e) { }

  return false
}
export const clone = o => tryParseJSON(stringifyJSON(o))