import React, {useState, useRef, forwardRef, useImperativeHandle} from 'react'
import { Div, TypingInput2 } from 'components/common'
import { Key } from 'utils'
// import './style.scss'

export default forwardRef((props:any, ref) => {
  const {values=[], label, labelledBy, onChange=()=>{}, ...rest} = props
  const [searchF, setSearchF] = useState('')
  const [dropdownShow, setDropdownShow] = useState(false)
  const inputRef:any = useRef({}), downRef:any = useRef({}), searchTextRef = useRef('')
  
  useImperativeHandle(ref, () => ({
    setValue(value:any){
      if(value){
        console.log(dropdownShow)
        inputRef.current.setValue(value.label||'')
      }
    },
    getValue(){
      return inputRef.current.getValue()
    },
    clear(){
      setSearchF('')
      inputRef.current.setValue('')
    }
  }))

  const onSelect = (c:any) =>{
    onChange(c)
    inputRef.current.setValue(c.label)
    inputRef.current.hideDropMenu()
  }
  const onDrop = (all=false) => {
    all && setSearchF('')
    inputRef.current.showDropMenu()
  }
  const onInput = (e:any) => {
    const _value = e.target.value.trim()
    searchTextRef.current = _value
    setSearchF(_value)
    onChange({id: 0, label: _value})
  }
  const isSelected = (e:any) => {
    const _value = inputRef.current.getValue && inputRef.current.getValue()
    return _value && e.label.toLowerCase()===_value.toLowerCase() ? 'true': 'false'
  }

  const onDropKeydown = (e:any) => {
    if (e.keyCode === Key.ENTER) {
      onDrop(true)
    }
  }

  const renderDrop = (values:any) =><>
      {values.length === 0 ? <div className='dropdown-item disabled'>No data match</div>
        :
      values.map((l:any, i:number)=><Div className='dropdown-item' role='option' aria-selected={isSelected(l)} key={i} onClick={()=>onSelect(l)}>{l.label}</Div>)}
      <div className="sr-only" role="status" aria-live="assertive" aria-atomic={true}>
        <span>{values.length} records found for {searchTextRef.current}</span>
      </div>
    </>
  
  return (
    <TypingInput2 ref={inputRef} labelledBy={labelledBy} onChange={onInput} label={label} onClick={()=>onDrop()} 
      onDropMenu={(evt:any)=>setDropdownShow(evt)} styles={{width: 'calc(100% - 15px)'}} {...rest}
      extra={<span ref={downRef} aria-hidden='true' className='fa fa-caret-down' style={{margin: '5px 0 0 0'}} onClick={()=>onDrop(true)} onKeyDown={onDropKeydown}/>}>
      {renderDrop(values.filter((l:any) => l.label.toLowerCase().indexOf(searchF.toLowerCase()) > -1).sort((a:any,b:any)=>a.label.localeCompare(b.label)))}
    </TypingInput2>
  )
})