import { MESSAGE2_SHOW, MESSAGE2_HIDE } from 'services/action-types'

const initialState = {
  items: []
}

export const message2_reducer= (state = initialState, action: any) => {
  switch (action.type) {
  case MESSAGE2_SHOW:
    return { ...state, items: [action.value] }
  case MESSAGE2_HIDE:
    return { ...state, items: [] }
  default:
    return state
  }
}