import { 
  AUTH_STARTING, 
  AUTH_NO_PERMISSION,
  AUTH_NO_ACCESS_BPS,
  AUTH_GO_LANDING_PAGE,
  AUTH_NO_ACCESS_MAINTAIN,
  AUTH_FIRST_ACCESS,
  AUTH_REGISTER_ACK,
  AUTH_REGISTER_SUCCESS,
  AUTH_FAILURE,
  AUTH_NONE,
  AUTH_LOGOUT,
  AUTH_TIMEOUT,
  AUTH_ADAL_TIMEOUT,
  AUTH_INITIALLED,
  AUTH_CATAGORY,
  AUTH_USER_PROFILE,
  AUTH_VERSION_REFRESH,
  AUTH_PUBLIC_VL_REFRESH
} from 'services/action-types'
import { initValueList, appendValueList } from 'utils/value-util'
import { setPublicValuelist } from 'utils'
import heartBeat from 'services/heart-beat'

const authInitialState = {
  status: AUTH_STARTING,
  value: {},
  catagory: 'auth',      // or 'client',
  user: {},
  ver: ''
}
  
export const authReducer =  (state = authInitialState, action: { type: any; value: any }) => {
  switch (action.type) {  
    case AUTH_CATAGORY:
      return { ...state, catagory: action.value }
    case AUTH_FAILURE:
      return { ...state, status: action.type, value: action.value }
    case AUTH_NONE:
      heartBeat.stop()
      return { ...state, status: action.type }    
    case AUTH_LOGOUT:
      return { ...state, status: action.type }
    case AUTH_PUBLIC_VL_REFRESH:
      setPublicValuelist(action.value)
      initValueList(action.value)
      return { ...state}
    case AUTH_INITIALLED:
      appendValueList(action.value)
      return { ...state, status: action.type }
    case AUTH_USER_PROFILE:
      heartBeat.stop()
      heartBeat.start()
      return { ...state, user: action.value }
    case AUTH_STARTING:
      return { ...state, status: action.type }
    case AUTH_TIMEOUT:
      heartBeat.stop()
      return { ...state, status: action.type }
    case AUTH_ADAL_TIMEOUT:
      heartBeat.stop()
      return { ...state, status: action.type }      
    case AUTH_FIRST_ACCESS:
    case AUTH_REGISTER_ACK:
      heartBeat.stop()
      heartBeat.start()
      return { ...state, 
        status: action.type,
        user: {...state.user, ...action.value}
      }
    case AUTH_REGISTER_SUCCESS:
      return { ...state, 
        status: action.type, 
        user: action.value 
      }
    case AUTH_NO_PERMISSION:
    case AUTH_NO_ACCESS_BPS:
    case AUTH_GO_LANDING_PAGE:
      return { ...state, status: action.type }
    case AUTH_NO_ACCESS_MAINTAIN:
      return { ...state, status: action.type }
    case AUTH_VERSION_REFRESH:
      return { ...state, ver: action.value }
    default:
      return state
  }
}