import { combineReducers } from 'redux'
import {spinner_reducer as spinner} from '../spinner/reducer'
import {message_reducer as message} from '../message/reducer'
import {unsaved_reducer as unsaved} from '../unsaved/reducer'
import {observe_reducer as observe} from '../observe/reducer'
import {upload_reducer as upload} from '../upload/reducer'
import {download_reducer as download} from '../download/reducer'
import {lock_reducer as lock} from '../lock/reducer'
import {thumbnail_reducer as thumbnail} from '../thumbnail/reducer'
import {message2_reducer as message2} from '../message2/reducer'
import {message3_reducer as message3} from '../message3/reducer'
import {message4_reducer as message4} from '../message4/reducer'
import {message5_reducer as message5} from '../message5/reducer'
import {progress_reducer as progress } from '../in-progress/reducer'
import {criteria_reducer as criteria } from '../criteria/reducer'
import {ui_notification_reducer as notification } from '../ui-notify/reducer'
import {auto_resize_modal_reducer as modal } from '../auto-size-modal/reducer'
export const commonReducer = combineReducers({
  spinner,
  message,
  unsaved,
  observe,
  upload,
  download,
  lock,
  thumbnail,
  message2,
  message3,
  message4,
  message5,
  progress,
  criteria,
  notification,
  modal
})