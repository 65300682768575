import { createSelector } from 'reselect'
export const getMessage5 = createSelector(
   (state: any) => state.ui.common.message5,
   data => data 
)
export const getMessageShow = createSelector(
   (state: any) => state.ui.common.message5.show,
   data => data 
)
export const getMessageRemaining = createSelector(
   (state: any) => state.ui.common.message5.remaining,
   data => data 
)