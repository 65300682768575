import { 
  ADMIN_IMPORT_WIN_LIST_REFRESH,
  ADMIN_IMPORT_WIN_DETAIL_REFRESH,
  ADMIN_IMPORT_WIN_DETAIL_CLEAR,
  ADMIN_IMPORT_WIN_ANALYZE_REFRESH,
  ADMIN_IMPORT_WIN_CRITERIA_REFRESH
} from 'services/action-types'

const initCriteria = {size: 30, page: 0}

const initialState = {
  criteria: initCriteria,
  list: {
    totalRecords: 0,
    items: [],
  },
  activeRow: -1,
  histories: [
    {
      action: {},
      result:{
        mergeOssNum: 0,
        markTPNum: 0,
        newIndNum: 0,
        leftIndNum: 0,
        newAssNum: 0,
        updateAssNum: 0,
        saveHistoryNum: 0,
        newWinMinsNum: 0
      }
    }
  ],
}

export const importReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_IMPORT_WIN_LIST_REFRESH:
    return state = { 
      ...state, 
      list: action.value
    }
  case ADMIN_IMPORT_WIN_DETAIL_REFRESH:
    return state = { 
      ...state, 
      activeRow: action.value.id
    }
  case ADMIN_IMPORT_WIN_DETAIL_CLEAR:
    return state = { 
      ...state, 
      activeRow: -1, 
      detail: {
         children: []
      }
    }
  case ADMIN_IMPORT_WIN_ANALYZE_REFRESH:
    return state = { 
      ...state, 
      histories: action.value
    }
  case ADMIN_IMPORT_WIN_CRITERIA_REFRESH:
    return state = { 
      ...state, 
      criteria: action.value
    }    
  default:
      return state
  }
}