import { ValueListDto, UserBase } from 'types'

export class ClientLogMsgDto{
    id!: number;
    pid!: number;
    threadName!: string;
    logName!: string;
    log!: string;
    timestamp!: number;
    time!: string;
	ret!: ValueListDto;
}
export class ProcessLogDto{
    id!: number;
    name!: string;
    status!: number;
    at!: number;
    by!: string;
    processBy!: UserBase;	
    details: ClientLogMsgDto[] = [];
}