import React, { useState, useEffect } from 'react'
import Sortable from '../sortable'
import { Li } from '../elements'
import Scrollbar from '../scroll-bar'
import './list.scss'

const DefaultExport = props =>  {
  const { children, className, rows, onSelect=()=>{}, sortable=false, onSort, activeRow=-1, addToRef, onNavigate=()=>{}, onFocus=()=>{}, onBlur=()=>{}, scrollbar=true, ...rest } = props
  const [ listSnap, setListSnap ] = useState([])

  useEffect(() => setListSnap([...rows]), [rows])
  
  const onClick = (event, index, item) => {
    if (event.target.className.indexOf("tms-control") > -1 && event.target.tagName !=='LI'){
      return
    }
    onSelect(item, index)
  }
  
  const onChange = (event) => {
    setListSnap(event)
    onSort(event)
    // document.querySelector( '.simple-list.' + className).querySelectorAll('.sortable.item')[newIndex].focus()
  }

  const renderNormalItem = (item, index) => 
    <Li key={index} className={`sortable simple ${activeRow === index ? 'itemSelected' : 'item'}`} 
      onClick={event=>onClick(event, index, item)}
      onFocus={event=>onFocus(event, item)}
      onBlur={event=>onBlur(event, item)}
      onKeyDown={event=>onNavigate(event, item)}
      ref={addToRef}
      { ...rest }>
      {children(item, index)}
    </Li>

  const renderSortableItem = (item, index) => 
  <li key={index} className={`sortable simple`} 
    // onClick={event=>onClick(event, index, item)}
    // onFocus={event=>onFocus(event, item)}
    // onBlur={event=>onBlur(event, item)}
    // onKeyDown={event=>onNavigate(event, item)}
    ref={addToRef}>
    {children(item, index)}
  </li>

  const renderList = items =><ul>{items.map(renderNormalItem)}</ul>

  const renderSortableList = sortable => {
    return sortable ? <Sortable id='simple-list-sortable' onChange={onChange} items={listSnap} renderItem={renderSortableItem}/>
  : renderList(listSnap)
  }
  
  // console.log(listSnap.map(l=>l.label))
  return (
    <div className={`list simple-list ${className||''}`}>
      {scrollbar ? 
      <Scrollbar>
        {renderSortableList(sortable)}
      </Scrollbar>
      : 
      renderSortableList(sortable)
    }
    </div>
  )
};

export default DefaultExport;
