import {useState, useEffect, useLayoutEffect, useRef} from 'react'
import { HTMLDiv, Div } from './elements'
import Scrollbar from './scroll-bar'
import ResizeDetector from './resize-detector'
import { getModalTooltip, newId, Key } from 'utils'
import './hint.scss'
import { OdsButton } from './ods'

const Icon = () => <svg aria-hidden={true} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.5 0C3.3645 0 0 3.33916 0 7.44346C0 11.5478 3.3645 14.8868 7.5 14.8868C11.6355 14.8868 15 11.5478 15 7.44346C15 3.33916 11.6355 0 7.5 0ZM7.5 13.5335C4.11636 13.5335 1.36364 10.8015 1.36364 7.44346C1.36364 4.0854 4.11636 1.35335 7.5 1.35335C10.8836 1.35335 13.6364 4.0854 13.6364 7.44346C13.6364 10.8015 10.8836 13.5335 7.5 13.5335Z" fill="#595959"/>
  <path d="M7.49973 3.15857C6.99855 3.15857 6.59082 3.56377 6.59082 4.06183C6.59082 4.55944 6.99855 4.96428 7.49973 4.96428C8.00091 4.96428 8.40864 4.55944 8.40864 4.06183C8.40864 3.56377 8.00091 3.15857 7.49973 3.15857Z" fill="#595959"/>
  <path d="M7.50018 6.31714C7.12363 6.31714 6.81836 6.62011 6.81836 6.99381V11.0539C6.81836 11.4276 7.12363 11.7305 7.50018 11.7305C7.87672 11.7305 8.182 11.4276 8.182 11.0539V6.99381C8.182 6.62011 7.87672 6.31714 7.50018 6.31714Z" fill="#595959"/>
</svg>

const naviInputs = "a, button"

const Hint = (props: any) => {
  const {code, label='info', describedBy, /*skipFocus=false,*/  onClick=()=>{}, pullUp, pullRight, pullCenter, showHeader=true, ...rest} = props
  const [show, setShow]=useState(false)
  const [menuHeight, setMenuHeight] = useState(0)
  const [hintId, setHintId] = useState<string>()
  const [hintHeaderId, setHintHeaderId] = useState<string>()
  const [position, setPosition] = useState<any>({top: 'auto', bottom: 'auto'})
  const [contentId, setContentId] = useState<string>('-1')
  const iconRef: any = useRef({}), containerRef: any = useRef({}), naviBeginRef: any = useRef({})
  const tooltips = getModalTooltip()

  useEffect(()=> {
    setHintId(newId())
    setHintHeaderId(newId())
    setContentId(newId())
  }, [])

  useLayoutEffect(() => {
    const scrollArea = iconRef.current.closest('.scroll-area > div')
    const handleScroll = () => {
      hide(false)
    }
    scrollArea && scrollArea.addEventListener('scroll', handleScroll)

    return () => {
      scrollArea && scrollArea.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const handleKeypress = (event: any) => {
    if (event.keyCode === Key.ESCAPE) {
      hide()
    }
  }

  // =================Navigation via keyboard======================
  const getNavis = () => {
    const navis = containerRef.current?.querySelectorAll(naviInputs) || []
    return navis.length > 0 ? {begin: navis[0], end: navis[navis.length-1]} : {}
  }
  const onFocusNaviEnd = (event: any)=>{
    event.preventDefault()    
    // naviBeginRef.current.focus && 
    naviBeginRef.current.focus()
  }
  const setNaviBegin = () => setTimeout(()=>{
    naviBeginRef.current = getNavis().begin
    if (naviBeginRef.current?.addEventListener) {
      naviBeginRef.current.addEventListener('keydown', onKeyDownNaviBegin)
    }
  }, 100)
  const onKeyDownNaviBegin = (event: any)=>{
    if (event.target == naviBeginRef.current && event.which === 9 && event.shiftKey) {
      event.preventDefault()
      getNavis().end?.focus()
    }
  }
  const setDefaultFocus = () => setTimeout(()=>{
    containerRef.current.querySelector('.footer button').focus()
  })
//=========================================================

  const onHintClick = (event: any) => {
    event.preventDefault()
    stopPropagation(event)
    if(show){
      hide()
    }else{
      setShow(true)
      adjustPos()
      setDefaultFocus()
      setNaviBegin()
      document.addEventListener('keydown', handleKeypress)
    }
    onClick()
  }
  const onGotIt = (event: any) => {
    stopPropagation(event)
    hide()
  }

  const hide =(restoreFocus=true)=>{
    setShow(false)
    if (restoreFocus) {
      iconRef.current.focus()
      document.removeEventListener('keydown', handleKeypress)
    }
    if (naviBeginRef.current?.addEventListener) {
      naviBeginRef.current.removeEventListener('keydown', onKeyDownNaviBegin)
    }
  }

  const stopPropagation = (event: any) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
  const content=tooltips.filter(e=>e.code === code)[0]||{value: `Need to predefine it by: ${code}`}

  const onResize = (w: any,h: any) => setMenuHeight(h + 27)

  const adjustPos = () => setTimeout(() => {
    const fixedParent = iconRef.current.closest('.e-modal-dialog')
    const parentPosition = fixedParent ? {
      top: fixedParent.getBoundingClientRect().top,
      bottom: fixedParent.getBoundingClientRect().bottom,
      left: fixedParent.getBoundingClientRect().left,
      right: fixedParent.getBoundingClientRect().right
    } : {top: 0, bottom: 0, left: 0, right: 0}

    const iconPosition = {
      top: iconRef.current.getBoundingClientRect().top,
      bottom: iconRef.current.getBoundingClientRect().bottom,
      left: iconRef.current.getBoundingClientRect().left,
      right: iconRef.current.getBoundingClientRect().right
    }

    const rect = containerRef.current.getBoundingClientRect()
    const offsetH = iconPosition.top + parentPosition.top + rect.height + 10
    let pos = {}
    if(offsetH > window.innerHeight && (iconPosition.bottom + parentPosition.top - rect.height - 10) > 0){
      pos = {
        top: 'auto',
        bottom: (window.innerHeight - iconPosition.top - parentPosition.top) + 'px',
        direction: 'up'
      }
    }else if(offsetH < window.innerHeight){
      pos = {
        top: (iconPosition.top - parentPosition.top) + 'px',
        bottom: 'auto',
        direction: 'down'
      }
    }else{
        pos = {
          direction: 'free'
        }
    }
    setPosition(pos)
  }, 200)

  useEffect(() => {
    const rect = containerRef.current.getBoundingClientRect()
    // ensure the effective top is not negative
    rect.top < 0 && setPosition({ ...position, top: '0px' })
    // ensure the effective bottom is not negative
    rect.bottom < 0 && setPosition({ ...position, bottom: '0px' })
  }, [position])

  const hintStyle = {
    height: menuHeight + 94 + 'px',
    top: position.top,
    bottom: position.bottom
  }

  return (
    <span className={`hint ${pullRight ? 'right' : pullCenter ? 'center' : ''} ${position.direction}`} {...rest}>
      <Div className='info-icon' ref={iconRef} role="button" aria-label={label} aria-describedby={describedBy} title="information" onClick={onHintClick}>
        <Icon/>
      </Div>
      <span ref={containerRef} id={hintId} role="dialog" aria-labelledby={hintHeaderId} aria-describedby={contentId}
        className={`item ${show ? 'show': ''}`} style={hintStyle} onClick={stopPropagation}>
        <div id={hintHeaderId} className='header'>{showHeader ? content.label : ''}</div>
        <Scrollbar><div style={{display: 'flex', flexDirection: 'column'}}>
          <HTMLDiv id={contentId} content={content.value} xss={false}/>
          <ResizeDetector onResize={onResize}/>
        </div></Scrollbar>
        <div className="footer">
          <OdsButton level="secondary" onClick={onGotIt}>Got it</OdsButton>
        </div>
      </span>
      {show && <div tabIndex={0} className='last-focus' onFocus={onFocusNaviEnd}></div>}
    </span>
  )
}

export default Hint
