//--------Http Request Begin-----------------------------------------
export const HTTP_REQUEST = 'HTTP_REQUEST'
//--------Http Request End-------------------------------------------

//--------Auth Begin-------------------------------------------------
export const AUTH_NONE = 'AUTH_NONE'
export const AUTH_STARTING = 'AUTH_STARTING'
export const AUTH_NO_PERMISSION = 'AUTH_NO_PERMISSION'
export const AUTH_NO_ACCESS_BPS = 'AUTH_NO_ACCESS_BPS'
export const AUTH_GO_LANDING_PAGE = 'AUTH_GO_LANDING_PAGE'
export const AUTH_NO_ACCESS_MAINTAIN = 'AUTH_NO_ACCESS_MAINTAIN'
export const AUTH_FIRST_ACCESS = 'AUTH_FIRST_ACCESS'
export const AUTH_REGISTER_ACK = 'AUTH_REGISTER_ACK'
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS'
export const AUTH_USER_PROFILE = 'AUTH_USER_PROFILE'
export const AUTH_INITIALLED = 'AUTH_INITIALLED'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const AUTH_TIMEOUT = 'AUTH_TIMEOUT'
export const AUTH_ADAL_TIMEOUT = 'AUTH_ADAL_TIMEOUT'
export const AUTH_CATAGORY = 'AUTH_CATAGORY'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_ENV = 'AUTH_ENV'
export const AUTH_CLEAN_USER = 'AUTH_CLEAN_USER'
export const AUTH_VERSION_REFRESH = 'AUTH_VERSION_REFRESH'
export const AUTH_PUBLIC_VL_REFRESH = 'AUTH_PUBLIC_VL_REFRESH'
//--------Auth End--------------------------------------------------

//--------Value List Begin------------------------------------------

//--------Value List End--------------------------------------------

//--------Nav Begin-------------------------------------------------
export const NAV_CATA = 'NAV_APP'
export const NAV_SKIP_TO = 'NAV_SKIP_TO'
export const ACTION_IND_REFRESH = 'ACTION_IND_REFRESH'
export const ACTION_AS_IND_REFRESH = 'ACTION_AS_IND_REFRESH'
//--------Nav End--------------------------------------------------

//--------Search Begin-----------------------------------------------
export const AD_USERS_REFRESH = 'AD_USERS_REFRESH'
export const IND_USERS_REFRESH = 'IND_USERS_REFRESH'
export const IND_USERS_REFRESH_ALL = 'IND_USERS_REFRESH_ALL'
export const WIN_ORG_REFRESH = 'WIN_ORG_REFRESH'
//--------Search End-------------------------------------------------
//--------Profile Begin-----------------------------------------------

export const USER_THUMBNAIL_REFRESH = 'USER_THUMBNAIL_REFRESH'

export const PROFILE_REFRESH = 'PROFILE_REFRESH'
export const PROFILE_REFRESH_PERSON = 'PROFILE_REFRESH_PERSON'
export const PROFILE_REFRESH_THUMBNAIL = 'PROFILE_REFRESH_THUMBNAIL'
export const PROFILE_REFRESH_SUMMARY = 'PROFILE_REFRESH_SUMMARY'
export const PROFILE_REFRESH_NEXTNOTE = 'PROFILE_REFRESH_NEXTNOTE'
export const PROFILE_REFRESH_EXPERIENCE = 'PROFILE_REFRESH_EXPERIENCE'
export const PROFILE_TOGGLE_SUPPRESSED = 'PROFILE_TOGGLE_SUPPRESSED'
export const PROFILE_REFRESH_EDUCATION = 'PROFILE_REFRESH_EDUCATION'
export const PROFILE_REFRESH_CERTIFICATION = 'PROFILE_REFRESH_CERTIFICATION'
export const PROFILE_REFRESH_SKILL = 'PROFILE_REFRESH_SKILL'
export const PROFILE_REFRESH_PROJECT = 'PROFILE_REFRESH_PROJECT'
export const PROFILE_REFRESH_HONOR = 'PROFILE_REFRESH_HONOR'
export const PROFILE_REFRESH_LANGUAGE = 'PROFILE_REFRESH_LANGUAGE'
export const PROFILE_REFRESH_LANGUAGE_CERT = 'PROFILE_REFRESH_LANGUAGE_CERT'
export const PROFILE_REFRESH_INTEREST = 'PROFILE_REFRESH_INTEREST'
export const PROFILE_REFRESH_LAST_UPDATE = 'PROFILE_REFRESH_LAST_UPDATE'
export const PROFILE_REFRESH_SUCCESSION = 'PROFILE_REFRESH_SUCCESSION'
export const PROFILE_LANG_CERT_REMOVED = 'PROFILE_LANG_CERT_REMOVED'


export const PROFILE_EDIT_MODE = 'PROFILE_EDIT_MODE'
export const PROFILE_ACCESS_MODE = 'PROFILE_ACCESS_MODE'
//--------Profile End-------------------------------------------------

//--------Mentor Begin-----------------------------------------------
export const MENTOR_PROFILE_REFRESH = 'MENTOR_PROFILE_REFRESH'
export const MENTOR_PROFILE_O_REFRESH = 'MENTOR_PROFILE_O_REFRESH'
export const MENTOR_STEP_REFRESH = 'MENTOR_STEP_REFRESH'
export const MENTOR_WIZARD_ERROR_REFRESH = 'MENTOR_WIZARD_ERROR_REFRESH'

export const MENTOR_MOST_SKILLS_REFRESH = 'MENTOR_MOST_SKILLS_REFRESH'
export const MENTOR_SEARCH_REQ_REFRESH = 'MENTOR_SEARCH_REQ_REFRESH'
export const MENTOR_SEARCH_REQ_CLEAR = 'MENTOR_SEARCH_REQ_CLEAR'
export const MENTOR_PAGE_NO_REFRESH = 'MENTOR_PAGE_NO_REFRESH'
export const MENTOR_SEARCH_RESULT_REFRESH = 'MENTOR_SEARCH_RESULT_REFRESH'
export const MENTOR_SEARCH_LAST_REQ_REFRESH = 'MENTOR_SEARCH_LAST_REQ_REFRESH'
export const MENTOR_SEARCH_SEARCH_RETURNED = 'MENTOR_SEARCH_SEARCH_RETURNED'
export const MENTOR_RESULT_SORT_REFRESH = 'MENTOR_RESULT_SORT_REFRESH'
export const MENTOR_OPEN_SECTION_REFRESH = 'MENTOR_OPEN_SECTION_REFRESH'
export const MENTOR_RELATED_REFRESH = 'MENTOR_RELATED_REFRESH'
export const MENTOR_DELETED_REFRESH = 'MENTOR_DELETED_REFRESH '
export const MENTOR_STATUS_COUNT_REFRESH = 'MENTOR_STATUS_COUNT_REFRESH'
export const MENTOR_SETUP_REFRESH = 'MENTOR_SETUP_REFRESH'
export const MENTOR_SEND_REQUEST_REFRESH = 'MENTOR_SEND_REQUEST_REFRESH'
export const MENTOR_SAVED_REFRESH = 'MENTOR_SAVED_REFRESH'
export const MENTOR_OPERATION_REFRESH = 'MENTOR_OPERATION_REFRESH'
export const MENTOR_NOTIFY_REFRESH = 'MENTOR_NOTIFY_REFRESH'
export const MENTOR_FILTER_WARNING_REFRESH = 'MENTOR_FILTER_WARNING_REFRESH'
//--------Profile End-------------------------------------------------

//--------Performance Begin-------------------------------------------
export const PERFORMANCE_PLAN_YEARS_REFRESH = 'PERFORMANCE_PLAN_YEARS_REFRESH'
export const PERFORMANCE_PLAN_YEARS_CLEAR = 'PERFORMANCE_PLAN_YEARS_CLEAR'
export const PERFORMANCE_SEARCH_PLAN_YEARS_REFRESH = 'PERFORMANCE_SEARCH_PLAN_YEARS_REFRESH'
export const PERFORMANCE_PLAN_ACCESS_MODE = 'PERFORMANCE_PLAN_ACCESS_MODE'

export const PERFORMANCE_PLAN_REFRESH = 'PERFORMANCE_PLAN_REFRESH'
export const PERFORMANCE_PLAN_UPDATE = 'PERFORMANCE_PLAN_UPDATE'
export const PERFORMANCE_PLAN_LOADING = 'PERFORMANCE_PLAN_LOADING'
export const PERFORMANCE_PLAN_CLEAR = 'PERFORMANCE_PLAN_CLEAR'
export const PERFORMANCE_PLAN_DELETED = 'PERFORMANCE_PLAN_DELETED'
export const PERFORMANCE_GOALS_REFRESH = 'PERFORMANCE_GOALS_REFRESH'
export const PERFORMANCE_GOALS_FILTER_REFRESH = 'PERFORMANCE_GOALS_FILTER_REFRESH'
export const PERFORMANCE_GOAL_REFRESH = 'PERFORMANCE_GOAL_REFRESH'
export const PERFORMANCE_POP_GOAL_REFRESH = 'PERFORMANCE_POP_GOAL_REFRESH'
export const PERFORMANCE_POP_SHADOW_GOAL_REFRESH = 'PERFORMANCE_POP_SHADOW_GOAL_REFRESH'
export const PERFORMANCE_GOAL_STEP_REFRESH = 'PERFORMANCE_GOAL_STEP_REFRESH'
export const PERFORMANCE_GOAL_VERIFIES_REFRESH = 'PERFORMANCE_GOAL_VERIFIES_REFRESH'
export const PERFORMANCE_GOAL_VERIFIES_REPLACE = 'PERFORMANCE_GOAL_VERIFIES_REPLACE'
export const PERFORMANCE_GOAL_BANNERS_ADD = 'PERFORMANCE_GOAL_BANNERS_ADD'
export const PERFORMANCE_GOAL_BANNERS_REMOVE = 'PERFORMANCE_GOAL_BANNERS_REMOVE'
// export const PERFORMANCE_GOAL_PAGE_RESIZE = 'PERFORMANCE_GOAL_PAGE_RESIZE'
export const PERFORMANCE_GOAL_UNSAVE_CHECK_REFRESH = 'PERFORMANCE_GOAL_UNSAVE_CHECK_REFRESH'
export const PERFORMANCE_POP_ERRORS_REFRESH = 'PERFORMANCE_POP_ERRORS_REFRESH'
export const PERFORMANCE_NEW_PLAN_REFRESH = 'PERFORMANCE_NEW_PLAN_REFRESH'

export const PERFORMANCE_INLINE_GOAL_REFRESH = 'PERFORMANCE_INLINE_GOAL_REFRESH'
export const PERFORMANCE_READONLY_GOAL_REFRESH = 'PERFORMANCE_READONLY_GOAL_REFRESH'
export const PERFORMANCE_GOALS_SEARCH_REFRESH = 'PERFORMANCE_GOALS_SEARCH_REFRESH'
export const PERFORMANCE_GOAL_UPDATE_REFRESH = 'PERFORMANCE_GOAL_UPDATE_REFRESH'
export const PERFORMANCE_GOAL_DRAFT_UPDATE_REFRESH = 'PERFORMANCE_GOAL_DRAFT_UPDATE_REFRESH'
export const PERFORMANCE_GOAL_DRAFT_UPDATE_CLEAR = 'PERFORMANCE_GOAL_DRAFT_UPDATE_CLEAR'
export const PERFORMANCE_GOAL_REPLY_ADDING = 'PERFORMANCE_GOAL_REPLY_ADDING'
export const PERFORMANCE_GOAL_MEASURE_REFRESH = 'PERFORMANCE_GOAL_MEASURE_REFRESH'
export const PERFORMANCE_GOAL_PROFILE_REFRESH = 'PERFORMANCE_GOAL_PROFILE_REFRESH'
export const PERFORMANCE_GOAL_PROFILE_LAST_UPDATE = 'PERFORMANCE_GOAL_PROFILE_LAST_UPDATE'
export const PERFORMANCE_GOAL_PRIORITY_REFRESH = 'PERFORMANCE_GOAL_PRIORITY_REFRESH'
export const PERFORMANCE_GOAL_LEARN_ACT_REFRESH = 'PERFORMANCE_GOAL_LEARN_ACT_REFRESH'
export const PERFORMANCE_GOAL_LEARN_ROLE_REFRESH = 'PERFORMANCE_GOAL_LEARN_ROLE_REFRESH'
export const PERFORMANCE_GOAL_LEARN_SKILL_REFRESH = 'PERFORMANCE_GOAL_LEARN_SKILL_REFRESH'
export const PERFORMANCE_GOAL_FEEDBACKS_REFRESH = 'PERFORMANCE_GOAL_FEEDBACKS_REFRESH'
export const PERFORMANCE_GOAL_FEEDBACK_REFRESH = 'PERFORMANCE_GOAL_FEEDBACK_REFRESH'
export const PERFORMANCE_GOAL_UPDATE_TAB_TOGGLE = 'PERFORMANCE_GOAL_UPDATE_TAB_TOGGLE'
export const PERFORMANCE_GOAL_LAST_LOADED = 'PERFORMANCE_GOAL_LAST_LOADED'
export const PERFORMANCE_GOAL_FB_LAST_LOADED = 'PERFORMANCE_GOAL_FB_LAST_LOADED'
export const PERFORMANCE_GOAL_NEW_NOTIF_REFRESH = 'PERFORMANCE_GOAL_NEW_NOTIF_REFRESH'
//--------Performance End---------------------------------------------

//--------Rating Begin-------------------------------------------
export const RATING_DETAIL_REFRESH = 'RATING_DETAIL_REFRESH'
export const RATING_DETAIL_INIT = 'RATING_DETAIL_INIT'
export const RATING_STEP_REFRESH = 'RATING_STEP_REFRESH'
export const RATING_UPDATES_REFRESH = 'RATING_UPDATES_REFRESH'
export const RATING_EDIT_REFRESH = 'RATING_EDIT_REFRESH'
export const RATING_EDIT_CANCEL = 'RATING_EDIT_CANCEL'
export const RATING_AUDIT_REFRESH = 'RATING_AUDIT_REFRESH'
export const RATING_CAL_RATE_REFRESH = 'RATING_CAL_RATE_REFRESH'
export const RATING_CAL_RATE_RESET = 'RATING_CAL_RATE_RESET'
export const RATING_OVER_RATE_REFRESH = 'RATING_OVER_RATE_REFRESH'
export const RATING_OVER_RATE_TOGGLE = 'RATING_OVER_RATE_TOGGLE'
export const RATING_DETAIL_CLEAN = 'RATING_DETAIL_CLEAN'
export const RATING_MANAGER_COMMENT_REFRESH = 'RATING_MANAGER_COMMENT_REFRESH'
//--------Rating End---------------------------------------------

export const REVIEW_NEW_REFRESH = 'REVIEW_NEW_REFRESH'
export const REVIEW_NEW_INIT = 'REVIEW_NEW_INIT'
export const REVIEW_STEP_REFRESH = 'REVIEW_STEP_REFRESH'
export const REVIEW_ITEM_ACTIVE_REFRESH = 'REVIEW_ITEM_ACTIVE_REFRESH'
export const REVIEW_MGR_PRE_STEP_REFRESH = 'REVIEW_MGR_PRE_STEP_REFRESH'
export const REVIEW_MGR_PRE_TYPE_REFRESH = 'REVIEW_MGR_PRE_TYPE_REFRESH'
export const REVIEW_IND_PRE_STEP_REFRESH = 'REVIEW_IND_PRE_STEP_REFRESH'

export const REVIEW_UPDATE_REFRESH = 'REVIEW_UPDATE_REFRESH'
export const REVIEW_PANEL_TOGGLE = 'REVIEW_PANEL_TOGGLE'
export const REVIEW_WARNING_REFRESH = 'REVIEW_WARNING_REFRESH'
export const REVIEW_OFFLINE_DOC_REFRESH = 'REVIEW_OFFLINE_DOC_REFRESH'

//--------Goal Lib Begin-------------------------------------------
export const GOALLIB_TOGGLE_ACCESS_MODE = 'GOALLIB_TOGGLE_ACCESS_MODE'
export const GOALLIB_UPDATE_ACCESS_MODE = 'GOALLIB_UPDATE_ACCESS_MODE'
export const GOALLIB_TEMPLATES_REFRESH = 'GOALLIB_TEMPLATES_REFRESH'
export const GOALLIB_TEMPLATE_DETAIL_REFRESH = 'GOALLIB_TEMPLATE_DETAIL_REFRESH'
export const GOALLIB_TEMPLATE_SELECTED = 'GOALLIB_TEMPLATE_SELECTED'
export const GOALLIB_ALL_TEMPLATES_SELECTED = 'GOALLIB_ALL_TEMPLATES_SELECTED'
export const GOALLIB_TAGS_REFRESH = 'GOALLIB_TAGS_REFRESH'
export const GOALLIB_TEMPLATE_FILTER_REFRESH = 'GOALLIB_TEMPLATE_FILTER_REFRESH'
export const GOALLIB_PLAN_YEARS_REFRESH = 'GOALLIB_PLAN_YEARS_REFRESH'
export const GOALLIB_TEAM_TREE_REFRESH = 'GOALLIB_TEAM_TREE_REFRESH'
export const GOALLIB_RESET_TEAM_TREE = 'GOALLIB_RESET_TEAM_TREE'
export const GOALLIB_RESET_YEAR_ELIGIBLE_APV = 'GOALLIB_RESET_YEAR_ELIGIBLE_APV'
export const GOALLIB_TREE_ACTIVE_ASS_ONLY = 'GOALLIB_TREE_ACTIVE_ASS_ONLY'
export const GOALLIB_DIRECT_ACTIVE_ASS_ONLY = 'GOALLIB_DIRECT_ACTIVE_ASS_ONLY'
export const GOALLIB_DIRECT_TEAM_REFRESH = 'GOALLIB_DIRECT_TEAM_REFRESH'
export const GOALLIB_DIRECT_TEAM_PLANS_SELECTED = 'GOALLIB_DIRECT_TEAM_PLANS_SELECTED'
export const GOALLIB_DEPLOY_PLANS_SELECTED = 'GOALLIB_DEPLOY_PLANS_SELECTED'
export const GOALLIB_DEPLOY_PLANS_UNSELECTED = 'GOALLIB_DEPLOY_PLANS_UNSELECTED'
export const GOALLIB_DEPLOY_MGRS_SELECTED = 'GOALLIB_DEPLOY_MGRS_SELECTED'
export const GOALLIB_DEPLOY_MGRS_UNSELECTED = 'GOALLIB_DEPLOY_MGRS_UNSELECTED'
export const GOALLIB_EXT_JOB_CLASS_SELECTED = 'GOALLIB_EXT_JOB_CLASS_SELECTED'
export const GOALLIB_EXT_JOB_CLASS_UNSELECTED = 'GOALLIB_EXT_JOB_CLASS_UNSELECTED'
export const GOALLIB_DIRECT_JOB_CLASS_SELECTED = 'GOALLIB_DIRECT_JOB_CLASS_SELECTED'
export const GOALLIB_DIRECT_JOB_CLASS_UNSELECTED = 'GOALLIB_DIRECT_JOB_CLASS_UNSELECTED'
export const GOALLIB_DEPLOY_EXPAND_ITEMS = 'GOALLIB_DEPLOY_EXPAND_ITEMS'
export const GOALLIB_DEPLOY_PROGRESS_REFRESH = 'GOALLIB_DEPLOY_PROGRESS_REFRESH'
export const GOALLIB_DEPLOY_RESULT_REFRESH = 'GOALLIB_DEPLOY_RESULT_REFRESH'

export const GOALLIB_TEMPLATE_MEASURE_REFRESH = 'GOALLIB_TEMPLATE_MEASURE_REFRESH'
export const GOALLIB_TEMPLATE_INLINE_REFRESH = 'GOALLIB_TEMPLATE_INLINE_REFRESH'
export const GOALLIB_TEMPLATE_PRIORITY_REFRESH = 'GOALLIB_TEMPLATE_PRIORITY_REFRESH'
export const GOALLIB_TEMPLATE_LEARN_ACT_REFRESH = 'GOALLIB_TEMPLATE_LEARN_ACT_REFRESH'
export const GOALLIB_TEMPLATE_LEARN_ROLE_REFRESH = 'GOALLIB_TEMPLATE_LEARN_ROLE_REFRESH'
export const GOALLIB_TEMPLATE_LEARN_SKILL_REFRESH = 'GOALLIB_TEMPLATE_LEARN_SKILL_REFRESH'
export const GOALLIB_TEMPLATE_DEPLOY_HISTORY_REFRESH = 'GOALLIB_TEMPLATE_DEPLOY_HISTORY_REFRESH'
export const GOALLIB_DEPLOY_CASCADE_MODE = 'GOALLIB_DEPLOY_CASCADE_MODE'

//--------Goal Lib End---------------------------------------------

//--------MyTeam Begin-------------------------------------------
export const TEAM_YEARS_REFRESH = 'TEAM_YEARS_REFRESH'
export const TEAM_YEARS_RESET = 'TEAM_YEARS_RESET'
export const TEAM_PLANS_REFRESH = 'TEAM_PLANS_REFRESH'
export const TEAM_PP_YEARS_REFRESH = 'TEAM_PP_YEARS_REFRESH'
export const TEAM_PP_PLANS_REFRESH = 'TEAM_PP_PLANS_REFRESH'
export const TEAM_STAGES_REFRESH = 'TEAM_STAGES_REFRESH'
export const TEAM_LAST_PATH = 'TEAM_LAST_PATH'
export const TEAM_QUERY_PARAM_REFRESH = 'TEAM_QUERY_PARAM_REFRESH'
export const TEAM_SHOWOPENPLAN_REFRESH = 'TEAM_SHOWOPENPLAN_REFRESH'

export const EXTEND_YEARS_REFRESH = 'EXTEND_YEARS_REFRESH'
export const EXTEND_YEAR_REFRESH = 'EXTEND_YEAR_REFRESH'
export const EXTEND_PLANS_REFRESH = 'EXTEND_PLANS_REFRESH'
export const EXTEND_QUERY_PARAM_REFRESH = 'EXTEND_QUERY_PARAM_REFRESH'
export const EXTEND_LIST_SELECT = 'EXTEND_LIST_SELECT'
export const EXTEND_LIST_SELECT_CLEAR = 'EXTEND_LIST_SELECT_CLEAR'
export const EXTEND_MANAGER_REFRESH = 'EXTEND_MANAGER_REFRESH'
export const EXTEND_LAST_SNAPSHOT = 'EXTEND_LAST_SNAPSHOT'
export const EXTEND_MANAGER_WIZARD_REFRESH = 'EXTEND_MANAGER_WIZARD_REFRESH'
export const EXTEND_MANAGER_SYNC_REFRESH = 'EXTEND_MANAGER_SYNC_REFRESH'
// export const TEAM_PLANS_OPEN_PP = 'TEAM_PLANS_OPEN_PP'
// export const TEAM_PLANS_CLOSE_PP = 'TEAM_PLANS_CLOSE_PP'
//--------MyTeam End---------------------------------------------

//--------Talent search Begin------------------------------------
export const TALENT_SEARCH_REQ_REFRESH = 'TALENT_SEARCH_REQ_REFRESH'
export const TALENT_SEARCH_REQ_CLEAR = 'TALENT_SEARCH_REQ_CLEAR'
export const TALENT_SEARCH_LAST_REQ_REFRESH = 'TALENT_SEARCH_LAST_REQ_REFRESH'
export const TALENT_SEARCH_RESULT_REFRESH = 'TALENT_SEARCH_RESULT_REFRESH'
export const TALENT_SEARCH_PAGE_NO_REFRESH = 'TALENT_SEARCH_PAGE_NO_REFRESH'
export const TALENT_SAVED_QUERIES_REFRESH = 'TALENT_SAVED_QUERIES_REFRESH'
export const TALENT_SAVED_QUERY_REFRESH = 'TALENT_SAVED_QUERY_REFRESH'
export const TALENT_RSLT_LISTS_REFRESH = 'TALENT_RSLT_LISTS_REFRESH'
export const TALENT_RSLT_LIST_REFRESH = 'TALENT_RSLT_LIST_REFRESH'
export const TALENT_RSLT_LIST_HISTORY_REFRESH = 'TALENT_RSLT_LIST_HISTORY_REFRESH'
export const TALENT_RSLT_OLD_LIST_HISTORY_REFRESH = 'TALENT_RSLT_OLD_LIST_HISTORY_REFRESH'
export const TALENT_RSLT_LIST_UPDATE_REFRESH = 'TALENT_RSLT_LIST_UPDATE_REFRESH'
export const TALENT_RSLT_LIST_LOADED_UPDATE_REFRESH = 'TALENT_RSLT_LIST_LOADED_UPDATE_REFRESH'
export const TALENT_RSLT_WORKINGLIST_REFRESH = 'TALENT_RSLT_WORKINGLIST_REFRESH'
export const TALENT_RSLT_MEMBER_REFRESH = 'TALENT_RSLT_MEMBER_REFRESH'
export const TALENT_RSLT_POSITIONS_REFRESH = 'TALENT_RSLT_POSITIONS_REFRESH'
export const TALENT_RSLT_NORMAL_SORT_REFRESH = 'TALENT_RSLT_NORMAL_SORT_REFRESH'
export const TALENT_RSLT_REMOVED_SORT_REFRESH = 'TALENT_RSLT_REMOVED_SORT_REFRESH'
export const TALENT_PAGE_STATUS_REFRESH = 'TALENT_PAGE_STATUS_REFRESH'
export const TALENT_SEARCH_SEARCH_RETURNED = 'TALENT_SEARCH_SEARCH_RETURNED'
export const TALENT_USER_REFRESH = 'TALENT_USER_REFRESH'
export const TALENT_USER_CLEAR = 'TALENT_USER_CLEAR'
export const TALENT_RSLT_LIST_EXPAND = 'TALENT_RSLT_LIST_EXPAND'
export const TALENT_RSLT_SHARE_DETAIL_REFRESH = 'TALENT_RSLT_SHARE_DETAIL_REFRESH'
export const TALENT_RSLT_NORMAL_TOGGLE = 'TALENT_RSLT_NORMAL_TOGGLE'
export const TALENT_RSLT_REMOVED_TOGGLE = 'TALENT_RSLT_REMOVED_TOGGLE'
export const TALENT_RSLT_RESET_TOGGLE = 'TALENT_RSLT_RESET_TOGGLE'
export const TALENT_RSLT_HISTORY_TOGGLE = 'TALENT_RSLT_HISTORY_TOGGLE'
export const TALENT_RSLT_DETAIL_TOGGLE = 'TALENT_RSLT_DETAIL_TOGGLE'
export const TALENT_RSLT_WIZARD_REFRESH = 'TALENT_RSLT_WIZARD_REFRESH'
export const TALENT_RSLT_ACCESS_CHANGES_REFRESH = 'TALENT_RSLT_ACCESS_CHANGES_REFRESH'
export const TALENT_ACCESS_MODE = 'TALENT_ACCESS_MODE'

//--------Talent search End-------------------------------------

//--------Feedback Begin-------------------------------------------
export const FEEDBACK_REFRESH = 'FEEDBACK_REFRESH'
//--------Feedback End---------------------------------------------

//--------Successor Confirmation Begin-----------------------------
export const SUCCESSOR_REFRESH = 'SUCCESSOR_REFRESH'
//--------Successor Confirmation End-------------------------------

//--------Admin Begin-------------------------------------------
export const ADMIN_ACTIVE_MENU_CHANGE = 'ADMIN_ACTIVE_MENU_CHANGE'
export const ADMIN_ACTIVE_MENU_ID_CHANGE = 'ADMIN_ACTIVE_MENU_ID_CHANGE'

export const ADMIN_VALUE_LIST_TYPES_REFRESH = 'ADMIN_VALUE_LIST_TYPES_REFRESH'
export const ADMIN_VALUE_LIST_VALUES_REFRESH = 'ADMIN_VALUE_LIST_VALUES_REFRESH'
export const ADMIN_VALUE_LIST_DETAIL_VALUES_REFRESH = 'ADMIN_VALUE_LIST_DETAIL_VALUES_REFRESH'
export const ADMIN_VALUE_LIST_VALUE_REFRESH = 'ADMIN_VALUE_LIST_VALUE_REFRESH'

export const ADMIN_EXTRACT_DATA_REFRESH = 'ADMIN_EXTRACT_DATA_REFRESH'

export const ADMIN_AUDIT_LIST_REFRESH = 'ADMIN_AUDIT_LIST_REFRESH'
export const ADMIN_AUDIT_LIST_CLEAR = 'ADMIN_AUDIT_LIST_CLEAR'
export const ADMIN_AUDIT_DETAIL_REFRESH = 'ADMIN_AUDIT_DETAIL_REFRESH'
export const ADMIN_AUDIT_ENTITY_REFRESH = 'ADMIN_AUDIT_ENTITY_REFRESH'
export const ADMIN_AUDIT_ACTION_REFRESH = 'ADMIN_AUDIT_ACTION_REFRESH'

export const ADMIN_IMPORT_LIST_REFRESH = 'ADMIN_IMPORT_LIST_REFRESH'
export const ADMIN_IMPORT_DETAIL_REFRESH = 'ADMIN_IMPORT_DETAIL_REFRESH'
export const ADMIN_IMPORT_DETAIL_CLEAR = 'ADMIN_IMPORT_DETAIL_CLEAR'
export const ADMIN_IMPORT_ANALYZE_REFRESH = 'ADMIN_IMPORT_ANALYZE_REFRESH'
// export const SSE_STATUS_REFRESH = 'SSE_STATUS_REFRESH'
export const SSE_STATUS_REFRESH1 = 'SSE_STATUS_REFRESH1'
export const SSE_INPRO_REFRESH = 'SSE_INPRO_REFRESH'

export const ADMIN_IMPORT_WIN_LIST_REFRESH = 'ADMIN_IMPORT_WIN_LIST_REFRESH'
export const ADMIN_IMPORT_WIN_CRITERIA_REFRESH = 'ADMIN_IMPORT_WIN_CRITERIA_REFRESH'
export const ADMIN_IMPORT_WIN_DETAIL_REFRESH = 'ADMIN_IMPORT_WIN_DETAIL_REFRESH'
export const ADMIN_IMPORT_WIN_DETAIL_CLEAR = 'ADMIN_IMPORT_WIN_DETAIL_CLEAR'
export const ADMIN_IMPORT_WIN_ANALYZE_REFRESH = 'ADMIN_IMPORT_WIN_ANALYZE_REFRESH'

export const ADMIN_USER_ROLES_REFRESH = 'ADMIN_USER_ROLES_REFRESH'
export const ADMIN_USER_ROLES_CLEAR = 'ADMIN_USER_ROLES_CLEAR'
export const ADMIN_USER_ROLE_REFRESH = 'ADMIN_USER_ROLE_REFRESH'
export const ADMIN_USER_MIN_REFRESH = 'ADMIN_USER_MIN_REFRESH'
export const ADMIN_USER_ROLE_VALUES_REFRESH = 'ADMIN_USER_ROLE_VALUES_REFRESH'
export const ADMIN_IND_ROLES_REFRESH = 'ADMIN_IND_ROLES_REFRESH'
export const ADMIN_USER_SORT_REFRESH = 'ADMIN_USER_SORT_REFRESH'

export const ADMIN_CACHE_NAMES_REFRESH = 'ADMIN_CACHE_NAMES_REFRESH'
export const ADMIN_CACHE_KEYS_REFRESH = 'ADMIN_CACHE_KEYS_REFRESH'
export const ADMIN_CACHE_DETAIL_REFRESH = 'ADMIN_CACHE_DETAIL_REFRESH'

export const ADMIN_PROCESS_LOG_LIST_REFRESH = 'ADMIN_PROCESS_LOG_LIST_REFRESH'
// export const ADMIN_PROCESS_LOG_TYPE_REFRESH = 'ADMIN_PROCESS_LOG_TYPE_REFRESH'
export const ADMIN_PROCESS_LOG_DETAIL_REFRESH = 'ADMIN_PROCESS_LOG_DETAIL_REFRESH'
export const ADMIN_PROCESS_LOG_LATEST_REFRESH = 'ADMIN_PROCESS_LOG_LATEST_REFRESH'
export const ADMIN_PROCESS_LOG_LIST2_REFRESH = 'ADMIN_PROCESS_LOG_LIST2_REFRESH'
export const ADMIN_PROCESS_LOG_CRITERIA_REFRESH = 'ADMIN_PROCESS_LOG_CRITERIA_REFRESH'
export const ADMIN_PROCESS_LOG_CRITERIA_CLEAR = 'ADMIN_PROCESS_LOG_CRITERIA_CLEAR'

export const ADMIN_ANNOUNCEMENT_LIST_REFRESH = 'ADMIN_ANNOUNCEMENT_LIST_REFRESH'
export const ADMIN_ANNOUNCEMENT_DETAIL_REFRESH = 'ADMIN_ANNOUNCEMENT_DETAIL_REFRESH'

export const ADMIN_SENDGRID_EVENT_REFRESH = 'ADMIN_SENDGRID_EVENT_REFRESH'
export const ADMIN_SENDGRID_LIST_REFRESH = 'ADMIN_SENDGRID_LIST_REFRESH'

export const ADMIN_ACCESS_LOG_REFRESH = 'ADMIN_ACCESS_LOG_REFRESH'

export const TM_ORG_TREE_REFRESH = 'TM_ORG_TREE_REFRESH'
//--------Admin End---------------------------------------------

//--------CorpAdmin Begin-------------------------------------------
export const ADMIN_INDIVIDUAL_REFRESH = 'ADMIN_INDIVIDUAL_REFRESH'
export const ADMIN_IND_ID_REFRESH = 'ADMIN_IND_ID_REFRESH'
export const ADMIN_INDIVIDUAL_RESET = 'ADMIN_INDIVIDUAL_RESET'
export const ADMIN_IND_ASS_REFRESH = 'ADMIN_IND_ASS_REFRESH'
export const ADMIN_IND_PLAN_YEARS_REFRESH = 'ADMIN_IND_PLAN_YEARS_REFRESH'
export const ADMIN_IND_PLAN_REFRESH = 'ADMIN_IND_PLAN_REFRESH'
export const ADMIN_PLAN_YEARS_REFRESH = 'ADMIN_PLAN_YEARS_REFRESH'
export const ADMIN_PLAN_YEAR_REFRESH = 'ADMIN_PLAN_YEAR_REFRESH'
export const ADMIN_STRUCTURE_REFRESH = 'ADMIN_STRUCTURE_REFRESH'

export const ADMIN_UNMATCHED_WIN_LIST_REFRESH = 'ADMIN_UNMATCHED_WIN_LIST_REFRESH'
export const ADMIN_UNMATCHED_WIN_REFRESH = 'ADMIN_UNMATCHED_WIN_REFRESH'
export const ADMIN_UNMATCHED_WIN_EMAIL_REFRESH = 'ADMIN_UNMATCHED_WIN_EMAIL_REFRESH'

export const ADMIN_ORG_PRIORITIES_REFRESH = 'ADMIN_ORG_PRIORITIES_REFRESH'
export const ADMIN_ORG_SELECTED_REFRESH = 'ADMIN_ORG_SELECTED_REFRESH'
export const ADMIN_ORG_PRIORITY_SELECTED_REFRESH = 'ADMIN_ORG_PRIORITY_SELECTED_REFRESH'
export const ADMIN_ORG_PRIORITY_RESET = 'ADMIN_ORG_PRIORITY_RESET'
export const ADMIN_ORG_TREE_REFRESH = 'ADMIN_ORG_TREE_REFRESH'
export const ADMIN_ORG_CHILDREN_REFRESH = 'ADMIN_ORG_CHILDREN_REFRESH'
export const ADMIN_TM_MINISTRIES_REFRESH = 'ADMIN_TM_MINISTRIES_REFRESH'
export const ADMIN_TM_MINISTRY_SELECTED_REFRESH = 'ADMIN_TM_MINISTRY_SELECTED_REFRESH'
export const ADMIN_WIN_MINISTRIES_REFRESH = 'ADMIN_WIN_MINISTRIES_REFRESH'
export const ADMIN_WIN_MINISTRIES_RESET = 'ADMIN_WIN_MINISTRIES_RESET'
export const ADMIN_WIN_MINS_REFRESH = 'ADMIN_WIN_MINS_REFRESH'

export const ADMIN_RATING_LIST_REFRESH = 'ADMIN_RATING_LIST_REFRESH'
export const ADMIN_RATING_LIST_REPLACE = 'ADMIN_RATING_LIST_REPLACE'
export const ADMIN_RATING_LIST_SELECT = 'ADMIN_RATING_LIST_SELECT'
export const ADMIN_RATING_LIST_SELECT_CLEAR = 'ADMIN_RATING_LIST_SELECT_CLEAR'
export const ADMIN_RATING_LIST_CLEAR = 'ADMIN_RATING_LIST_CLEAR'
export const ADMIN_RATING_DETAIL_REFRESH = 'ADMIN_RATING_DETAIL_REFRESH'
export const ADMIN_RATING_SEARCH_CRIT_UPDATE = 'ADMIN_RATING_SEARCH_CRIT_UPDATE'
export const ADMIN_RATING_SEARCH_CRIT_RESET = 'ADMIN_RATING_SEARCH_CRIT_RESET'
export const ADMIN_RATING_YEARS_REFRESH = 'ADMIN_RATING_YEARS_REFRESH'
export const ADMIN_RATING_MINISTRIES_REFRESH = 'ADMIN_RATING_MINISTRIES_REFRESH'
export const ADMIN_RATING_MINISTRY_UPDATE = 'ADMIN_RATING_MINISTRY_UPDATE'
export const ADMIN_RATING_STAGES_VL_REFRESH = 'ADMIN_RATING_STAGES_VL_REFRESH'
export const ADMIN_RATING_STAGES_REFRESH = 'ADMIN_RATING_STAGES_REFRESH'
export const ADMIN_RATING_ALL_STAGES_REFRESH = 'ADMIN_RATING_ALL_STAGES_REFRESH'
export const ADMIN_RATING_GOAL_STATUS_REFRESH = 'ADMIN_RATING_GOAL_STATUS_REFRESH'
export const ADMIN_RATING_CALC_REFRESH = 'ADMIN_RATING_CALC_REFRESH'
export const ADMIN_RATING_CALC_CLEAR = 'ADMIN_RATING_CALC_CLEAR'
export const ADMIN_RATING_DETAIL_UPDATE = 'ADMIN_RATING_DETAIL_UPDATE'
export const ADMIN_RATING_REMAIN_COUNT_REFRESH = 'ADMIN_RATING_REMAIN_COUNT_REFRESH'
export const ADMIN_RATING_REVIEW_TAB_REFRESH = 'ADMIN_RATING_REVIEW_TAB_REFRESH'
export const ADMIN_RATING_REVIEW_STEP_REFRESH = 'ADMIN_RATING_REVIEW_STEP_REFRESH'

export const ADMIN_BARG_REVIEW_REFRESH = 'ADMIN_BARG_REVIEW_REFRESH'
export const ADMIN_REVIEW_DETAIL_UPDATE = 'ADMIN_REVIEW_DETAIL_UPDATE'
export const ADMIN_BARG_UPDATE_REFESH = 'ADMIN_BARG_UPDATE_REFESH'
export const ADMIN_BARG_UPDATE_REVIEW = 'ADMIN_BARG_UPDATE_REVIEW'

export const ADMIN_REPORT_MINISTRIES_REFRESH = 'ADMIN_REPORT_MINISTRIES_REFRESH'
export const ADMIN_REPORT_YEARS_REFRESH = 'ADMIN_REPORT_YEARS_REFRESH'

export const ADMIN_SUCC_LIST_REFRESH = 'ADMIN_SUCC_LIST_REFRESH'
export const ADMIN_SUCC_LIST_SELECT = 'ADMIN_SUCC_LIST_SELECT'
export const ADMIN_SUCC_MINISTRIES_REFRESH = 'ADMIN_SUCC_MINISTRIES_REFRESH'
export const ADMIN_SUCC_DEPTS_REFRESH = 'ADMIN_SUCC_DEPTS_REFRESH'
export const ADMIN_SUCC_SEARCH_CRIT_UPDATE = 'ADMIN_SUCC_SEARCH_CRIT_UPDATE'

//--------Admin End---------------------------------------------


//--------Global Begin--------------------------------------------
export const SPINNER_SHOW = 'SPINNER_SHOW'
export const SPINNER_HIDE = 'SPINNER_HIDE'
export const SPINNER_HIDE_CLEAR = 'SPINNER_HIDE_CLEAR'
export const SPINNER_INPROGRESS = 'SPINNER_INPROGRESS'

export const MESSAGE_SHOW = 'MESSAGE_SHOW'
export const MESSAGE_HIDE = 'MESSAGE_HIDE'

export const MESSAGE2_SHOW = 'MESSAGE2_SHOW'
export const MESSAGE2_HIDE = 'MESSAGE2_HIDE'

export const MESSAGE3_SHOW = 'MESSAGE3_SHOW'
export const MESSAGE3_HIDE = 'MESSAGE3_HIDE'

export const MESSAGE4_SHOW = 'MESSAGE4_SHOW'

export const MESSAGE5_SHOW = 'MESSAGE5_SHOW'
export const MESSAGE5_HIDE = 'MESSAGE5_HIDE'

export const REGISGER_UNSAVED = 'REGISGER_UNSAVED'
export const UNREGISGER__UNSAVED = 'UNREGISGER__UNSAVED'

export const PATH_NOTIFY_REFRESH = 'PATH_NOTIFY_REFRESH'

export const UPLOAD_PROGRESS_CLEAR = 'UPLOAD_PROGRESS_CLEAR'
export const UPLOAD_PROGRESS_REFRESH = 'UPLOAD_PROGRESS_REFRESH'

export const UI_NOTIFICATION_REFRESH = 'UI_NOTIFICATION_REFRESH'
export const UI_NOTIFICATION_FETCHING = 'UI_NOTIFICATION_FETCHING'
export const UI_NOTIFICATION_FILTER = 'UI_NOTIFICATION_FILTER'

export const AUTO_RESIZE_MODAL_REFRESH = 'AUTO_RESIZE_MODAL_REFRESH'

export const DOWNLOAD_REQUEST_REFRESH = 'DOWNLOAD_REQUEST_REFRESH'

// export const DOWNLOAD_CHECK_REFRESH = 'DOWNLOAD_CHECK_REFRESH'
// export const DOWNLOAD_PROCESS_REFRESH = 'DOWNLOAD_PROCESS_REFRESH'
// export const DOWNLOAD_DELETE_REFRESH = 'DOWNLOAD_DELETE_REFRESH'

export const DOWNLOAD_TOGGLE_REFRESH = 'DOWNLOAD_TOGGLE_REFRESH'
export const DOWNLOAD_WAITING_REFRESH = 'DOWNLOAD_WAITING_REFRESH'
export const DOWNLOAD_TRIGGER_REFRESH = 'DOWNLOAD_TRIGGER_REFRESH'
export const DOWNLOAD_START_REFRESH = 'DOWNLOAD_START_REFRESH'
export const DOWNLOAD_REMOVE_REFRESH = 'DOWNLOAD_REMOVE_REFRESH'
export const DOWNLOAD_BRANDNEW_REFRESH = 'DOWNLOAD_BRANDNEW_REFRESH'
export const DOWNLOAD_FINISHED_REFRESH = 'DOWNLOAD_FINISHED_REFRESH'

export const DOWNLOAD_CHECK_REFRESH = 'DOWNLOAD_CHECK_REFRESH'
export const DOWNLOAD_PROCESS_REFRESH = 'DOWNLOAD_PROCESS_REFRESH'
export const DOWNLOAD_DELETE_REFRESH = 'DOWNLOAD_DELETE_REFRESH'

export const RESOURCE_LOCK_APPLIED = 'RESOURCE_LOCK_APPLIED'
export const RESOURCE_LOCK_RELEASE = 'RESOURCE_LOCK_RELEASE'
export const RESOURCE_LOCK_RESET = 'RESOURCE_LOCK_RESET'
export const RESOURCE_LOCK_REFRESH = 'RESOURCE_LOCK_REFRESH'
export const RESOURCE_LOCK_INVALID = 'RESOURCE_LOCK_INVALID'

export const RESOURCE_LOCK_KEEPING_CONFIRM = 'RESOURCE_LOCK_KEEPING_CONFIRM'
export const RESOURCE_LOCK_KEEPING_REFRESH = 'RESOURCE_LOCK_KEEPING_REFRESH'

export const RESOURCE_LOCK_TIMEOUT_START = 'RESOURCE_LOCK_TIMEOUT_START'
export const RESOURCE_LOCK_TIMEOUT_END = 'RESOURCE_LOCK_TIMEOUT_END'
export const RESOURCE_LOCK_TIMEOUT_CLOSE = 'RESOURCE_LOCK_TIMEOUT_CLOSE'

export const CRITERIA_AUTO_COMP_REFRESH = 'CRITERIA_AUTO_COMP_REFRESH'
export const CRITERIA_AUTO_COMP_CLEAR = 'CRITERIA_AUTO_COMP_CLEAR'
export const CRITERIA_SEARCH_ACTIVE_APPLY_REFRESH = 'CRITERIA_SEARCH_ACTIVE_APPLY_REFRESH'

export const DESTROY_ALL = 'DESTROY_ALL'
//--------Global End----------------------------------------------
