import { 
  RATING_DETAIL_INIT,
  RATING_DETAIL_REFRESH,
  RATING_STEP_REFRESH,
  RATING_UPDATES_REFRESH,
  RATING_EDIT_REFRESH,
  RATING_EDIT_CANCEL,
  RATING_AUDIT_REFRESH,
  RATING_CAL_RATE_REFRESH,
  RATING_CAL_RATE_RESET,
  RATING_OVER_RATE_REFRESH,
  RATING_OVER_RATE_TOGGLE,
  RATING_DETAIL_CLEAN,
  RATING_MANAGER_COMMENT_REFRESH
} from 'services/action-types'
import { VALUE_RATING_FACTOR_CAL_CALIB, VALUE_RATING_FACTOR_OVR_CALIB } from 'services/constants'
import { clone } from 'utils'
import { rebuild } from './util'

const initialCalc = {
  code: 'SELECT',
  jsonData: {
    header: '',
    detail: 'Assess all four factors to see the performance rating.'
  }
}

const initialCalcReadonly = {
  code: 'SELECT',
  jsonData: {
    header: '',
    detail: 'Assess all four factors to see the performance rating.'
  }
}

// const initialCalcReadonly = {
//   code: 'SELECT',
//   jsonData: {
//     header: '',
//     detail: 'No performance rating.'
//   }
// }

const initialState = {
  step: 0,
  stage: {},
  detail: {
    submitBy: {},
    continueRate: {},
    conversation: {
      noConversationReason: {}
    },
    details: [],
    showJust: false,
    formula: {},
    calRate: {}
  },
  updates: [],
  edit: [false, false, false, false],
  audit: {}
}

export const ratingReducer =  (state = initialState, action) => {
  switch (action.type) {
  case RATING_DETAIL_INIT:
    const _detail = {
      ...action.value,
      continueRate: action.value.continueRate || {},
      conversation: action.value.conversation || {
        noConversationReason: {}
      },
      submitBy: action.value.submitBy || {},
      acks: action.value.acks || [],
      details: action.value.details || [],
      calRate: getCalcDetail(action.value)
    }
    return state = { 
      ...state, 
      detail: _detail,
      detailO: clone(_detail),
    }
  case RATING_DETAIL_REFRESH:
    return state = { 
      ...state, 
      detail: action.value
    }
  case RATING_STEP_REFRESH:
    return state = { 
      ...state, 
      step: action.value,
    }
  case RATING_UPDATES_REFRESH:
    return state = { 
      ...state, 
      updates: action.value,
    }
  case RATING_AUDIT_REFRESH:
    return state = { 
      ...state, 
      audit: action.value,
    }
  case RATING_CAL_RATE_REFRESH:
    return state = { 
      ...state, 
      detail: {...state.detail, calRate: rebuild(action.value)}
    }
  case RATING_CAL_RATE_RESET:
    return state = { 
      ...state, 
      detail: {...state.detail, calRate: initialCalc}
    }
  case RATING_OVER_RATE_REFRESH:
    return state = { 
      ...state,
      detail: {...state.detail, calRate: action.value}
    }
  case RATING_OVER_RATE_TOGGLE:
    return state = { 
      ...state, 
      detail: {...state.detail, showJust: action.value}
    }
  case RATING_EDIT_REFRESH:
    const _edit = [...state.edit]
    _edit[action.value.step] = {open: action.value.open, cancel: action.value.cancel}
    return state = { 
      ...state, 
      edit: _edit,
    }
  case RATING_EDIT_CANCEL:
    return state = { 
      ...state, 
      detail: {...state.detailO, calRate: getCalcDetail(state.detailO)}
    }
  case RATING_DETAIL_CLEAN:
    return state = { 
      ...state, ...initialState
    }
  case RATING_MANAGER_COMMENT_REFRESH:
    const detail_1 = {
      ...action.value,
      details: action.value.details || [],
      acks: state.detail.acks,
      calRate: getCalcDetail(action.value)
    }
    
    return state = { 
      ...state, 
      detail: detail_1,
      detailO: clone(detail_1),
    }
  default:
    return state
  }
}

export const getCalcDetail = (detail, readonly=false) => {
  const cal =  detail?.details.filter(d=>d.ratingFactor.value === VALUE_RATING_FACTOR_OVR_CALIB)[0] 
            || detail?.details.filter(d=>d.ratingFactor.value === VALUE_RATING_FACTOR_CAL_CALIB)[0]
  return cal ? rebuild(cal.ratingValue) : readonly ? initialCalcReadonly : initialCalc
}