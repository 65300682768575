import { MESSAGE4_SHOW } from 'services/action-types'
import heartBeat from 'services/heart-beat'
const PING_TIMEOUT_SHORT = 20 * 1000

const initialState = {
  content: ''
}

export const message4_reducer= (state = initialState, action: any) => {
  switch (action.type) {
  case MESSAGE4_SHOW:
    const push_msg = (action.value || []).filter((t:any)=>t.app === 'TMS')[0]?.push_msg || ''
    if(state.content !== push_msg){
      heartBeat.stop()
      heartBeat.start(push_msg ? PING_TIMEOUT_SHORT : 0)
    }
    return { ...state, content: push_msg}
  default:
    return state
  }
}