import { dispatchFetch } from 'store'

export const update_resource_access = (value: any) => {
  dispatchFetch({
    spinner: true,
    path: `api/mentor/profile/res/${value.id}`,
    method: 'POST',
    body: value,
    success: ()=>{}
  })
}