import React, {useState, useEffect} from 'react'
import { loggerInfo, Key } from 'utils'
import { Div, Span } from './elements'
import { DropDownMenu }  from './dropdown'
import './gadget2.scss'

const Gadget2 = (props: any) => {
  const { collapsable, readOnly, headerText, addActions=[], updateActions=[], children, multi, menuStyle, collapsed = false } = props
  const [collapse, setCollapse] = useState(false)

  useEffect(()=> {
    collapsed && onCollapse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCollapse = () => setCollapse(!collapse)

  const onKeyDown = (e: any, action: any) => {
      if(e.keyCode === Key.ENTER){
          action()
      }
  }
  loggerInfo("gadget2.render")

  return (
    <div className='gadget2'>
        <div className="header">
            <span className="header-title" role='heading'>
                {collapsable && <Span role='button' aria-expanded={!collapse? 'true' : 'false'} aria-label={`${!collapse? 'Collapse' : 'Expand'} ${headerText}`} className={`collapser fa fa-chevron-${!collapse? 'down' : 'right'}`} onClick={onCollapse}></Span>}

                <span className="title" aria-level={3} style={{paddingLeft: !collapsable ? '20px': '5px'}}>{headerText}</span>

                {!readOnly && <>
                    {addActions.length === 1 && <Span style={{color: 'grey'}} aria-label={headerText} role="button" className='fa fa-plus-circle header-action' onKeyDown={(e: any)=>onKeyDown(e, addActions[0].action)} onClick={addActions[0].action}/>}
                    {!multi && updateActions.length === 1 && <Span style={{color: 'grey'}} className='fa fa-cogs header-action' onKeyDown={(e: any)=>onKeyDown(e, updateActions[0].action)}  onClick={updateActions[0].action}/>}
                </>
                }
            </span>
            <span className="header-spacer"></span>

            {!readOnly && <>
                {addActions.length > 1 && <span className="header-actions">
                    <DropDownMenu display={<div><span style={{color: 'grey'}} className='fa fa-plus-circle'/></div>}>
                        {addActions.map((a: any, i: any)=><Div role="option" className='dropdown-item' key={i} onClick={a.action}>{a.label}</Div>)}
                    </DropDownMenu>
                    </span>
                }

                {((multi && updateActions.length > 0) || updateActions.length > 1) && <span className="header-actions">
                    <DropDownMenu display={<span aria-label={headerText} className="fa fa-ellipsis-h" style={{marginLeft: '10px'}} ></span>} menuStyle={menuStyle} >
                        {updateActions.map((a: any, i: any)=><Div role="option" className='dropdown-item' key={i} onClick={a.action}>{a.label}</Div>)}
                    </DropDownMenu>
                    </span>
                }
            </>}
        </div>

        {!collapse && <div className='gadget-container'>{children}</div>}
    </div>
  )
}

export default Gadget2