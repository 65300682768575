import {
  SUCCESSOR_REFRESH,
} from 'services/action-types'

const initialState = {
  acceptType: {},
  jobClassification: {},
  jobLevel: {},
  jobLocation: {},
  ministry: {},
  notes: '',
  ownerPosNum: '',
  posNum: '',
  posTitle: '',
  supportRemote: 1,
  uuid: ''
}

export const successorReducer =  (state = initialState, action) => {
  switch (action.type) {
    case SUCCESSOR_REFRESH:
      return action.value
    default:
      return state
    }
}