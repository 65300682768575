import { 
  ADMIN_SENDGRID_EVENT_REFRESH,
  ADMIN_SENDGRID_LIST_REFRESH,
} from 'services/action-types'

const initialState = {
    events: [],
    list: [],
}

export const sendGridReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_SENDGRID_EVENT_REFRESH:
    return state = { 
      ...state, 
      events: action.value
    }
  case ADMIN_SENDGRID_LIST_REFRESH:
    return state = { 
      ...state, 
      list: action.value
    }
  default:
      return state
  }
}