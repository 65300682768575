import React from 'react'
import './warning-orange.scss'

const WarningOrange = (props:any) => {
  const { children } = props
  
  return (
    <div className='talent-warning-orange simple-row'>
      <span className='fa fa-info-circle'/>
      <div style={{width: 'calc(100% - 34px)'}}>
        <div className='simple-row talent-row'>{children}</div>
      </div>
    </div>
  )
}

export default WarningOrange