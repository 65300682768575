import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import { OdsTextarea } from 'components/common'
import EditButton from './edit-button'
import './style.scss'
import { focusOnRefs } from 'utils';

interface propsType{
    onEdit?: any;
    onFocus?: any;
    onBlur?: any;
    onValueChange?: any;
    className?: any;
    maxLength?: any;
    value?: any;
    onChange?: any;
}
  
const EditTextarea = forwardRef((props: propsType, ref) =>  {
  const {onEdit=()=>{}, onFocus=()=>{}, onBlur=()=>{}, onValueChange=()=>{}, maxLength, ...rest} = props
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState('')
  const containerRef: any = useRef({}), inputRef: any = useRef({})

  useImperativeHandle(ref, () => ({
    // focus: ()=> focusOnRefs([editBtnRef]),
    focusInput: () =>{
      focusOnRefs([inputRef])
    }
  }))

  const handleFocus = (e: any) => {
    setEditing((e.target.value?.length || 0) <= maxLength )
    onFocus(e)
  }
  
  const handleBlur = (e: any) => {
    const _value = e.target.value
    setValue(_value)
    if(containerRef.current.contains(e.relatedTarget)){
      return
    }
    handleChange(e, _value)
  }

  const handleButtonBlur = (e: any) => {
    const target = e.relatedTarget
    if(containerRef.current.contains(target) || !target || target.offsetParent?.className.indexOf('e-modal-dialog') >=0 ){
      return
    }
    handleChange(e, value)
  }
  
  const handleEdit = (e: any) => {
    onEdit(e)
    if(!editing){
      inputRef.current.focus()
    }else{
      handleChange(e, value)
    }
  }

  const handleChange = (e: any, v: any) => {
    onValueChange(v)
    onBlur(e)
    setEditing(false)
  }

  const inputProps = {ref: inputRef, onFocus: handleFocus, onBlur: handleBlur, maxLength, ...rest}
  return (
    <div className='simple-row edit-textarea' ref={containerRef}>
      <OdsTextarea {...inputProps} extra={<EditButton inEditing={editing} onClick={handleEdit} onBlur={handleButtonBlur}/>}/>
    </div>
  )
})
export default EditTextarea