import { 
  TEAM_YEARS_REFRESH,
  TEAM_YEARS_RESET,
  TEAM_PLANS_REFRESH,
  TEAM_PP_YEARS_REFRESH,
  TEAM_PP_PLANS_REFRESH,
  TEAM_STAGES_REFRESH,
  TEAM_LAST_PATH,
  TEAM_QUERY_PARAM_REFRESH,
  TEAM_SHOWOPENPLAN_REFRESH
} from 'services/action-types'

const initialState = {
  years: [],
  year: {},
  plans: [],
  ppYears: [],
  ppPlans: [],
  lastPath: '',
  stages: [],
  showOpenPlan:false,
  queryParam: {
    year: {}, 
    mgrId: '',
    group: {}
  }
}

export const teamReducer =  (state = initialState, action) => {
  switch (action.type) {
  case TEAM_YEARS_REFRESH:
    return state = { 
      ...state, 
      years: action.value,
    }
  case TEAM_YEARS_RESET:
    return state = { 
      ...state, 
      ...initialState,
    }
    
  case TEAM_PLANS_REFRESH:
    return state = { 
      ...state, 
      year:  action.value.year,
      plans: action.value.plans,
      showOpenPlan:false
    }
  case TEAM_PP_YEARS_REFRESH:
    return state = { 
      ...state, 
      ppYears: action.value,
    }
  case TEAM_PP_PLANS_REFRESH:
    return state = { 
      ...state, 
      ppPlans: action.value.plans
    }
  case TEAM_LAST_PATH:
    return state = { 
      ...state, 
      lastPath: action.value
    }
  case TEAM_STAGES_REFRESH:
    return state = { 
      ...state, 
      stages: action.value
    }
  case TEAM_QUERY_PARAM_REFRESH:
    return state = { 
      ...state, 
      queryParam: action.value
    }
  case TEAM_SHOWOPENPLAN_REFRESH:
    return state = { 
      ...state, 
      showOpenPlan: action.value
    }
    
  default:
      return state
  }
}