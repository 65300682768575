import { createSelector } from 'reselect'
export const getLock = createSelector(
   (state: any) => state.ui.common.lock.body, 
   data => data 
)
export const getAcquireSuccess = createSelector(
   (state: any) => state.ui.common.lock.acquireSuccess, 
   data => data
)
export const getRemainTime = createSelector(
   (state: any) => state.ui.common.lock.remain, 
   data => data 
)
export const getLockTimeoutShow = createSelector(
   (state: any) => state.ui.common.lock.showTimeout, 
   data => data 
)
export const getKeepTime = createSelector(
   (state: any) => state.ui.common.lock.keepTime, 
   data => data 
)
export const getTimeoutFunc = createSelector(
   (state: any) => state.ui.common.lock.timeoutFunc, 
   data => data
)