import { RESOURCE_LOCK_APPLIED,
  RESOURCE_LOCK_RESET,
  RESOURCE_LOCK_REFRESH, 
  RESOURCE_LOCK_RELEASE,
  RESOURCE_LOCK_KEEPING_CONFIRM,
  RESOURCE_LOCK_KEEPING_REFRESH, 
  RESOURCE_LOCK_TIMEOUT_START, 
  RESOURCE_LOCK_TIMEOUT_END,
  RESOURCE_LOCK_TIMEOUT_CLOSE,
  RESOURCE_LOCK_INVALID
} from 'services/action-types'
import { dispatchSimple, dispatchFetch, getState } from 'store'
// import { prompt } from 'components/common' 
import { getValueByCode , buildFailHandler } from 'utils'
import { getNavUser } from 'components/selectors'
import { getLock, getTimeoutFunc, getAcquireSuccess } from './selector'

// , ('RES_LOCK_ETYPE', 'LIST','Rs_List', 1, 1, NULL, NULL, NULL, 0, 1)
// , ('RES_LOCK_ETYPE', 'LIST_MEM','Rs_List_Member', 2, 2, NULL, NULL, NULL, 0, 1)
// , ('RES_LOCK_ETYPE', 'LIST_NOTE','Rs_List_Member_Notes', 3, 3, NULL, NULL, NULL, 0, 1)

// , ('RES_LOCK_FIELD', 'LIST','Rs_List', 100, 1, NULL, NULL, NULL, 0, 1)
// , ('RES_LOCK_FIELD', 'LIST_MEM','Rs_List_Member', 200, 2, NULL, NULL, NULL, 0, 1)
// , ('RES_LOCK_FIELD', 'LIST_NOTE','Rs_List_Member_Notes', 300, 2, NULL, NULL, NULL, 0, 1)

// , ('RES_LOCK_FIELD', 'LIST_MEM_REDINESS','readiness', 201, 4, NULL, NULL, NULL, 0, 1)
// , ('RES_LOCK_FIELD', 'LIST_MEM_INFORMTYPE','informType', 202, 5, NULL, NULL, NULL, 0, 1)
// , ('RES_LOCK_FIELD', 'LIST_MEM_NOTIFY','Notification', 203, 6, NULL, NULL, NULL, 1, 1)

let intervalId: any

export const resource_lock_refresh = () => dispatchSimple({type: RESOURCE_LOCK_REFRESH})
export const resource_lock_reset = () => {
  clearInterval(intervalId)
  dispatchSimple({type: RESOURCE_LOCK_RESET})
}
export const resource_lock_applied = (value: any) => dispatchSimple({type: RESOURCE_LOCK_APPLIED, value})
export const trigger_resource_lock = ({toggle, request, callbacks}: any) =>{
  // if(!toggle){
  //   callbacks.success()
  // }else{
    acquire_resource_lock(request, callbacks)
  // }
}
const acquire_resource_lock = ({entityId, entityGroupId, excludeLock, type, field}: any, callbacks: any) => {
  resource_lock_reset()
  const body = {
    id: 0,
    lockByIndId: getNavUser(getState()).userPK,
    entityId,
    entityGroupId,
    excludeLock,
    entityTypeVLId: getValueByCode('RES_LOCK_ETYPE', type)?.id || 0,
    entityFieldVLId: getValueByCode('RES_LOCK_FIELD', field)?.id || 0,
    lock: 1
  }
  dispatchFetch({
    spinner: false,
    path: `api/lock/acquire`,
    method: 'POST',
    body,
    success: data=>{
      if(data.acquireSuccess){
        callbacks.success(data)
      }else if(callbacks.failure){
        callbacks.failure(data)
        return
      }
      countDown(data)
      resource_lock_applied({...data, timeoutFunc: callbacks.timeout})
    }
  })
}
const turnoff_resource_lock = () => dispatchSimple({type: RESOURCE_LOCK_INVALID})
export const renew_resource_lock = () => {
  const _state = getState()
  const body = getLock(_state)
  const timeoutFunc = getTimeoutFunc(_state)
  resource_lock_reset()
  dispatchFetch({
    spinner: false,
    path: `api/lock/renew`,
    method: 'POST',
    body,
    success: data=>{
      resource_lock_applied({...data, timeoutFunc})
      countDown(data)
    },
    ...buildFailHandler(turnoff_resource_lock, ['V6020'])
  })
}


export const resource_lock_release = () => dispatchSimple({type: RESOURCE_LOCK_RELEASE})

export const release_resource_lock = () => {
  const _state = getState()  
  const body = getLock(_state)
  const acquireSuccess = getAcquireSuccess(_state)
  resource_lock_reset()
  resource_lock_release()
  acquireSuccess === 1 && dispatchFetch({
    spinner: false,
    path: `api/lock/release`,
    method: 'POST',
    body,
    success: ()=>{}
  })
}

const countDown = (data: any) => {
  if(data.acquireSuccess !== 1){
    // prompt.error(`Current resource is locked by ${data.lockBy?.displayName}`)
    return
  }
  
  intervalId = setInterval(()=>{
    resource_lock_refresh()
  }, 1000)
}

export const resource_lock_keeping_confirm = () => dispatchSimple({type: RESOURCE_LOCK_KEEPING_CONFIRM})
export const resource_lock_keeping_refresh = () => dispatchSimple({type: RESOURCE_LOCK_KEEPING_REFRESH})

export const resource_lock_timeout_start = () => dispatchSimple({type: RESOURCE_LOCK_TIMEOUT_START})
export const resource_lock_timeout_end = () => dispatchSimple({type: RESOURCE_LOCK_TIMEOUT_END})
export const resource_lock_timeout_close = () => dispatchSimple({type: RESOURCE_LOCK_TIMEOUT_CLOSE})

let intervalHandler: any
export const startKeeping = () => {  
  intervalHandler = setInterval(()=>{
    resource_lock_keeping_refresh()
  }, 1000)
}
export const endKeeping = () => {  
  clearInterval(intervalHandler)
}