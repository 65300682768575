import React, { useState, useEffect, forwardRef, useRef, InputHTMLAttributes } from 'react'
import CharCount from './character-count'
import newId from '../../utils/new-id'
import './animate-input.scss'

class propsType implements InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    labelledBy?: string;
    describedBy?: string;
    value?: string;
    className?: string;
    extra?: any;    
    // overlimit=false, countable=true, 
    overlimit?: boolean=false;
    charCount?: boolean=false;
    maxLength?: number | undefined;
    required?: any;
    id?: any;
    title?: string;
    placeholder?: string;
    style?:any;
    readOnly?: boolean;
    onFocus?: any=(e: any)=>{};
    onKeyDown?: any=(e: any)=>{};
    onKeyUp?: any=(e: any)=>{};
    onBlur?: any=(e: any)=>{};
    onClick?: any=(e: any)=>{};
    onChange?: any=(e: any)=>{};
    
}
const AnimateInput = forwardRef((props: propsType, ref: any) => {
    const { label, labelledBy, describedBy, value, className, extra, onFocus=()=>{}, onBlur=()=>{},
        charCount=false, readOnly=false,
        maxLength, ...rest } = props

    const overlimit=false
    const [id, setId] = useState('')
    const [ focused, setFocused ] = useState(false)
    const __ref = useRef({})
    const _ref = ref || __ref
    useEffect(() => { 
        setId(newId())
    }, [])

    useEffect(() => { 
        if (_ref.current) {
            _ref.current.value = value || ''
        } 
    }, [value, _ref])

    const handleFocus = (event: any) => {
        console.log(focused)
        onFocus(event)
        setFocused(true)
    }

    const handleBlur = (event: any) => {
        onBlur(event)
        setFocused(false)
    }
    
    return (<div className='animate-input-container'>
        <div className={`animate-input ${className}`}>
            {label && <label className='sr-only' htmlFor={id}>{label}</label>}
            <input autoComplete='off' {...rest} ref={_ref} aria-labelledby={labelledBy} {...(describedBy && {'aria-describedby': describedBy})} id={id} onFocus={handleFocus} onBlur={handleBlur} 
                maxLength={overlimit ? undefined : maxLength} readOnly={readOnly}
            /> {extra}
            <span className="underline-animation"></span>
        </div>
        {
        // @ts-ignore 
        charCount && <CharCount focused={focused} size={maxLength} value={value}/> 
        }
    </div>
    )
})

export default AnimateInput