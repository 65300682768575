import React from 'react'
import { displayYear, displayUserName } from 'utils'

const PlanTitle = (props: any) => {
  const { plan, mgr=false, /*bold=true*/ } = props
  const posLabel = plan.position + (plan.assMaps.length > 1 ? '(+)' : '')
  // const showName = plan.ind.lastName && plan.ind.firstName ?
  //                   plan.ind.lastName + ' ' + plan.ind.firstName
  //                   :
  //                   plan.ind.displayName
  
  return (<>
    {!!mgr && <span>{displayUserName(plan.ind)}'s </span>}
    <span>{displayYear(plan.displayYear)} </span>
    {/* <span className={bold ? 'strong' : ''}>{posLabel} </span>  */}
    <span>{posLabel} </span> 
    <span>({plan.ministry.name}) </span>
  </>)
};

export default PlanTitle;