export type FFValue = string | boolean | number;

export function parseFlagValue(flagValue: FFValue) {
  switch (flagValue.toString().toLowerCase()) {
    case "true":
    case "1":
    case "on":
    case "yes":
      return true;
    case "false":
    case "0":
    case "off":
    case "no":
      return false;
    default:
      return flagValue;
  }
}

export function parseFlags(flags: Record<string, FFValue>) {
  return Object.entries(flags).reduce(
    (newFlags, [key, value]) => ({
      ...newFlags,
      [key]: parseFlagValue(value)
    }),
    {}
  );
}

let allFlags = {}
export const setFlags = (flags: any) =>allFlags = flags
export const getFlags = () =>allFlags