import fetchWithTimeout from 'services/fetch-with-timeout'
import { msalApiFetch } from 'services/msal-api'
import { getTMSUser } from 'utils/routing-util'

export { msalLogin } from 'services/msal-config'

export const fetch_public_vl = (callback: any, errorCallback: any) => http_request1({url: '/public/valuelist', body: null, method: 'GET', callback, errorCallback})

export const fetch_msal_config = (callback: any, errorCallback: any) => http_request1({url: '/public/adal-config', body: null, method: 'POST', callback, errorCallback})

const prepareHeaders = () => {
  const _headers: HeadersInit = {}
  _headers['Content-Type'] = 'application/json; charset=utf-8'
  _headers['Access-Control-Allow-Origin'] = '*'
  _headers['observe'] = 'response'
  return _headers
}

const prepareOptions = (method: any, body: any) =>{
  const methodName = method.toUpperCase()
  const options: any={ credentials: 'same-origin' }  
  options['headers'] = prepareHeaders()
  options['method'] = methodName
  if(methodName !== 'GET'){
    options['body'] = body
  }
  return options
}

const http_request1 = ({url, method, body, callback, errorCallback}: any) => fetchWithTimeout(url, prepareOptions(method, body))
.then(checkStatus)
.then(callback)
.catch(error=>handleError(error, errorCallback))

const http_request2 = ({url, method, body}: any) => new Promise((resolve,reject) => {
  const options = prepareOptions(method, body)

  const checkStatus = (response: { status: number; json: () => Promise<any> }) => {
    if(response.status===200 || response.status===201){
      response.json().then(resolve).catch(reject)
    } else if (response.status===250) {
      response.json().then(reject)
    }else{
      reject(response)
    }
  }
  
  const TMSUser = JSON.parse(getTMSUser() as string)
  if(!!TMSUser){
    options['headers']['TMSUser'] = TMSUser.code
    options['headers']['TMSEmail'] = TMSUser.label
    options['headers']['TMSPass'] = TMSUser.pass
  }
  msalApiFetch(fetchWithTimeout, url, options).then(checkStatus)
})

export const fetch_redirect = (path: any, callback: any, errorCallback: any) => 
  http_request2({method: 'GET', url: path})
  .then(callback)
  .catch(error=>handleError(error, errorCallback))

const checkStatus = (response: any) => new Promise((resolve, reject) => {
  if(response.status===200 || response.status===201){
    response.json().then(resolve).catch(reject)
  } else if (response.status===250 || response.status===255) {
    response.json().then(reject)
  }else{
    reject(response)
  }
})

const handleError = (error: any, errorCallback: any)=>{
  console.log(error)
  const _message: any = {...error}

  _message.content = 'The service you are attempting to access is currently unavailable. Please try again at a later time.';
  
  _message.status = error.statusText
  _message.url = error.url
  if(error.msgCode){
    _message.msgCode = error.msgCode
  }
  _message.statusCode = error.status
  _message.ok = error.ok
  _message.redirected = error.redirected
  _message.type = error.type

  errorCallback(_message)
}