import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { loggerInfo, Key, newId } from 'utils'
import TreeNode from './treenode'
import './tree.scss'

export default forwardRef((props: any, ref) => {
    const { roots, name, id, expandItemId, expandLevel, maxLevel=5, defaultSelected, initCollapsed, getNodeLabel=()=>{}, onKeyDown=()=>{}, onFocus =()=>{}, onBlur=()=>{}, nodeSelected=()=>{}} = props
    const [selected, setSelected] = useState<any>({})
    const [treeId, setTreeId] = useState<string>('-1')

    useEffect(()=> {
        setTreeId(!id ? newId(): id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=> {
        if (defaultSelected && defaultSelected.id) {
            setSelected(defaultSelected)
            nodeSelected(defaultSelected)

            setTimeout(()=> {
                let selectedItem: any = document.querySelector(`#${treeId} .tree-item.selected`)
                selectedItem && selectedItem.focus()
            }, 200)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSelected])

    useImperativeHandle(ref, () => ({
		clear() { 
            setSelected({}) 
        }
    }))

    const selecteNode = (event: any, node: any) => {
        if (event.target.className.indexOf("tms-action") > -1) return;
        setSelected(node)
        nodeSelected(node, event)
    }

    const _onKeyDown = (event: any, item: any) => {
        if (event.keyCode === Key.UP_ARROW || event.keyCode === Key.DOWN_ARROW) {  
            event.preventDefault()
            event.stopPropagation()
            let treeItems = event.target.closest(".tree").querySelectorAll(".tree-item")
            if (treeItems.length === 0) return
            let lastIndex = treeItems.length - 1;

            let currentIndex = 0;
            for (let i = 0; i < treeItems.length; i++) {
                if (treeItems[i] === event.target) {
                    currentIndex = i;
                    break;
                }
            }
    
            let nextIndex = event.keyCode === Key.UP_ARROW ? currentIndex - 1 : currentIndex + 1;
            if (nextIndex >= 0 && nextIndex <= lastIndex) {
                treeItems[nextIndex].focus();
            }
        } else if (event.keyCode === Key.ENTER) {   
            selecteNode(event, item)
        } 
        onKeyDown(event, item)
    }

    const expandNode = (item: any) => {
        return item.id === expandItemId 
            || (Array.isArray(expandItemId) && expandItemId.includes(item.id))
            || (Array.isArray(item.children) && item.children.map((e: any)=>e.id).includes(expandItemId)) 
            //check performance
            || (item.level === 1 && Array.isArray(item.children) && item.children.find((e: any)=>(Array.isArray(e.children) && e.children.map((c: any)=>c.id).includes(expandItemId))))
            || (item.level < expandLevel)
    }

    const constructTreeNode = (item: any, index: number) => {
        if (item.children && item.children.length > 0 && (maxLevel === -1 || item.level < (maxLevel-1))) {
            return (
                <li key={item.id ?? index} role='presentation' style={{width:'100%'}}>
                    <TreeNode 
                        nodeLabel={getNodeLabel(item)} 
                        item={item} 
                        onKeyDown={_onKeyDown} 
                        onFocus={onFocus}
                        onBlur={onBlur}
                        nodeSelected={selecteNode}
                        selected={selected === item} 
                        size={item.children.length}
                        expandId={expandItemId}
                        initCollapsed={!expandNode(item) || initCollapsed}
                        treeId={treeId}>
                        { item.children.map(constructTreeNode) }
                    </TreeNode>
                </li>
            )
        } else {
            return (
                <li key={item.id ?? index} role='presentation'>
                    <div className={`tree-item tms-control ${selected === item && 'selected'}`} 
                        id={`${treeId}_${item.id}`}
                        style={{paddingLeft: '20px'}}
                        role="treeitem"
                        tabIndex={0} 
                        aria-selected={selected === item}
                        aria-level={item.level+1}
                        onKeyDown={event=>_onKeyDown(event, item)} 
                        onClick={event=>selecteNode(event, item)}
                        onFocus={event=>onFocus(event, item)}
                        onBlur={event=>onBlur(event, item)}>
                        {getNodeLabel(item)}
                    </div>
                </li>
            )
        }
    }

    loggerInfo("tree render")
    return (
        <ul className="tree" id={treeId} role="tree" aria-label={name}>
            {roots && roots.map(constructTreeNode)}
        </ul>
    )
})

