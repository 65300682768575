import React from 'react'
import { Div } from './elements'
import { loggerInfo } from 'utils'
import './paginate2.scss'

const Paginate2 = (props: any) => {
  const { numOfperPage, numOfTotal, pageNumber, pageCount, pageMore, onPrevPage, onNextPage } = props
  loggerInfo('paginate.render')
  return <div className='paginate2' tabIndex={0}>
    {
    pageNumber < 1 ? <div/> :
    !numOfTotal ? 
    <div className='pageNo'>Page: {pageNumber}</div>
    :
    <div className='pageNo'>{`${(pageNumber - 1) * numOfperPage + 1}-${Math.min(numOfTotal, pageNumber * numOfperPage)} of ${numOfTotal}`}</div>
    }
    <div className='pageCtrl'>
    {pageNumber > 1 ? 
      <Div className='clickable-div' onClick={onPrevPage}>Previous</Div>
      :
      <div className='disable-div'>Previous</div>
    }
    {( pageNumber < pageCount || (pageNumber === pageCount && pageMore)) ?
      <Div className='clickable-div' onClick={onNextPage}>Next</Div>
      :
      <div className='disable-div'>Next</div>
    }
    </div>
  </div>
}
Paginate2.defaultProps = {
  pageNumber: 0,
  pageCount: 0,
  pageMore: false,
  onPrevPage: ()=>{},
  onNextPage: ()=>{}
}
export default Paginate2