import React from 'react'
import { message4_refresh } from './actions'
import { Button, HTMLDiv } from '../elements'
import { SecurityUser } from 'types';
import { displayUserName } from 'utils';

interface propsType{
  user: SecurityUser
  content: string;
}
const Item = (props: propsType) =>{
  const { user, content } = props
  const onHide = () => message4_refresh('')
  
  return <div role='alert' className='simple-row item'>
    <span style={{flex: '0 0 35px', padding: '5px 0 0 5px', marginLeft: '2px'}}>
      <img alt='FORTE' style={{width: '60px'}} src='./img/forte-Small-20px.png'/>
    </span>
    <span style={{ flex: '1 1 auto', width: 'calc(100% - 180px)', padding: '10px 0'}}>
      <div className='strong' style={{padding: '0 8px 0 15px'}}>Hi {displayUserName(user)},</div>
      <HTMLDiv aria-hidden='true' className='content' xss={false} content={content}/> 
    </span>
    <span style={{flex: '0 0 1px', borderLeft: '1px solid rgb(216, 215, 217)'}}></span>
    <span style={{flex: '0 0 100px', marginRight: '5px'}}>
      <Button style={{width: '100%', height: 'calc(100% - 20px)', marginTop: '10px', border: 0, background: 'transparent'}} onClick={onHide}>
        Close
      </Button>
    </span>
  </div>
}
export default Item