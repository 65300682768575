export enum StepName{
  EINIT = 'init',
  EUPDA = 'add-updates',
  EDESC = 'add-description',
  EMEAS = 'add-measures',
  EPRIO = 'edit-priorities',
  ESKIL = 'add-skills',
  ESHAR = 'share-goal',
  ESHARD = 'goal-shared',
  EDELE = 'delete-goal',
  EDELED = 'goal-Deleted',
  EDELEP = 'delete-goal-p',
  EFEED = 'ask-feedback',
  EADDB = 'add-back',
  ECLOSEC = 'goal-close-complete',
  ECLOSEI = 'goal-close-incomplete',
  ECLOSED = 'goal-closed'
}
export const StepsE: any = {
  [StepName.EDESC]: {name: StepName.EDESC, autoSize: true, height: '445px', width: '900px' },
  [StepName.EPRIO]: {name: StepName.EPRIO, autoSize: false, height: '90%', width: '75%' },
  [StepName.EMEAS]: {name: StepName.EMEAS, autoSize: true, height: '755px', width: '965px' },
  [StepName.EUPDA]: {name: StepName.EUPDA, autoSize: true, height: '515px', width: '965px' },
  [StepName.ESKIL]: {name: StepName.ESKIL, autoSize: true, height: '455px', width: '965px' },    
  [StepName.ESHAR]: {name: StepName.ESHAR, autoSize: true, height: '535px', width: '965px' },
  [StepName.ESHARD]: {name: StepName.ESHARD, autoSize: true, height: '535px', width: '665px' },
  [StepName.EDELE]: {name: StepName.EDELE, autoSize: true, height: '535px', width: '680px' },
  [StepName.EDELED]: {name: StepName.EDELED, autoSize: true, height: '200px', width: '535px' },
  [StepName.EDELEP]: {name: StepName.EDELEP, autoSize: true, height: '200px', width: '535px' },
  [StepName.EADDB]: {name: StepName.EADDB, autoSize: true, height: '200px', width: '535px' },
  [StepName.ECLOSEC]: {name: StepName.ECLOSEC, autoSize: true, height: '445px', width: '730px' },
  [StepName.ECLOSEI]: {name: StepName.ECLOSEI, autoSize: true, height: '580px', width: '715px' },
  [StepName.ECLOSED]: {name: StepName.ECLOSED, autoSize: true, height: '200px', width: '535px' },
}