export class StepType{
  label!: string;
  name!: string;
  header!: string;
  description!: any;
  noskip?: boolean = false;
}

export enum Verifies{
  INIT = 'init', 
  CLOSE = 'close',
  SKIP = 'skip'
}

export enum StepName{
  WTYPE = 'step-goal-type',
  WDESC = 'step-goal-description',
  WPRIO = 'step-goal-priorities',
  WMEAS = 'step-goal-measures',
  WSHAR = 'step-goal-share',
  WUPDA = 'step-goal-updates',
  WDONE = 'step-goal-done',
  WSKIL = 'step-goal-skills',
  WACTI = 'step-goal-activities',
  WROLE = 'step-goal-roles',
}
export const StepsW: any = {
  [StepName.WTYPE]: {name: StepName.WTYPE, autoSize: true, height: '900px', width: '900px' },
  [StepName.WDESC]: {name: StepName.WDESC, autoSize: true, height: '585px', width: '900px' },
  [StepName.WPRIO]: {name: StepName.WPRIO, autoSize: false, height: '90%', width: '75%' },
  [StepName.WMEAS]: {name: StepName.WMEAS, autoSize: true, height: '825px', width: '950px' },
  [StepName.WUPDA]: {name: StepName.WUPDA, autoSize: true, height: '715px', width: '965px' },
  [StepName.WSKIL]: {name: StepName.WSKIL, autoSize: true, height: '455px', width: '900px' },
  [StepName.WACTI]: {name: StepName.WACTI, autoSize: true, height: '535px', width: '950px' },
  [StepName.WROLE]: {name: StepName.WROLE, autoSize: true, height: '500px', width: '900px' },
  [StepName.WSHAR]: {name: StepName.WSHAR, autoSize: true, height: '435px', width: '900px' },
  [StepName.WDONE]: {name: StepName.WDONE, autoSize: true, height: '475px', width: '900px' },
}