import {
  SSE_STATUS_REFRESH1,
  SSE_INPRO_REFRESH
} from 'services/action-types'
import { dispatchSimple, dispatchFetch } from 'store'

export const refresh_sse_status1 = (value?: any) => dispatchSimple({type: SSE_STATUS_REFRESH1, value})
export const refresh_sse_inpro = (value?: any) => dispatchSimple({type: SSE_INPRO_REFRESH, value})
export const fetch_client_log = (value: any, callback=()=>{}) => dispatchFetch({
  spinner: false,
  path: `api/admin/clog/${value.key}`,
  method: 'GET',
  success: callback
})
export const clean_client_log = (value: any, callback=()=>{}) => {
  callback()
}
// dispatchFetch({
//   spinner: true,
//   path: `api/admin/clog/${value.key}`,
//   method: 'DELETE',
//   success: callback
// })