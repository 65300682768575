import { 
  ADMIN_PROCESS_LOG_LIST_REFRESH,
  ADMIN_PROCESS_LOG_DETAIL_REFRESH,
  ADMIN_PROCESS_LOG_LATEST_REFRESH,
  // ADMIN_PROCESS_LOG_TYPE_REFRESH,
  ADMIN_PROCESS_LOG_LIST2_REFRESH,
  ADMIN_PROCESS_LOG_CRITERIA_REFRESH,
  ADMIN_PROCESS_LOG_CRITERIA_CLEAR
} from 'services/action-types'
import { ProcessLogDto } from 'types/clog'
import { dateAdd, daysDiff } from 'utils'
// import { getDefaultYear } from '../rating/reducer'

export const processTypes = [
  {label: 'Goal deploy', value: 'Goal-Deploy'},
  {label: 'Year close', value: 'Year-Close'},
  {label: 'Win import', value: 'Win-Import'},
]
export const sortValues = [
  {sortDirection: 'DESC', sortBy: 'PROCESS_AT', sortLabel: 'processed (newest - oldest)'},
  {sortDirection: 'ASC', sortBy: 'PROCESS_AT', sortLabel: 'processed (oldest - newest)'},
  {sortDirection: 'DESC', sortBy: 'PLAN_NUM', sortLabel: 'Plans (most - least)'},
  {sortDirection: 'ASC', sortBy: 'PLAN_NUM', sortLabel: 'Plans (least - most)'},
]

export const initItem = {code: null, label: 'All'}
// const iniPeriod =  {code: 0, label: 'All'} //getDefaultYear()
// const initDate = {id: 0, label: 'All'}
// export const initCompGroup = {id: 0, label: 'All'}
// export const initMin = {id: 0, label: 'All'}
// const initDate = {id: 0, label: 'Select'}
// const initMin = {id: 0, label: 'Select'}
const initBargain = {id: 0, label: 'Select'}
// const initClass = {id: 0, label: 'All'}
export const initCriteria = {
  processType: processTypes[0],
  period: initItem,
  compGroup: initItem,
  from: dateAdd(new Date(), -7, 'day'),
  to: new Date(),
  ministry: initItem,
  barg: initBargain,
  classi: initItem,
  deployId: '',
  result: initItem,
  planId: '',
  by: '',
  planOwner: '',
  templateName: '',
  templateId: '',
  page: 0,
  size: 50,
  sort: sortValues[0],
  sortDirection: sortValues[0].sortDirection,
  sortBy: sortValues[0].sortBy,
}
const initialState = {
  criteria: initCriteria,
  // type: processTypes[0],
  list: {
    items:[ProcessLogDto],
    totalRecords: 0
  },
  detail: ProcessLogDto,
  latest: ProcessLogDto,
  list2: [ProcessLogDto],
}

export const processLogReducer =  (state = initialState, action: { type: any; value: any }) => {
  switch (action.type) {
  case ADMIN_PROCESS_LOG_LIST_REFRESH:
    return state = { 
      ...state, 
      list: action.value,
    }
  // case ADMIN_PROCESS_LOG_TYPE_REFRESH:
  //   return state = { 
  //     ...state, 
  //     type: action.value,
  //     detail: ProcessLogDto
  //   }    
  case ADMIN_PROCESS_LOG_DETAIL_REFRESH:
    return state = { 
      ...state, 
      detail: action.value,
    }
  case ADMIN_PROCESS_LOG_LATEST_REFRESH:
    return state = { 
      ...state,
      latest: action.value
    }
  case ADMIN_PROCESS_LOG_LIST2_REFRESH:
    return state = { 
      ...state, 
      list2: action.value,
    }
  case ADMIN_PROCESS_LOG_CRITERIA_REFRESH:
    return state = { 
      ...state, 
      criteria: action.value,
    }
  case ADMIN_PROCESS_LOG_CRITERIA_CLEAR:
    return state = { 
      ...state, 
      criteria: initCriteria,
    }
  default:
      return state
  }
}