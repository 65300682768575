
import {http_request} from './http-service'
// import packageJson from '../../package.json'
// import { refresh_latest_version } from 'components/auth/actions'
import { message4_refresh } from 'components/common'

export default (()=>{
  const PING_TIMEOUT_LONG = 10 * 60 * 1000
  let intervalHandler: string | number | NodeJS.Timeout | undefined = 0
  const start=(interval: number = PING_TIMEOUT_LONG)=>{
    intervalHandler = setInterval(() => {
      ping()
    }, interval || PING_TIMEOUT_LONG)
  }

  const stop = () => {
    clearInterval(intervalHandler)
    intervalHandler = 0
  }

  const ping = () => http_request({method: 'GET', url: '/public/ping', errorHandle: false})
    .then((data: any) => {
      //if(packageJson.version !== data.ver){
      //  refresh_latest_version({current: packageJson.version, newer: data.ver})
      //}
      message4_refresh(data)
    })
    .catch(error => {
      console.log(error)
    })

  return ({
    start,
    stop
  })
})()