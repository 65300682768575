import { 
    UI_NOTIFICATION_REFRESH,
    UI_NOTIFICATION_FETCHING,
    UI_NOTIFICATION_FILTER
} from 'services/action-types'
import {dispatchFetch, dispatchSimple} from 'store'

export const refresh_ui_notification = (value: any) => dispatchSimple({type: UI_NOTIFICATION_REFRESH, value})
const fetching_ui_notification = (value: any) => dispatchSimple({type: UI_NOTIFICATION_FETCHING, value})
export const filter_ui_notification = (value: any) => dispatchSimple({type: UI_NOTIFICATION_FILTER, value})

export const fetch_ui_notification = (value: any) => {
  fetching_ui_notification(true)
  dispatchFetch({
    spinner: true,
    path: `api/notify/ui/${value.indId}`,
    method: 'GET',
    success: (data: any)=>{
      refresh_ui_notification(data)
      fetching_ui_notification(false)
    }
  })
}
export const fetch_ui_notification_by_type = (value: any, callback: any=()=>{}) => {
  dispatchFetch({
    spinner: true,
    path: `api/notify/ui/${value.indId}/type/${value.type}`,
    method: 'GET',
    success: (data: any)=>{
      refresh_ui_notification(data)
      callback(data)
    }
  })
}
