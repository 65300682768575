import React from 'react'
import { Div } from '../elements'
import './dropdown-item.scss'

const DropDownItem = (props: any) => {
  const { children, selected, disable, onClick } = props

  return (
    disable ?
    <div className='dropdown-item auto-wrap item-label disabled'>{children}</div>
    :
    selected ?
    <div role='button' tabIndex={0} className='dropdown-item auto-wrap selected'>
      <img alt='' aria-hidden='true' src='./img/icon-check-thin.svg'/>{children}
    </div>
    :
    <Div role='button' className='dropdown-item auto-wrap' onClick={onClick}>{children}</Div>
  )
}

export default DropDownItem