import {
    ADMIN_SUCC_LIST_REFRESH,
    ADMIN_SUCC_LIST_SELECT,
    ADMIN_SUCC_DEPTS_REFRESH,
    ADMIN_SUCC_SEARCH_CRIT_UPDATE,
    ADMIN_SUCC_MINISTRIES_REFRESH
} from 'services/action-types'

export const initMinDept = {id: 0, code: '0', label: 'All'}

export const initSearchCriteria = {
    page: 0, 
    sortBy: 'POS_MIN', 
    sortDirection: 'ASC', 
    ministry:initMinDept, 
    dept: initMinDept,
    posNum:'',
    posCurrName:'',
    listOwnerName:'',
    listId:'',
    noOwner:false
}

const initialState = {
    rsMinistries: [],
    depts: [initMinDept],
    searchCriteria: initSearchCriteria,
    succList: {
        items: [], 
        totalRecords: -1,
        replace: false
    }
}

export const succReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_SUCC_LIST_REFRESH:
            return state = {
                ...state,
                succList: {...state.succList,
                    totalRecords: action.value.totalRecords,
                    items: action.value.items,
                    replace: false
                }
            }
        case ADMIN_SUCC_LIST_SELECT:
            return state = {
                ...state,
                succList: { ...state.succList,
                    items: processSelection(state.succList.items, action.value),
                    replace: true
                }
            }
        case ADMIN_SUCC_MINISTRIES_REFRESH:
            return state = {
                ...state,
                rsMinistries: [initMinDept, ...action.value],
                depts: [initMinDept]
            }
        case ADMIN_SUCC_DEPTS_REFRESH:
            return state = {
                ...state,
                depts: [initMinDept, ...action.value]
            }
        case ADMIN_SUCC_SEARCH_CRIT_UPDATE:
            return state = {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    ...action.value
                }
            }
        default:
            return state
    }
}

const processSelection = (items, value) => {
    const _items = [...items]
    if(value.listId === 0){
        _items.map(e=>e.checked=value.checked)
    }else{
        _items.filter(e=>e.listId === value.listId)[0].checked = value.checked
    }
    return _items
}