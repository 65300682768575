import React from 'react'
// @ts-ignore
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { connect } from 'react-redux'
import MessageItem from './item'
import { getMessage4 } from './selector'
import './style.scss'
import { getNavUser } from 'components/selectors'

const Message4 = (props: any) =>{
  const { content, user } = props
  const showClass = !!!content ? '' : 'show'
  return <div className='message4'>
    <div className={`message4-container ${showClass}`}>
    <TransitionGroup>
      <CSSTransition timeout={500} classNames="fade">
        <MessageItem user={user} content={content}/>
      </CSSTransition>
    </TransitionGroup>
    </div>
  </div>
}

const mapStateToProps = (state: any, ownProps: any) =>({
	content: getMessage4(state),
  user: getNavUser(state)
})

export default connect(mapStateToProps, {})(Message4)