
import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import './character-count.scss'

const CharacterCount = forwardRef((props: any, ref) =>  {
  const { /*focused=false,*/ size = 0, value, } = props
  const wrapperRef: any = useRef({})

  useImperativeHandle(ref, () => ({
    focus(){ 
      wrapperRef.current.scrollIntoViewIfNeeded() 
    }
  }))

  const length = value?.length || 0 
  const focused = true
  
  return (<span ref={wrapperRef} className='char-count-container'>{
    !size ? <span/> :
    length > size ?
    // <div className='char-count over small'>{(length - size).toLocaleString()} charactor over limit</div>
    <div className='char-count grey small'>0 characters remaining</div>
    :
    focused ?
    <div className='char-count grey small' >{(size - length).toLocaleString()} characters remaining </div>
    :
    <span/>
  }</span>)   
})
export default CharacterCount