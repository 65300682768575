import { UPLOAD_PROGRESS_CLEAR, UPLOAD_PROGRESS_REFRESH } from 'services/action-types'

const initialState = {
  data: {}
}


export const upload_reducer= (state = initialState, action: any) => {
  switch (action.type) {
  case UPLOAD_PROGRESS_CLEAR:
    return { 
			...state, 
			data: {}
		}
	case UPLOAD_PROGRESS_REFRESH:
		return { 
			...state, 
			data: action.value
		}
  	default:
    	return state
  }
}