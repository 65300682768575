import { 
  ADMIN_EXTRACT_DATA_REFRESH
} from 'services/action-types'
import { saveFileAs } from 'utils'
const initialState = {
}

export const extractReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_EXTRACT_DATA_REFRESH:
    saveFileAs(action.value.data, action.value.filename)
    return state 
  default:
      return state
  }
}