import { 
  ADMIN_ACCESS_LOG_REFRESH,
} from 'services/action-types'

const initialState = {
    list: [],
}

export const accessLogReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_ACCESS_LOG_REFRESH:
    return state = { 
      ...state, 
      list: action.value.items || []
    }
  default:
      return state
  }
}