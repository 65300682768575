// eslint-disable-next-line
import { getRatingFactorValues, getRatingNoCovReasonValues, tryParseJSON, stringifyJSON, dateCompareYMD, trim } from 'utils'
import { ON_BEHALF_NORMAL, BY_ROLE_MANAGER, RATING_STEP_MGR_START, RATING_STEP_MGR_FACTOR, VALUE_RATING_FACTOR_NORMAL, VALUE_RATING_FACTOR_OVR_CALIB } from 'services/constants'
import { focusOnFirst } from 'utils/nav-util'

export const sumGoals = goals =>{
  const drafts = goals.filter(g=>g.status.code === 'DRAFT')
  const others = goals.filter(g=>g.status.code !== 'DRAFT')
  const performances = others.filter(g=>g.type.code === 'PROJECT')
  const learnings = others.filter(g=>g.type.code === 'LEARN')
  const improves = others.filter(g=>g.type.code === 'IMPROVE')
  return ({
    drafts: drafts.length,
    performances: {
      total: performances.length,
      complete: performances.filter(g=>g.status.code === 'COMPLETE').length,
      incomplete: performances.filter(g=>g.status.code === 'INCOMP').length,
      active: performances.filter(g=>g.status.code === 'SHARE').length
    },
    learnings: {
      total: learnings.length,
      complete: learnings.filter(g=>g.status.code === 'COMPLETE').length,
      incomplete: learnings.filter(g=>g.status.code === 'INCOMP').length,
      active: learnings.filter(g=>g.status.code === 'SHARE').length
    },
    improves: {
      total: improves.length,
      complete: improves.filter(g=>g.status.code === 'COMPLETE').length,
      incomplete: improves.filter(g=>g.status.code === 'INCOMP').length,
      active: improves.filter(g=>g.status.code === 'SHARE').length
    }
  })
}

export const conversation_values = [
  {code: 1, label: `I <span class='strong'>had</span> a performance conversation.`},
  {code: 0, label: `I <span class='strong'>wasn't able to</span> have a performance conversation.`}
]

export const getconversationLabel = (code, defaultValue) => (conversation_values.filter(e=>e.code === code)[0] || defaultValue || {label: 'Select statement'}).label
export const getReasons = () => getRatingNoCovReasonValues()  
export const getReasonLabel = ncReason => (getReasons().filter(e=>e.code === ncReason?.code)[0] || {label: 'Select reason'}).label

export const rebuild = o => ({...o, jsonData: tryParseJSON(o.jsonData)})
export const rejectdByMgr = reject => {
  if(reject?.onBehalf !== ON_BEHALF_NORMAL){
    return false
  }
  return reject?.byRole === BY_ROLE_MANAGER
}

export const enableToNext = (detail, step) => {
  if(step === RATING_STEP_MGR_START){
    return enableToNext_mgr_start(detail)
  }else if(step === RATING_STEP_MGR_FACTOR){
    return enableToNext_mgr_factor(detail)
  }else{
    return false
  }
}
const enableToNext_mgr_start = detail => {
  if(detail.conversation?.conversation !==0 && detail.conversation?.conversation !==1){
    return false
  }
  
  if(detail.conversation?.conversation === 1){
    if(!detail.conversation.conversationAt || !dateCompareYMD(new Date(), new Date(detail.conversation?.conversationAt))){
      return false
    }
  }

  if(detail.conversation?.conversation === 0){
    if(!detail.conversation.noConversationReason){
      return false
    }
    if(detail.conversation.noConversationReason.code === 'OTHR'){
      if(detail.inEditing){
        return false
      }
      if(!trim(detail.conversation.comment)){
        return false
      }
    }
  }
  return true
}

const enableToNext_mgr_factor = detail => {
    if(detail.details.filter(e=>e.ratingFactor.value === VALUE_RATING_FACTOR_OVR_CALIB).length > 0){
    return true
  }
  if(!detail.calRate.id){
    return false
  }
  if(detail.details.filter(e=>e.ratingFactor.value === VALUE_RATING_FACTOR_NORMAL).length >= getRatingFactorValues(detail.formula).length){
    return true
  }
  return false
}

// export const focusOnRating = () => {
//   setTimeout(()=> {
//     let detail = document.querySelector("#ratingDetail")
//     detail && detail.focus()
//   })
// }

export const focusOnRating = () => {
  setTimeout(()=> focusOnFirst(document.querySelector('#ratingDetail')), 200)
}