import React, { useEffect, useState } from 'react'
import { FFValue, parseFlags, setFlags } from "./parseToggles"
import { fetch_all_feature_flag } from './actions'

type FeatureFlags = Record<string, FFValue>

interface FeatureFlagProps {
  children?: any;
  featureFlags?: FeatureFlags;
}

const Ctx = React.createContext<any>({})

export const FeatureFlagProvider: React.FC<FeatureFlagProps> = ({
  children
}) => {
  const [featureFlags, setFeatureFlags] = useState({})
  useEffect(() => {
    fetch_all_feature_flag((data:any)=>{
      setFlags(parseFlags(data))
      setFeatureFlags(parseFlags(data))
    })
  }, [])

  return <Ctx.Provider value={featureFlags}>{children}</Ctx.Provider>
}

export function useFeatureFlags<T>(): T {
  return React.useContext<T>(Ctx)
}

export default useFeatureFlags
export { default as Flagged } from './flagged'
export { flagged } from './flagged'
