import React, { useRef } from 'react'
import './style.scss'

interface propsType{
    id?: any;
    children?: any; 
    onClick?: any; 
    className?: string;
    turnOff?: number;
    level: string;   // primary, secondary, tertiary
}
  
const Button = (props: propsType) => {
    const { children, className, level='primary', onClick=()=>{}, turnOff=200, ...rest } = props
    const ref: any = useRef({})
    const onBtnClick = (event: any) => {
        ref.current.setAttribute('disabled', 'disabled')
        onClick(event)
        setTimeout(() => {
            ref.current?.removeAttribute('disabled')
        }, turnOff);
    }

    return (<button ref={ref} className={`ontario-button ontario-button--${level} ${className}`} onClick={onBtnClick} {...rest}>
        {children}
    </button>)
}
export default Button

// export const Button = forwardRef((props: any, ref) => {
//     const {children, label, onClick, turnOff=200, ...rest} = props
//     const __ref: any = useRef({})
//     const _ref = ref || __ref
    
//     const onBtnClick = (event: any) => {
//       _ref.current.setAttribute('disabled', 'disabled')
//       onClick(event)
//       setTimeout(() => {
//         _ref.current && _ref.current.removeAttribute('disabled')
//       }, turnOff);
//     }
//     return <>
//       <button {...rest} type='button' ref={_ref} onClick={onBtnClick}>
//         {children}
//       </button>
//     </>
//   })
  