import React from 'react'
import { verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'

const Droppable = ({ id, items, children }: any) => {

  return (
    <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>{children}</SortableContext>
  )
}

export default Droppable
