import { saveAs } from 'file-saver'

export const saveFileAs = (data, fileName) =>{
  const type = (data.type.split('/')||[])[1]
  const extension = (fileName.split('.')||[])[1]
  let _fileName = fileName
  if(!extension && type ){
    _fileName = `${_fileName}.${type}`
  }
  saveAs(data, decodeURIComponent(_fileName.replace(/\+/g," ")));
}