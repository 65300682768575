
import { clear_spinner } from 'components/common/actions'
import { handle_global_timeout, handle_msal_timeout } from 'components/auth/actions'
import { resource_lock_timeout_start, resource_lock_timeout_close } from 'components/common/lock/actions'

export const handleGlobalTimeout = (value: any) =>{
  clear_spinner(value)
  handle_global_timeout(value)
  resource_lock_timeout_close()
}

export const handleAdalTimeout = (value: any) =>{
  clear_spinner(value)
  handle_msal_timeout(value)
}

export const handleLockTimeout = (value: any) =>{
  clear_spinner(value)
  resource_lock_timeout_start()
}