import React from 'react'
import { getValue } from 'utils'
import { Div } from '../common'
import './util.scss'

export const renderEditButton = (event, label) => <Div className='action edit' role='button' aria-label={label} onClick={event}>
  <svg viewBox="0 0 24 24" width="24px" height="24px" x="0" y="0" preserveAspectRatio="xMinYMin meet" focusable="false">
    <path d="M21.71,5L19,2.29a1,1,0,0,0-1.41,0L4,15.85,2,22l6.15-2L21.71,6.45A1,1,0,0,0,22,5.71,1,1,0,0,0,21.71,5ZM6.87,18.64l-1.5-1.5L15.92,6.57l1.5,1.5ZM18.09,7.41l-1.5-1.5,1.67-1.67,1.5,1.5Z"></path>
  </svg>
</Div>

export const renderAddButton = (event, label) => <Div className='action' role='button' aria-label='add menu, expand and use right arrow to enter' onClick={event}>
  <svg viewBox="0 0 24 24" width="24px" height="24px" x="0" y="0" preserveAspectRatio="xMinYMin meet" focusable="false">
    <path d="M21,13H13v8H11V13H3V11h8V3h2v8h8v2Z"></path>
  </svg>
</Div>

export const buildSkillTree = skills => {
  const tree = {}
  skills.sort((a,b)=>a.displayOrder - b.displayOrder).map(s =>{
    const cataId = s.parentId || s.skill?.parentId || s.category?.id
    if(tree[cataId]){
      tree[cataId].skills.push(s)
    }else{
      tree[cataId]={
        category: getValue(cataId),
        skills:[s]
      }
    }
    return tree
  })
  const _tree = Object.keys(tree).map(e=>tree[e])
  return [..._tree.filter(e=>e.category?.code!=='OTHER').sort((a,b)=>a.category?.label.localeCompare(b.category?.label)), ..._tree.filter(e=>e.category?.code==='OTHER')]
}

export const listToMatrix = (list, elementsPerSubArray) => {
  let matrix = [], i, k

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++
      matrix[k] = []
    }
    matrix[k].push(list[i])
  }

  return matrix
}

export const buildLocationTree = locs => {
  const _locs = locs.sort((a,b)=>a.displayOrder - b.displayOrder)
  const tree = [..._locs.filter(e=>e.parentId===0)]
  tree.map(e=>e.children=_locs.filter(c=>c.parentId===e.id))
  return Object.keys(tree).map(e=>tree[e])
}