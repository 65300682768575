import {useState} from 'react'
import { OdsImage } from 'components/common'
import './warning-bar.scss'

export const WarningBar = props => {
  const { children, style, className, srcSvg, closable=false, onClose=()=>{} } = props
  const [show, setShow] = useState(true)
  const onClick= () =>{
    setShow(false)
    onClose()
  }
  return (!show ? <span/> : <div className={`simple-row ${className}`} style={style}>
    <div className="d-table-row">
      <span className="d-table-cell"><img className='ms-1' alt='' aria-hidden='true' src={srcSvg}/></span>
      <span role='alert' aria-live='assertive' className='d-table-cell px-1 warning-bar-content'>
        {children}
      </span>
      {closable && <span className="d-table-cell">
        <OdsImage href='ontario-icon-close' aria-label='Close Message' onClick={onClick}/>
      </span>}
    </div>
  </div>
  )
}

export const BlueWarning = props => <WarningBar className='blue-warning-bar' {...props}
  srcSvg='./img/icon-warning-circle-blue.svg'/>

export const RedWarning = props => <WarningBar className='red-warning-bar' {...props}
  srcSvg='./img/icon-warning-circle-red.svg'/>

export const YellowWarning = props =><WarningBar className='yellow-warning-bar' {...props}
  srcSvg='./img/icon-warning-triangle-yellow.svg'/>

export const GreenWarning = props =><WarningBar className='green-warning-bar' {...props}
  srcSvg='./img/icon-check-circle.svg'/>
