import { getNavUser } from 'components/selectors'
import { createSelector } from 'reselect'
import { VALUE_GOAL_STATUS_DRAFT } from 'services/constants'
import { getAccessMode } from '../selectors'
import { buildGoalTree, filterGoals } from '../util'

export const getPlan = createSelector(
   state => state.ui.performance.plan, 
   data => data
)
export const getAssignment = createSelector(
   [state => state.ui.performance.plan.assMaps, state => state.ui.performance.plan.assignmentId],
   (assMaps, assignmentId) => assMaps.filter(a=>a.assignmentId === assignmentId)[0]
)
export const getPlanId = createSelector(
   state => state.ui.performance.plan.id, 
   data => data
)
export const getPlanMinistryId = createSelector(
   state => state.ui.performance.plan.tmMinistryId,
   data => data
)
export const getGoals = createSelector(
   [
      state => state.ui.performance.goals, 
      state => getAccessMode(state),
   ],
   (data, accessMode) => {
      const _data = data.filter(g=>g.status.code !== 'DELETED')
      return accessMode.manager ? _data.filter(e=>e.status.code !== VALUE_GOAL_STATUS_DRAFT) : _data
   }
)

export const getDeletedGoals = createSelector(
   [
      state => state.ui.performance.goals, 
      state => state.ui.performance.filter
   ],
   (data, filter) => ({
      label: 'Deleted goals', 
      code: 'DELETED', 
      goals: filter.type.some(f => f.id === 0 || f.code === 'DELETED') ? 
      data.filter(g=>g.status.code === 'DELETED')
      :
      []
   })
)

export const getGoalCount = createSelector(
   state => getGoals(state), 
   data => data.length
)

export const getGoalsTree = createSelector(
   [
      state => getGoals(state),
      state => state.ui.performance.filter
   ], 
   (data, filter) => {
      const goalsTree = buildGoalTree(filterGoals(data, filter))
      return filter.type.filter(f=>f.id === 0)[0] || filter.type.length === 0? 
        goalsTree :
        goalsTree.filter(e=>filter.type.filter(f=>f.code === e.code)[0])
   }
)

export const getPlanStatus = createSelector(
   state => state.ui.performance.plan.status, 
   data => data
)

const getCurrentGoal = createSelector(
   state => state.ui.performance.goal,
   data => data
)

export const getGoal = createSelector(
   [
      state => getPlan(state),
      state => getCurrentGoal(state), 
      state => state.ui.performance.accessMode,
      state => state.ui.nav.user
   ],
   (plan, goal, accessMode, user) => {
      if (accessMode.manager && !accessMode.min && !accessMode.corp && !accessMode.hra && (plan.stageStatus.yearClose !== 1)) {
         goal.updates = (goal.updates || []).map(e => ({...e, newNotif: (!goal.loadedAt || e.createAt > goal.loadedAt || goal.status?.code==='DELETED' && e.updateAt > goal.loadedAt) && e.ownBy!=user.userPK && (!e.status || e.status.code==='POST')}))
         goal.newProgressUpdates = goal.updates.reduce((total, u)=> total + (u.newNotif ? 1: 0), 0)
         goal.feedbacks = (goal.feedbacks || []).map(e => ({...e, newNotif: e.feedbackAt && (!goal.fbLoadedAt || e.feedbackAt > goal.fbLoadedAt) && e.requestToEmail.toLowerCase()!==user.email.toLowerCase()}))
         goal.newReceivedFeedbacks = goal.feedbacks.reduce((total, u)=> total + (u.newNotif ? 1: 0), 0)
         goal.newSharedGoal = (!goal.loadedAt || goal.shareAt > goal.loadedAt) && !goal.deployedByMgr && goal.status?.code==='SHARE' ? 1 : 0
         return goal
      } else {
         goal.newProgressUpdates = 0
         goal.newReceivedFeedbacks = 0
         goal.newSharedGoal = 0
         return goal
      }
   }
)
export const getExpanded = createSelector(
   state => state.ui.performance.expanded, 
   data => data
)
export const getFilter = createSelector(
   state => state.ui.performance.filter, 
   data => data
)
export const getPlanDeleted = createSelector(
   state => state.ui.performance.plan.deleted, 
   data => data
)
export { getSearchGoals } from '../../search/selectors'
export { getSearchPlanYears } from '../selectors'

export const getReadonlyGoal = createSelector(
   state => state.ui.performance.readonlyGoal, 
   data => data
)

export const getCurrentGoalType = createSelector(
   state => getCurrentGoal(state),
   data => data.type
)
export const getCurrentGoalId = createSelector(
   state => getCurrentGoal(state),
   data => data.id
)

export const getNewNotifable = createSelector(
   [
      state => getPlan(state),
      state => getAccessMode(state),
   ],
   (plan, accessMode) => accessMode.manager && !accessMode.min && !accessMode.corp && !accessMode.hra && (plan.stageStatus.yearClose !== 1)
)
export const getNewProgressUpdates = createSelector(
   [
      state => getNewNotifable(state),
      state => getUpdates(state),
   ],
   (notifable, updates) =>  notifable ? updates.reduce((total, u)=> total + (u.newNotif ? 1: 0), 0) : 0
)
export const getNewReceivedFeedbacks = createSelector(
   [
      state => getNewNotifable(state),
      state => getFeedbacks(state),
   ],
   (notifable, feedbacks) => notifable ? feedbacks.reduce((total, u)=> total + (u.newNotif ? 1: 0), 0) : 0
)
export const getNewSharedGoal = createSelector(
   [
      state => getNewNotifable(state),
      state => getCurrentGoal(state),
   ],
   (notifable, goal) => notifable && (!goal.loadedAt || goal.shareAt > goal.loadedAt) && !goal.deployedByMgr && goal.status?.code==='SHARE' ? 1 : 0
)

export const getUpdates = createSelector(
   [
      state => getNewNotifable(state),
      state => getCurrentGoal(state), 
      state => getNavUser(state)
   ],
   (notifable, goal, user) => {
      if (notifable && (goal.updates || []).length > 0) {
         goal.updates = goal.updates.map(e => ({
            ...e, 
            newNotif: (!goal.loadedAt || e.createAt > goal.loadedAt) && e.ownBy!==user.userPK && (!e.status || e.status.code==='POST')
         }))
      }
      return goal.updates
   }
)

export const getFeedbacks = createSelector(
   [
      state => getNewNotifable(state),
      state => getCurrentGoal(state), 
      state => getNavUser(state)
   ],
   (notifable, goal, user) => {
      if (notifable && (goal.feedbacks || []).length > 0) {
         goal.feedbacks = goal.feedbacks.map(e => ({
            ...e, 
            newNotif: e.feedbackAt && (!goal.fbLoadedAt || e.feedbackAt > goal.fbLoadedAt) && e.requestToEmail.toLowerCase()!==user.email.toLowerCase()
         }))
      }
      return goal.feedbacks
   }
)
export const getGoalFilter = createSelector(
   state => state.ui.performance.filter, 
   data => data
)
