import { getValueByCode } from './value-util'

const lenKeys = {}
lenKeys['PROF_LEN_SUMMARY'] = 1500
lenKeys['PROF_LEN_NEXTR_NOTE'] = 500

lenKeys['PROF_EXP_LEN_POSITION'] = 150
lenKeys['PROF_EXP_LEN_ORGNAME'] = 150
lenKeys['PROF_EXP_LEN_JOBLEVEL'] = 100
lenKeys['PROF_EXP_LEN_DIVISION'] = 200
lenKeys['PROF_EXP_LEN_BRANCH'] = 100
lenKeys['PROF_EXP_LEN_LOCATION'] = 150
lenKeys['PROF_EXP_LEN_DESC'] = 3000

lenKeys['PROF_EDU_LEN_DEGREE'] = 150
lenKeys['PROF_EDU_LEN_FIELD'] = 150
lenKeys['PROF_EDU_LEN_SCHOOL'] = 150
lenKeys['PROF_EDU_LEN_DESC'] = 3000

lenKeys['PROF_SKILL_LEN_NAME'] = 150

lenKeys['PROF_PRJ_LEN_NAME'] = 150
lenKeys['PROF_PRJ_LEN_ORGNAME'] = 150
lenKeys['PROF_PRJ_LEN_DESC'] = 3000

lenKeys['PROF_CRT_LEN_NAME'] = 150
lenKeys['PROF_CRT_LEN_ORGNAME'] = 150
lenKeys['PROF_CRT_LEN_URL'] = 500

lenKeys['PROF_HOR_LEN_TITLE'] = 150
lenKeys['PROF_HOR_LEN_ASSO'] = 150
lenKeys['PROF_HOR_LEN_ORGNAME'] = 150
lenKeys['PROF_HOR_LEN_DESC'] = 3000
lenKeys['MTPROF_LEN_GOALS'] = 1500

lenKeys['PRA_PLAN_LEN_NOGOAL_REASON'] = 250

lenKeys['PRA_GOAL_LEN_TITLE'] = 50
lenKeys['PRA_GOAL_LEN_MEASURE'] = 500
lenKeys['PRA_GOAL_LEN_DESC'] = 1000
lenKeys['PRA_GOAL_LEN_COMP_NOTE'] = 1000
lenKeys['PRA_GOAL_LEN_INCOMP_NOTE'] = 1000

lenKeys['PRA_UPD_LEN_UPDATE'] = 3000
lenKeys['PRA_UPD_LEN_REPLY'] = 3000
lenKeys['PRA_UPD_LEN_COMMENT'] = 3000

lenKeys['PRA_FBK_LEN_INVITE'] = 2000
lenKeys['PRA_FBK_LEN_FEEDBACK'] = 4000

lenKeys['PRA_RATE_LEN_FINALTHOUGHT'] = 5000
lenKeys['PRA_RATE_LEN_REJECTCOMMENT'] = 1000
lenKeys['PRA_RATE_LEN_NOCONVDESC'] = 1000
lenKeys['PRA_RATE_LEN_JUSTIFICATION'] = 2000
lenKeys['PRA_RATE_LEN_RATIONALE'] = 2000

lenKeys['RS_LIST_NAME'] = 200
lenKeys['RS_LIST_DESC'] = 1000
lenKeys['RS_LIST_MEM_DESC'] = 4000
lenKeys['RS_LIST_SHARE_DESC'] = 2000
lenKeys['RS_EXT_LINK_NAME'] = 320		
lenKeys['RS_EXT_NAME'] = 400

lenKeys['PRA_REVIEW_LEN_COMMENT'] = 5000
lenKeys['PRA_REVIEW_LEN_REJECTCOMMENT'] = 1000

export const getMaxLen = key => {
    return getValueByCode('INPUT_FIELD_RULE', key)?.value || lenKeys[key]
    // return lenKeys[key]
}
export const checkCharCount = (obj, limits) => {
    // return Object.keys(limits).every(k=>((obj[k]?.length || 0) - 0) <= (limits[k] - 0))
    return true
}