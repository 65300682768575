import { msalApiFetch } from './msal-api'
import { 
  handleNoPermission, 
  handleNoAccessBPS,
  handleNoAccessMaintain,
  handleNoService, 
  handleGatewayError,
  handleLandingPage
} from './http-error-handler'
import { handleGlobalTimeout, handleAdalTimeout } from './timeout-handler'
import fetchWithTimeout from './fetch-with-timeout'
import { prepareOptions, attachOnBehalf } from 'utils'
import { getSuccessHandler } from './custom-handler'

export const http_request = (props: any) => new Promise((resolve,reject) => {
  const {type, method, url, body=null, errorHandle=true, onprogress, ...rest} = props
  const options = {...prepareOptions(method, body, onprogress), type, ...rest}

  const checkStatus = (response: any) => {
    if(response.status !== 200 && response.status !== 201 && !errorHandle){
      return
    }

    switch(response.status){
      case 200:
      case 201:
        // handleNoAccessMaintain(response)
        // break
        // handleNoAccessBPS(response)
        // break
        // if(url.indexOf('user-profile') >0){
            // handleLandingPage(response)
            // handleNoPermission(response)
        //  break
        // }
        // handleNoPermission(response)
        // break
        getSuccessHandler(props)(response, resolve, reject)
        break
      case 250:
        response.json().then(reject)
        break
      case 251:
        handleNoPermission(response)
        break
      case 252:
        handleNoAccessBPS(response)
        break
      case 254:
        handleNoAccessMaintain(response)
        break
      case 255:
        handleLandingPage(response)
        break
      case 256: // invalid session for BPS users
      case 401: // UNAUTHORIZED
        handleGlobalTimeout(response)
        break
      case 500:
      case 503:
      case 403:
        handleNoService(response)
        break
      case 502:
      case 504:
        handleGatewayError(response)
        break;
      case 410:
      case 412:      
      case 404:
      case 405:
      case 408:	// Request Timeout
        reject(response)
        break;
      default:
        reject(response)
    }
  }

  const handleOtherError = (error: any) => {
    if(!errorHandle){
      return
    }
    const content = error.message || error.statusText
    // AADSTS50058: A silent sign-in request was sent but no user is signed in.
    // Trace ID: d89d0089-f727-483d-b992-f5fbd14ec000
    // Correlation ID: 90d8997b-a72c-44bb-a5cc-8832a1e78732
    // Timestamp: 2018-12-27 14:40:09Z
    // login_required
    if(error.msg === 'Token Renewal Failed' || content.indexOf('AADSTS50058') > -1){
      handleAdalTimeout(error)
    } else if (content.indexOf("Network request failed") > -1 || content.indexOf("Failed to fetch") > -1) {
      handleNoService(error)
    }else{
      reject(error)
    }
  }
  msalApiFetch(fetchWithTimeout, attachOnBehalf(url), options).then(checkStatus).catch(handleOtherError)
})