import React from 'react'

import ReactResizeDetector from 'react-resize-detector';

const ResizeDetector = (props: any) =>{
  const { onResize } = props
  
  return <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
}

export default ResizeDetector