import {
    ADMIN_TM_MINISTRIES_REFRESH,
    ADMIN_TM_MINISTRY_SELECTED_REFRESH,
    ADMIN_WIN_MINISTRIES_REFRESH,
    ADMIN_WIN_MINISTRIES_RESET
} from 'services/action-types'

const initialState = {
	tmMinistries: [],
	winMinistries: [],
	selectedTmMinistry: {}
}

export const minMappingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_TM_MINISTRIES_REFRESH:
            return state = {
                ...state,
                tmMinistries: action.value
            }
        case ADMIN_WIN_MINISTRIES_REFRESH: 
            return state = {
                ...state,
                winMinistries: action.value
            }
        case ADMIN_TM_MINISTRY_SELECTED_REFRESH:
            return state = {
                ...state,
                selectedTmMinistry: action.value
            }
        case ADMIN_WIN_MINISTRIES_RESET:
            return state = {
                ...state,
                winMinistries: [],
                selectedTmMinistry: {}
            }
        default:
            return state
    }
}