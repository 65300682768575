import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import './tree.scss';
import { Key, newId } from 'utils'


export default forwardRef((props: any, ref) =>  {
  const { item, treeId, selected, nodeLabel, children, initCollapsed, expandId=-1, headerStyle = {}, headerTitleStyle = {}, onCollapse=()=>{}, onKeyDown = () => { }, onFocus =()=>{}, onBlur=()=>{}, nodeSelected = () => { } } = props
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => { 
    setCollapsed(initCollapsed && (!item || item.id !== expandId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCollapsed, expandId])

  
  const api = () => ({
    collapse: (e: any) => setCollapsed(true),
    getItem: () => { return item }
  })
  useImperativeHandle(ref, api)

  const _onKeyDown = (event: any, item: any) => {
    if (event.target.className.indexOf("tms-action") > -1 && event.keyCode === Key.ENTER) return
    onKeyDown(event, item)

    if (event.keyCode === Key.RIGHT_ARROW && collapsed) {   
      setCollapsed(false)
    } else if (event.keyCode === Key.LEFT_ARROW && !collapsed) {   
      setCollapsed(true)
    } else if (event.keyCode === Key.ENTER) {
      setCollapsed(!collapsed)
    }
  }

  const onClick = (event: any, item: any) => {
    if (event.target.className.indexOf("tms-action") > -1) return
    nodeSelected(event, item)
    collapse(!collapsed)
  }

  const collapse = (c: any) => {
    setCollapsed(c)
    onCollapse(c)
  }

  const getNodeId = () => {
    return `${treeId}_${item && item.id ? item.id : newId()}`
  }

  return (<>
    <div className="tree-node simple-row">
        <div className={`header tree-item tms-control ${selected ? 'selected': ''}`} 
            id={getNodeId()}
            style={headerStyle}
            role="treeitem"
            aria-expanded={!collapsed}
            aria-selected={selected}
            {...(item && item.level>=0 && {'aria-level': item.level+1})}
            tabIndex="0"
            onKeyDown={event => _onKeyDown(event, item)}
            onClick={event => onClick(event, item)} 
            onFocus={event => onFocus(event, item)}
            onBlur={event => onBlur(event, item)}>
          <span className={`collapsed ${!collapsed ? 'expanded' : ''}`} onClick={() => collapse(!collapsed)}></span>
          <div style={{'width': '100%', ...headerTitleStyle}}>
            {nodeLabel}
          </div>
      </div>
    </div>
    <ul className={`tree-item-parent children ${collapsed ? 'collapsed' : ''}`} aria-label={item && item.label}>
      {collapsed ? null : children}
    </ul>
    </>
  )
})

