import React, { useState } from 'react'
import { stringFormat, Key } from 'utils'

import './show-more.scss'

class propsType{ 
  moreCount?: string = ""; 
  className?: string = ""; 
  children?: any; 
  onClick?: any; 
  style?: any; 
  moreLabel?: string; 
  focusMore?: any; 
}
const ShowMore = (props: propsType) => {
  const { moreCount='', className = '', children, onClick=()=>{}, style, moreLabel, focusMore } = props
  const [ expanded, setExpanded ] = useState(false)

  const onClickMore = () =>{
    const _expanded = !expanded
    setExpanded(_expanded)
    onClick(_expanded)
    _expanded && focusMore && setTimeout(()=>document.querySelector(focusMore).focus())
  }
  const buildMoreLabel = <>
    {stringFormat(moreLabel || 'Show {0} more', [moreCount])}
    <span aria-hidden='true' className='fa fa-angle-down'/>
  </>
  
  const buildLessLabel = <>Show less<span aria-hidden='true' className='fa fa-angle-up'/></>

  const onKeyDown = (event:any) => {
    if (event.keyCode === Key.UP_ARROW) {
      event.target.previousElementSibling.focus()
    } else if (event.keyCode === Key.ENTER || event.keyCode===Key.SPACE) {
      onClickMore()
    }
  }

  return (<>
      { expanded && <>{children}</> }
      <span tabIndex={0} role='button' className={`show-more ${className}`} style={style} onClick={onClickMore} onKeyDown={onKeyDown}
          aria-label={expanded ?'press enter to show less': `press enter to ${stringFormat(moreLabel || 'Show {0} more', [moreCount])}`}>
           {expanded? buildLessLabel : buildMoreLabel}
      </span>
    </>
  )
};

export default ShowMore;


