import {
    ADMIN_ORG_SELECTED_REFRESH,
    ADMIN_ORG_PRIORITIES_REFRESH,
    ADMIN_ORG_PRIORITY_SELECTED_REFRESH,
    ADMIN_ORG_PRIORITY_RESET,
    ADMIN_ORG_TREE_REFRESH,
    ADMIN_ORG_CHILDREN_REFRESH
} from 'services/action-types'

const initialState = {
    orgTree: [],
    org: {},
    priorities: [],
    priority: {}
}

export const orgPrioritiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ORG_TREE_REFRESH:
            return state = { 
            ...state, 
            orgTree: action.value
            }
        case ADMIN_ORG_CHILDREN_REFRESH:
            const tree = [...state.orgTree]
            const parentNode = getNodeById(action.value.parentId, { id: -1, children: tree })
            if (parentNode) {
                parentNode.children = action.value.children
            }
            return state = {
                ...state,
                orgTree: tree
            }
        case ADMIN_ORG_SELECTED_REFRESH:
            return state = {
                ...state,
                org: action.value
            }
        case ADMIN_ORG_PRIORITIES_REFRESH: 
            return state = {
                ...state,
                priorities: (action.value||[]).sort((a,b)=>b.lastUpdate-a.lastUpdate)
            }
        case ADMIN_ORG_PRIORITY_SELECTED_REFRESH:
            return state = {
                ...state,
                priority: action.value
            }
        case ADMIN_ORG_PRIORITY_RESET:
            return state = initialState
        default:
            return state
    }
}

const getNodeById = (id, node) => {
    function runner(node) {
      if (node.id === id) {
          return node
      } else if (node.children) {
        let result = null
        for (let i = 0; result == null && i < node.children.length; i++) {
            result = runner(node.children[i])
        }
        return result
      }
      return null
    }
    return runner(node);
  }