
import { getTMSUser } from 'utils'

const prepareHeaders = method => {
  const _headers = {}
  _headers['origin-method'] = method
  switch(method){
    case 'UPLOAD':
      _headers['observe'] = 'response'
      break
    case 'DOWNLOAD':
      _headers['Content-Type'] = 'application/json'
      _headers['responseType'] = 'blob'
      break
    case 'GRAGH': 
      _headers['Content-Type'] = 'application/json'
      _headers['responseType'] = 'blob'
      break
    default: // GET, POST
      _headers['Content-Type'] = 'application/json; charset=utf-8'
      _headers['Access-Control-Allow-Origin'] = '*'
      _headers['observe'] = 'response'
      break
  }

  const TMSUser = JSON.parse(getTMSUser())
  if(!!TMSUser){
    _headers['TMSUser'] = TMSUser.code
    _headers['TMSEmail'] = TMSUser.label
    _headers['TMSPass'] = TMSUser.pass
  }
  return _headers
}

const prepareMethod = method => {
  switch(method){
    case 'GET':
    case 'DELETE':
    case 'PUT':
      return method
    case 'GRAGH':
      return 'GET'
    default: 
      return 'POST'
  }
}

const prepareBody = (method, _body) => {
  switch(method){
    case 'UPLOAD':
      return _body
    default: 
      return _body instanceof Object ? JSON.stringify(_body) : _body
  }
}

export const prepareOptions = (method, body=null, onprogress) => {
  const options={ credentials: 'same-origin', onprogress }
  const methodName = method.toUpperCase()
  options['origin-method'] = methodName
  options['headers'] = prepareHeaders(methodName)
  options['method'] = prepareMethod(methodName)
  if(methodName !== 'GET'){
    options['body'] = prepareBody(methodName, body)
  }
  return options
}
export const buildFailHandler = (failure, ignore) =>{
  return (
    failure ?
    {
      failure: error => ignore.indexOf(error.msgCode) > -1 && failure(error),
      ignore,
    }
    :
    {}
  )
}