import { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { RedWarning, getValueByCode, minutesDiff, newId, stringFormat, toDate } from 'utils'
import { getMessageRemaining, getMessageShow } from './selector'
import Modal from '../modal'
import { OdsButton } from '../ods'
import { HTMLDiv } from '../elements'
import { message5_show, message5_hide } from './actions'
import './style.scss'

const CHECK_INTERVAL = 60000
const Message5 = (props: any) =>{
  const { show, remaining } = props
  const modalRef: any = useRef({})
  let intervalHandler: any = 0

  const offlineTime = getValueByCode('OFFLINE_MSG', 'TIME') || {value: 0, jsonData: ["05:30:00", "05:50:00", "05:55:00", "06:00:00"]}
  // const offlineTime = {value: 1, jsonData: ["13:00:00", "13:10:00", "13:25:00", "13:30:00"]}
  const offlineTimeStart = offlineTime.jsonData[0]
  const offlineTimeEnd = offlineTime.jsonData[offlineTime.jsonData.length -1]

  const offlineMsg = (getValueByCode('OFFLINE_MSG', 'MSG') || {jsonData: {
    title: 'FORTE will be offline',
    body: `FORTE will be offline for scheduled maintenance in {0} minutes. Please save your work so that you don't lose any information. Note: FORTE will be offline for approximately 1.5 hours. You won't be able to access FORTE during that time.`
  }}).jsonData

  useEffect(() => {
    if(offlineTime.value){
      setTimeout(() => { handleRun() }, 500)
    }
    return ()=>handleClear()
  }, [])
  
  const diffMin = (m: any) => minutesDiff(new Date(toDate(new Date(), 'YYYY-MM-DD') +' '+ m), new Date())

  const handleRun = () => {
    checkState(true)
    intervalHandler = setInterval(()=>{
      checkState(false)
    }, CHECK_INTERVAL)
  }
  
  const checkState = (asap: boolean)=>{
    const starting = diffMin(offlineTimeStart)
    if(starting > 0){
      return
    }
    const remaining = diffMin(offlineTimeEnd)
    if(remaining <= 0){
      handleClear()
    }else{
      const show = asap || offlineTime.jsonData.some((m: any)=>!diffMin(m))
      message5_show({show, remaining})
    }
  }

  const handleClear = () => {
    clearInterval(intervalHandler)
    intervalHandler = 0
  }
  
  useEffect(() => {
    if(show){
      modalRef.current.show()
    }else{
      modalRef.current.hide()
    }
  }, [show])

  const onClose = () => modalRef.current.hide()
  const onHide = () => message5_hide()

  const aodaProps = {describedBy: newId(), labelledBy: newId()}  
  return (<Modal ref={modalRef} onHide={onHide} {...aodaProps} className={`message5-modal`}>
    <RedWarning>
      <div id={aodaProps.labelledBy} className='simple-row strong big title'>{offlineMsg.title}</div>
      <div id={aodaProps.describedBy} className='offline-message-body'>
        <HTMLDiv aria-hidden='true' xss={false} content={stringFormat(offlineMsg.body, [remaining])}/>
      </div>
    </RedWarning>
      
    <div className='simple-row' style={{padding: '25px 0px 0px 0px'}}>
      <OdsButton level='primary' onClick={onClose}>Close</OdsButton>
    </div>
  </Modal>)
}
const mapStateToProps = (state: any, ownProps: any) =>({
	show: getMessageShow(state),
  remaining: getMessageRemaining(state)
})

export default connect(mapStateToProps, {})(Message5)