import React from 'react'

export const Warning = props => <svg aria-hidden='true' stroke= 'none' width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18 18H15V12H18V18ZM18 24H15V21H18V24ZM0 28.5H33L16.5 0L0 28.5Z" fill="#FFD440"/>
    <rect x="15" y="12" width="3" height="6" fill="#1A1A1A"/>
    <rect x="15" y="21" width="3" height="3" fill="#1A1A1A"/>
</svg>

export const Warning2 = props => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z" fill={props.color}/>
</svg>

export const Sync = props =><svg width="37" height="27" viewBox="0 0 37 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M31.9545 13.5L37 13.5L30.2727 6.75L23.5455 13.5L28.5909 13.5C28.5909 19.0856 24.0668 23.625 18.5 23.625C16.8014 23.625 15.1868 23.2031 13.7909 22.4437L11.3355 24.9075C13.4041 26.2238 15.8595 27 18.5 27C25.9336 27 31.9545 20.9588 31.9545 13.5ZM8.40909 13.5C8.40909 7.91437 12.9332 3.375 18.5 3.375C20.1986 3.375 21.8132 3.79687 23.2091 4.55625L25.6645 2.0925C23.5959 0.776249 21.1405 -6.93243e-07 18.5 -8.08661e-07C11.0664 -1.1336e-06 5.04546 6.04125 5.04546 13.5L-5.90104e-07 13.5L6.72727 20.25L13.4545 13.5L8.40909 13.5Z" fill="#2B8737"/>
</svg>

export const Unsync = props =><svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M12.75 4.19643V0.464286C11.5714 0.767858 10.4464 1.23214 9.39286 1.80357L12.0714 4.48214C12.3036 4.39286 12.5179 4.28571 12.75 4.19643ZM30.6071 14.2857C30.6071 10.3393 28.9821 6.78571 26.3929 4.21429L30.6071 0H19.8929V10.7143L23.8929 6.71429C25.8214 8.66072 27.0357 11.3214 27.0357 14.2857C27.0357 15.8036 26.6964 17.2321 26.125 18.5357L28.8036 21.2143C29.9464 19.1786 30.6071 16.8036 30.6071 14.2857ZM2.51786 0L0 2.51786L4.21429 6.73214C2.83929 8.91071 2.03571 11.5 2.03571 14.2857C2.03571 18.2321 3.66071 21.7857 6.25 24.3571L2.03571 28.5714H12.75V17.8571L8.75 21.8571C6.82143 19.9107 5.60714 17.25 5.60714 14.2857C5.60714 12.5 6.05357 10.8214 6.82143 9.33928L21.25 23.7679C20.8036 24 20.3571 24.1964 19.8929 24.375V28.1071C21.3214 27.7321 22.6607 27.1429 23.875 26.3929L28.4821 31L31 28.4821L2.51786 0Z" fill="#595959"/>
</svg>

export const Calendar = props => <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M16.9643 2.75H14.9286V0.515625C14.9286 0.232031 14.6996 0 14.4196 0H12.7232C12.4433 0 12.2143 0.232031 12.2143 0.515625V2.75H6.78571V0.515625C6.78571 0.232031 6.5567 0 6.27679 0H4.58036C4.30045 0 4.07143 0.232031 4.07143 0.515625V2.75H2.03571C0.91183 2.75 0 3.67383 0 4.8125V19.9375C0 21.0762 0.91183 22 2.03571 22H16.9643C18.0882 22 19 21.0762 19 19.9375V4.8125C19 3.67383 18.0882 2.75 16.9643 2.75ZM16.7098 19.9375H2.29018C2.15022 19.9375 2.03571 19.8215 2.03571 19.6797V6.875H16.9643V19.6797C16.9643 19.8215 16.8498 19.9375 16.7098 19.9375Z" fill={props.color}/>
</svg>


export const SentTo = props => <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M8.3125 9.5C10.9361 9.5 13.0625 7.37363 13.0625 4.75C13.0625 2.12637 10.9361 0 8.3125 0C5.68887 0 3.5625 2.12637 3.5625 4.75C3.5625 7.37363 5.68887 9.5 8.3125 9.5ZM11.6375 10.6875H11.0178C10.1939 11.066 9.27734 11.2812 8.3125 11.2812C7.34766 11.2812 6.43477 11.066 5.60723 10.6875H4.9875C2.23398 10.6875 0 12.9215 0 15.675V17.2188C0 18.2021 0.797852 19 1.78125 19H14.8438C15.8271 19 16.625 18.2021 16.625 17.2188V15.675C16.625 12.9215 14.391 10.6875 11.6375 10.6875Z" fill="#0066CC"/>
</svg>

export const Error = props => <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M10.5156 0.0273438C4.71962 0.0273438 0.015625 4.50734 0.015625 10.0273C0.015625 15.5473 4.71962 20.0273 10.5156 20.0273C16.3116 20.0273 21.0156 15.5473 21.0156 10.0273C21.0156 4.50734 16.3116 0.0273438 10.5156 0.0273438ZM11.5656 15.0273H9.46562V9.02734H11.5656V15.0273ZM11.5656 7.02734H9.46562V5.02734H11.5656V7.02734Z" fill="#CD0000"/>
</svg>




