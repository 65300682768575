import React from 'react'
import { ReactComponent as PrimaryIcons } from "ontario-design-system/icons/ontario-icons-primary.svg";
import { ReactComponent as SecondaryIcons } from "ontario-design-system/icons/ontario-icons-secondary.svg";

const OdsIcons = () => {
    return (<>
        <PrimaryIcons/>
        <SecondaryIcons/>
    </>
    )
}
export default OdsIcons