import { useEffect, forwardRef, useRef } from 'react'
import newId from 'utils/new-id'
import CharCounter from '../../character-count'
import './style.scss'

export default forwardRef((props:any, ref) =>  {
  const { value, label, desc, maxLength, extra, className, ...rest} = props
  const __ref = useRef({})
  const defaultRef:any = ref|| __ref

  useEffect(() => {
    let textArea:any = defaultRef.current
    const handleResize = () => {
      const height = textArea.style.height.replace('px', '')
      const scrollHeight = textArea.scrollHeight
      if(height < scrollHeight){
        textArea.style.height = (scrollHeight + 4) + "px"
      }
    }
    textArea.addEventListener("input", handleResize)

    setTimeout(() => {
      value && handleResize()
    }, 200)

    return ()=>{
      textArea.removeEventListener("input", handleResize)
    }
  }, [defaultRef, value])


  const textAreaId = newId()
  const __rest = {...rest, autoComplete: 'off', id: textAreaId, maxLength}

  return <div className={`ontario-form-group simple-row textarea2 ${className}`}>
    {label && <label role='dialog' aria-live='polite' className="ontario-label" htmlFor={textAreaId}>
        <span>{label}</span>
        <div className='simple-row ontario-label__flag'>{desc}</div>
      </label>
    }
    <div className='simple-row textarea-wrap'>
    <textarea aria-label='Please input details' ref={defaultRef} {...__rest} value={value ?? ''} className="ontario-input ontario-textarea"/>
    {extra}
    </div>
    <CharCounter size={maxLength} value={props.value}/>
  </div>
})