import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom/client'
import Modal from './modal'
import { DESTROY_ALL } from '../../services/action-types'
import './confirm.scss'
import { newId } from '../../utils';
import { OdsButton } from './ods'

const Confirm = (props: any) => new Promise((resolve, reject) => {
  const confirmDialogDiv = document.createElement('div')
  document.body.appendChild(confirmDialogDiv)
  const root = ReactDOM.createRoot(confirmDialogDiv)

  const destroy = () => removeDialog()
  document.addEventListener(DESTROY_ALL, destroy)

  const removeDialog = () => {
    root.unmount();
    document.body.removeChild(confirmDialogDiv);
    document.removeEventListener(DESTROY_ALL, destroy)
  }

  const onHide = (e: any) => {
    removeDialog()
    reject(e)
  }
  
  const onOk = (e: any) => {
    // removeDialog()
    resolve(e)
  }
  
  root.render(<ConfirmDialog onHide={onHide} onOk={onOk} {...props}/>)
  // ReactDOM.render(
  //   <ConfirmDialog onHide={onHide} onOk={onOk} {...props}/>,
  //   confirmDialogDiv
  // )
});

export default Confirm

const ConfirmDialog = (props: any) => {
  const { onHide, onOk, title, content, okText='OK', cancelText='Cancel', showCancel=true, noFrame=false, restoreFocus=true, style } = props
  const [contentId, setContentId] = useState<string>()
  const modalRef: any = useRef({})
  useEffect(() => { modalRef.current.show(); setContentId(newId()) }, [])
  const onClickHide = () => modalRef.current.hide()
  const onClickOk = () => {
    onOk()
    modalRef.current.hide()
  }
  
  const styles={
    width: 'inherit',
    maxWidth: '400px',
    height: 'inherit',
    ...style
  }

  return <div className='confirm'>
    <Modal ref={modalRef} role="alertdialog" describedBy={contentId} onHide={onHide} style={styles} restoreFocus={restoreFocus} styleHeaderUnderline={false}
      header={<span style={{paddingLeft: '10px'}}>{title}</span>} 
      footer={<div className='simple-row'><div className='col-sm-12'>
        {okText && <OdsButton level='primary' onClick={onClickOk}>{okText}</OdsButton>}
        {showCancel && <OdsButton level='secondary' onClick={onClickHide}>{cancelText}</OdsButton>}
      </div></div>}>
      <div id={contentId} className={!noFrame ? 'sub-text': ''}>{content}</div>
    </Modal>
  </div>
}