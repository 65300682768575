import React, { Component } from 'react'

export default class PressHold extends Component {  
  constructor(props: any) {
    super(props)
    this.handleButtonPress = this.handleButtonPress.bind(this)
    this.handleButtonRelease = this.handleButtonRelease.bind(this)
  }

  handleButtonPress (action: any) {
    // @ts-ignore
    this.buttonPressTimer = setInterval(action, 100);
  }

  handleButtonRelease () {
    // @ts-ignore
    clearInterval(this.buttonPressTimer);
  }

  render() {
    const { styles, className, action }: any = this.props
    return <div aria-hidden='true' className={className} 
      style={styles}
      onMouseDown={()=>this.handleButtonPress(action)} 
      onMouseUp={this.handleButtonRelease} 
      onMouseLeave={this.handleButtonRelease}/>
  }
}