import { 
  ADMIN_ANNOUNCEMENT_LIST_REFRESH,
  ADMIN_ANNOUNCEMENT_DETAIL_REFRESH
} from 'services/action-types'
const initTemplate={id: 0, label: 'Please select...', variables: []}
const initialState = {
  list: [],
  detail: initTemplate,
}

export const announcementReducer =  (state = initialState, action: { type: any; value: any }) => {
  switch (action.type) {
  case ADMIN_ANNOUNCEMENT_LIST_REFRESH:
    return state = { 
      ...state, 
      list: action.value,
    }
  case ADMIN_ANNOUNCEMENT_DETAIL_REFRESH:
    return state = { 
      ...state, 
      detail: action.value,
    }
  default:
      return state
  }
}