import { createSelector } from 'reselect'
import { getCurrentYear } from 'utils'
import { buildPlansTree } from './util'
export const getTeamYears = createSelector(
   state => state.ui.team.years, 
   data => data.sort((a,b)=>b.label.localeCompare(a.label))
)

export const getTeamYear = createSelector(
   state => state.ui.team.year, 
   data => data
)

export const getLastPath = createSelector(
   state => state.ui.team.lastPath, 
   data => data
)

export const getTeamPlans = createSelector(
   [
   state => state.ui.team.plans, 
   state => getTeamShowOpenPlan(state),
   state => getTeamYear(state),
   ],
   (data, showOpen, year) => buildPlansTree(
      year.code >= getCurrentYear().value ? data 
      : 
      !showOpen ? data.filter(d=>d.history) 
      : 
      data.filter(d=>!d.history)
   )
)

export const getTeamPPYears = createSelector(
   state => state.ui.team.ppYears, 
   data => data.sort((a,b)=>b.label.localeCompare(a.label))
)

export const getAllPlans = createSelector(
   state => state.ui.team.ppPlans, 
   data => [].concat.apply([], buildPlansTree(data).map(e=>e.plans))
)

export const getTeamStages = createSelector(
   state => state.ui.team.stages, 
   data => data
)

export const getTeamQueryParam = createSelector(
   state => state.ui.team.queryParam, 
   data => data
)

export const getTeamShowOpenPlan = createSelector(
   state => state.ui.team.showOpenPlan, 
   data => data
)

export { getNavUser } from '../navbar/selectors'
export { getNavId } from '../navbar/selectors'
export { getPlan } from '../performance/selectors'