// eslint-disable-next-line
import React, { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { getTMSUser } from 'utils/routing-util'
import './style.scss'

// import ConfirmDialog from './confirm-dialog'
const ConfirmDialog = lazy(() => import('./confirm-dialog'))

const LoginTester = (readyTester=[], f) => {

  const confirmDialogDiv = document.createElement('div')
  document.body.appendChild(confirmDialogDiv)
  const root = createRoot(confirmDialogDiv)

  const onOk = e => {
    root.unmount()
    goOn()
  }

  const goOn = () =>{
    document.body.removeChild(confirmDialogDiv)
    f()
  }

  if(!!!getTMSUser()){
    root.render(<ConfirmDialog tester={readyTester} onOk={onOk}/>)
  }else{
    goOn()
  }
}

export default LoginTester