let id_index = 0
export const PATH_ID_PROFILE='/profile'

export const PATH_ID_PERFORMANCE='/performance'

export const PATH_ID_MENTOR_WIZARD='/mentorWizard'
export const PATH_ID_MENTOR_PROFILE='/mentorprofile'
export const PATH_ID_MENTOR_MENTORS='/mentors'
export const PATH_ID_MENTOR_RESULTS='/mentorresult'
export const PATH_ID_MENTOR_CRITERIA='/mentorcriteria'

export const PATH_ID_TALENT='/talent'
export const PATH_ID_TALENT_SEARCH='/talentsearch'
export const PATH_ID_TALENT_LIST='/mytalent'
export const PATH_ID_TALENT_HISTORY='history'

export const PATH_ID_TEAM='/myteam'
export const PATH_ID_TEAM_DIRECT='/team'
export const PATH_ID_TEAM_EXTENDED='/extended'
export const PATH_ID_MANAGE_GOALS='/goallib'

export const PATH_ID_FEEDBACK='/feedback'
export const PATH_ID_SUCCESSOR='/successor'
export const PATH_ID_SHARE_PROFILE='/shareprofile'
export const MENU_ID_ADMIN='/admin'
export const PATH_ID_SYSTEM_ADMIN='/sysadmin'
export const PATH_ID_CORPORATE_ADMIN='/corpadmin'
export const PATH_ID_ADMIN_RATING='/rating'
export const PATH_ID_NOTIFY='/notify'
export const MENU_ID_HELP='/help'

id_index = 0
export const MENU_ID_INDIVIDUAL_MGMT = id_index++
export const MENU_ID_RATING = id_index++
export const MENU_ID_ORG_PRIORITIES = id_index++
export const MENU_ID_SUCC_PLANNING = id_index++
export const MENU_ID_MINISTRY_MAPPING = id_index++
export const MENU_ID_ADMIN_USERS = id_index++
export const MENU_ID_WIN_IMPORT = id_index++
export const MENU_ID_WIN_IMPORT_WIN = id_index++
export const MENU_ID_WIN_AD_MAPPING = id_index++
export const MENU_ID_REPORT = id_index++
export const MENU_ID_PROCESS_LOG = id_index++
export const MENU_ID_ANNOUNCEMENT = id_index++

id_index = 0
export const MENU_ID_DATA_EXTRACT = id_index++
export const MENU_ID_SYSTEM_VAL = id_index++
export const MENU_ID_BUSINESS_VAL = id_index++
export const MENU_ID_HELP_VAL = id_index++
export const MENU_ID_MENTOR_VAL = id_index++
export const MENU_ID_CACHE = id_index++
export const MENU_ID_AUDIT = id_index++
export const MENU_ID_SEND_GRID = id_index++
export const MENU_ID_ACCESS = id_index++

export const PROFILE_KEY_SUMMARY = 'Summary'
export const PROFILE_KEY_EXPERIENCE = 'Experience'
export const PROFILE_KEY_EDUCATION = 'Education'
export const PROFILE_KEY_SKILL = 'Skill'
export const PROFILE_KEY_PROJECT = 'Project'
export const PROFILE_KEY_LICENCE = 'Licence'
export const PROFILE_KEY_LANGUAGE = 'Language'
export const PROFILE_KEY_HONOR = 'Honor'

export const MENTOR_KEY_BASIC = 'Basic'
export const MENTOR_KEY_GOALS = 'Goals'
export const MENTOR_KEY_MEETING = 'Meeting'
export const MENTOR_KEY_SKILLS = 'Skills'
export const MENTOR_KEY_STYLES = 'Styles'
export const MENTOR_KEY_INTERESTS = 'Interests'

export const NEXTROLE_STATUS_ACTIVE = 'ACTIVE'
export const NEXTROLE_STATUS_NO = 'NO'
export const NEXTROLE_STATUS_EXCLUDE = 'EXCLUDE'
export const NEXTROLE_STATUS_CASUALLY = 'CASUALLY'
export const NEXTROLE_GROUP_PREF_OPP = 'PREF_OPP'
export const NEXTROLE_GROUP_PREF_FED = 'PREF_FED'
export const NEXTROLE_GROUP_PREF_GRP = 'PREF_GRP'
export const NEXTROLE_GROUP_PREF_JLV = 'PREF_JLV'
export const NEXTROLE_GROUP_PREF_CLS = 'PREF_CLS'
export const NEXTROLE_GROUP_PREF_LOC = 'PREF_LOC'

export const VALUE_TYPE_SKILL_CATE = 'SKILL_CATE'
export const VALUE_TYPE_SKILL ='SKILL'
export const VALUE_TYPE_NEXTROLE_STATUS ='NEXTROLE_STATUS'
export const VALUE_TYPE_NEXTROLE_GROUP ='NEXTROLE_GROUP'
export const VALUE_TYPE_NEXTROLE_INTEREST = 'NEXTROLE_INTEREST'
export const VALUE_TYPE_CERT_NAME ='CERT_NAME'
export const VALUE_TYPE_CERT_ORG ='CERT_ORG'
export const VALUE_TYPE_LANG ='LANG'
export const VALUE_TYPE_LANG_PROF ='LANG_PROF'
export const VALUE_TYPE_GOAL_CLS_REASON ='GOAL_CLS_REASON'
export const VALUE_TYPE_IMP_GOAL_CLS_REASON ='IMPROVE_GOAL_CLOSE_REASON'
export const VALUE_TYPE_NEXTROLE_JCLAZ ='NEXTROLE_JCLAZ'
export const VALUE_TYPE_NEXTROLE_JLEVEL ='NEXTROLE_JLEVEL'

export const VALUE_TYPE_PLAN_TYPE ='PPLAN_TYPE'
export const VALUE_PLAN_TYPE_NO_GOAL ='NO_GOAL'
export const VALUE_PLAN_TYPE_GOAL ='GOAL'
export const VALUE_PLAN_TYPE_RATING ='RATING'
export const VALUE_PLAN_TYPE_REVIEW ='REVIEW'
export const VALUE_PLAN_TYPE_NO_FINAL ='NO_FINAL_REVIEW'

export const VALUE_TYPE_GOAL_TYPE ='GOAL_TYPE'
export const VALUE_TYPE_GOAL_STATUS ='GOAL_STATUS'
export const VALUE_TYPE_PPLAN_ACK ='PPLAN_ACK'
export const VALUE_TYPE_ORG_TYPE ='ORG_TYPE'
export const VALUE_TYPE_UPDATE_TYPE = 'UPDATE_TYPE'
export const VALUE_TYPE_UPDATE_STATUS = 'UPDATE_STATUS'
export const VALUE_TYPE_UPDATE_TAG = 'UPDATE_TAG'
export const VALUE_TYPE_GOAL_LEARN_ACT = 'GOAL_LEARN_ACT'
export const VALUE_TYPE_GOAL_LEARN_ROLE = 'GOAL_LEARN_ROLE'
export const VALUE_TYPE_GOAL_FEEDBACK_REL = 'FEEDBACK_REL'
export const VALUE_TYPE_ROLE = 'ROLE'
export const VALUE_TYPE_IMPROVE_GOAL_REASON = 'IMPROVE_GOAL_REASON'
export const VALUE_TYPE_IMPROVE_GOAL_CONV = 'IMPROVE_GOAL_CONV'
export const VALUE_TYPE_FEEDBACK_MGR_REL = 'FEEDBACK_MGR_REL'
export const VALUE_TYPE_FEEDBACK_EMP_REL = 'FEEDBACK_EMP_REL'
export const VALUE_TYPE_EXP_OPS_MIN = 'EXP_OPS_MIN'
export const VALUE_TYPE_NO_GOAL_REASON ='PPLAN_NO_GOAL'
export const VALUE_TYPE_ADMIN_REPORT ='ADMIN_REPORT'

export const VALUE_TYPE_RATING_TYPE = 'RATING_TYPE'
export const VALUE_TYPE_RATING_CONTINUE = 'RATING_CONTINUE'
export const VALUE_TYPE_RATING_ACK = 'RATING_ACK'
export const VALUE_TYPE_RATING_NOCOV_REASON = 'RATING_NOCOV_REASON'
export const VALUE_TYPE_GOAL_LIB_DEPLOY_TYPE = 'GOAL_LIB_DEPLOY_TYPE'

export const VALUE_TYPE_REVIEW_TYPE = 'REVIEW_TYPE'
export const VALUE_TYPE_REVIEW_CONTINUE = 'REVIEW_CONTINUE'
// export const VALUE_TYPE_REVIEW_RATING_TYPE = 'REVIEW_RATING_TYPE'
export const VALUE_TYPE_REVIEW_STATUS = 'REVIEW_STATUS'

export const VALUE_TYPE_RATING_FACTOR = 'RATING_FACTOR'
//OPS: RATING_OPS, TASK: RATING_TASK, COLL: RATING_COLL, EMBRA: RATING_EMBRA, JUSTIF:, CAL_CALIB: RATING_CALIBRATION, OVR_CALIB: RATING_CALIBRATION
// export const VALUE_TYPE_RATING_OPS = 'RATING_OPS'
// export const VALUE_TYPE_RATING_TASK = 'RATING_TASK'
// export const VALUE_TYPE_RATING_COLL = 'RATING_COLL'
// export const VALUE_TYPE_RATING_EMBRA = 'RATING_EMBRA'
export const VALUE_TYPE_RATING_CALIBRATION = 'RATING_CALIBRATION'
export const VALUE_TYPE_RATING = 'RATING'
export const VALUE_TYPE_COMPENSATION_GROUP = 'COMP_GROUP'
export const VALUE_TYPE_BEN_STATUS = 'TP_BEN_STATUS'
export const VALUE_TYPE_EMPLOYEE_GROUP = 'EMPLOYEE_GROUP'

export const VALUE_TYPE_UI_BLOCK = 'UI_BLOCK'

export const VALUE_RATING_FACTOR_NORMAL = '0'
export const VALUE_RATING_FACTOR_CAL_CALIB = '1'
export const VALUE_RATING_FACTOR_OVR_CALIB = '2'

export const VALUE_TYPE_PLAN_STATUS = 'PPLAN_STATUS'
export const VALUE_TYPE_PLAN_REVIEW_STATUS = 'PPLAN_REVIEW_STATUS'
export const VALUE_TYPE_RS_SEARCH_AUTOCOMP = 'RS_SEARCH_AUTOCOMP'
export const VALUE_TYPE_RS_SEARCH = 'RS_SEARCH'

export const VALUE_TYPE_RS_MEMB_ASSM = 'RS_MEMB_ASSM'
export const VALUE_TYPE_RS_MEMB_INFORM = 'RS_MEMB_INFORM'
export const VALUE_TYPE_RS_MEMB_ACCEPT = 'RS_MEMB_ACCEPT'
export const VALUE_TYPE_RS_EXTRS_FTYPE = 'RS_EXTRS_FTYPE'

export const VALUE_TYPE_MT_SEARCH_AUTOCOMP = 'MT_SEARCH_AUTOCOMP'

export const VALUE_TYPE_MODAL_TOOLTIP = 'MODAL_TOOLTIP'
export const VALUE_PRA_MEASURE = 'PRA_MEASURE'
export const VALUE_PRA_PRIORITY = 'PRA_PRIORITY'
export const VALUE_PRA_UPDATE = 'PRA_UPDATE'
export const VALUE_AUTO_UPDATE_MANAGER = 'AUTO_UPDATE_MANAGER'
export const VALUE_AUTO_UPDATE_MANAGER_PRA = 'AUTO_UPDATE_MANAGER_PRA'
export const VALUE_MANAGE_GOALS_ADMIN = 'MANAGE_GOALS_ADMIN'
export const VALUE_RELATED_TO_ROLE = 'RELATED_TO_ROLE'
export const VALUE_LEARNING_ACTIVITIES = 'LEARNING_ACTIVITIES'
export const VALUE_PREF_GRP = 'PREF_GRP'
export const VALUE_SUCCESSOR_ROLES = 'SUCCESSOR_ROLES'
export const VALUE_IMPROVEMENT_GOAL = 'IMPROVEMENT_GOAL'
export const VALUE_IMPROVEMENT_GOAL_PIP = 'IMPROVEMENT_GOAL_PIP'
export const VALUE_IMPROVEMENT_GOAL_NON_PIP = 'IMPROVEMENT_GOAL_NON_PIP'
export const VALUE_IMPROVEMENT_GOAL_NON_PIP_BA = 'IMPROVEMENT_GOAL_NON_PIP_BA'
// export const VALUE_PREF_OPP = 'PREF_OPP'
// export const VALUE_PREF_FED = 'PREF_FED'
// export const VALUE_PREF_JLV = 'PREF_JLV'
// export const VALUE_PREF_LOC = 'PREF_LOC'

export const VALUE_TS_SEARCH_CAREER = 'TS_SEARCH_CAREER'
export const VALUE_TS_SEARCH_PICTURE = 'TS_SEARCH_PICTURE'
export const VALUE_TS_SEARCH_FILTER = 'TS_SEARCH_FILTER'
export const VALUE_TS_SEARCH_EXPERIENCE = 'TS_SEARCH_EXPERIENCE'
export const VALUE_TS_SEARCH_SKILL_MORE = 'TS_SEARCH_SKILL_MORE'
export const VALUE_TS_SEARCH_JOB_CATEGORY = 'TS_SEARCH_JOB_CATEGORY'
export const VALUE_TS_SEARCH_JOB_LEVEL = 'TS_SEARCH_JOB_LEVEL'
export const VALUE_TS_SEARCH_SKILL = 'TS_SEARCH_SKILL'

export const VALUE_TS_SEARCH_KEYWORD = 'TS_SEARCH_KEYWORD'
export const VALUE_TS_SEARCH_LIST_TYPE = 'TS_SEARCH_LIST_TYPE'
export const VALUE_TS_SEARCH_ALUMNI = 'TS_SEARCH_ALUMNI'
export const VALUE_TS_SEARCH_EXTERNAL = 'TS_SEARCH_EXTERNAL'
export const VALUE_TS_SEARCH_FLEX_WORK = 'TS_SEARCH_FLEX_WORK'
export const VALUE_TS_SEARCH_POSITIONS = 'TS_SEARCH_POSITIONS'
export const VALUE_TS_SEARCH_LIST_CLASSIFICATION = 'TS_SEARCH_LIST_CLASSIFICATION'

export const VALUE_INFORM_TYPE_IMMI = 'IMMI'

export const VALUE_PRA_UPDATE_TAGS = 'PRA_UPDATE_TAGS'
export const VALUE_PRA_RATE_REASON = 'PRA_RATE_REASON'
export const VALUE_PRA_RATE_IND_SHARE = 'PRA_RATE_IND_SHARE'
export const VALUE_PRA_RATE_STATUS_E = 'PRA_RATE_STATUS_E'
export const VALUE_PRA_RATE_STATUS_M = 'PRA_RATE_STATUS_M'
export const VALUE_PRA_RATE_STATUS_T = 'PRA_RATE_STATUS_T'

export const VALUE_ADMIN_LEFT_BEHIND_PLAN = 'ADMIN_LEFT_BEHIND_PLAN'
export const VALUE_ADMIN_SUCCESSOR_LIST = 'ADMIN_SUCCESSOR_LIST'
export const VALUE_ADMIN_SUCCESSOR_ASSIGN = 'ADMIN_SUCCESSOR_ASSIGN'
export const VALUE_ADMIN_ACCESS_LEVELS = 'ADMIN_ACCESS_LEVELS'
export const VALUE_ADMIN_MANAGE_GOAL = 'ADMIN_MANAGE_GOAL'
export const VALUE_ADMIN_EMAIL_ALL = 'ADMIN_EMAIL_ALL'
export const VALUE_ADMIN_ASSIGN_MANAGER = 'ADMIN_ASSIGN_MANAGER'

export const VALUE_PRA_RATE_FACTORS = 'PRA_RATE_FACTORS'
export const VALUE_PRA_RATE_MGR_SHARE = 'PRA_RATE_MGR_SHARE'

export const VALUE_GOAL_STATUS_DRAFT = 'DRAFT'
export const VALUE_GOAL_STATUS_SHARE = 'SHARE'
export const VALUE_GOAL_STATUS_INCOMP = 'INCOMP'
export const VALUE_GOAL_STATUS_COMPLETE = 'COMPLETE'
export const VALUE_GOAL_STATUS_DELETED = 'DELETED'

export const VALUE_RATING_CONTINUE_LEFT = 'LEFT'
export const VALUE_RATING_CONTINUE_CONT = 'CONT'

export const VALUE_GL_DEPLOY_DISABLED_VACANT = 'GL_DEPLOY_DISABLED_VACANT'
export const VALUE_GL_DEPLOY_DISABLED_NOT_REPORT = 'GL_DEPLOY_DISABLED_NOT_REPORT'
export const VALUE_GL_DEPLOY_DISABLED_NO_PLAN = 'GL_DEPLOY_DISABLED_NO_PLAN'
export const VALUE_GL_DEPLOY_DISABLED_PLAN_NOACCESS = 'GL_DEPLOY_DISABLED_PLAN_NOACCESS'
export const VALUE_GL_DEPLOY_DISABLED_PLAN_LOCKED = 'GL_DEPLOY_DISABLED_PLAN_LOCKED'
export const VALUE_GL_DEPLOY_DISABLED_MIN_SCOPE = 'GL_DEPLOY_DISABLED_MIN_SCOPE'
export const VALUE_GL_DEPLOY_DISABLED_JOB_CLASS = 'GL_DEPLOY_DISABLED_JOB_CLASS'
export const VALUE_GL_DEPLOY_DISABLED_INACTIVE = 'GL_DEPLOY_DISABLED_INACTIVE'
export const VALUE_GL_DEPLOY_DISABLED_NO_GOAL = 'GL_DEPLOY_DISABLED_NO_GOAL'

export const VALUE_GL_DEPLOY_RESULTS = 'GL_DEPLOY_RESULTS'

export const VALUE_TYPE_GL_RESULT = 'GL_RESULT'

export const RATING_TYPE_EMPL = 'EMPL'
export const RATING_TYPE_MGR = 'MGR'
export const RATING_TYPE_MIN = 'MIN'
export const RATING_TYPE_CORP = 'CORP'

export const PLAN_STATUS_DRAFT = 'DRAFT'
export const PLAN_STATUS_OPEN = 'OPEN'
export const PLAN_STATUS_MGR_RATE = 'MGR_RATE'
export const PLAN_STATUS_MIN_RATE = 'MIN_RATE'
export const PLAN_STATUS_CORP_RATE = 'CORP_RATE'
export const PLAN_STATUS_MGR_COMM = 'MGR_COMM'
export const PLAN_STATUS_COMPLETE = 'COMPLETE'
export const PLAN_STATUS_YEAR_CLOSE = 'YEAR_CLOSE'
export const PLAN_STATUS_INCOMP = 'INCOMP'

export const PLAN_STATUS_REVIEW_OPEN = 'OPEN'
export const PLAN_STATUS_REVIEW_FINAL = 'FINAL'
export const PLAN_STATUS_REVIEW_COMPLETE = 'COMPLETE'
export const PLAN_STATUS_REVIEW_YEAR_CLOSE = 'YEAR_CLOSE'

export const REVIEW_STATUS_DRAFT = 'DRAFT'
export const REVIEW_STATUS_REQUEST = 'REQUEST'
export const REVIEW_STATUS_PROCESS = 'PROCESS'
export const REVIEW_STATUS_FINISH = 'FINISH'

export const REVIEW_TYPE_INIT = 'INIT'
export const REVIEW_TYPE_MID = 'MID'
export const REVIEW_TYPE_MERIT = 'MERIT'
export const REVIEW_TYPE_PROB = 'PROB'
export const REVIEW_TYPE_FINAL = 'FINAL'

export const VALUE_TYPE_RS_LIST_ACCESS = 'RS_LIST_ACCESS'
export const VALUE_TYPE_RS_ACCESS_LEVEL = 'ACL_GRP'

export const VALUE_TYPE_RS_LIST_TYPE = 'RS_LIST_TYPE'
export const RS_LIST_TYPE_SUCCESSOR = 'SUCCESSOR'
export const RS_LIST_TYPE_RECRUITER = 'RECRUITER'
export const RS_LIST_TYPE_CUSTOM = 'CUSTOM'
export const RS_LIST_TYPE_DEFAULT = 'MY_DEFAULT'
export const RS_LIST_TYPE_SHARED = 'MY_SHARED'

export const RS_SHARE_TYPE_OWNER = 'RS_OWNER'
export const RS_SHARE_TYPE_EDITOR = 'RS_EDITOR'
export const RS_SHARE_TYPE_VIEWER = 'RS_VIEWER'
export const RS_SHARE_TYPE_REMOVE = 'RS_REMOVE'
export const RS_SHARE_TYPE_SELECT = 'RS_SELECT'

export const VALUE_TS_ACCESS_LEVEL = 'TS_ACCESS_LEVEL'

export const VALUE_TYPE_MENTOR_TYPE = 'MT_PROF_TYPE'
export const VALUE_TYPE_MENTOR_ACK = 'MT_ACK'

// id_index = 0
// export const RS_LIST_STEP_CURRENT = id_index++
// export const RS_LIST_STEP_DELETED = id_index++
// export const RS_LIST_STEP_CHANGES = id_index++

export const VALUE_CURRENT_YEAR = 'CURRENT_YEAR'

id_index = 0
export const RATING_STEP_IND_INIT = id_index++
export const RATING_STEP_IND_START = id_index++
export const RATING_STEP_IND_SHARE = id_index++
export const RATING_STEP_IND_SUMMARY = id_index++
export const RATING_STEP_IND_ACCESSMENT = id_index++

id_index = 0
export const RATING_STEP_MGR_INIT = id_index++
export const RATING_STEP_MGR_START = id_index++
export const RATING_STEP_MGR_FACTOR = id_index++
export const RATING_STEP_MGR_SHARE = id_index++

id_index = 0
export const REVIEW_STEP_IND_INIT = id_index++
export const REVIEW_STEP_IND_START = id_index++
export const REVIEW_STEP_IND_MINE = id_index++
export const REVIEW_STEP_MGR_INIT = id_index++
export const REVIEW_STEP_MGR_START = id_index++
export const REVIEW_STEP_MGR_PREV = id_index++

export const ON_BEHALF_PROFILE = 'profile'
export const ON_BEHALF_PERFORMANCE = 'performance'
export const ON_BEHALF_TALENT = 'talent'
export const ON_BEHALF_GOALLIB = 'goallib'
export const ON_BEHALF_EXTEND = 'mgr-ext'

export const ON_BEHALF_NORMAL = 0
export const ON_BEHALF_CORP = 1
export const ON_BEHALF_MIN = 2

export const BY_ROLE_CORP = 1
export const BY_ROLE_MIN = 2
export const BY_ROLE_MANAGER = 3

id_index = 0
export const REVIEW_POP_MGR_START_FINAL = id_index++      // 0
export const REVIEW_POP_MGR_CANCEL_REVIEW = id_index++    // 1
export const REVIEW_POP_MGR_DRAFT_UPDATE = id_index++     // 2
export const REVIEW_POP_MGR_SEND_BACK = id_index++        // 3
export const REVIEW_POP_MGR_PROGRESS = id_index++         // 4
export const REVIEW_POP_MGR_DRAFT_REVIEW = id_index++     // 5
export const REVIEW_POP_MGR_DRAFT_COMMENT = id_index++    // 6
export const REVIEW_POP_MGR_DRAFT_BOTH = id_index++       // 7

export const LOCK_TIME_OUT = 5 * 60 * 1000                  // 5 mins
export const LOCK_KEEP_TIME = 600                           // 10 mins

// export const LOCK_TIME_OUT = 15 * 1000
// export const LOCK_KEEP_TIME = 30                           //seconds

export const GLOABAL_TIME_OUT =  (LOCK_TIME_OUT + LOCK_KEEP_TIME * 1000) + (60 * 1000)

export const TALENT_CHANGE_HISTORY_LIST_PROPERTEIS = 'List properties'
export const TALENT_CHANGE_HISTORY_TALENT_CARD = 'Talent card'

export const MENTOR_PROF_INVALIDE = -1
export const MENTOR_PROF_STATUS_READY = 4
export const VALUE_MT_SEARCH_FILTER = 'MT_SEARCH_FILTER'
export const VALUE_MT_SEARCH_SKILLS = 'MT_SEARCH_SKILLS'
export const VALUE_MT_SAVED_SECTION_RESULTS = 'MT_SAVED_SECTION_RESULTS'
export const VALUE_MT_SEARCH_SECTION = 'MT_SEARCH_SECTION'
export const VALUE_MT_SAVED_SECTION_MENTORS = 'MT_SAVED_SECTION_MENTORS'
export const VALUE_TYPE_MT_SEARCH = 'MT_SEARCH'

export const PROC_LOG_TYPE_GDEPLOY = "Goal-Deploy"
export const PROC_LOG_TYPE_YCLOSE = "Year-Close"
export const PROC_LOG_TYPE_WIMPORT = "Win-Import"
