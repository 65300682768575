import { dispatchSimple, dispatchFetch } from 'store'
import { DOWNLOAD_TOGGLE_REFRESH, DOWNLOAD_WAITING_REFRESH, DOWNLOAD_TRIGGER_REFRESH, DOWNLOAD_START_REFRESH, DOWNLOAD_CHECK_REFRESH, 
  DOWNLOAD_PROCESS_REFRESH, DOWNLOAD_DELETE_REFRESH, DOWNLOAD_REMOVE_REFRESH, DOWNLOAD_BRANDNEW_REFRESH, DOWNLOAD_FINISHED_REFRESH } from 'services/action-types'
import { newId } from 'utils'

export const download_toggle_refresh = () => dispatchSimple({type: DOWNLOAD_TOGGLE_REFRESH})
export const download_waiting_refresh = (value: any) => dispatchSimple({type: DOWNLOAD_WAITING_REFRESH, value})
export const download_trigger = () => dispatchSimple({type: DOWNLOAD_TRIGGER_REFRESH})
export const download_start_refresh = (value: any) => dispatchSimple({type: DOWNLOAD_START_REFRESH, value})
export const download_remove_refresh = (value: any) => dispatchSimple({type: DOWNLOAD_REMOVE_REFRESH, value})

export const download_check_refresh = (value: any) => dispatchSimple({type: DOWNLOAD_CHECK_REFRESH, value})
export const download_process_refresh = (value: any) => dispatchSimple({type: DOWNLOAD_PROCESS_REFRESH, value})
export const download_delete_refresh = () => dispatchSimple({type: DOWNLOAD_DELETE_REFRESH})
export const download_brandnew_refresh = (value: any) => dispatchSimple({type: DOWNLOAD_BRANDNEW_REFRESH, value})
export const download_finished_refresh = (value: any) => dispatchSimple({type: DOWNLOAD_FINISHED_REFRESH, value})

export const download_request = (name: string, url: string, value: any, otherUrls: any) => download_waiting_refresh({id: newId(), name, url, params: value, otherUrls})

export const start_download = (value: any) => {
  dispatchFetch({
    spinner: true,
    path: value.url,
    method: 'POST',
    body: value.params,
    success: download_start_refresh
  })
}

export const check_download = (value: any) => {
  const url = value.otherUrls?.checkUrl ? `${value.otherUrls?.checkUrl}/${value.reportId}` : `api/tms/report/${value.reportId}`
  dispatchFetch({
    spinner: false,
    path: url,
    method: 'GET',
    success: download_check_refresh
  })
}
export const process_download = (value: any) => {
  const url = value.otherUrls?.downloadUrl ? `${value.otherUrls?.checkUrl}/${value.reportId}` : `api/tms/report/${value.reportId}`
  dispatchFetch({
    spinner: false,
    path: url,
    method: 'DOWNLOAD',
    success: data=>{
      download_process_refresh(data)
      download_finished_refresh(value)
      setTimeout(() => {
        delete_download(value)
      }, 1000)
    }
  })
}
const delete_download = (value: any) => {
  const url = value.otherUrls?.deleteUrl ? `${value.otherUrls?.deleteUrl}/${value.reportId}` : `api/tms/report/${value.reportId}`
  dispatchFetch({
    spinner: false,
    path: url,
    method: 'DELETE',
    success: download_delete_refresh,
    failure: download_delete_refresh
  })
}