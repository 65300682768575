import React, { useState, useEffect } from 'react'

const HeaderSort = (props: any) => {
  const { className = '', children, sortDir, onClick } = props
  const [arrow, setArrow] = useState('')

  useEffect(() => {
    setArrow(sortDir ? sortDir.toLowerCase(): '')
  }, [sortDir])

  const onclickSort = () => {
    setArrow(arrow.toLowerCase() === 'desc' ? 'asc' : 'desc')
    onClick && onClick(arrow === 'desc')
  }

  return (
    <div className='sort'>
      <div className={className}
        onClick={onclickSort}>
        {children}
        <span className={`fa fa-fw fa-sort fa-sort-${arrow}`}></span>
      </div>
    </div>
  )
};

export default HeaderSort;


