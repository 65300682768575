// import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
// import Scrollbar2 from 'react-custom-scrollbars'
// import PressHold from './press-hold'

// import './scroll-bar.scss'

// export default forwardRef((props, ref) => {
//   const { styles, hidearrow, onScroll, ...rest } = props
//   const [showBar, setShowBar] = useState(false)
//   const [enable, setEnable] = useState(true)
//   const scrollRef = useRef()

// 	useImperativeHandle(ref, () => ({
// 		lock: locked => setEnable(!locked),
//     getviewScrollTop: () => scrollRef.current.viewScrollTop
//   }))

//   const handleUpdate = values => {
//     const { scrollHeight, clientHeight } = values
//     setShowBar(scrollHeight > clientHeight)
//   }

//   const handleScroll = () => {
//     onScroll && enable && onScroll(scrollRef.current.getValues())
//   }

//   const onScrollUp = () => {
//     const current = scrollRef.current
//     current && current.scrollTop(current.getScrollTop() - 40)
//   }

//   const onScrollDown = () => {
//     const current = scrollRef.current
//     current && current.scrollTop(current.getScrollTop() + 40)
//   }
    
//     return (
//     <div className='scroll-bar' style={{...styles}} >
//       {!hidearrow && showBar && <PressHold className='fa fa-caret-up' action={onScrollUp}/>}
//         <Scrollbar2 className={`scroll-area ${showBar ? 'show-bar' : ''}`}
//           ref={scrollRef}
//           renderTrackVertical={props => <div {...props} 
//             className={`track-vertical ${showBar ? 'show-bar' : ''} ${!hidearrow ? 'show-arrow' : ''}`}/>}
//           renderThumbVertical={props => <div {...props} className='thumb-vertical'/>}
//           renderTrackHorizontal={props => <div {...props} className='track-horizontal'/>}
//           renderThumbHorizontal={props => <div {...props} className='thumb-horizontal'/>}
//           {...props}
//           onUpdate={handleUpdate}
//           onScroll={handleScroll}/>
//       {!hidearrow && showBar && <PressHold className='fa fa-caret-down' action={onScrollDown}/>}
//     </div>
//   )
// })

import React, { Component } from 'react'
import {Scrollbars as Scrollbar2} from 'react-custom-scrollbars-2'
import PressHold from './press-hold'

import './scroll-bar.scss'
export default class scrollbar extends Component {  
  constructor(props, ...rest) {
    super(props, ...rest)
    this.state = { showBar: false, enable: true }
    this.scrollRef = React.createRef()
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    
    this.onScrollUp = this.onScrollUp.bind(this)
    this.onScrollDown = this.onScrollDown.bind(this)
    this.handleContainerScroll = this.handleContainerScroll.bind(this)
  }

  lock = locked => this.setState({ enable: !locked })

  getviewScrollTop = () => this.scrollRef.current.viewScrollTop

  handleUpdate(values){
    const { scrollHeight, clientHeight } = values
    const _showBar = scrollHeight > clientHeight
    this.props.onShowBar && this.props.onShowBar(_showBar)
    this.setState({ showBar: _showBar })
  }

  handleScroll(){
    this.props.onScroll && this.state.enable && this.props.onScroll(this.scrollRef.current.getValues())
  }

  onScrollUp(){
    const current = this.scrollRef.current
    current && current.scrollTop(current.getScrollTop() - 40)
  }

  onScrollDown(){
    const current = this.scrollRef.current
    current && current.scrollTop(current.getScrollTop() + 40)
  }

  handleContainerScroll(event) {
    const view = event.target;
    view.scrollTop = 0;
    view.scrollLeft = 0;
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    this.scrollRef.current?.container.addEventListener('scroll', this.handleContainerScroll)
  }

  componentWillUnmount(){
    this.scrollRef.current?.container.removeEventListener('scroll', this.handleContainerScroll)
  }

  render(){
    const { showBar } = this.state
    const { styles, hidearrow } = this.props
    const { onShowBar, className, ...rest } = this.props
    return <div className={`scroll-bar ${className}`} style={{...styles}} >
      {!hidearrow && showBar && <PressHold className='fa fa-caret-up' action={this.onScrollUp}/>}
        <Scrollbar2 className={`scroll-area ${showBar ? 'show-bar' : ''}`}
          ref={this.scrollRef}
          renderTrackVertical={props => <div {...props} 
            className={`track-vertical ${showBar ? 'show-bar' : ''} ${!hidearrow ? 'show-arrow' : ''}`}/>}
          renderThumbVertical={props => <div {...props} className='thumb-vertical'/>}
          renderTrackHorizontal={props => <div {...props} className='track-horizontal'/>}
          renderThumbHorizontal={props => <div {...props} className='thumb-horizontal'/>}
          {...rest}
          onUpdate={this.handleUpdate}
          onScroll={this.handleScroll}/>
      {!hidearrow && showBar && <PressHold className='fa fa-caret-down' action={this.onScrollDown}/>}
    </div>
  }
}