import { USER_THUMBNAIL_REFRESH } from 'services/action-types'

const thumbnailState = {
  images: {}
}

export const thumbnail_reducer =  (state = thumbnailState, action: any) => {
  switch (action.type) { 
    case USER_THUMBNAIL_REFRESH:
      return { 
        ...state, 
        images: setThumbs(state.images, action.value) 
      }
  default:
    return state
  }
}

const setThumbs = (images: any, value: any) => {
  images[value.id] = value.image
  return images
}