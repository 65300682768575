import { ValueListDto, ValueListBase, AdUserDto, UserBase, Person, DocumentDto, ProfileSkillDetail } from 'types'

class PraPlanCreateDto{
	//Plan data
    position!: string;
    type!: ValueListDto;
    ind!: UserBase;
    year!: Date;
		
	//plan support data
    tmMinistryId!: number;
    managerId!: number;   //id : [0: unknow, >0: selected mgr, <0: suggest mgr]
    assignmentId!: number;
}
class TmOrgDto{
    id!: number;
    ver!: number;
    name!: string; //label
    label!: string;
    minId!: number;
    belongsId!: number;
    type!: ValueListDto;
    fromDate!: number;
    active: number = 1;    
    path: string[] = []; //TBS, CAC, EAB
    hasChild!: number; //1: Has Children; 0: no Children
    ministry!: TmOrgDto;
    belongsTo!: TmOrgDto;
}
class PraAssMapDto{
    id!: number;
    planId!: number;
    assignmentId!: number;
    primary!: number;
    ver!: number;
    position!: string;
    compGroup!: string;
    tmMinistry!: TmOrgDto;
    winMinistryId!: string;
    winMinistry!: string;
    from!: number;
    to!: number;
    manualAssignment!: boolean;
    positionWinId!: string;
}
class PraPlanAutoPushJson{
    detail!: string;
    at!: number;
    by!: number;
    onBehalf!: number; //1:'Corp Admin'; 2:'Min Admin';
}
class PPlanAutoPushDetail extends PraPlanAutoPushJson {
    actBy!: Person;
}
class PPlanRating{
    calcualeRate!: ValueListBase;
    finalRate!: string;
    finalThought!: string;
}
class PraPlanRateStageStatusDto{
    beforeRatingSeason!: number;
    startRatingSeason!: number;
    minFrozenRateData!: number;
    minStartCalibration!: number;
    minFinalizeCalibration!: number;
    corpFinalizeCalibration!: number;
    corpPublishRating!: number;
    yearClose!: number;
}
class PraPlanRateCreateDto{
    planId!: number;
    continueRate!: ValueListDto;	//1: End of performance year, 0: job change;
    finalThought!: string;
    onBehalf!: number; //by updateBy : 1 corp admin, 2 min admin 
}
class PraPlanRateDetailDto{
    id!: number;
    ver!: number;
    active: number = 1;
    onBehalf!: number; //by updateBy : 1 corp admin, 2 min admin
    planRatingId!: number;
    planRatingTypeId!: number;
    planRateType!: ValueListDto;
    ratingFactorId!: number;
    ratingFactor!: ValueListDto;
    ratingValueId!: number;
    ratingValue!: ValueListDto;
    comment!: string;
    score: number = -1;
    updated!: number; //For UI setup if the value changed when submitted request
    override!: number; //To mark the rating is override by admin
    submit!: number; 
}
class PraPlanRateDetailWrapDto extends PraPlanRateCreateDto {
    type!: ValueListDto;
    noGoalReason!: ValueListDto;
    submitAt!: number;
    submitBy!: UserBase;
    details: PraPlanRateDetailDto[] = [];  //only for RATING plan type
}
class PraPlanRateAuditDto{
    planRateId!: number;
    by!: number;
    at!: number;
    onBehalf!: number; //1:'Corp Admin'; 2:'Min Admin'; 0:'by'
}
class RateConversationJson{
    conversation!: number;  //1 : yes; 0: no
    conversationAt!: number;
    noConversationReasonId!: number;
    comment!: string; 
}
class RateConversationDetail extends RateConversationJson {
    noConversationReason!: ValueListDto;
    updated!: number; //For UI setup if the value changed
}
class RateRejectJson{
    comment!: string;
    at!: number;
    by!: number;
    byRole!: number; //1:'Corp Admin'; 2:'Min Admin'; 3:'Manager'
    onBehalf!: number; //1:'Corp Admin'; 2:'Min Admin'; 0:'by'
}
class RateRejectDetail extends RateRejectJson {
    manager!: UserBase;	
}
class RateDirectAssignJson{
    detail!: string;
    at!: number;
    by!: number;
    onBehalf!: number; //1:'Corp Admin'; 2:'Min Admin';
}
class RateDirectAssignDetail extends RateDirectAssignJson {
    actBy!: UserBase;
}
class PraPlanRateAckDto{
    id!: number;
    ver!: number; //Update_At time
    active: number = 1;
    planRateId!: number;
    ack!: ValueListDto;
    displayOrder!: number;
}
class UpdateHistoryUpdateDataDto{
    id!: number;
    active!: number;        //0: deleted parent update/comment
    replyId!: number;
    content!: string;
    type!: ValueListDto; //reply, update comment
    status!: ValueListDto; //draft, post
    readOnly!: number;
    createAt!: number;     //for UI order
    updateAt!: number;
    updateById!: string;
    updateBy!: UserBase; //last updateBy
    onBehalf!: number;
    removeHistory!: number; //set by UI if the update to be deleted
}
class UpdateHistoryGoalDataDto{
    id!: number;
    name!: string;
    type!: ValueListDto;
    status!: ValueListDto;
    updates: UpdateHistoryUpdateDataDto[] = [];
}
class HistoryDateRationaleJson{
    detail!: string;
    changeList: string[] = [];
    at!: number;
    by!: number;
}
class PraPlanUpdateRationaleDetail extends HistoryDateRationaleJson{
    byUser!: UserBase;
}
class PraPlanRateDto extends PraPlanRateDetailWrapDto {
    id!: number;
    ver!: number;
    lastUpdate!: PraPlanRateAuditDto;
    conversation!: RateConversationDetail; //for Manager and History
    rateReject!: RateRejectDetail;	
    directAssign!: RateDirectAssignDetail;
    acks: PraPlanRateAckDto[] = [];
    goals: UpdateHistoryGoalDataDto[] = [];
    rationales: PraPlanUpdateRationaleDetail[] = [];
}
class PraPlanReviewDto{
    id!: number;
    ver!: number;
    planId!: number;
    active!: number;  //in case soft delete review
    lastUpdate!: PraPlanRateAuditDto;  //id is review Id
    type!: ValueListDto; //init, mid, final
    status!: ValueListDto;
    requestAt!: number;
    employeeComment!: string;
    emplUpdateAt!: number;
    reviewAt!: number;
    managerComment!: string;
    mgrUpdateAt!: number;
    finalReviewReason!: ValueListDto;
    cleanDraft!: number;
    submitAt!: number;  //review complete at
    submitBy!: UserBase; //Manager name may different for different review
    updateTimeAfterComplete!: number;
    reminderAt!: number;
    reminder!: string;
    reject!: RateRejectDetail;
    reSubmitAfterReject!: number;
    employeeSubmitByUI!: number; //Identify submit action. 1: submit, 0: update
}
class GoalCreateDto{
    name!: string;
    type!: ValueListDto;
	//context
    planId!: number;
    displayOrder!: number;
}
class EndorseJson{
    detail!: string;
    at!: number;
    by!: number;
    onBehalf!: number; //1:'Corp Admin'; 2:'Min Admin'; 0:'by'
}
class EndorseDetail extends EndorseJson {
    manager!: Person;	
}
export class PraUpdateTagDto{
    id!: number;
    ver!: number; //Update_At time
    active: number = 1;
    updateId!: number;
    tag!: ValueListDto;
    displayOrder!: number;
    createById!: string;
    createBy!: UserBase;
    createAt!: number;
}
export class PraUpdateMeasureDto{
    id!: number;
    ver!: number; //Update_At time
	updateId!: number;
    measure!: MeasureDto;	
	createById!: string;
    createBy!: UserBase;
    createAt!: number;
}
export class GoalUpdateDto{
    id!: number;
    ver!: number;
    active: number = 1;
    content!: string;
    type!: ValueListDto; //reply, update comment, feedback
    tags: PraUpdateTagDto[] = []; 
	measureList: PraUpdateMeasureDto[] = [];
	//context
    goalId!: number;
    measureId!: number;
    replyToId!: number;  //Available when type = reply
    displayOrder!: number;
    ownBy!: number; //business data owner, [Update/Emp; Comment/Mgr; Reply/Emp&Mgr]
    onBehalf!: number;
    draft!: number;
    updateAt!: number;
    updateById!: string;
    updateBy!: UserBase; //last updateBy
    createAt!: number;
		
	//update need
    status!: ValueListDto;
}
export class GoalStatusDto{
	id!: number;
    ver!: number;
    statusReason!: ValueListDto;
    statusAt!: number;
    statusComment!: string;
}
export class MeasureDto{
    id!: number;
    ver!: number;
    active: number = 1;
    goalId!: number;
    content!: string;
    displayOrder!: number;
    type!: ValueListDto;
    status!: ValueListDto;
    updates: GoalUpdateDto[] = [];
}
export class OrgPriorityDto{
    id!: number;
    ver!: number;
    org!: TmOrgDto;
    name!: string;
    content!: string;
    displayOrer!: number;
    active: number = 1;
    used!: boolean;
    lastUpdate!: number; //sort by
}
export class GoalPriorityDto{
    id!: number;
    ver!: number; //Update_At time
    active: number = 1;
    goalId!: number;
    priority!: OrgPriorityDto;
    displayOrder!: number;
    createById!: string;
    createBy!: UserBase;
    createAt!: number;
}
export class GoalValueDto{
    id!: number;
    ver!: number; //Update_At time
    active: number = 1;
    goalId!: number;
    value!: ValueListDto;
    displayOrder!: number;
}
class GoalFeedbackDetailDto{
    id!: number;
    ver!: number;
    active: number = 1;
    ownerId!: number; //owner individual id
    url!: string;
    status!: ValueListDto; //Draft, Request(FB), Response(FB) 
    type!: ValueListDto; //share, confide
    lock!: number;
    managerAck!: number; //can be null
	
	//Send to
    requestTo!: AdUserDto;
    requestToEmail!: string;
    relationType!: ValueListDto;
    invite!: number; 
	
	//Send From
    requestAt!: number;
    requestBy!: Person; //Create By
    onBehalf!: number;
    owner!: Person;
    feedbackOwnerId!: number;  // emp or mgr
	
	//Response detail
    feedback!: string;  //feedback word
    feedbackAt!: number;
}
export class GoalDto extends GoalCreateDto {
    id!: number;
    ver!: number;
    active: number = 1;
    description!: string;
    from!: number;
    to!: number;
    ownBy!: number; //For UI use
    status!: ValueListDto;
    statusReason!: ValueListDto;
    statusComment!: string;
    statusAt!: number;
    shareAt!: number;
    loadedAt!: number;
    fbLoadedAt!: number;
    newSharedGoal: number = 0;
    newProgressUpdates: number = 0;
    newReceivedFeedbacks: number = 0;
    deployType!: string;
    deployedByMgr!: boolean;
    endorses: EndorseDetail[] = [];
    updates: GoalUpdateDto[] = [];
  	 //Performance Goal
     measures: MeasureDto[] = [];
     priorities: GoalPriorityDto[] = [];
    //Learning Goal
    activities: GoalValueDto[] = [];
    learningSkills: ProfileSkillDetail[] = [];
    relateRoles: GoalValueDto[] = [];
    feedbacks: GoalFeedbackDetailDto[] = [];

    draftUpdate: GoalUpdateDto = new GoalUpdateDto(); //UI Only
    newPriorities: GoalPriorityDto[] = []; //UI Only
}
class PraPlanAckDto{
    id!: number;
    ver!: number; //Update_At time
    active: number = 1;
    planId!: number;
    ack!: ValueListDto;
    displayOrder!: number;
}
export class PraPlanDto extends PraPlanCreateDto{
    id!: number;
    ver!: number;
    active: number = 1;
    status!: ValueListDto; //Draft, Open, RatingReq, RatingResp, RatingAppv(Complete)
    profileId!: number;
    manager!: UserBase;//id : [0: unknow, >0: selected mgr, <0: suggest mgr]
    winUpdateManger!: number;  //MCP == 0; BU : {0, 1} by UI
	
	//primary
    planType2EGroup!: ValueListDto;  //use for map to plan type: MCP->Rating, BU->Review, UI use only
    compGroup!: string;
    ministry!: TmOrgDto;
    winMinistryId!: string;
    winMinistry!: string;
    from!: number;
    to!: number;	
	//Others
    assMaps: PraAssMapDto[] = [];
    comment!: string;	            // for Review type Plan, send back message
    noGoalReason!: ValueListDto;  //NoGoal pre-defined reason
    noGoalCustomReason!: string;
    autoPush!: PPlanAutoPushDetail;
    formula!: ValueListDto;
    finalRating!: PPlanRating; //final rating	
	
	//For RATING
    stageStatus!: PraPlanRateStageStatusDto;
    planRatingList: PraPlanRateDto[] = [];
	
	//For REVIEW
    planReviewList: PraPlanReviewDto[] = [];
    reviewDocs: DocumentDto[] = [];
    goals: GoalDto[] = [];
    acks: PraPlanAckDto[] = [];
    ableChangePlanType!: boolean;
    needGoal!: boolean;
    history!: boolean;
    deployNotify!: boolean;
}