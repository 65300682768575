import { MESSAGE_SHOW, MESSAGE_HIDE } from 'services/action-types'

const initialState = {
  messages: []
}

export const message_reducer= (state = initialState, action: any) => {
  const messages: any = [...state.messages]
  switch (action.type) {
  case MESSAGE_SHOW:
    messages.push(action.value)
    return { ...state, messages: [...messages] }
  case MESSAGE_HIDE:
    return {  ...state, messages: [...messages.filter((e: any)=> e.id !== action.value)] }
  default:
    return state
  }
}