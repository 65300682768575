import { 
  ADMIN_USER_ROLES_REFRESH,
  ADMIN_USER_ROLES_CLEAR,
  ADMIN_IND_ROLES_REFRESH,
  ADMIN_USER_ROLE_VALUES_REFRESH,
  ADMIN_USER_ROLE_REFRESH,
  ADMIN_USER_MIN_REFRESH,
  ADMIN_USER_SORT_REFRESH
} from 'services/action-types'

export const sortValues = [
  {sortLabel: 'First name (A-Z)', sortFunc: (a,b)=>a.user.firstName?.localeCompare(b.user.firstName)},
  {sortLabel: 'Last name (A-Z)', sortFunc: (a,b)=>a.user.lastName?.localeCompare(b.user.lastName)},
]
export const sortValuesPpra = [
  {sortLabel: `'No access' first`, sortFunc: (a,b)=>a.uroleWithAdminPos - b.uroleWithAdminPos},  
  {sortLabel: `'No access' last`, sortFunc: (a,b)=>b.uroleWithAdminPos - a.uroleWithAdminPos}
]

const initialState = {
  roles: [],
  min: {},
  userAllRoles: [],
  roleValues: [],
  role: {},
  sort: sortValues[0],
}

export const userReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_USER_ROLES_REFRESH:
    return state = { 
      ...state, 
      roles: action.value,
    }
  case ADMIN_USER_ROLES_CLEAR:
    return state = { 
      ...state, 
      roles: [],
      role: {}
    }
  case ADMIN_IND_ROLES_REFRESH:
    return state = { 
      ...state, 
      userAllRoles: action.value
    }
  case ADMIN_USER_ROLE_VALUES_REFRESH:
    return state = { 
      ...state, 
      roleValues: action.value
    }
  case ADMIN_USER_ROLE_REFRESH:
    return state = { 
      ...state, 
      role: action.value
    }
  case ADMIN_USER_MIN_REFRESH:
    return state = { 
      ...state, 
      min: action.value
    }
  case ADMIN_USER_SORT_REFRESH:
    return state = { 
      ...state, 
      sort: action.value || sortValues[0]
    }
    
  default:
      return state
  }
}