import { 
  ADMIN_AUDIT_LIST_REFRESH,
  ADMIN_AUDIT_LIST_CLEAR,
  ADMIN_AUDIT_DETAIL_REFRESH,  
  ADMIN_AUDIT_ENTITY_REFRESH,
  ADMIN_AUDIT_ACTION_REFRESH
} from 'services/action-types'

const initialState = {
    list: {
      totalRecords: 0,
      items: [],
      reset: true,
      init: true
    },
    detail: {},
    entity: [],
    action: []
}

export const auditReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_AUDIT_LIST_REFRESH:
    return state = { 
      ...state, 
      list: action.value,
      detail: {},
      reset: false
    }
  case ADMIN_AUDIT_LIST_CLEAR:
    return state = { 
      ...state, 
      list: {
        totalRecords: 0,
        items: [],
        reset: true,
        init: true
      },
      detail: {},
      reset: false
    }
  case ADMIN_AUDIT_DETAIL_REFRESH:
    return state = { 
      ...state, 
      detail: action.value
    }
  case ADMIN_AUDIT_ENTITY_REFRESH:
    return state = { 
      ...state, 
      entity: action.value
    }
    case ADMIN_AUDIT_ACTION_REFRESH:
  return state = { 
    ...state, 
    action: action.value
  }
  default:
      return state
  }
}