import React, { useRef, useState } from 'react'
import {OdsInput } from 'components/common'
import EditButton from './edit-button'
import './style.scss'

interface propsType{
  onEdit?: any;
  onFocus?: any;
  onBlur?: any;
  onValueChange?: any;
  maxLength?: any;
}
  
const EditInput = (props: propsType) =>{
    const {onEdit=()=>{}, onFocus=()=>{}, onBlur=()=>{}, onValueChange=()=>{}, maxLength, ...rest} = props
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState('')
    const containerRef: any = useRef({}), inputRef: any = useRef({})
    
    const handleFocus = (e: any) => {
      setEditing((e.target.value?.length || 0) <= maxLength )
      onFocus(e)
    }

    const handleBlur = (e: any) => {
      const _value = e.target.value
      setValue(_value)
      if(containerRef.current.contains(e.relatedTarget)){
        return
      }
      handleChange(e, _value)
    }

    const handleButtonBlur = (e: any) => {
      if(containerRef.current.contains(e.relatedTarget)){
        return
      }
      handleChange(e, value)
    }

    const handleEdit = (e: any) => {
      onEdit(e)
      if(!editing){
        inputRef.current.focus()
      }else{
        handleChange(e, value)
      }
    }

    const handleChange = (e: any, v: any) => {
      onValueChange(v)
      onBlur(e)
      setEditing(false)
    }
  
  const inputProps = {ref: inputRef, onFocus: handleFocus, onBlur: handleBlur, maxLength, ...rest}
  return (    
    <div className='simple-row edit-input' ref={containerRef}>
      <OdsInput {...inputProps} extra={<EditButton inEditing={editing} onClick={handleEdit} onBlur={handleButtonBlur}/>}/>
    </div>
  )
}
export default EditInput