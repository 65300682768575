import { useRef, forwardRef} from 'react'
import Select from './select'
import './month.scss'
const option0 = {value: -1, label: '\u00A0'}
const options = [
  {value: 0, label: 'January'},
  {value: 1, label: 'February'},
  {value: 2, label: 'March'},
  {value: 3, label: 'April'},
  {value: 4, label: 'May'},
  {value: 5, label: 'June'},
  {value: 6, label: 'July'},
  {value: 7, label: 'August'},
  {value: 8, label: 'September'},
  {value: 9, label: 'October'},
  {value: 10, label: 'November'},
  {value: 11, label: 'December'}
]
export default forwardRef((props: any, ref) => {
  const { defaultValue, label, onChange, isDisabled=false, required=false, /*blockMsg=''*/ } = props
  const __ref = useRef({})
  const monthRef = ref || __ref

  const handleChange = (option: any) => {
    onChange(option ? option.value : option0.value)
  }

  const getDefault = () => {
    return options.filter(e => e.value === defaultValue)[0]
  }

  const _options = required ? options : [option0, ...options]
  return (
    <div className='month'>
      <Select ref={monthRef} label={label} blockMsg={`invalid ${label}`} selected={getDefault()} isDisabled={isDisabled} onChange={handleChange} placeholder='Month'
      options={_options}/>

      {/* <DropDownList onChange={(index:number)=>handleChange(_options[index])}>
        {_options.map((t: any,i: number)=><option key={i} value={i}
        selected={t.value===defaultValue}>{t.label}</option>)}
      </DropDownList> */}
    </div>
  )
}
)


