import 'whatwg-fetch'
import { tryParseJSON } from 'utils'

const FETCH_TIMEOUT = 10 * 60 * 1000
const statusText = 'Request timed out'

const FetchWithTimeout = (url: string, options: any) => new Promise((resolve, reject) => {
  const timeout = setTimeout(() => reject({statusText, url}), FETCH_TIMEOUT)

  const done = (response: any) => {
    clearTimeout(timeout) 
    resolve(response)
  }

  if(options['headers']['origin-method'] === 'UPLOAD' && options['onprogress']){
    request(url, options).then(done, reject).catch(reject)    
  }else{
    fetch(url, options).then(done, reject).catch(reject)
  }
})

export default FetchWithTimeout

const request = (url: string, options: any) => {
  const _headers = options['headers']
  const _method = options['method']
  const _body = options['body']
  const _onprogress = options['onprogress']
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(_method || 'GET', url)
    Object.keys(_headers).forEach(key => xhr.setRequestHeader(key, _headers[key]))
    xhr.onload = () => {
      resolve({
        body: {},
        bodyUsed: false,
        headers: {},
        ok: true,
        redirected: false,
        status: xhr.status,
        statusText: xhr.statusText,
        type: 'basic',
        url: xhr.responseURL,
        json: ()=>new Promise(resolve=>resolve(tryParseJSON(xhr.response)))
      })
    }
    xhr.onerror = () => {
      reject(xhr.statusText)
    }

    // event.loaded returns how many bytes are downloaded
    // event.total returns the total number of bytes
    // event.total is only available if server sends `Content-Length` header
    // console.log(`Uploaded ${event.loaded} of ${event.total} bytes`);
    xhr.upload.onprogress = _onprogress

    xhr.send(_body)
  })
}