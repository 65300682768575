import {
  SSE_STATUS_REFRESH1,
  SSE_INPRO_REFRESH
} from 'services/action-types'

const initialState = {
    sseStatus: [],
    sseInProgress: 0
}

export const progress_reducer =  (state = initialState, action: any) => {
  switch (action.type) {
  case SSE_STATUS_REFRESH1:
    return state = { 
      ...state, 
      sseStatus: action.value || []
    }
  case SSE_INPRO_REFRESH:
    return state = { 
      ...state, 
      sseInProgress: action.value ? state.sseInProgress + action.value : 0
    }
    
  default:
      return state
  }
}