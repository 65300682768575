
import { handleGlobalTimeout, handleLockTimeout } from 'services/timeout-handler'
import { GLOABAL_TIME_OUT, LOCK_TIME_OUT } from 'services/constants'

export default (()=>{
  let global_timeout: string | number | NodeJS.Timeout | undefined, lock_timeout: string | number | NodeJS.Timeout | undefined
  const globalReset=()=>{
    clearTimeout(global_timeout)
    global_timeout = setTimeout(handleGlobalTimeout, GLOABAL_TIME_OUT)
    
    clearTimeout(lock_timeout)
    lock_timeout = setTimeout(handleLockTimeout, LOCK_TIME_OUT)
  }

  return ({
    globalReset
  })
})()