import { Key } from "./key-util";

export const trim = str =>{
    return str && str.trim()
}
export const trimEnd = str =>{
    return str && str.trimEnd()
}
export const uniqueArray = a => {
    var unique = a.filter((value, index, self) => {
        return self.indexOf(value) === index;
    }); 
    return unique;
}

export const trimRight = (str, c) => {
    return str.slice(-1) === c ? str.slice(0, str.length -1) : str
}

export const leftChars = (str, length) => {
    return str ? str.slice(0, length) : str
}

export const cutoff = (str, length, more) => {
    const _str = leftChars(str, length)
    return str===_str ? _str : (_str + more)
}
export const compareStr = (a, b) =>a?.toLowerCase().split(' ').join('') === b?.toLowerCase().split(' ').join('')

export const cloneMap = map => {
    // IE11 doesn't support map constructor with iteratable
    let newMap = new Map()
    map && map.forEach((value, key) => newMap.set(key, value))
    return newMap
}

export const stringFormat = (theString, argumentArray) => {
    const regex = /{(\d+)}/;
    const _r = (p, c) => p.replace(regex, c)
    return argumentArray.reduce(_r, theString)
}

export const join = (strArray, by) =>{
    return strArray.filter(s=>s).map(s=>s.trim()).join(by)
}

const getExt = str =>{
    const ext = str.split('.').pop()
    return ext && ext.trim().toUpperCase()
}

export const getExtention = str =>{
    const ext = getExt(str)
    return ext && '(' + ext + ')'
}

export const verifyExtention = (str, candidates) =>{
    const ext = getExt(str).toUpperCase()
    return candidates.map(e=>e.toUpperCase()).indexOf(ext) > -1
}

export const validateEmail = email => {
    /* eslint-disable */
    return email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export const validatePhone = phone => {
    return phone.replace(' ', '').match(
        /^[\+]?[0-9]{0,3}?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    )
}

export const formatDateText = value => {
    /*if (value.length > 4) {
        value = value.trim().split('-').join('')
        if (value.length <= 6) {
        value = value.slice(0,4) + "-" + value.slice(4);
        } else if(value.length > 6) {
        value = value.slice(0,4) + "-" + value.slice(4,6) + "-" + value.slice(6);
        }
    }*/
    if (value.length === 5 && !isNaN(value)) {
        value = value.slice(0,4) + "-" + value.slice(4);
    }
    if (value.length === 8 && !isNaN(value.slice(0,4)) && value.charAt(4) === '-' && !isNaN(value.slice(5,8))) {
        value = value.slice(0,7) + "-" + value.slice(7);
    }
    return value;
}

export const getValidDateInputKeys = () => {
    let filter = []
    for(let i = 0; i <= 9; i++){
        filter.push(i + Key.KEY_0);
        filter.push(i + Key.NUMPAD_0);  
    }
  
    filter.push(Key.BACKSPACE);     
    filter.push(Key.TAB);     
    filter.push(Key.DELETE);    
    filter.push(Key.LEFT_ARROW);    
    filter.push(Key.RIGHT_ARROW);  
    filter.push(Key.DASH)
    filter.push(Key.SUBTRACT)
    
    return filter; 
}

export const fixArticle = txt => {
    const vocals = "aeiouAEIOU"
    return (vocals.indexOf(txt[0]) >= 0 ? 'an' : 'a') + ' ' + txt
}

export const capitalize = s => {
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const joinMulti = ss => {
    if(!ss?.length) return ''
    
    if(ss.length === 1){
      return ss[0]
    }
    return [ss.splice(0, ss.length - 1).join(', '), ss[ss.length - 1]].join(' and ')
}
