import React, { useState } from 'react'
import { ResizeDetector } from '.'

const MoreBase = (props: any) =>{
  const { minHeight = 75, expanded, content, children } = props
  const [ maxHeight, setMaxHeight ] = useState(10)
  const onResize = (width: any, height: any) => setMaxHeight(height)

  return (
    <div className='more'>
      <div className={`more-container ${maxHeight > minHeight ? 'more-container2' : ''}`}
        style={{maxHeight: !expanded ? minHeight + 'px' : 'none'}}>
        <div>
          {content}
          <ResizeDetector onResize={onResize}/>
        </div>
      </div>
      { maxHeight > (minHeight + 5) && children }
    </div>
  )
};

export default MoreBase;