import { 
  EXTEND_YEARS_REFRESH,
  EXTEND_YEAR_REFRESH,
  EXTEND_PLANS_REFRESH,
  EXTEND_LAST_SNAPSHOT,
  EXTEND_QUERY_PARAM_REFRESH,
  EXTEND_LIST_SELECT,
  EXTEND_LIST_SELECT_CLEAR,
  EXTEND_MANAGER_REFRESH,
  EXTEND_MANAGER_SYNC_REFRESH
} from 'services/action-types'
import { displayMgrName } from './util'

export const sortValues = [
  {
    label: 'Sort: Employee first name (A-Z)', 
    func: (a,b)=>
    a.firstName.localeCompare(b.firstName) 
    || displayMgrName(a).localeCompare(displayMgrName(b)) 
    || (b.primary - a.primary)
  },
  // {label: 'Sort: Employee first name (Z-A)', func: (a,b)=>b.firstName.localeCompare(a.firstName)},
  {
    label: 'Sort: Employee last name (A-Z)', 
    func: (a,b)=>
      a.lastName?.localeCompare(b.lastName)
      || displayMgrName(a).localeCompare(displayMgrName(b)) 
      || (b.primary - a.primary)
  },
  // {label: 'Sort: Employee last name (Z-A)', func: (a,b)=>b.lastName.localeCompare(a.lastName)},
  // {label: 'Sort: Manager first name (A-Z)', func: (a,b)=>a.mgrFirstName.localeCompare(b.mgrFirstName)},
  // {label: 'Sort: Manager first name (Z-A)', func: (a,b)=>b.mgrFirstName.localeCompare(a.mgrFirstName)},
  // {label: 'Sort: Manager last name (A-Z)', func: (a,b)=>a.mgrLastName.localeCompare(b.mgrLastName)},
  // {label: 'Sort: Manager last name (Z-A)', func: (a,b)=>b.mgrLastName.localeCompare(a.mgrLastName)},
]

export const initGroup = {label: 'All', code: '0'}
export const initSort = sortValues[0]
export const initQueryParam = {
  year: {}, 
  mgrId: '',
  manager: {},
  mgrName: '',
  groups: [initGroup],
  group: initGroup,
  sort: initSort
}

const initialState = {
  years: [],
  year: {},
  plans: [],
  ppYears: [],
  ppPlans: [],
  lastSnap: {
    path: '',
    indId: ''
  },
  stages: [],
  managers: [],
  queryParam: initQueryParam
}

export const extendReducer =  (state = initialState, action) => {
  switch (action.type) {
  case EXTEND_YEARS_REFRESH:
    return state = { 
      ...state, 
      years: action.value.years,
      year:  action.value.year,
    }
  case EXTEND_YEAR_REFRESH:
    return state = { 
      ...state, 
      year:  action.value,
    }    
  case EXTEND_PLANS_REFRESH:
    return state = { 
      ...state, 
      plans: action.value
    }
  case EXTEND_LAST_SNAPSHOT:
    return state = { 
      ...state, 
      lastSnap: action.value
    }
  case EXTEND_QUERY_PARAM_REFRESH:
    return state = { 
      ...state, 
      queryParam: action.value
    }
  case EXTEND_LIST_SELECT:
    return state = {
      ...state,
      plans: processSelection(state.plans, action.value)
    }
  case EXTEND_LIST_SELECT_CLEAR:
    return state = {
      ...state,
      plans: clearSelection(state.plans)
    }
  case EXTEND_MANAGER_REFRESH:
    return state = { 
      ...state, 
      managers: action.value
    }
  case EXTEND_MANAGER_SYNC_REFRESH:
    return state = { 
      ...state, 
      plans: syncPlans(state.plans, action.value)
    }
  default:
      return state
  }
}
const processSelection = (items, value) => {
  const _items = [...items]
  if(value.id === 0){
      _items.filter(e=>e.primary === 1).map(e=>e.checked=value.checked)
  }else{
      _items.filter(e=>e.primary === 1 && e.id === value.id)[0].checked = value.checked
  }
  return _items
}
const clearSelection = items => {
  const _items = [...items]
  _items.map(e=>e.checked=0)
  return _items
}
const syncPlans = (items, value) => {
  const _items = [...items]
  const index = _items.map(e=>e.id).indexOf(value.planId)
  _items[index] = {..._items[index], ...value}
  return _items
}