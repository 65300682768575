
import { clear_spinner, prompt } from 'components/common/actions'
import { handle_no_permission, handle_no_access_bps, handle_no_access_maintain } from 'components/auth/actions'
import { message2_show_refresh } from 'components/common/message2/actions'
import { reloadLanding } from 'utils/routing-util'
import { getCachedToken } from 'services/msal-config'

export const handleNoPermission = (value: any) =>{
  clear_spinner(value)
  handle_no_permission(value)
}

export const handleNoAccessBPS = (value: any) =>{
  clear_spinner(value)
  handle_no_access_bps(value)
}

export const handleLandingPage = (value: any) =>{
  getCachedToken((token: any)=>reloadLanding({
    status: value.status,
    statusText: value.statusText,
    url: value.url,
    tokenInfo: token.tokenInfo
  }))
}

export const handleNoAccessMaintain = (value: any) =>{
  clear_spinner(value)
  handle_no_access_maintain(value)
}

export const handleNoService = (value: any) =>{
  clear_spinner(value)
  message2_show_refresh(value)
  // prompt.error('The service you are attempting to access is currently unavailable. Please try again at a later time.')
}

export const handleGatewayError = (value: any) =>{
  clear_spinner(value)
  prompt.error('Unable to connect to the server: Gateway Timeout.')
}
