import React from 'react'
import { DndContext, KeyboardSensor, useSensor, useSensors } from '@dnd-kit/core'
import {restrictToWindowEdges, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import Droppable from './droppable'
import { arrayMove } from './array'
import { SortableItem } from './item'
import { CustomPointerSensor } from './sensor'
import './style.scss'

const Sortable = (props: any) => {
  const { id, items, onChange, renderItem, onDragCancel } = props

  const sensors = useSensors(
    useSensor(CustomPointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const modifiers=[restrictToVerticalAxis, restrictToWindowEdges]

  const handleDragEnd = ({ active, over }: any) => {
    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const activeIndex = active.data.current.sortable?.index;
      const overIndex = over.data.current?.sortable.index || 0;

      onChange(arrayMove(items, activeIndex, overIndex), overIndex)
    }
  }

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} modifiers={modifiers} onDragCancel={onDragCancel}>
      <Droppable id={id} items={items}>
        <div className='droppable'>
          {items.map((item: any, i: number) => <SortableItem key={item.id} id={item.id}>{renderItem(item, i)}</SortableItem>)}
        </div>
      </Droppable>
    </DndContext>
  )
}

export default Sortable
