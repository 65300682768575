import { createContext } from 'react';
import {
  PERFORMANCE_PLAN_YEARS_REFRESH,
  PERFORMANCE_PLAN_YEARS_CLEAR,
  PERFORMANCE_SEARCH_PLAN_YEARS_REFRESH,
  PERFORMANCE_PLAN_ACCESS_MODE,
  PERFORMANCE_PLAN_REFRESH,
  PERFORMANCE_PLAN_UPDATE,
  PERFORMANCE_PLAN_CLEAR,
  PERFORMANCE_PLAN_DELETED,
  PERFORMANCE_PLAN_LOADING,
  PERFORMANCE_GOALS_REFRESH,
  PERFORMANCE_GOALS_FILTER_REFRESH,
  PERFORMANCE_GOAL_REFRESH,
  PERFORMANCE_READONLY_GOAL_REFRESH,
  PERFORMANCE_GOAL_UPDATE_REFRESH,
  PERFORMANCE_GOAL_DRAFT_UPDATE_REFRESH,
  PERFORMANCE_GOAL_DRAFT_UPDATE_CLEAR,
  PERFORMANCE_GOAL_MEASURE_REFRESH,
  PERFORMANCE_GOAL_PRIORITY_REFRESH,
  PERFORMANCE_GOAL_LEARN_ACT_REFRESH,
  PERFORMANCE_GOAL_LEARN_ROLE_REFRESH,
  PERFORMANCE_GOAL_LEARN_SKILL_REFRESH,
  PERFORMANCE_GOAL_FEEDBACKS_REFRESH,
  PERFORMANCE_GOAL_FEEDBACK_REFRESH,
  PERFORMANCE_GOAL_PROFILE_REFRESH,
  PERFORMANCE_GOAL_PROFILE_LAST_UPDATE,
  PERFORMANCE_GOAL_REPLY_ADDING,
  RATING_DETAIL_CLEAN,
  PERFORMANCE_GOAL_UPDATE_TAB_TOGGLE,
  PERFORMANCE_GOAL_NEW_NOTIF_REFRESH,
  PERFORMANCE_GOAL_FB_LAST_LOADED,
  PERFORMANCE_GOAL_LAST_LOADED,
  PERFORMANCE_POP_GOAL_REFRESH,
  PERFORMANCE_POP_SHADOW_GOAL_REFRESH,
  PERFORMANCE_GOAL_STEP_REFRESH,
  PERFORMANCE_INLINE_GOAL_REFRESH,
  PERFORMANCE_GOAL_VERIFIES_REFRESH,
  PERFORMANCE_GOAL_VERIFIES_REPLACE,
  PERFORMANCE_GOAL_BANNERS_ADD,
  PERFORMANCE_GOAL_BANNERS_REMOVE,
  // PERFORMANCE_GOAL_PAGE_RESIZE,
  PERFORMANCE_GOAL_UNSAVE_CHECK_REFRESH,
  PERFORMANCE_POP_ERRORS_REFRESH,
} from 'services/action-types'
import { GoalDto, GoalUpdateDto, MeasureDto, PraPlanDto } from 'types'
import { clone, newId } from 'utils';
import { BannerMsg } from './wizard/banner/banner-contents';

const initPlan = {
  id: 0,
  acks: [],
  annual: {},
  goals: [],
  positions: [],
  managerId: -1,
  manager: {},
  ministry: {},
  status: {},
  type: {},
  assMaps: [],
  assignmentId: 0,
  ind: {},
  profileId: 0,
  planRatingList: [],
  deleted: false,
}

const initGoal = {
  priorities: [],
  updates: <any>[],
  measures: [],
  activities: [],
  feedbacks: [],
  learningSkills: [],
  relateRoles: [],
  loadedAt: 0,
  fbLoadedAt: 0,
}
export class accessModeType {
  owner: boolean = false;
  manager: boolean = false;
  corp: boolean = false;
  min: boolean = false;
  hra: boolean = false;
  adminRating: boolean = false;
  ppra: boolean = false;
  ready: boolean = false;
}

export class GoalContextType{
  goal: GoalDto = new GoalDto();
  originalGoal: GoalDto = new GoalDto();
  accessMode: accessModeType = new accessModeType();
  // for Improvement Goal
  plan?: PraPlanDto;
  setPlan?: (plan: PraPlanDto | null) => void;
}

export const GoalContext = createContext<GoalContextType>(new GoalContextType())

export const initNewGoal = {
  name:'',
  type: {},
  priorities: [],
  measures: [new MeasureDto(), new MeasureDto()],
  draftUpdate: new GoalUpdateDto(),
  activities: [],
  relateRoles: [],
  learningSkills: [],
  updates: [new GoalUpdateDto()],
  newPriorities: []
}
export const initFilterType = {id: 0, label: 'All'}
export const allFilterStatus = {id: 0, label: 'All'}
const initFilterStatus = [allFilterStatus]
export const initFilters = { type: [initFilterType], status: initFilterStatus, endorse: false, sorting: {createAt: true, alphbeta: false} }
export const goalTypeDeleted = {id: '-1', code: 'DELETED', label: 'Deleted'}
const initialState = {
  years: [],
  yearsInd: 0,
  searchYears: [],
  plan: initPlan,
  goals: [],
  goal: initGoal,
  originalGoal: initNewGoal,

  popGoal: initNewGoal,
  popShadowGoal: initNewGoal,

  about: [],
  profile: {
    skills:[],
    nextRole:[],
    ver: 0,
    lastUpdate: {},
    person: {}
  },
  accessMode: {
    owner: false,
    manager: false,
    corp: false,
    min: false,
    hra: false,
    ready: false,
  },
  updateAdding: <any>{show: false, type: {}},
  filter: initFilters,
  readonlyGoal: {},
  activeTab: 0,
  fbLoadedAt: 0,
  planLoading: false,

  goalStep: -1,
  verifies: {},
  banners: <any>[],
  unsaveCheck: false,
  popErrors: [],
  replyAdding: {},
}

export const performanceReducer =  (state = initialState, action: any) => {
  switch (action.type) {
  case PERFORMANCE_PLAN_YEARS_REFRESH:
    return state = {
      ...state,
      years: action.value.years,
      yearsInd: action.value.yearsInd
    }
  case PERFORMANCE_PLAN_YEARS_CLEAR:
    return state = {
      ...state,
      years: [],
      yearsInd: 0
    }
  case PERFORMANCE_SEARCH_PLAN_YEARS_REFRESH:
    return state = {
      ...state,
      searchYears: action.value
    }
  case PERFORMANCE_PLAN_ACCESS_MODE:
    return state = {
      ...state,
      accessMode: action.value
    }
  case PERFORMANCE_PLAN_REFRESH:
    return state = {
      ...state,
      plan: action.value,
      // plan: {...action.value, status: {...action.value.status, code: 'YEAR_CLOSE', label: 'Performance year closed'}},
      goals: action.value.goals,
      goal: initGoal,
    }
  case PERFORMANCE_PLAN_UPDATE:
    return state = {
      ...state,
      plan: action.value
    }
  case PERFORMANCE_PLAN_CLEAR:
    return state = {
      ...state,
      goal: initGoal,
      goals: [],
      about: []
    }
  case PERFORMANCE_PLAN_DELETED:
    return state = {
      ...state,
      plan: {...initPlan, deleted: true},
    }
  case PERFORMANCE_GOALS_REFRESH:
    return state = {
      ...state,
      goals: action.value,
    }
  case PERFORMANCE_GOALS_FILTER_REFRESH:
    return state = {
      ...state,
      filter: action.value,
      goal: initGoal
    }
  case PERFORMANCE_GOAL_REFRESH:
    return state = {
      ...state,
      ...replaceGoal(state, [...state.goals], action.value),
      // ...replaceGoal(state.goals, action.value),
      fbLoadedAt: 0
    }
  case PERFORMANCE_READONLY_GOAL_REFRESH:
    return state = {
      ...state,
      readonlyGoal: action.value
    }
  case PERFORMANCE_GOAL_UPDATE_REFRESH:
    return state = {
      ...state,
      goal: {...state.goal, updates: action.value},
      originalGoal: {...state.originalGoal, updates: clone(action.value)}
    }
  case PERFORMANCE_GOAL_DRAFT_UPDATE_REFRESH:
    return state = {
      ...state,
      goal: {...state.goal, updates: [...excludeDraftUpdate(state), action.value]},
    }
  case PERFORMANCE_GOAL_DRAFT_UPDATE_CLEAR:
    return state = {
      ...state,
      goal: {...state.goal, updates: excludeDraftUpdate(state)},
    }
  case PERFORMANCE_GOAL_MEASURE_REFRESH:
    return state = {
      ...state,
      goal: {...state.goal, measures: action.value},
      originalGoal: {...state.originalGoal, measures: clone(action.value)}
    }
  case PERFORMANCE_GOAL_PRIORITY_REFRESH:
    return state = {
      ...state,
      goal: {...state.goal, priorities: action.value}
    }
  case PERFORMANCE_GOAL_LEARN_ACT_REFRESH:
    return state = {
      ...state,
      goal: {...state.goal, activities: action.value}
    }
  case PERFORMANCE_GOAL_LEARN_SKILL_REFRESH:
    return state = {
      ...state,
      goal: {...state.goal, learningSkills: action.value}
    }
  case PERFORMANCE_GOAL_LEARN_ROLE_REFRESH:
    return state = {
      ...state,
      goal: {...state.goal, relateRoles: action.value}
    }
  case PERFORMANCE_GOAL_FEEDBACKS_REFRESH:
    return state = {
      ...state,
      goal: {...state.goal, feedbacks: action.value},
    }
  case PERFORMANCE_GOAL_FEEDBACK_REFRESH:
    const fbs: any = [...state.goal.feedbacks]
    const index_fbs = fbs.map((f: any)=>f.id).indexOf(action.value.id)
    fbs[index_fbs] = action.value
    return state = {
      ...state,
      goal: {...state.goal, feedbacks: fbs}
    }
  case PERFORMANCE_GOAL_PROFILE_REFRESH:
    return state = {
      ...state,
      profile: {
        ...state.profile,
        skills: action.value.skills,
        nextRole: action.value.nextRole,
        ver: action.value.ver,
        lastUpdate: action.value.lastUpdate,
        person: action.value.person,
      }
    }
  case PERFORMANCE_GOAL_PROFILE_LAST_UPDATE:
    return state = {
      ...state,
      profile: {
        ...state.profile,
        lastUpdate: action.value
      }
    }
  // case PERFORMANCE_GOAL_REPLY_ADDING:
  //   return state = {
  //     ...state,
  //     ...replaceUpdates(state, action.value)
  //   }
  case RATING_DETAIL_CLEAN:
    return state = {
      ...state,
      plan: {...state.plan, planRatingList: []},
    }
  case PERFORMANCE_GOAL_UPDATE_TAB_TOGGLE:
    return state = {
      ...state,
      activeTab: action.value,
    }
  case PERFORMANCE_GOAL_NEW_NOTIF_REFRESH:
    const _goals2: any = [...state.goals]
    const _gIndex = _goals2.map((g: any)=>g.id).indexOf(action.value.id)
    if(_gIndex >=0 ){
      _goals2[_gIndex] = action.value
    }
    return state = {
      ...state,
      goals: _goals2
    }
  case PERFORMANCE_GOAL_FB_LAST_LOADED:
    return state = {
      ...state,
      fbLoadedAt: action.value
    }
  case PERFORMANCE_GOAL_LAST_LOADED:
    return state = {
      ...state,
      goal: {
        ...state.goal,
        loadedAt: action.value,
        fbLoadedAt: state.fbLoadedAt > 0 ? state.fbLoadedAt: state.goal.fbLoadedAt
      }
    }
  case PERFORMANCE_PLAN_LOADING:
    return state = {
      ...state,
      planLoading: action.value
    }
  case PERFORMANCE_POP_GOAL_REFRESH:
    return state = {
      ...state,
      popGoal: action.value,
      unsaveCheck: false,
    }
  case PERFORMANCE_POP_SHADOW_GOAL_REFRESH:
    return state = {
      ...state,
      popShadowGoal: {...state.popShadowGoal, ...clone(action.value)}
    }
  case PERFORMANCE_INLINE_GOAL_REFRESH:
    return state = {
      ...state,
      goal: action.value
    }
  case PERFORMANCE_GOAL_STEP_REFRESH:
    return state = {
      ...state,
      goalStep: action.value ? state.goalStep + action.value : action.value,
      popErrors: []
    }
  case PERFORMANCE_GOAL_VERIFIES_REFRESH:
    return state = {
      ...state,
      verifies: {...state.verifies,  ...action.value}
    }
  case PERFORMANCE_GOAL_VERIFIES_REPLACE:
    return state = {
      ...state,
      verifies: action.value
    }
  case PERFORMANCE_GOAL_BANNERS_ADD:
    return state = {
      ...state,
      ...(state.banners.indexOf(action.value) >= 0 ? {} :
      {
        banners: [...state.banners.filter((b:any)=>b.code !== action.value), {code: action.value, id: newId()}],
        // resize: state.resize + 1
      }),
    }
  case PERFORMANCE_GOAL_BANNERS_REMOVE:
    return state = {
      ...state,
      banners: [...state.banners].filter((b:any)=>b.code !== action.value),
      // resize: state.resize + 1
    }
  // case PERFORMANCE_GOAL_PAGE_RESIZE:
  //   return state = {
  //     ...state,
  //     resize: state.resize + 1
  //   }
  case PERFORMANCE_GOAL_UNSAVE_CHECK_REFRESH:
    return state = {
      ...state,
      unsaveCheck: action.value,
      popErrors: action.value ? [] : state.popErrors
    }
  case PERFORMANCE_POP_ERRORS_REFRESH:
    return state = {
      ...state,
      popErrors: action.value,
      unsaveCheck: false,
      banners: state.banners.filter((b:any)=>b.code === BannerMsg.typeInfo)
    }
  case PERFORMANCE_GOAL_REPLY_ADDING:
    return state = {
      ...state,
      replyAdding: action.value
    }
  default:
    return state
  }
}

const getTypeCode = (state: any) => state.accessMode.manager ? 'COMMENT' : 'UPDATE'

const excludeDraftUpdate = (state: any) => {
  return state.goal.updates.filter((e: any) => !(e.status?.code === 'DRAFT' && e.type?.code === getTypeCode(state)))
}

const replaceGoal = (state: any, _goals: any, _goal: any) => {
    const _index = _goals.map((g: any)=>g.id).indexOf(_goal.id)
    if(_index > -1 ){
      _goals[_index] = _goal
    }
    return {
      goals: _goals,
      goal: _goal,
      originalGoal: clone(_goal)
    }
}

// const replaceUpdates = (state: any, value: any) => {
//   const _goal = {...state.goal}
//   const _updates = [..._goal.updates]
//   const _index = _updates.map(u=>u.id).indexOf(value.update.id)
//   _updates[_index].replyAdding = value.replyAdding
//   return {
//     goal: _goal
//   }
// }
