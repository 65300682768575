import { PATH_NOTIFY_REFRESH } from 'services/action-types'

const initialState = {
  path: {
	  key: '',
	  value: ''
  }
}

export const observe_reducer= (state = initialState, action: any) => {
  switch (action.type) {
	case PATH_NOTIFY_REFRESH:
		return { 
			...state, 
			path: action.value
		}
  	default:
    	return state
  }
}