import { parseJwt } from 'services/msal-config'
import { PATH_ID_SHARE_PROFILE, PATH_ID_FEEDBACK, PATH_ID_PROFILE, PATH_ID_PERFORMANCE, PATH_ID_TEAM_DIRECT, PATH_ID_NOTIFY, 
  ON_BEHALF_PROFILE, ON_BEHALF_PERFORMANCE, ON_BEHALF_TALENT, ON_BEHALF_GOALLIB, ON_BEHALF_EXTEND
} from 'services/constants'
import { tryParseJSON, stringifyJSON } from 'utils'
import { AccessSource } from 'types'

const redirectUrls = [PATH_ID_SHARE_PROFILE, PATH_ID_FEEDBACK, PATH_ID_PROFILE, PATH_ID_PERFORMANCE, PATH_ID_TEAM_DIRECT, PATH_ID_NOTIFY]
const redirectKey = 'redirectTo'
export const storeRedirect = ()=>{
  const r = window.location.hash
  redirectUrls.map(u=>r && r.indexOf(u) > -1 && localStorage.setItem(redirectKey, r))
}

export const restoreRedirect = ()=>{
  const redirectTo = localStorage.getItem(redirectKey)
  if(redirectTo){
    localStorage.removeItem(redirectKey)
    if (redirectTo.indexOf(PATH_ID_NOTIFY) === -1) {
      window.location.hash = redirectTo
    } else {
      return redirectTo
    }
  } 
}
export const cleanLocalStorage = () => {
  const keeps = [redirectKey, tmsStartPage, msalConfig]
  const keys = Object.keys(localStorage).filter(k=>keeps.indexOf(k) < 0)
  keys.map(k=>localStorage.removeItem(k))
}

export const reloadLanding = (value: any) => {
  cleanLocalStorage()
  setMsalError(value)
  reloadApplication()
}

const actionAsKey = 'actionAsKey'
export const setActionAsProd = (value: any) => localStorage.setItem(actionAsKey, value)
export const isActionAsProd = () => localStorage.getItem(actionAsKey)

const bpsTenant = 'bpsTenant'
const getBpsTenant = () => tryParseJSON(localStorage.getItem(bpsTenant))
export const setBpsTenant = (tenant: any) => localStorage.setItem(bpsTenant, stringifyJSON(tenant))
export const removeBpsTenant = () => localStorage.removeItem(bpsTenant)

const msalLoginStatus = 'msalLoginStatus'
export const getMsalLoginStatus = () => localStorage.getItem(msalLoginStatus)
export const setMsalLoginStatus = (status: any) => localStorage.setItem(msalLoginStatus, status)
export const removeMsalLoginStatus = () => localStorage.removeItem(msalLoginStatus)

export const skipUrls = () => {
  const skips = ['#code=', '#error=', '#id_token=']
  return skips.every(e=>window.location.href.indexOf(e) < 0)
}

const getNewLocation = () => {
  return window.location.href
    .replace('?selectOrg=1', '')
    .replace('?selectorg=1', '')
    .replace('?prompt=login', '')
    .replace('&prompt=login', '')
}

export const continueLanding = () =>{
  setOriginTmsStartPage()
  setMsalLoginStatus(1)
  const newLoc = getNewLocation()
  if(window.location.href === newLoc){
    window.location.reload()
  }else{
    window.location.replace(newLoc)
  } 
}

const originTmsStartPage = 'originTmsStartPage'
export const getOriginTmsStartPage = () => localStorage.getItem(originTmsStartPage)
export const setOriginTmsStartPage = () => localStorage.setItem(originTmsStartPage, window.location.href)
export const removeOriginTmsStartPage = () => localStorage.removeItem(originTmsStartPage)

const tmsStartPage = 'tmsStartPage'
export const getTmsStartPage = () => localStorage.getItem(tmsStartPage)
export const setTmsStartPage = () => {
  if(skipUrls()){
    localStorage.setItem(tmsStartPage, getNewLocation())
  }
}
export const removeTmsStartPage = () => localStorage.removeItem(tmsStartPage)

export const getBpsTenantAuth = () => {
  if(noneBps()){
    return false
  }else{
    return getBpsTenant()
  }
}

export const reloadApplication = () => {
  window.location.href = `${getTmsStartPage()}`
  window.location.reload()
}

const tmsMsalError = 'tmsMsalError'
export const getMsalError = () => tryParseJSON(localStorage.getItem(tmsMsalError))
export const setMsalError = (error: any) => localStorage.setItem(tmsMsalError, stringifyJSON(error))
export const removeMsalError = () => localStorage.removeItem(tmsMsalError)

const msalConfig = 'tmsAdalConfig'
export const getAdalConfig = () => tryParseJSON(localStorage.getItem(msalConfig))
export const setAdalConfig = (config: any) => localStorage.setItem(msalConfig, stringifyJSON(config))
const removeAdalConfig = () => localStorage.removeItem(msalConfig)

const publicValuelist = 'publicValuelist'
export const getPublicValuelist = () => tryParseJSON(localStorage.getItem(publicValuelist))
export const setPublicValuelist = (vl: any) => localStorage.setItem(publicValuelist, stringifyJSON(vl))
const removePublicValuelist = () => localStorage.removeItem(publicValuelist)

// const noneBps = () => {
//   // if(msalConfig.actionAs === 2){
//   console.log(msalConfig)
//   if(msalConfig.actionAs !== 2){
//     return true
//   }
//   return false
// }

const noneBps = () => {
  const bps_key = 'bps'
  // const bps_key = 'localhost'
  if(window.location.origin.toLocaleLowerCase().indexOf(bps_key) < 0){
    return true
  }
  return false
}

export const isBpsLanding = () => {
  if(noneBps()){
    return false
  }
  
  if(window.location.href.toLocaleLowerCase().indexOf('?selectorg=1') > -1){ // force to menu
    return true
  }

  if(isValidToken()){ // has valid id token
    return false
  }

  const status = getMsalLoginStatus()

  if(status ==='2' && skipUrls()){
    return true
  }

  if(status !== '1' && status !== '2' && status !== '3'){  // minddle or ready
    return true
  }
  
  return false
}

export const isLoginPrompt = () => {
  // if((getOriginTmsStartPage() || '').toLocaleLowerCase().indexOf('prompt=login') > -1){ // force to login
  //   return true
  // }else if(window.location.href.toLocaleLowerCase().indexOf('prompt=login') > -1){ // force to login
  //   return true
  // }
  return false
}

export const isBpsLandingWait = () => {
  return false//getMsalLoginStatus() ==='2' && !skipUrls()
}

const tmsUser = 'TMSUser'
export const getTMSUser = () => localStorage.getItem(tmsUser)
export const setTMSUser = (user: any) => localStorage.setItem(tmsUser, user)
const removeTMSUser = () => localStorage.removeItem(tmsUser)

export const cleanSettings = () => {
  removeBpsTenant()
  removeTMSUser()
  clearOnBehalf()
  removeAdalConfig()
  removePublicValuelist()
  removeMsalLoginStatus()
  removeTmsStartPage()
  removeOriginTmsStartPage()
  removeMsalError()
}

const tryCount = 10
const tryCountKey = 'tryCountKey'
export const limitedLogin = (callback=()=>{}, altCallback=()=>{}) =>{
  const count = parseInt(localStorage.getItem(tryCountKey) || '0')
  if(count < tryCount){
    localStorage.setItem(tryCountKey, `${count + 1}`)
    callback()
  }else{
    localStorage.removeItem(tryCountKey)
    altCallback()
  }
}

export const clearOnBehalf = () => {
  sessionStorage.removeItem(ON_BEHALF_PROFILE)
  sessionStorage.removeItem(ON_BEHALF_PERFORMANCE)
  sessionStorage.removeItem(ON_BEHALF_TALENT)
  sessionStorage.removeItem(ON_BEHALF_GOALLIB)
  sessionStorage.removeItem(ON_BEHALF_EXTEND)
}

export const setOnBehalf = (key: any, value: any) => value ? sessionStorage.setItem(key, JSON.stringify(value)) : sessionStorage.removeItem(key)

export const attachOnBehalf = (url: string) => {
  const accessMode = getSaveAccessMode()
  let _url = url
  if(!accessMode){
    return _url
  }
  const onBehalf = 
    (accessMode.min || accessMode.hra) ? 2 
    : 
    accessMode.corp ? 1 
    : 
    accessMode.strat ? 4
    : 
    accessMode.ppra ? 5
    : 
    0

  if(onBehalf){
    _url = _url.indexOf('?') > -1 ? (_url + '&Onbehalf='+onBehalf) : (_url + '?Onbehalf='+onBehalf)
  }

  const indId = accessMode.indId
  if(indId){
    _url = _url.indexOf('?') > -1 ? (_url + '&actAsIndId='+indId) : (_url + '?actAsIndId='+indId)
  }

  return _url
}

export const getSaveAccessMode = () => {
  const parse = (p: any) => JSON.parse(p) 
  return parse(sessionStorage.getItem(ON_BEHALF_PROFILE)) 
  || parse(sessionStorage.getItem(ON_BEHALF_PERFORMANCE))
  || parse(sessionStorage.getItem(ON_BEHALF_TALENT))
  || parse(sessionStorage.getItem(ON_BEHALF_GOALLIB))
  || parse(sessionStorage.getItem(ON_BEHALF_EXTEND))
}


/* eslint-disable no-useless-escape */
export const getUrlParameter = (url: string, name: string) => {
  const _name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + _name + '=([^&#]*)', 'i')
  const  results = regex.exec(url)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const isValidToken = () => {
  const url = window.location.href
  const tokens = url.match(/\#(?:id_token)\=([\S\s]*?)\&/)
  const pToken = tokens && parseJwt(tokens[1])
  return pToken?.tid
}

export const attachAccessSource = (url: string) => {
  const accessMode = getSaveAccessMode()
  let _url = url
  if(!accessMode){
    return _url
  }

  const accessSource = 
    accessMode.owner ? AccessSource.SELF
    :
    (accessMode.min || accessMode.hra) ? AccessSource.MIN_ADMIN
    : 
    accessMode.corp ? AccessSource.CORP_ADMIN
    :
    accessMode.extManager ? AccessSource.EXT_MANAGER
    :
    accessMode.manager ? AccessSource.MANAGER
    : 
    0

  // SELF = 1,
  // MANAGER = 2,
  // EXT_MANAGER = 3,
  // CLIENT = 4,
  // MIN_ADMIN = 5,
  // CORP_ADMIN = 6
  if(accessSource){
    _url =_url + `${_url.indexOf('?') > -1 ? '&' : '?'}` + 'accessSource=' + accessSource
  }

  return _url
}
