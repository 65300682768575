import React from 'react'
import { YellowWarning } from 'utils'

interface propsType{
  describedBy: string; 
  labelledBy: string; 
  onClose: any;
}
const SomeoneElse = (props: propsType) => {
  const { describedBy, labelledBy, onClose=()=>{} } = props

  return (<div className='simple-row' style={{padding: '0 20px'}}>
    <YellowWarning>
      <div tabIndex={0} id={labelledBy} className='simple-row strong big'>Someone else has made changes</div>
      <div id={describedBy} className='simple-row' style={{padding: '10px 0 0 0'}}>
        Please refresh the page and try again.
      </div>
    </YellowWarning>
    <div className='simple-row' style={{padding: '30px 0 0 0'}}>
      <button className='tms-button primary' onClick={onClose}>Close</button>
    </div>
  </div>)
}
export default SomeoneElse
