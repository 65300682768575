import dayjs, { ManipulateType } from 'dayjs'
import { isIE } from './browser-util'
import { loggerInfo } from 'utils'

export const toDate = (dt: any, format: any='MMM D, YYYY') => {
    if(!!!dt){
        return ''
    }
    let ret = removeTime(dt);
    return formatDate(ret, format || 'MMM D, YYYY');
}

export const toShortDate = (dt: any) =>{
    return (formatDate(dt, 'MMM D, YYYY')||'').replace(`, ${dayjs().format('YYYY')}`, '')
}

export const toDateTime = (dt: any) =>formatDate(dt, 'MMM D, YYYY h:mm A')

export const toShortDateTime = (dt: any, yearFormat: any, dayFormat: any, hourFormat: any) =>{
    const _dt = new Date(dt)
    const today = new Date()
    let _format = ''
    if(_dt.getFullYear() !== today.getFullYear()){
        _format = yearFormat || 'DD/MMM/YY'
    }else if(_dt.getMonth() === today.getMonth() && _dt.getDate() === today.getDate()){
        _format = hourFormat || 'h:mm A'
    }else{
        _format = dayFormat || 'DD MMM'
    }
    return formatDate(dt, _format)
}
export const toLongDateTime = (dt: any) => toShortDateTime(dt, 'MMM DD, YYYY', 'MMM DD, YYYY', 'MMM DD, YYYY h:mm A')
export const toTime = (dt: any) =>formatDate(dt, 'h:mm A')

export const toDateTimeWithSeconds = (dt: any) => formatDate(dt, 'MMM D, YYYY h:mm:ss A')

export const formatDate = (dt: any, format: string) =>dt && dayjs1(dt, format)

export const isValidDate = (dt: any) => dt instanceof Date && !isNaN(dt.getTime())

export const isValidDateStr = (ds: string) => ds && ds.match(/^\d{4}-|\/\d{2}-|\/\d{2}$/g) && dayjs(ds, 'YYYY-MM-DD').format('YYYY-MM-DD') === ds

export const toMonth = (dt: any) => new Date(dt.getFullYear(), dt.getMonth())

export const toLocalDateTime = (dt: any, format: any) => {
    const _dt =  new Date(dt)
    return toDate(_dt.getTime() + _dt.getTimezoneOffset()  * 60000, format)
}
// export const toLongTime = dt => {
//     const _dt =  new Date(dt)
//     return new Date(_dt.getTime() + _dt.getTimezoneOffset() * 60000).getTime()
// }

// convert local date to UTC
export const toUtcDateTime = (local: any) => {
    const dt = new Date(local)
    const timeZoneOffset = dt.getTimezoneOffset() * 60 * 1000
    dt.setTime(dt.getTime() - timeZoneOffset) // local = UTC + offset
    return dt
}

export const atEndOfDay = (dt: any) => {
    const _dt =  new Date(dt)
    _dt.setHours(23, 59, 59, 999)
    return _dt
}

// export const toLocalDateTime = (dt: any) => dt
export const toLongTime = (dt: any) => new Date(dt).getTime()

export const toLocalDate = (dt: any) => {
    if (!dt) return null
    const _dt =  new Date(dt)
    return new Date(_dt.getTime() + _dt.getTimezoneOffset() * 60000)
}

const dayjs1 = (dt: any, format: any) =>{
    let ret = parseDate(dt)
    try{
        let dt1 = dayjs(removeNanoSeconds(dt));
        ret = dt1.format(format)
    }catch(e){
        loggerInfo(e)
    }
    
    let result = (ret === 'Invalid Date') ? dt : ret;
    return removeNanoSeconds(result);
}

const parseDate = (dt: any) =>{
    dt = removeNanoSeconds(dt);
    if(!isIE() || dt instanceof Date || !dt.split) return dt
    
    const date=dt.split('-')
    if(date.length > 1 && date[1].length > 2){
        return `${date[1]} ${date[0]}, ${date[2].length===2 && '20'}${date[2]}`
    }else{
        return dt
    }
}

const removeTime = (dt: any) => {
    // if (dt && dt instanceof Date) {
    //     dt.setHours(null);
    //     dt.setMinutes(null);
    //     dt.setSeconds(null);
    //     dt.setMilliseconds(null);
    // }else if(dt){
    //     dt = dt.replace('00:00:00', '').trim();
    // }

    return dt;
}

const removeNanoSeconds = (dt: any) => {
    // if (dt && dt instanceof Date) {
    //     dt.setMilliseconds(null);
    // }else if(dt){
    //     dt = dt.replace('.000000', '').trim();
    // }

    return dt;
}

const buildDate = (dt: any) =>{
    if(!isIE() || dt instanceof Date) return dt
    const date=dt.split(/[ ,]+/)
    if(date.length === 2){
        const dt1 = date[0] + " 1," + date[1]
        return new Date(dt1)
    }else{
        return new Date(dt)
    }
}

export const sortDatesDescending = (dates: any) => dates.sort((a: any, b: any) => buildDate(b.period) - buildDate(a.period))

export const dateDiff = (from: any, to: any, pure=false) => {
    const months = dayjs(to).startOf('M').diff(dayjs(from).startOf('M'), 'month')
    const y = Math.floor(months/12)
    const m = months - y * 12
    let ret = ''
    
    if(y === 0 && m === 0){
        ret = '1 month'
    }else if(y === 0 && m === 1){
        ret = `1 month`
    }else if(y === 0 && m > 1){
        ret = `${m} months`
    }else if(y === 1 && m === 0){
        ret = '1 year'
    }else if(y === 1 && m === 1){
        ret = '1 year and 1 month'
    }else if(y === 1 && m > 1){
        ret = `1 year and ${m} months`
    }else if(y > 1 && m === 0){
        ret = `${y} years`
    }else if(y > 1 && m === 1){
        ret = `${y} years and 1 month`
    }else if(y > 1 && m > 1){
        ret = `${y} years and ${m} months`
    }
    return pure ? ret : `(${ret})`
    // var months;
    // months = (from.getFullYear() - from.getFullYear()) * 12;
    // months -= from.getMonth() + 1;
    // months += to.getMonth();
    // return months <= 0 ? 0 : months;
}
export const daysDiff = (d: any) => {
    return Math.floor((new Date().getTime() - d)/(1000 * 3600 * 24))
}
export const dateCompareYM = (a: any, b: any) =>{
    return toDate(a, 'YYYYMM') >= toDate(b, 'YYYYMM') 
}

export const dateCompareYMD = (a: any, b: any) =>{
    return toDate(a, 'YYYYMMDD') >= toDate(b, 'YYYYMMDD') 
}

export const dateLaterThan = (a: any, b: any) => {
    return formatDate(createDate(a), 'YYYY-MM-DD') > formatDate(createDate(b), 'YYYY-MM-DD')
}

export const createDate = (value?: any) =>{
    if(!value){
        return new Date()
    }
    if(value instanceof Date){
        return value
    }
    if (typeof value === 'number' ) {
        return new Date(value)
    }
    return new Date(value.split('-').join('/'))
}

export const now = () => new Date().getTime()

export const minutesDiff = (a: Date, b: Date) => {
  const diff =(a.getTime() - b.getTime()) / 1000/60
  return Math.ceil(diff)
 }

 export const dateAdd = (date: Date, diff: number, type: ManipulateType) => dayjs(date).add(diff, type)