import { AUTO_RESIZE_MODAL_REFRESH } from 'services/action-types'

const initialState = {
	resize: 0
}

export const auto_resize_modal_reducer= (state = initialState, action: any) => {
  switch (action.type) {
	case AUTO_RESIZE_MODAL_REFRESH:
		return { 
			...state, 
      resize: state.resize + 1
		}
  default:
    return state
  }
}