import { CustomDetailJson, UserBase } from 'types'

class ValueListSnapBase{
	id!: number;
	type!: string;
	code!: string;
	label!: string;
	value!: string;
	displayOrder!: number;
	other!: CustomDetailJson;
}
class PraPlanPIPExemptionJson{
	exemption: number = 0;
	reasons: ValueListSnapBase[] = [];
	at!: number;
	by!: number;
	onBehalf!: number; //1:'Corp Admin'; 2:'Min Admin';
	
	nameApprover!: string;     //free type
	indIdApprover!: number;   //Approver Forte ind_id
}
export class PPlanPIPExemptionDetail extends PraPlanPIPExemptionJson{
	actBy!: UserBase;
	customReason!: string;
	errors: number[] = [];							//UI Only
}
export class PraPlanRatingListDto{
	planId!: number;
	requirePIPStatus!: number;
	exemptionPIP!: number;
	history!: number;
	assHistory!: number;
	planType!: ValueListSnapBase;
	exemptionPIPDetail!: PPlanPIPExemptionDetail;
	exemptionPIPDetailUI!: PPlanPIPExemptionDetail;	//UI Only
}