import { SPINNER_SHOW, SPINNER_HIDE, SPINNER_HIDE_CLEAR, SPINNER_INPROGRESS } from 'services/action-types'

const initialState = {
	inprogressCount: 0,
	spinnerCount: 0,
	isInprogress: false
}

export const spinner_reducer= (state = initialState, action: any) => {
  switch (action.type) {
	case SPINNER_INPROGRESS:
		return { 
			...state, 
			isInprogress: true
		}
 	case SPINNER_SHOW:
    return { 
			...state, 
			spinnerCount: state.spinnerCount + (action.value ? 1 : 0),
			inprogressCount: state.inprogressCount + 1,
			isInprogress: state.inprogressCount + 1 > 0
		}
 	case SPINNER_HIDE:
		return { 
			...state, 
			spinnerCount: state.spinnerCount - (action.value ? 1 : 0),
			inprogressCount: state.inprogressCount - 1,
			isInprogress: state.inprogressCount - 1 > 0
		}
	case SPINNER_HIDE_CLEAR:
		return {
			...state, 
			...initialState
		}
  default:
    return state
  }
}