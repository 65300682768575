import { loggerInfo } from 'utils'

export const getSuccessHandler = (value: any) => {
    const method = value.method?.toUpperCase()
    const type = value.type?.toUpperCase()
    if(method === 'DOWNLOAD'){
        return downloadHandler
    }else if(method === 'GET' && type === 'GRAGH'){
        return fileHandler
    }else if((method === 'GET' || method === 'POST') && type === 'SAS'){
        return downloadHandler
    }else{
        return defaultHandler
    }
}

const defaultHandler = (response: any, resolve: any, reject: any) => response.json().then(resolve).catch((data: any)=>{
    loggerInfo(response)
    reject(data)
})

const fileHandler = (response: any, resolve: any, reject: any)=>response.blob().then((data: any)=>{
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend =()=>resolve(reader.result)
}).catch(reject)

const downloadHandler =  (response: any, resolve: any, reject: any)=>{
    const filename = response.headers.get('content-disposition')
    .split(';')
    .find((n: any) => n.indexOf('filename=')>=0)
    .replace('filename=', '')
    .trim()
    response.blob().then((data: any)=>resolve({data, filename})).catch(reject) 
}