import { msalConfig, getToken } from './msal-config'

export const msalApiFetch = (f: any, url: string, options: any) => msalConfig.actionAs ? 
  getToken(resp => f(url, setAuth(options, resp)))
  :
  f(url, options)

const setAuth = (options: any, resp: any) => {
  const at = options['type'] === 'GRAGH' ? resp.accessToken : resp.idToken
  options.headers.Authorization = `Bearer ${at}`
  return options
}