import React, { useEffect, useRef } from 'react'
import { Div } from '../elements'
import { loggerInfo } from 'utils'

const DefaultExport = props => {
  loggerInfo('ListItem.render')
  const { children, onSelect, /*selected,*/  actived, onNavigate=()=>{}, ...rest } = props
  const itemRef = useRef({})

  useEffect(() => { actived && itemRef.current.focus() }, [actived])
  // useEffect(() => { selected && itemRef.current.focus() }, [selected])
  return <div className='list-item'>
    <Div ref={itemRef} className='item item-with-checkbox' onClick={onSelect} onKeyDown={onNavigate} {...rest}>
      {children()}
    </Div>
  </div>
};

export default DefaultExport;