import { createSelector } from 'reselect'
import { VALUE_TYPE_NEXTROLE_STATUS, NEXTROLE_STATUS_NO, NEXTROLE_STATUS_EXCLUDE } from 'services/constants'
import { getNextRoleStatusValues } from 'utils'
import { buildSkillTree } from './util'

export const getProfile = createSelector(
   state => state.ui.profile,
   data => ({
      ...data, 
      langCertVisit: !data.editMode ? 
      (data.langCerts.filter(e=>e.status===1).length > 0 ? [1]:[])
      :
      (data.langCertRemoved.length + data.langCerts.length > 0 ? [1]:[])
   })
)
export const getProfileUsersAll = createSelector(
   state => state.ui.profile.usersAll, 
   data => data
)
export const getProfileEditMode = createSelector(
   state => state.ui.profile.accessMode,
   data => !data.manager && !data.min && !data.shared
)
export const getProfileEditModeField = createSelector(
   state => state.ui.profile.editMode,
   data => data
)
export const getProfileAccessMode = createSelector(
   state => state.ui.profile.accessMode, 
   data => data
)
export const getProfileVer = createSelector(
   state => state.ui.profile.ver,
   data => data
)

export const getProfileId = createSelector(
   state => state.ui.profile.id,
   data => data
)

export const getProfileLastUpdate = createSelector(
   state => state.ui.profile.lastUpdate,
   data => data
)

export const getSuccessionRoles = createSelector(
   state => state.ui.profile.successionRole, 
   // data => data
   data => data.filter(r=>r.acceptType?.code === 'CONFIRM' || r.acceptType?.code === 'PEND')// || r.acceptType?.code === 'DECLN')
         .sort((a,b)=>(b.addBackAt - a.addBackAt) || (b.createAt - a.createAt))
)
export const getSuccessorCollapse = createSelector(
   state => state,
   data => true
)
export const getProfileHonors = createSelector(
   state => state.ui.profile.honors,
   data => [...data.filter(e=>e.issueDate).sort((a,b)=>b.issueDate - a.issueDate), ...data.filter(e=>!e.issueDate)]
)
export const getProfileLanguages = createSelector(
   state => state.ui.profile.languages, 
   data => data.sort((a,b)=>a.languagee.label.localeCompare(b.languagee.label))
)
export const getProfileLangCerts = createSelector(
   state => state.ui.profile.langCerts, 
   data => data.sort((a, b)=>a.langCert.displayOrder - b.langCert.displayOrder)
)
export const getProfileLangCertRemoved = createSelector(
   state => state.ui.profile.langCertRemoved, 
   data => data
)
export const getProfileLangCertVisit = createSelector(
   state => getProfile(state), 
   data => data.langCertVisit
)
export const getProfileCertificates = createSelector(
   state => state.ui.profile.certificates, 
   // data => data.sort((a,b)=>b.issueDate - a.issueDate)
   // data => [...data.filter(e=>e.issueDate).sort((a,b)=>b.issueDate - a.issueDate), ...data.filter(e=>!e.issueDate)]
   data => data.sort((a,b)=>a.name.label.localeCompare(b.name.label))
)
export const getProfileEducation = createSelector(
   state => state.ui.profile.education, 
   // data => data.sort((a,b)=>b.from - a.from)
   data => [...data.filter(e=>e.from).sort((a,b)=>b.from - a.from), ...data.filter(e=>!e.from)]
)
export const getProfileExperienceShowSuppressed = createSelector(
   state => state.ui.profile.showSuppressed, 
   data => data
)
export const filterProfileExperience = state => 
   !state.ui.profile.showSuppressed || state.ui.profile.accessMode.manager 
   ?
   state.ui.profile.experience.filter(e=>e.status !== 2)
   :
   state.ui.profile.experience
   
export const getProfileExperience = createSelector(
   [filterProfileExperience], 
   // data => [...data.filter(e=>e.from).sort((a,b)=>b.from - a.from), ...data.filter(e=>!e.from)]
   data => data
)

export const getSuppressedCount = createSelector(
   state => state.ui.profile.experience.filter(e=>e.status === 2).length,
   data => data
)

export const getProfileInterestStatus = createSelector(
   state => state.ui.profile.nextRole, 
   data => data.filter(e=>e.interest.type === VALUE_TYPE_NEXTROLE_STATUS)[0] ||{
      id: 0, 
      interest: getNextRoleStatusValues().filter(e=>e.code === NEXTROLE_STATUS_NO)[0]
    }
)
export const getProfileNextRoleNote = createSelector(
   [state => state.ui.profile.nextRoleNote, state => state.ui.profile.ver], 
   (content, ver) => ({content, ver})
)
export const getProfilePreferred = createSelector(
   state => state.ui.profile.nextRole,
   data => data
)

export const getPreferredExpand = createSelector(
   state => getProfileInterestStatus(state),
   data => data.interest?.code !== NEXTROLE_STATUS_NO && data.interest?.code !== NEXTROLE_STATUS_EXCLUDE
)
export const getPerson = createSelector(
   state => state.ui.profile.person, 
   data => data
)

export const getProfileProject = createSelector(
   state => state.ui.profile.projects, 
   // data => [...data.filter(e=>!e.from), ...data.filter(e=>e.from).sort((a,b)=>b.from - a.from)]
   data => [...data.filter(e=>e.from).sort((a,b)=>b.from - a.from), ...data.filter(e=>!e.from)]
)

export const getProfileSkills = createSelector(
   state => state.ui.profile.skills, 
   skills => ({
      all: skills, 
      top: skills.filter(s=>s.top > 0).sort((a,b)=>a.top - b.top),
      topOther: skills.filter(s=>!!!s.top).slice(0, 3),
      tree: buildSkillTree(skills.filter(s=>!!!s.top))
    })
)

export const getProfileSummary = createSelector(
   [state => state.ui.profile.summary, state => state.ui.profile.ver], 
   (content, ver) => ({content, ver})
)
export const getProfilePublicView = createSelector(
   state => state.ui.profile.publicView, 
   data => data
)
