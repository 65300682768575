import React from 'react'
import { ImgButton } from './elements'
import './deletable-item.scss'

const DeletableItem = (props: any) => {
  const {value, title, label='', className='', readonly=false, onDelete} = props
  return <>
    <div className={`deletable ${className}`}>
      <div className="item">
        <span className='label' title={title}>{value}</span>
      { onDelete && !readonly &&<>
        {/* <ImgButton image='fa fa-close delete' aria-label={`Remove ${label} selection ${value}`} className="deletableItemIcon" onClick={onDelete}></ImgButton> */}
        <ImgButton src='./img/icon-close.svg' aria-label={`Remove ${label} selection ${value}`} className='deletableItemIcon' onClick={onDelete}/>
        </>
      }
      </div>
    </div>
  </>
}

export default DeletableItem


