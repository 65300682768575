import { MESSAGE3_SHOW, MESSAGE3_HIDE } from 'services/action-types'

const initialState = {
  content: {}
}

export const message3_reducer= (state = initialState, action: any) => {
  switch (action.type) {
  case MESSAGE3_SHOW:
    return state = { 
      ...state, 
      content: action.value
    }
  case MESSAGE3_HIDE:
    return { 
      ...state, 
      content: {} 
    }
  default:
    return state
  }
}