import { PointerSensor, PointerSensorOptions } from '@dnd-kit/core'
import type { PointerEvent } from 'react'

export class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: (
        { nativeEvent: event }: PointerEvent,
        { onActivation }: PointerSensorOptions,
      ): boolean => {
        if (!event.isPrimary || event.button !== 0) {
          return false;
        }
        const e: any = event
        if(e.target.parentElement.role === 'button'){
            return false
        }
        onActivation?.({ event });

        return true;
      },
    },
  ];
}