import React from 'react'
import { Link2 } from 'components/common'
import { update_resource_access } from './actions'
import { getValueByCode } from 'utils'

const CountLink = (props: any) =>{
  const { item, ...rest } = props

  const onClick = (e: any) => {
    const value = getValueByCode('MT_RES_GROUP', e.code)    
    value && update_resource_access(value)
  }
  return (
    <Link2 label='Opens in a new window' target='_blank' href={item.href} onClick={()=>onClick(item)} {...rest}>{item.label}
    { item.external ?
      <span aria-label='Opens in a new window' title='Opens in a new window' style={{verticalAlign: 'middle'}}>
        <span className='fa fa-external-link' aria-hidden='true'/>
      </span>
      :
      <span/>
    }
    </Link2>
  )
}

export default CountLink