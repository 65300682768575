import {
  CRITERIA_AUTO_COMP_REFRESH,
  CRITERIA_AUTO_COMP_CLEAR,
  CRITERIA_SEARCH_ACTIVE_APPLY_REFRESH
} from 'services/action-types'

const initialState = {
  autoComp: [],
  activeApply: {},
}

export const criteria_reducer =  (state = initialState, action: any) => {
  switch (action.type) {
  case CRITERIA_AUTO_COMP_REFRESH:
    return state = { 
      ...state, 
      autoComp: action.value,
    }
  case CRITERIA_AUTO_COMP_CLEAR:
    return state = { 
      ...state, 
      autoComp: [],
    }
  case CRITERIA_SEARCH_ACTIVE_APPLY_REFRESH:
    return state = { 
      ...state, 
      activeApply: action.value,
    }
  default:
      return state
  }
}