import { 
  ADMIN_ACTIVE_MENU_CHANGE, ADMIN_ACTIVE_MENU_ID_CHANGE
} from 'services/action-types'

const initialState = {
  menu: {},
  menuId: 0
}

export const containerReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_ACTIVE_MENU_CHANGE:
    return state = { 
      ...state, 
      menu: action.value,
      menuId: action.value.id
    }
  case ADMIN_ACTIVE_MENU_ID_CHANGE:
    return state = { 
      ...state, 
      menuId: action.value
    }
  default:
      return state
  }
}