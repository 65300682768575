import { join, displayUserName } from 'utils'

export const buildPlansTree = plans => {
  const tree = {}
  plans.map(s =>{
    const indId = s.indId
    if(tree[indId]){
      const _position = tree[indId].plans.filter(p=>p.planId === s.planId)[0]
      if(_position){
        _position.positions.push(s)
      }else{
        tree[indId].plans.push({...s, positions: [s]})
      }      
    }else{
      tree[indId]={ ...s, plans:[{...s, positions: [s]}] }
    }
    return tree
  })
  return Object.keys(tree).map(e=>tree[e]).sort((a,b)=>(a.firstName+a.lastName).localeCompare(b.firstName+b.lastName))
}

export const displayMgrName = user =>{
  if(user.mgrFirstName || user.mgrLastName){
    return join([user.mgrFirstName, user.mgrLastName], ' ') 
  }else{
    return (user.mgrDisplayName||user.mgrEmail||'').replace(',', '')
  }
}

export const sortingPlan = plans => plans.sort((a,b)=>displayMgrName(a).localeCompare(displayMgrName(b)) ||displayUserName(a).localeCompare(displayUserName(b)))

export const getExtendFocusClass = pid => `plan-item-${pid}`