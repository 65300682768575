import { 
    ADMIN_INDIVIDUAL_REFRESH,
    ADMIN_IND_ASS_REFRESH,
    ADMIN_INDIVIDUAL_RESET,
    ADMIN_IND_PLAN_REFRESH,
    ADMIN_IND_PLAN_YEARS_REFRESH,
    ADMIN_WIN_MINS_REFRESH,
    ADMIN_PLAN_YEARS_REFRESH,
    ADMIN_PLAN_YEAR_REFRESH,
    ADMIN_IND_ID_REFRESH,
    ADMIN_STRUCTURE_REFRESH
  } from 'services/action-types'

import { getCurYear, filterReportYears } from '../rating/reducer'

const initialState = {
    ind: {},
    indId: 0,
    assignment: {},
    planYears: [],
    curPlanYears: [],
    currentYear: {},
    winMins: [],
    plan: {},
    structure: []
}

export const indMgmtReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_INDIVIDUAL_REFRESH:
    return state = { 
      ...state, 
      ind: action.value,
      assignment: {}
    }
  case ADMIN_IND_ID_REFRESH: 
    return state = {
      ...state,
      indId: action.value
    }
  case ADMIN_IND_ASS_REFRESH:
    return state = { 
      ...state, 
      assignment: action.value
    }
  case ADMIN_IND_PLAN_REFRESH:
    return state = { 
      ...state, 
      plan: action.value
    }
  case ADMIN_IND_PLAN_YEARS_REFRESH:
    return state = { 
      ...state, 
      planYears: action.value
    }
  case ADMIN_PLAN_YEARS_REFRESH:
    return state = { 
      ...state, 
      curPlanYears: action.value
    }
  case ADMIN_PLAN_YEAR_REFRESH:
    const reportYears = filterReportYears(action.value)
    return state = {
      ...state,
      currentYear: getCurYear(reportYears),
    }
  case ADMIN_WIN_MINS_REFRESH:
    return state = {
      ...state,
      winMins: action.value.map(e=>({id: e.winOrgId, label: e.winOrgName, active: e.winOrgType===1}))
    }
  case ADMIN_INDIVIDUAL_RESET: 
    return state = {
      ...initialState,
      indId: state.indId
    }
  case ADMIN_STRUCTURE_REFRESH: 
    return state = {
      ...state,
      structure: action.value
    }    
  default:
      return state
  }
}