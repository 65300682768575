import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import rootReducer from '../reducers'
import fetchMiddleware from '../middleware'
import { FetchActionType, SimpleActionType } from 'types'
const aborts:any = {}

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, fetchMiddleware]
})
export default store

export const getState = store.getState
export const getCatagory = () => getState().ui.auth.catagory   // client, tms

export const dispatchFetch = (value: FetchActionType) => store.dispatch({
    fetchConfig: value,
    type: undefined
})

export const dispatchFetchAbort = (value: FetchActionType) => {    
    aborts[value.path]?.abort()
    aborts[value.path] = new AbortController()
    store.dispatch({
        fetchConfig: {...value, signal: aborts[value.path].signal},
        type: undefined
    })
}

export const dispatchSimple = (value: SimpleActionType) => store.dispatch(value)