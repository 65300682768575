import { REGISGER_UNSAVED, UNREGISGER__UNSAVED } from 'services/action-types'

// const initialValue = {
// 	key: '',
// 	func: () => true,
// }

const initialState = {
  stack: []
}

export const unsaved_reducer= (state = initialState, action: any) => {
  switch (action.type) {
	case REGISGER_UNSAVED:
		return { 
			...state, 
			stack: [...state.stack, action.value]
		}
 	case UNREGISGER__UNSAVED:
		return { 
			...state, 
      stack: [...state.stack.filter((e: any)=>e.key !== action.value.key)]
		}
  default:
    return state
  }
}