import React from 'react'
import { loggerInfo } from 'utils'
import './progress-bar.scss'

const ProgressBar = (props: any) => {
  const { message, progress} = props
  loggerInfo('progressbar.render')

  return <div className='tms-progress-bar'>
    <div>{message}</div>
    <div className="progress-bar-container">
        <div className="progress-bar" style={{width: progress + '%'}}></div>
    </div>
    {progress > 0 && <div>{progress}% completed</div>}
  </div>
}

export default ProgressBar
