import React from 'react'
import { Button, Scrollbar } from 'components/common'

interface propsType{
  describedBy: string; 
  labelledBy: string; 
  onClose: any;
}
const ServiceUnavailable = (props: propsType) => {
  const { describedBy, labelledBy, onClose=()=>{}} = props
  
  return (<>
    <div id={labelledBy} className='simple-row strong big' tabIndex={0}>
      <img className='image-warning' alt='' aria-hidden='true' src='./img/icon-warning-circle-red.svg'/>
      <span>Mentorship unavailable</span>
    </div>
    <div className='mentor-service-pops-modal-container'><Scrollbar>
      <div tabIndex={0} id={describedBy} style={{padding: '10px 0 0 0'}}>
        Mentorship is currently unavailable. We appreciate your patience while we work on fixing this! Please check back again soon.        
      </div>
    </Scrollbar></div>  
    <div className='simple-row rating-section' style={{padding: '30px 0 0 0'}}>
      <Button className='tms-button primary' onClick={onClose}>Close</Button>
    </div>
  </>)
}

export default ServiceUnavailable