import { 
  REVIEW_NEW_INIT,
  REVIEW_STEP_REFRESH,
  REVIEW_NEW_REFRESH,
  REVIEW_ITEM_ACTIVE_REFRESH,
  REVIEW_UPDATE_REFRESH,
  REVIEW_PANEL_TOGGLE,
  REVIEW_WARNING_REFRESH,
  REVIEW_OFFLINE_DOC_REFRESH,
  REVIEW_MGR_PRE_STEP_REFRESH,
  REVIEW_MGR_PRE_TYPE_REFRESH,
  REVIEW_IND_PRE_STEP_REFRESH
} from 'services/action-types'
import { clone } from 'utils'

export const preTypeAll = {id: -1, label: 'All', code: '0'}
const initialState = {
  step: 0,
  stage: {},
  detail: {
  },
  edit: [false, false, false, false],
  audit: {},
  activeItems: [],
  updateDetail: {
  },
  panelShowing: false,
  reviewWarning: {},
  reviewDocs: undefined,
  preStep: 0,
  preType: preTypeAll,
  indPreStep: 0
}

export const reviewReducer =  (state = initialState, action) => {
  switch (action.type) {
  case REVIEW_NEW_INIT:
    return state = { 
      ...state, 
      detail: action.value,
      detailO: clone(action.value),
    }
  case REVIEW_NEW_REFRESH:
    return state = { 
      ...state, 
      detail: action.value,
      // detailO: clone(action.value),
    }
  case REVIEW_UPDATE_REFRESH:
    return state = { 
      ...state, 
      updateDetail: action.value,
    }
    
  case REVIEW_STEP_REFRESH:
    return state = { 
      ...state, 
      step: action.value,
    }
  case REVIEW_ITEM_ACTIVE_REFRESH:
    return state = { 
      ...state, 
      activeItems: action.value
    }
  case REVIEW_PANEL_TOGGLE:
    return state = { 
      ...state, 
      panelShowing: action.value
    }
  case REVIEW_WARNING_REFRESH:
    return state = { 
      ...state, 
      reviewWarning: {...state.reviewWarning, ...action.value}
    } 
  case REVIEW_OFFLINE_DOC_REFRESH:
    return state = { 
      ...state, 
      reviewDocs: action.value
    }  
  case REVIEW_MGR_PRE_STEP_REFRESH:
    return state = { 
      ...state, 
      preStep: action.value
    }
  case REVIEW_MGR_PRE_TYPE_REFRESH:
    return state = { 
      ...state, 
      preType: action.value
    }
  case REVIEW_IND_PRE_STEP_REFRESH:
    return state = { 
      ...state, 
      indPreStep: action.value
    }    
  default:
    return state
  }
}