import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loggerInfo } from 'utils'
import { fetch_thumbnail } from './actions'
import { getThumbnail } from './selectors'

const Thumbnail = (props: any) =>{
  const { id, thumbnail, style, className, title } = props

  useEffect(() => {
    id && fetch_thumbnail(id)
    // fetch_thumbnail(id)
  }, [id])
  
  loggerInfo('thumbnail render')
  return (
    <span className='thumbnail' title={title}>
      { id ? 
        <img style={style} className={className} alt='' src={thumbnail} 
        onError={(e: any)=>{e.target.onerror = null; e.target.src='./img/default-thumbnail.jpg'}}/>
        :
        <img style={style} className={className} alt='' src='./img/default-thumbnail.jpg'/>
      }
    </span>
  )
}
const mapStateToProps = (state: any, ownProps: any) => {
  return ({
    thumbnail: getThumbnail(state, ownProps)
  })
}

export default connect(mapStateToProps, {})(Thumbnail)