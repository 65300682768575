import { 
  ADMIN_REPORT_MINISTRIES_REFRESH,
  ADMIN_REPORT_YEARS_REFRESH
} from 'services/action-types'
import { filterReportYears, getCurYear } from '../rating/reducer'

const initialState = {
  ministries: [],
  reportYears: [],
  currentYear: {}
}

export const reportReducer =  (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_REPORT_MINISTRIES_REFRESH:
    return state = {
      ...state,
      ministries: action.value
  }
  case ADMIN_REPORT_YEARS_REFRESH:
    const reportYears = filterReportYears(action.value)
    return state = {
      ...state,
      reportYears,
      currentYear: getCurYear(reportYears),
    }
  default:
    return state
  }
}