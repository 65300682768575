import {
  TM_ORG_TREE_REFRESH,
  IND_USERS_REFRESH,
  IND_USERS_REFRESH_ALL,
  PERFORMANCE_GOALS_SEARCH_REFRESH,
  WIN_ORG_REFRESH,
  AD_USERS_REFRESH,
} from 'services/action-types'

const initUsersAll = {items: [], totalRecords: 0}

const searchInitialState = {
  orgTree: [],
  indUsers: [],
  usersAll: initUsersAll,
  adUsers: [],
  adAllUsers: [],
  goals: [],
  winMins: []
}

const sortUser = (u1, u2) => {
  const name1 = u1.sortByName || ''
  const name2 = u2.sortByName || ''
  const email1 = u1.userEmail || u1.email || ''
  const email2 = u2.userEmail || u2.email || ''

  if (name1 !== name2) {
    return name1.localeCompare(name2)
  } else {    
    if (email1.toLowerCase().indexOf("ontario.ca") > 0) {
      return (email2.toLowerCase().indexOf("ontario.ca") > 0) ? 0 : -1
    } else if (email2.toLowerCase().indexOf("ontario.ca") > 0) {
      return (email1.toLowerCase().indexOf("ontario.ca") > 0) ? 0 : 1
    } else {
      return email1.localeCompare(email2)
    }
  }
}

export const searchReducer =  (state = searchInitialState, action) => {
  switch (action.type) {
  case TM_ORG_TREE_REFRESH:
    return state = { 
      ...state, 
      orgTree: action.value
    }
  case AD_USERS_REFRESH:
    return state = {
      ...state,
      adUsers: [...action.value.filter(v=>v.valid || v.active).sort(sortUser)],
      adAllUsers: [...action.value.sort(sortUser)]
    }
  case IND_USERS_REFRESH:
    return state = {
      ...state,
      indUsers: action.value
    }
  case PERFORMANCE_GOALS_SEARCH_REFRESH:
    return state = {
      ...state,
      goals: action.value
    }
  case WIN_ORG_REFRESH:
    return state = {
      ...state,
      winMins: action.value
    }
  case IND_USERS_REFRESH_ALL:
    return state = {
      ...state,
      users: [],
      usersAll: action.value
    }
  default:
    return state
  }
}
