import { UI_NOTIFICATION_REFRESH, UI_NOTIFICATION_FETCHING, UI_NOTIFICATION_FILTER } from 'services/action-types'

const initialState = {
	events: [],
	fetching: false
}

export const ui_notification_reducer= (state = initialState, action: any) => {
  switch (action.type) {
	case UI_NOTIFICATION_FETCHING:
		return { 
			...state, 
			fetching: action.value
		}
	case UI_NOTIFICATION_REFRESH:
		return { 
			...state, 
			events: [...state.events, ...action.value]
		}
	case UI_NOTIFICATION_FILTER:
		return { 
			...state, 
			events: [...state.events].filter(action.value)
		}		
  default:
    return state
  }
}