import { http_request } from 'services/http-service'
import { show_spinner, hide_spinner, prompt } from 'components/common/actions'

const fetchMiddleware = (store: any) => (next: any) => (action: any) => {
  if (!action || !action.fetchConfig) {
    return next(action)
  }

  const {
    spinner,
    path,
    method = 'GET',     
    success: successHandler, 
    failure: failureHandler, 
    ignore = [], 

    // body, 
    // onprogress, 
    // type, 
    // signal,
    ...rest
  } = action.fetchConfig

  let url = path
  if(url.indexOf('http:')=== -1 && url.indexOf('https:') === -1){
    url = `${window.location.protocol}//${window.location.host}/${url|| '/'}`
  }
  
  show_spinner(spinner)
  
  const handleResponse = (response: any)=>{
    hide_spinner(spinner)
    successHandler(response)
  }

  const handleError = (error: any)=>{
    !ignore.some((e: any)=>e === error.msgCode || e === error.status) && 
    error.name !== 'AbortError' && 
    prompt.errorCast({code: error.msgCode, content: error.msg || error.message || error.statusText})
    console.error(error)
    hide_spinner(spinner)
    failureHandler && failureHandler(error)
  }
  
  http_request({method, url, ...rest}).then(handleResponse, handleError).catch(handleError)
}

export default fetchMiddleware 