import React, { useEffect, useRef, forwardRef } from 'react'
import { TypeAhead } from 'components/common'
import './select.scss'
export default forwardRef((props: any, ref)=> {
  const { selected='', onChange, label, labelledBy, isDisabled=false, options, placeholder='', blockMsg='' } = props
  const __ref = useRef({})
  const wrapperRef: any = ref || __ref
  useEffect(() => { 
    wrapperRef.current.setValue(selected && selected.value !=='' ?  selected : '')
  }, [selected, wrapperRef])

  const onDrop = () => {
     wrapperRef.current.onToggle()   
  }

  return (<>
    <div className={`select ${isDisabled ? 'show' : 'hide'}`}>
      <div className='typing-input disable'>
        <div className='selected'>{selected.label}</div>
        <span className='fa fa-caret-down tms-control'></span>
      </div>
    </div>
    <div className={`select ${isDisabled ? 'hide' : 'show'}`}>

      <TypeAhead labelledBy={labelledBy} label={label} ref={wrapperRef} placeholder={placeholder} menuStyle={{width: '100%'}} blockMsg={blockMsg}
        extra={<span className='fa fa-chevron-down' onClick={onDrop}/>}
        options={options} onChange={onChange}/>
    </div>
    </>
  )
})