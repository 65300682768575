import { useRef, useState, useEffect, forwardRef } from 'react'
import { newId, Key} from 'utils'
import './elements.scss'

export const Div = forwardRef((props: any, ref) => {
  const {children, role, className, noOutline=false, onClick=()=>{}, onKeyDown=()=>{}, ...rest} = props
  const __ref = useRef({})
  const _ref: any = ref || __ref
  const _onKeyDown= (event: any) => {
    if(event.keyCode===Key.ENTER || (event.keyCode===Key.SPACE && (role==='button' || role==='option'))){
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      if(_ref.current){
      _ref.current.click()
      }else{
        onClick(event)
      }
    }else{
      onKeyDown(event)
    }
  }
  return <div role='link' ref={_ref} {...(role && {role: role})} className={`${className} ${!noOutline ? 'tms-control': ''}`} 
    // tabIndex={className && className.indexOf('dropdown-item')>-1 ? '-1': '0'} 
    tabIndex={0} 
    {...rest} onKeyDown={_onKeyDown} onClick={onClick}>
    {children}
  </div>
})

export const Li = forwardRef((props: any, ref) =>{
  const {children, className, onClick=()=>{}, onKeyDown=()=>{}, ...rest} = props
  const __ref = useRef({})
  const _ref: any = ref || __ref
  const _onKeyDown= (event: any) => {
    if(event.keyCode===Key.ENTER){
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      if(_ref.current){
      _ref.current.click()
      }else{
        onClick(event)
      }
    } else if (event.keyCode===Key.RIGHT_ARROW || event.keyCode === Key.DOWN_ARROW || event.keyCode === Key.UP_ARROW ) {
      onKeyDown(event)
    }
  }
  return <li ref={_ref} className={`${className} tms-control`} 
    // tabIndex={className && className.indexOf('dropdown-item')>-1 ? -1: 0} 
    tabIndex={0} 
    {...rest} onKeyDown={_onKeyDown} onClick={onClick}>
    {children}
  </li>
})

export const Span = forwardRef((props: any, ref) =>{
  const {children, role, className, onClick=()=>{}, onKeyDown=()=>{}, ...rest} = props
  const __ref = useRef({})
  const _ref: any = ref || __ref
  const _onKeyDown= (event: any) => {
    if(event.keyCode===Key.ENTER || (event.keyCode===Key.SPACE && (role==='button' || role==='option'))){
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      if(_ref.current){
      _ref.current.click()
      }else{
        onClick(event)
      }
    }else{
      onKeyDown(event)
    }
  }
  return <span ref={_ref} {...(role && {role: role})} 
    // tabIndex={className && className.indexOf('dropdown-item')>-1 ? '-1': '0'} 
    tabIndex={0} 
    className={`${className} tms-control`} {...rest} onKeyDown={_onKeyDown} onClick={onClick}>
    {children}
  </span>
})

export const Input = forwardRef((props: any, ref) => {
  const {label, className, ...rest} = props
  const [ id, setId ] = useState('')
  useEffect(() => { setId(newId()) }, [])
  return <>
    <label className='sr-only' htmlFor={id}>{label}</label>
    <input autoComplete='off' className={`${className}`} {...rest} ref={ref} id={id}/>
  </>
})

export const Checkbox = forwardRef((props: any, ref) => {
  const {label, display, checked, onChange=()=>{}, describedReq=false, required=false, disabled=false, className='aligned', ...rest} = props
  const [ id, setId ] = useState('')
  const [ displayId, setDisplayId] = useState('')
  const __ref = useRef({})
  const _ref = ref || __ref
  useEffect(() => { 
    setId(newId()) 
    setDisplayId(newId())
  }, [])

  return <div className={`tms-checkbox ${className}`}>
    <label className="container" htmlFor={id} style={{cursor: !disabled ? 'pointer': 'inherit'}}> 
      <input type="checkbox" id={id} ref={_ref} checked={checked} className={disabled ? 'disabled': ''}
        {...(describedReq && {"aria-describedby": displayId})} 
        {...(required && {"aria-required": true})}
        {...(disabled && {disabled: 'disabled'})} 
        onChange={onChange} {...rest}/>
      <span className="checkmark"></span>
      <span className='sr-only'>{label}</span>
    </label>
    <div id={displayId} className='display'>{display}</div>
  </div>
})

export const Input2 = forwardRef((props: any, ref) => {
  const {label} = props
  const [ id, setId ] = useState('')
  useEffect(() => { setId(newId()) }, [])
  return <>
    { label }
    <label className='sr-only' htmlFor={id}>{label}</label>
    <input {...props} ref={ref} id={id}/>
  </>
})

// export const Textarea = forwardRef((props, ref) => {
//   const {children, onChange=()=>{}, label, ...rest} = props
//   const [ id, setId ] = useState('')
//   useEffect(() => { setId(newId()) }, [])
//   return <>
//     <label className='sr-only' htmlFor={id}>{label}</label>
//     <textarea {...rest} ref={ref} id={id} onChange={onChange}>
//       {children}      
//     </textarea>
//   </>
// })

export const Button = forwardRef((props: any, ref) => {
  const {children, label, onClick, turnOff=200, ...rest} = props
  const __ref: any = useRef({})
  const _ref = ref || __ref
  
  const onBtnClick = (event: any) => {
    _ref.current.setAttribute('disabled', 'disabled')
    onClick(event)
    setTimeout(() => {
      _ref.current && _ref.current.removeAttribute('disabled')
    }, turnOff);
  }
  return <>
    <button {...rest} type='button' ref={_ref} onClick={onBtnClick}>
      {children}
    </button>
  </>
})

export const ImgButton = forwardRef((props: any, ref) => {
  const {image, src, children, ...rest} = props
  const __ref = useRef({})
  const _ref = ref || __ref
  return <Span role='button' ref={_ref} style={{display:'inline-block'}} {...rest}>
    {image && <span aria-hidden={true} className = {image}/>}
    {src && <img alt='' aria-hidden='true' src={src}/>}
    {children}
  </Span>          
})

export const Link = forwardRef((props: any, ref) => {
  const {children, label, href, onClick=()=>{}, ...rest} = props
  const onClickLink = () => {
    onClick()
    if(href){
      window.open(href, '_blank')
    }
  }
  return <>
  <span className='tms-link'>
    <button role="link" {...rest} ref={ref} aria-label={label} onClick={onClickLink}>
      {children}
    </button>
    </span>
  </>
})

export const Link2 = forwardRef((props: any, ref) => {
  const {children, label, arialabel, className, href, style, disabled, onClick=()=>{}, ...rest} = props
  const __ref = useRef({})
  const _ref: any = ref || __ref
  const _onKeyDown= (event: any) => {
    if(event.keyCode===Key.ENTER){
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      _ref.current.click()
    }
  }
  const _onClick = (event: any) => {
    if(!href || href==='#') {
      event.preventDefault()      
    }
    onClick(event)
  }
  const onClickDisabled = (event: any) => event.preventDefault()
  return <>
  <div className={`tms-link2 ${className}`} style={style}>
    {disabled ? 
      <a {...rest} ref={_ref} href={!!href ? href: '#'} className="tms-control disabled" onClick={onClickDisabled}>
     <span className='sr-only'>{label}</span>
      {children}
    </a>
    :
    !!href ?
    <a {...rest} ref={_ref} href={!!href ? href: '#'} className="tms-control" onClick={_onClick} onKeyDown={_onKeyDown}>
      <span className='sr-only'>{label}</span>
      {children}
    </a>
    :
    <button role="link" {...rest} ref={_ref} aria-label={arialabel ? arialabel: label} onClick={_onClick}>
      {children}
    </button>
    }
    </div>
  </>
})

export const Title = (props: any) =>{
  const {children, id, required, none, focusble=false, left=false, ...rest} = props
  return <span className='tms-title'  {...rest}>
    {left && required && <Star/>}
    <span id={id} /*tabIndex={focusble ? '0': '0'}*/  className='tms-title-label'>{children}{!left && required && <Star/>}</span>
    
  </span>
}
export const Star = () =>{
  return <abbr title="Required" aria-label='Required' style={{color: '#dc0000', textDecoration: 'none'}}>*</abbr>
}
export const Small = (props: any) =>{
  const {children, ...rest} = props
  return <span {...rest} style={{fontSize: '13px'}}>{children}</span>
}
export const Dot = () =><span aria-hidden='true'>{` \u2022 `}</span>

export const NewIndicator = (props: any) => {
  const {title, ...rest} = props
  return <span className='new-indicator' title={title} {...rest}><span className='sr-only'>{title}</span></span>
}
export const ImageDiv = (props: any) => {
  const { contentType, imageSource } = props
  return <img style={{maxWidth: '100%', maxHeight: '100%'}} src={`data:${contentType};base64,${imageSource}`}/>
}
export const VideoDiv = (props: any) => {
  const { src } = props
  return <iframe title="Video" src={src} id="myFrame" style={{width: '100%', height: '380px',maxHeight: '100%', border: 'none'}} allowFullScreen></iframe>
  // return <video style={{maxWidth: '100%', maxHeight: '100%'}} src={src} type="video/mp4"/>
}
export const HTMLDiv = (props: any) =>{
  const {content, placeHolder, xss=true, ...rest} = props
  // const getHtml = value => ({__html: (value||'').replace(/(\n|\r|\r\n)/g, '<br>').split(' ').join('&nbsp;')})
  const getHtml = (value: any) => !!!xss ? 
  {__html: (value||'').replace(/(\n|\r|\r\n)/g, '<br>')}
  :
  {__html: (value||'')
            .split('<').join('&lt;')
            .split('>').join('&gt;')
            .split('"').join('&quot;')
            .split("'").join('&apos;')
            .replace(/(\n|\r|\r\n)/g, '<br>')}
                    
  return <>
  {
    !!content ? 
    <div dangerouslySetInnerHTML={getHtml(content)} {...rest}/>
    :
    <div className='place-holder'>{placeHolder||'No content.'}</div>
  }
  
  </>
}
export const ExtLink = () => <span aria-label='Opens in a new window' title='Opens in a new window'>
  <span className='fa fa-external-link' aria-hidden='true' />
</span>

export const HelpLink = (props: any) => {
  const {children, href, ...rest} = props
  return <a href={href} {...rest} target="_blank" rel="noopener noreferrer">{children}
    <span aria-label='Opens in a new window' title='Opens in a new window'>
      <span className='fa fa-external-link' aria-hidden='true'/>
    </span>
  </a>
}
export const HTMLDiv2 = (props: any) => {
  const { header, children } = props
  return (<HTMLDiv xss={false} content={`<span><span class='strong'>${header}:</span>&nbsp;<span>${children}</span></span>`}/>)
}