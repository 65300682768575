import React, { useState } from 'react'

const DefaultExport = (props: any) => {
  const { message } = props
  const [ show, setShow ] = useState(false)
  const onClick = () => setShow(!show)
  return (<div>
    <div style={{textAlign: 'center', fontSize: '30px', marginTop: '20%'}}>
      {message?.content}
      {message?.status &&
        <span tabIndex={0} className='fa fa-info-circle' style={{fontSize: '16px'}} onClick={onClick}/>
      }
      {show && <div style={{fontSize: '16px', width: '50%', float: 'right', padding: '0 20px 0 0'}}>
        {Object.keys(message).map((key, index) => <div key={index} className='simple-row'>
          <span className='strong' style={{paddingRight: '5px'}}>{key}:</span>
          <span>{message[key].toString()}</span>
        </div>)}
      </div>}
    </div>
  </div>)
};

export default DefaultExport;