import {
    GOALLIB_TEMPLATES_REFRESH,
    GOALLIB_TEMPLATE_DETAIL_REFRESH,
    GOALLIB_TEMPLATE_SELECTED,
    GOALLIB_ALL_TEMPLATES_SELECTED,
    GOALLIB_TAGS_REFRESH,
    GOALLIB_TEMPLATE_PRIORITY_REFRESH,
    GOALLIB_TEMPLATE_LEARN_SKILL_REFRESH,
    GOALLIB_TEMPLATE_LEARN_ACT_REFRESH,
    GOALLIB_TEMPLATE_MEASURE_REFRESH,
    GOALLIB_TEMPLATE_LEARN_ROLE_REFRESH,
    GOALLIB_PLAN_YEARS_REFRESH,
    GOALLIB_DEPLOY_RESULT_REFRESH,
    GOALLIB_DEPLOY_PROGRESS_REFRESH,
    GOALLIB_TEAM_TREE_REFRESH,
    GOALLIB_RESET_TEAM_TREE,
    GOALLIB_DEPLOY_PLANS_SELECTED,
    GOALLIB_DEPLOY_MGRS_SELECTED,
    GOALLIB_DEPLOY_MGRS_UNSELECTED,
    GOALLIB_DEPLOY_EXPAND_ITEMS,
    GOALLIB_DEPLOY_CASCADE_MODE,
    GOALLIB_DEPLOY_PLANS_UNSELECTED,
    GOALLIB_EXT_JOB_CLASS_SELECTED,
    GOALLIB_EXT_JOB_CLASS_UNSELECTED,
    GOALLIB_DIRECT_JOB_CLASS_SELECTED,
    GOALLIB_DIRECT_JOB_CLASS_UNSELECTED,
    GOALLIB_DIRECT_TEAM_REFRESH,
    GOALLIB_DIRECT_TEAM_PLANS_SELECTED,
    GOALLIB_TOGGLE_ACCESS_MODE,
    GOALLIB_RESET_YEAR_ELIGIBLE_APV,
    GOALLIB_TREE_ACTIVE_ASS_ONLY,
    GOALLIB_DIRECT_ACTIVE_ASS_ONLY,
    GOALLIB_TEMPLATE_FILTER_REFRESH,
    GOALLIB_UPDATE_ACCESS_MODE,
    GOALLIB_TEMPLATE_INLINE_REFRESH,
    GOALLIB_TEMPLATE_DEPLOY_HISTORY_REFRESH
} from 'services/action-types'
import { displayUserName, clone } from 'utils'

const initType = {id: 0, label: 'All'}
const initTag = {id: 0, label: 'All'}
export const initdeployProgress = {message: '', percentage: 0}

const initialState = {
  templates: [],
  filteredTemplates: [],
  selectedTemplate: {},
  originalTemplate: {},
  selectedTemplateId: null,
  selectedTemplateIds: [],
  tags: [],
  templateFilter: {types: [initType], tags: [initTag]},
  years: [],
  deployProgress: initdeployProgress,
  deployResult: {},
  teamTree: [],
  filteredTeamTree: [],
  parentMap: {},
  selectedMgrIds: [],
  jobClassMap: {},
  jobClasses: [],
  selectedJobClasses: [],
  ministryMap: {},
  historyMap: {},
  planExistsMap: {},
  reportMatchMap: {},
  activeOnly: true,
  planExists: false,
  eligibleApproved: false,
  expandItemIds: [],
  dTeamPlanList: [],
  filteredDTeamPlanList: [],
  dTeamSelectedPlanIds : [],
  accessMode: {
    corp: false,
    min: false,
    indId: 0
  }
}

export const goalLibReducer =  (state = initialState, action) => {
  switch (action.type) {
  case GOALLIB_TOGGLE_ACCESS_MODE:
    return state = {
      ...state,
      accessMode: action.value
    }
  case GOALLIB_UPDATE_ACCESS_MODE:
    return state = {
      ...state,
      accessMode: {...state.accessMode, ...action.value}
    }
  case GOALLIB_TEMPLATES_REFRESH:
    return state = {
      ...state,
      templates: action.value,
      filteredTemplates: action.value,
      selectedTemplate: {},
      selectedTemplateId: null,
      selectedTemplateIds: [],
      templateFilter: {types: [initType], tags: [initTag]}
    }
  case GOALLIB_TEMPLATE_DETAIL_REFRESH:
    return state = {
      ...state,
      ...replaceTemplate([...state.templates], [...state.filteredTemplates], action.value)
    }
  case GOALLIB_TEMPLATE_SELECTED:
    return state = {
      ...state,
      selectedTemplateIds: processSingleSelection(state.selectedTemplateIds, action.value)
    }
  case GOALLIB_ALL_TEMPLATES_SELECTED:
    return state = {
      ...state,
      selectedTemplateIds: processAllSelection(state.filteredTemplates, action.value)
    }
  case GOALLIB_TAGS_REFRESH:
    return state = {
      ...state,
      tags: (action.value || []).sort((a,b)=>a.name.localeCompare(b.name))
    }
  case GOALLIB_PLAN_YEARS_REFRESH:
    return state = {
      ...state,
      years: action.value,
    }
  case GOALLIB_TEMPLATE_FILTER_REFRESH:
    let _templates = filterTemplates(state.templates, action.value).sort((a,b)=>a.name.localeCompare(b.name))
    let _selectedIds = state.selectedTemplateIds.filter(e => _templates.some(t => t.id === e))
    let _selectedTemplateId = _templates.length > 0 ? (_templates.some(e => e.id === state.selectedTemplate.id) ? state.selectedTemplate.id : _templates[0].id) : null
    return state = {
      ...state,
      templateFilter: action.value,
      filteredTemplates: _templates,
      selectedTemplateIds: _selectedIds,
      selectedTemplateId: _selectedTemplateId
    }
  case GOALLIB_TEMPLATE_PRIORITY_REFRESH:
    return state = {
      ...state,
      selectedTemplate: {
        ...state.selectedTemplate,
        priorities: action.value.entityList,
        ver: action.value.ver,
        lastUpdate: action.value.lastUpdate
      }
    }
  case GOALLIB_TEMPLATE_MEASURE_REFRESH:
    return state = {
      ...state,
      selectedTemplate: {
        ...state.selectedTemplate,
        measures: action.value.entityList,
        ver: action.value.ver,
        lastUpdate: action.value.lastUpdate
      }
    }
  case GOALLIB_TEMPLATE_LEARN_SKILL_REFRESH:
    return state = {
      ...state,
      selectedTemplate: {
        ...state.selectedTemplate,
        learningSkills: action.value.entityList,
        ver: action.value.ver,
        lastUpdate: action.value.lastUpdate
      }
    }
  case GOALLIB_TEMPLATE_LEARN_ACT_REFRESH:
    return state = {
      ...state,
      selectedTemplate: {
        ...state.selectedTemplate,
        activities: action.value.entityList,
        ver: action.value.ver,
        lastUpdate: action.value.lastUpdate
      }
    }
  case GOALLIB_TEMPLATE_LEARN_ROLE_REFRESH:
    return state = {
      ...state,
      selectedTemplate: {
        ...state.selectedTemplate,
        relateRoles: action.value.entityList,
        ver: action.value.ver,
        lastUpdate: action.value.lastUpdate
      }
    }
  case GOALLIB_DEPLOY_PROGRESS_REFRESH:
    console.log(action.value)
    return state = {
      ...state,
      deployProgress: action.value.percentage === 0 ? action.value : { ...action.value, percentage: Math.max(state.deployProgress.percentage, action.value.percentage)}
    }

  // case GOALLIB_DEPLOY_PROGRESS_REFRESH:
  //   return state = {
  //     ...state,
  //     deployProgress: { ...state.deployProgress, percentage: Math.max(state.deployProgress.percentage, action.value.percentage)}
  //   }
  case GOALLIB_DEPLOY_RESULT_REFRESH:
    return state = {
      ...state,
      deployResult: action.value
    }
  case GOALLIB_DIRECT_TEAM_REFRESH:
    let filteredPlans = filterDTeamPlans(action.value, state.selectedJobClasses, state.activeOnly)
    return state = {
      ...state,
      dTeamPlanList: action.value,
      filteredDTeamPlanList: filteredPlans,
      dTeamSelectedPlanIds: state.dTeamSelectedPlanIds.filter(e => filteredPlans.some(p => p.planId === e)),
      jobClasses: extractDTeamJobClasses(action.value),
      planExists: action.value.length > 0,
      eligibleApproved: true
    }
  case GOALLIB_DIRECT_ACTIVE_ASS_ONLY: {
    filteredPlans = filterDTeamPlans(state.dTeamPlanList, state.selectedJobClasses, action.value)
    return state = {
      ...state,
      activeOnly: action.value,
      filteredDTeamPlanList: filteredPlans,
      dTeamSelectedPlanIds: state.dTeamSelectedPlanIds.filter(e => filteredPlans.some(p => p.planId === e))
    }
  }
  case GOALLIB_DIRECT_TEAM_PLANS_SELECTED:
    return state = {
      ...state,
      dTeamSelectedPlanIds: action.value
    }
  case GOALLIB_DIRECT_JOB_CLASS_SELECTED:
    //let _selectedJobClasses = [action.value, ...state.selectedJobClasses]
    let _selectedJobClasses = [...action.value, ...state.selectedJobClasses]
    return state = {
      ...state,
      selectedJobClasses: _selectedJobClasses,
      filteredDTeamPlanList: filterDTeamPlans(state.dTeamPlanList, _selectedJobClasses, state.activeOnly)
    }
  case GOALLIB_DIRECT_JOB_CLASS_UNSELECTED:
    //_selectedJobClasses = state.selectedJobClasses.filter(e=>e.name!==action.value.name)
    _selectedJobClasses = state.selectedJobClasses.filter(e=>!action.value.some(v=>v.name === e.name))
    filteredPlans = filterDTeamPlans(state.dTeamPlanList, _selectedJobClasses, state.activeOnly)
    if (_selectedJobClasses.length > 0) {
      return state = {
        ...state,
        selectedJobClasses: _selectedJobClasses,
        filteredDTeamPlanList: filteredPlans,
        dTeamSelectedPlanIds: state.dTeamSelectedPlanIds.filter(e => filteredPlans.some(p => p.planId === e))
      }
    } else {
      return state = {
        ...state,
        selectedJobClasses: [],
        filteredDTeamPlanList: filterDTeamPlans(state.dTeamPlanList, [], state.activeOnly)
      }
    }
  case GOALLIB_TEAM_TREE_REFRESH:
    let tree = trimDuplicates(trimInactiveSibling(action.value.data))
    let ret = trimIdleVacants(tree)
    let _userId = (state.accessMode.corp || state.accessMode.min) ? state.accessMode.indId : action.value.userId
    let ret2 = extractClusterProps(ret.itemTree, _userId)
    let _state = {
      ...state,
      teamTree: ret.itemTree,
      jobClassMap: ret2.jobClassMap,
      ministryMap: ret2.ministryMap,
      historyMap: ret2.historyMap,
      planExistsMap: ret.planExistsMap,
      reportMatchMap: ret2.reportMatchMap,
      planExists: ret.planExists,
      eligibleApproved: true
    }

    if (state.selectedMgrIds.length > 0) {
      let ret3 =  addSelectedMgrs(state, tree, [], {ids: state.selectedMgrIds}, state.filteredTeamTree, ret2.reportMatchMap)
      return state = {
        ..._state,
        filteredTeamTree: ret3.tree,
        parentMap: ret3.parentMap,
        expandItemIds: ret3.expandIds,
        jobClasses: ret3.jobClasses
      }
    } else {
      return state = _state
    }
  case GOALLIB_RESET_YEAR_ELIGIBLE_APV:
    return state = {
      ...state,
      eligibleApproved: false
    }
  case GOALLIB_DEPLOY_PLANS_SELECTED:
    return state = {
      ...state,
      filteredTeamTree: addSelectedPlans(state.filteredTeamTree, action.value)
    }
  case GOALLIB_DEPLOY_PLANS_UNSELECTED:
    return state = {
      ...state,
      filteredTeamTree: removeSelectedPlans(state.filteredTeamTree, action.value)
    }

  case GOALLIB_DEPLOY_MGRS_SELECTED:
    ret =  addSelectedMgrs(state, state.teamTree, state.filteredTeamTree, action.value, null, state.reportMatchMap)
    return state = {
      ...state,
      selectedMgrIds: [...state.selectedMgrIds, ...action.value.ids],
      filteredTeamTree: ret.tree,
      parentMap: ret.parentMap,
      expandItemIds: ret.expandIds,
      jobClasses: ret.jobClasses
    }
  case GOALLIB_DEPLOY_MGRS_UNSELECTED:
    ret = removeSelectedMgrs(state, action.value)
    return state = {
      ...state,
      selectedMgrIds: state.selectedMgrIds.filter(e=>!action.value.ids.includes(e)),
      filteredTeamTree: ret.tree,
      jobClasses: ret.jobClasses,
      selectedJobClasses: ret.selectedJobClasses
    }
  case GOALLIB_EXT_JOB_CLASS_SELECTED:
    //let selectedJobClasses = [action.value, ...state.selectedJobClasses]
    let selectedJobClasses = [...action.value, ...state.selectedJobClasses]
    tree = state.teamTree.filter(e => state.selectedMgrIds.includes(e.id))
    ret = updateSelectedJobClass(state, tree, selectedJobClasses)
    return state = {
      ...state,
      selectedJobClasses: selectedJobClasses,
      filteredTeamTree: ret.tree,
      expandItemIds: ret.expandIds,
      parentMap: ret.parentMap
    }
  case GOALLIB_EXT_JOB_CLASS_UNSELECTED:
    //selectedJobClasses = state.selectedJobClasses.filter(e=>e.name!==action.value.name)
    selectedJobClasses = state.selectedJobClasses.filter(e=>!action.value.some(v=>v.name === e.name))
    if (selectedJobClasses.length > 0) {
      ret = updateSelectedJobClass(state, state.filteredTeamTree.map(e=>e.item), selectedJobClasses)
    } else {
      let tree = state.teamTree.filter(e => state.selectedMgrIds.includes(e.id))
      ret = updateSelectedJobClass(state, tree, selectedJobClasses)
    }
    return state = {
      ...state,
      selectedJobClasses: selectedJobClasses,
      filteredTeamTree: ret.tree,
      expandItemIds: ret.expandIds,
      parentMap: ret.parentMap
    }
  case GOALLIB_TREE_ACTIVE_ASS_ONLY: {
      let tree = state.teamTree.filter(e => state.selectedMgrIds.includes(e.id))
      ret = changeActiveOnly(state, tree, action.value)

      return state = {
        ...state,
        activeOnly: action.value,
        filteredTeamTree: ret.tree,
        expandItemIds: ret.expandIds,
        parentMap: ret.parentMap
      }
    }
  case GOALLIB_DEPLOY_EXPAND_ITEMS:
    return state = {
      ...state,
      expandItemIds: action.value
    }
  case GOALLIB_DEPLOY_CASCADE_MODE:
    return state = {
      ...state,
      filteredTeamTree: cascadeSelect(state, action.value)
    }
  case GOALLIB_RESET_TEAM_TREE:
    return state = {
      ...state,
      teamTree: [],
      filteredTeamTree: [],
      parentMap: {},
      jobClassMap: {},
      selectedMgrIds: [],
      selectedJobClasses: [],
      ministryMap: {},
      historyMap: {},
      planExistsMap: {},
      reportMatchMap: {},
      activeOnly: true,
      planExists: false,
      eligibleApproved: false,
      expandItemIds: [],
      jobClasses: [],
      dTeamPlanList: [],
      dTeamSelectedPlanIds: [],
      filteredDTeamPlanList: []
    }
  case GOALLIB_TEMPLATE_INLINE_REFRESH:
    return state = {
      ...state,
      selectedTemplate: action.value
    }
  case GOALLIB_TEMPLATE_DEPLOY_HISTORY_REFRESH:
    return state = {
      ...state,
      deployHistory: action.value
    }
  default:
      return state
  }
}

const filterTemplates = (templates, filter) => {
    let _tpls = templates.filter(tpl => {
      let ret = true
      if (filter.types && filter.types.length > 0) {
        ret = filter.types.some(e=> e.id === 0 || e.id === tpl.type.id)
      }
      if (ret && filter.tags && filter.tags.length > 0) {
        if (!filter.tags.some(ft => ft.id === 0)) {
          ret = tpl.tags.some(t => filter.tags.some(ft => ft.id === t.id))
        }
      }
      return ret
    })
    return _tpls
}

const replaceTemplate = (_templates, _filteredTemplates, _template) => {
  let _index = _templates.map(g=>g.id).indexOf(_template.id)
  if(_index > -1 ){
    _templates[_index] = _template
  }
  _index = _filteredTemplates.map(g=>g.id).indexOf(_template.id)
  if(_index > -1 ){
    _filteredTemplates[_index] = _template
  }
  return {
    templates: _templates,
    filteredTemplates: _filteredTemplates,
    selectedTemplate: _template,
    originalTemplate: clone(_template),
    selectedTemplateId: _template.id
  }
}

const processSingleSelection = (items, value) => {
  if (value.checked) {
    return [...items, value.id]
  } else {
    return items.filter(e => e !== value.id)
  }
}

const processAllSelection = (items, value) => {
  if (value.checked) {
    return items.map(e => e.id)
  } else {
    return []
  }
}

const trimIdleVacants = items => {
  let planExistsMap = {}
  let planExists = false
  let itemTree = []
  for (const item of items) {
    _extractPlanExistsMap(item, planExistsMap)
    if (!planExists && planExistsMap[item.id].some(e => e===1)) {
      planExists = true
    }
    if (item.indId>0 || planExistsMap[item.id].some(e => e===1)) {
      itemTree.push(item)
      _trimIdleVacants(item, planExistsMap)
    }
  }
  return {planExistsMap, planExists, itemTree}
}

const _extractPlanExistsMap = (item, existResult) => {
  if (item.children && item.children.length > 0) {
    for (const c of item.children) {
      let {planExists} = _extractPlanExistsMap(c, existResult)
      planExists.forEach(p => _addResultItem(p, existResult, item.id))
    }
    _addResultItem(item.planId>0 ? 1 : 0, existResult, item.id)
    return {planExists: existResult[item.id]}
  } else {
    _addResultItem(item.planId>0 ? 1 : 0, existResult, item.id)
    return {planExists: item.planId>0 ? [1] : [0]}
  }
}

const _trimIdleVacants = (item, planExistsMap) => {
  if (item.children && item.children.length > 0) {
    let ret = []
    for (const c of item.children) {
      if (c.indId>0 || planExistsMap[c.id].some(e => e===1)) {
        ret.push(c)
        _trimIdleVacants(c, planExistsMap)
      }
    }
    item.children = ret
  }
}

const extractClusterProps = (items, userId) => {
  let jobClassMap = {}, ministryMap = {}, historyMap = {}, reportMatchMap = {}
  for (const item of items) {
    _extractClusterProps(item, jobClassMap, ministryMap, historyMap)
    reportMatchMap[item.id] = !(item.indId > 0 && item.planId > 0 && item.managerId != userId && item.managerId !=null)
  }
  return {jobClassMap, ministryMap, historyMap, reportMatchMap}
}

const _extractClusterProps = (item, jobClassResult, ministryResult, historyResult) => {
  if (item.children && item.children.length > 0) {
    for (const c of item.children) {
      let {jobClasses, ministries, histories} = _extractClusterProps(c, jobClassResult, ministryResult, historyResult)
      jobClasses.forEach(p => _addResultObject(p, jobClassResult, item.id))
      ministries.forEach(p => _addResultItem(p, ministryResult, item.id))
      histories.forEach(p => _addResultItem(p, historyResult, item.id))
    }
    _addResultObject({name: item.posJobClass, order: item.jobClassOrder}, jobClassResult, item.id)
    _addResultItem(item.minId, ministryResult, item.id)
    _addResultItem(item.history, historyResult, item.id)
    return {
      jobClasses: jobClassResult[item.id],
      ministries: ministryResult[item.id],
      histories: historyResult[item.id]
    }
  } else {
    _addResultObject({name: item.posJobClass, order: item.jobClassOrder}, jobClassResult, item.id)
    _addResultItem(item.minId, ministryResult, item.id)
    _addResultItem(item.history, historyResult, item.id)
    return {
      jobClasses: item.posJobClass ? [{name: item.posJobClass, order: item.jobClassOrder}] : [],
      ministries: item.minId > 0 ? [item.minId] : [],
      histories: [item.history]
    }
  }
}

const _addResultItem = (prop, propResult, itemId) => {
  if (!propResult[itemId]) {
    propResult[itemId] = []
  }
  if (!propResult[itemId].includes(prop)) {
    propResult[itemId].push(prop)
  }
}

const _addResultObject = (prop, propResult, itemId) => {
  if (!propResult[itemId]) {
    propResult[itemId] = []
  }
  if (prop.name && !propResult[itemId].some(e => e.name === prop.name)) {
    propResult[itemId].push(prop)
  }
}

// Inactive assignment with active sibling should be trimmed
const trimInactiveSibling = items => {
  let _items = []
  for (const t of items) {
    let _item = _trimInactiveSibling(t, items)
    if (_item) _items.push(_item)
  }
  return _items
}

const _trimInactiveSibling = (item, siblings) => {
  let ret = {...item, children: []}
  let trimmable = item.history === 1 && siblings.some(e => e.positionId === item.positionId && e.indId > 0 && e.indId !== item.indId && e.history===0)
  if (!trimmable && item.children && item.children.length > 0) {
    for (const c of item.children) {
       let ct = _trimInactiveSibling(c, item.children)
       ret.children.push(ct)
    }
  }
  return ret
}

// Trim duplicate exists in position report that has been listed in top level plans
const trimDuplicates = items => {
  if (!items.some(e => !e.planId)) return items
  let _items = []
  let topLevelPlanIds = items.filter(e => e.planId).map(e => {return {planId: e.planId, rootId: e.rootId}}) || []
  for (const t of items) {
    let _item = _trimDuplicate(t, topLevelPlanIds)
    if (_item) _items.push(_item)
  }
  return _items
}

const _trimDuplicate = (item, topLevelPlanIds) => {
  if (item.planId && topLevelPlanIds.some(e=>e.planId === item.planId && e.rootId !== item.rootId)) return null
  let ret = {...item, children: []}
  if (item.children && item.children.length > 0) {
    for (const c of item.children) {
       let ct = _trimDuplicate(c, topLevelPlanIds)
       if (ct) ret.children.push(ct)
    }
  }
  return ret
}

const addSelectedMgrs = (state, teamTree, fileredTeamTree, value, prevFilteredTeamTree, reportMatchMap) => {
  let parentMap = {...state.parentMap}
  for (const id of value.ids) {
    let root = teamTree.find(e=>e.id ===id)
    if (_filterNode(root, state.selectedJobClasses, state.jobClassMap, state.accessMode, state.ministryMap, state.activeOnly, state.historyMap, state.planExistsMap)) {
      let result = {...root, children: []}
      const {allPlanIds, eligiblePlanIds} = _constructTree(root, result, state.jobClassMap, state.selectedJobClasses, state.accessMode, state.ministryMap, state.activeOnly, state.historyMap, state.planExistsMap, reportMatchMap)
      _populateSelectAll(result, parentMap, state.selectedJobClasses, state.accessMode, state.activeOnly, reportMatchMap)
      let _eligible = enabledForSelect(root, state.selectedJobClasses, state.accessMode, state.activeOnly, reportMatchMap)
      result = {...result, eligible: _eligible}

      // preserve preivous selection if user click on 'back'
      let _selectedPlanIds = []
      if (prevFilteredTeamTree) {
        let prevSelectedPlanIds = prevFilteredTeamTree.find(e=>e.item.id === root.id).selectedPlanIds
        _selectedPlanIds = prevSelectedPlanIds.filter(e => eligiblePlanIds.includes(e))
      } else {
        _selectedPlanIds = _eligible>0 ? [root.planId] : []
      }
      fileredTeamTree = [
        ...fileredTeamTree,
        {
          item: result,
          selectedPlanIds: _selectedPlanIds,
          allPlanIds: allPlanIds,
          eligiblePlanIds: eligiblePlanIds
        }
      ]
    } else {
      // none matched jobclass, need to keep root node as placeholder
      let _eligible = enabledForSelect(root, state.selectedJobClasses, state.accessMode, state.activeOnly, reportMatchMap)
      let result = {...root, eligible: _eligible, children: []}
      fileredTeamTree = [
        ...fileredTeamTree,
        {
          item: result,
          selectedPlanIds: [],
          allPlanIds: root.planId ? [root.planId] : [root.id],
          eligiblePlanIds: enabledForSelect(root, state.selectedJobClasses, state.accessMode, state.activeOnly, reportMatchMap)>0 ? [root.planId]: []
        }
      ]
    }
  }
  let _filteredTeamTree = fileredTeamTree.sort(sortTeamTree)
  let expandIds = _populateExpandItems(_filteredTeamTree)
  let jobClasses = _populateJobClasses(state.jobClassMap, _filteredTeamTree)
  return {tree: _filteredTeamTree, parentMap: parentMap, expandIds: expandIds, jobClasses: jobClasses}
}

const sortTeamTree = (a, b) => {
    if (a.item.indId>0 && b.item.indId>0) {
        return displayUserName(a.item).localeCompare(displayUserName(b.item))
    } else if (a.item.indId>0) {
        return -1
    } else if (b.item.indId>0) {
        return 1
    } else {
        return 0
    }
}

const removeSelectedMgrs = (state, value) => {
  let tree = state.filteredTeamTree.filter(e=>!value.ids.includes(e.item.id))
  let jobClasses = _populateJobClasses(state.jobClassMap, tree)
  let selectedJobClasses = state.selectedJobClasses.filter(e=>jobClasses.includes(e))
  return {tree: tree, jobClasses: jobClasses, selectedJobClasses: selectedJobClasses}
}

const changeActiveOnly = (state, tree, activeOnly) => {
  return _handleFilterChange(state, tree, state.selectedJobClasses, activeOnly)
}

const updateSelectedJobClass = (state, tree, selectedJobClasses) => {
  return _handleFilterChange(state, tree, selectedJobClasses, state.activeOnly)
}

const _handleFilterChange = (state, tree, selectedJobClasses, activeOnly) => {
  let parentMap = {}
  let selectedPlanIds = []
  let _tree = []
  tree.forEach (root => {
    if (_filterNode(root, selectedJobClasses, state.jobClassMap, state.accessMode, state.ministryMap, activeOnly, state.historyMap, state.planExistsMap)) {
      let result = {...root, eligible: enabledForSelect(root, selectedJobClasses, state.accessMode, activeOnly, state.reportMatchMap), children: []}
      const {allPlanIds, eligiblePlanIds} = _constructTree(root, result, state.jobClassMap, selectedJobClasses, state.accessMode, state.ministryMap, activeOnly, state.historyMap, state.planExistsMap, state.reportMatchMap)

      _populateSelectAll(result, parentMap, selectedJobClasses, state.accessMode, activeOnly, state.reportMatchMap)

      // filter out previous selectedPlanIds in root nodes
      let filteredRoot = state.filteredTeamTree.find(e=>e.item.id ===root.id)
      selectedPlanIds = filteredRoot.selectedPlanIds.length > 0 ? filteredRoot.selectedPlanIds.filter(e=>eligiblePlanIds.includes(e)) : []
      _tree = [..._tree, {item: result, selectedPlanIds: selectedPlanIds, allPlanIds: allPlanIds, eligiblePlanIds: eligiblePlanIds}]
    } else {
      // need to keep root node as placeholder
      let result = {...root, eligible: enabledForSelect(root, selectedJobClasses, state.accessMode, activeOnly, state.reportMatchMap), children: []}
      _tree = [..._tree, {
        item: result,
        selectedPlanIds: [],
        allPlanIds: root.planId ? [root.planId] : [root.id],
        eligiblePlanIds: enabledForSelect(root, selectedJobClasses, state.accessMode, activeOnly, state.reportMatchMap)>0 ? [root.planId]: []
      }]
    }
  })

  // Keep existing expandIds as much as possible
  let expandIds = []
  if (state.expandItemIds.length > 0) {
    expandIds = cleanExpandItems(_tree, state.expandItemIds)
  }
  if (expandIds.length === 0) {
    expandIds = _populateExpandItems(_tree)
  }

  return {tree: _tree, parentMap: parentMap, expandIds: expandIds}
}

const _filterNode = (t, selectedJobClasses, jobClassMap, accessMode, ministryMap, activeOnly, historyMap, planExistsMap) => {
  return ((selectedJobClasses.length===0
      || selectedJobClasses.some(e => e.name === t.posJobClass)
      || (jobClassMap[t.id] && jobClassMap[t.id].some(e=>selectedJobClasses.some(s => e.name===s.name))))
    && (!accessMode.min || ministryMap[t.id].some(e=>accessMode.minIds.includes(e)))
    && (!activeOnly || historyMap[t.id].some(e=>e===0))
    && (t.indId > 0 || planExistsMap[t.id].some(e=>e===1)))
}

const enabledForSelect = (c, selectedJobClasses, accessMode, activeOnly, reportMatchMap) => {
  if (!c.indId) return -1
  if (!reportMatchMap[c.rootId] || (c. managerId == null && c.id == c.rootId)) return -2
  if (!c.planId) return -3
  if (!c.hasAccess) return -4
  if (c.planStatus!=='OPEN' && c.planStatus!=='DRAFT') return -5
  if (accessMode.min && !accessMode.minIds.includes(c.minId)) return -6
  if (selectedJobClasses.length > 0 && !selectedJobClasses.some(e=>e.name===c.posJobClass)) return -7
  if (activeOnly && c.history===1) return -8
  if (c.noGoal) return -9
  return 1
}

const _constructTree = (root, result, jobClassMap, selectedJobClasses, accessMode, ministryMap, activeOnly, historyMap, planExistsMap, reportMatchMap) => {
  let _allPlanIds = new Set()
  let _eligiblePlanIds = new Set()
  _allPlanIds.add(root.planId ? root.planId : root.id)
  if (enabledForSelect(root, selectedJobClasses, accessMode, activeOnly, reportMatchMap)>0) {
    _eligiblePlanIds.add(root.planId)
  }

  if (root.children && root.children.length > 0) {
    for (const t of root.children) {
      if (_filterNode(t, selectedJobClasses, jobClassMap, accessMode, ministryMap, activeOnly, historyMap, planExistsMap)) {
        let tc = {...t, eligible: enabledForSelect(t, selectedJobClasses, accessMode, activeOnly, reportMatchMap), children: []}
        result.children.push(tc)
        _allPlanIds.add(t.planId ? t.planId : t.id)
        if (enabledForSelect(t, selectedJobClasses, accessMode, activeOnly, reportMatchMap)>0) _eligiblePlanIds.add(t.planId)
        const {allPlanIds, eligiblePlanIds} = _constructTree(t, tc, jobClassMap, selectedJobClasses, accessMode, ministryMap, activeOnly, historyMap, planExistsMap, reportMatchMap)
        allPlanIds.forEach(e => _allPlanIds.add(e))
        eligiblePlanIds.forEach(e => _eligiblePlanIds.add(e))
      }
    }
  }
  return {allPlanIds: [..._allPlanIds], eligiblePlanIds: [..._eligiblePlanIds]}
}

const _populateSelectAll = (item, parentMap, selectedJobClasses, accessMode, activeOnly, reportMatchMap) => {
  if (item.children && item.children.length > 0) {
    if (item.children.some(e=>enabledForSelect(e, selectedJobClasses, accessMode, activeOnly, reportMatchMap)>0)) {
      item.children.unshift({id: `-${item.id}`, parentId: item.id, rootId: item.rootId})
    }
    parentMap[item.id] = item
    for (const c of item.children) {
      _populateSelectAll(c, parentMap, selectedJobClasses, accessMode, activeOnly, reportMatchMap)
    }
  }
}

const _populateExpandItems = (tree) => {
  let expandItemIds = []
  if (tree.length > 0) {
      let item = tree[0].item
      if (item.children) expandItemIds.push({id: item.id, level: item.level})
      while (item && item.children && item.children.length > 0) {
          let c = (item.children[0].id.startsWith('-')) ? item.children[1] : item.children[0]
          expandItemIds.push({id: c.id, level: c.level})
          item = c
      }
  }
  return expandItemIds
}

const cleanExpandItems = (tree, expandItemIds) => {
  let ret = []
  tree.forEach(root => {
    if (expandItemIds.some(e => e.id === root.item.id)) {
      ret.push({id: root.item.id, level: root.item.level})
    }
    _cleanExpandItems(root.item, expandItemIds, ret)
  })
  return ret
}

const _cleanExpandItems = (item, expandItemIds, result) => {
  if (item.children && item.children.length > 0) {
    item.children.forEach(c => {
      if (expandItemIds.some(e => e.id === c.id)) {
        result.push({id: c.id, level: c.level})
      }
      _cleanExpandItems(c, expandItemIds, result)
    })
  }
}

const _populateJobClasses = (jobClassMap, tree) => {
  let ret = []
  tree.map(e=>e.item).forEach(t => {
    jobClassMap[t.id] && jobClassMap[t.id].forEach(e=>!ret.some(r=>r.name===e.name) && ret.push(e))
  })
  return ret
}

const addSelectedPlans = (filteredTeamTree, value) => {
  return filteredTeamTree.map(t=> {
    if (t.item.id===value.rootId) {
      let _selectedPlanIds = [...t.selectedPlanIds]
      for (const id of value.planIds) {
        if (!_selectedPlanIds.includes(id)) {
          _selectedPlanIds.push(id)
        }
      }
      return {...t, selectedPlanIds: _selectedPlanIds}
    } else {
      return t
    }
  })
}

const removeSelectedPlans = (filteredTeamTree, value) => {
  return filteredTeamTree.map(t=> {
    if (t.item.id===value.rootId) {
      let _selectedPlanIds = t.selectedPlanIds.filter(e => !value.planIds.includes(e))
      return {...t, selectedPlanIds: _selectedPlanIds}
    } else {
      return t
    }
  })
}

const cascadeSelect = (state, value) => {
  let ret = state.filteredTeamTree.map(root => {
    let _selectedPlanIds = [...root.selectedPlanIds]
    if (enabledForSelect(root.item, state.selectedJobClasses, state.accessMode, state.activeOnly, state.reportMatchMap)>0) {
      _selectItem(root.item, _selectedPlanIds, value)
    }
    _traverseTree(_selectedPlanIds, root.item, value, state.selectedJobClasses, state.accessMode, state.activeOnly, state.reportMatchMap)
    return {...root, selectedPlanIds: _selectedPlanIds}
  })
  return ret
}

const _traverseTree = (selectedPlanIds, item, select, selectedJobClasses, accessMode, activeOnly, reportMatchMap) => {
  if (item.children && item.children.length > 0) {
    item.children.forEach(c => {
      if (enabledForSelect(c, selectedJobClasses, accessMode, activeOnly, reportMatchMap)>0) {
        _selectItem(c, selectedPlanIds, select)
      }
      _traverseTree(selectedPlanIds, c, select, selectedJobClasses, accessMode, activeOnly, reportMatchMap)
    })
  }
}

const _selectItem = (item, selectedPlanIds, select) => {
  if (select) {
    if (!selectedPlanIds.includes(item.planId)) {
      selectedPlanIds.push(item.planId)
    }
  } else {
    // selectedPlanIds = selectedPlanIds.filter(e => e !== c.planId)
    const index = selectedPlanIds.indexOf(item.planId);
    if (index > -1) {
      selectedPlanIds.splice(index, 1);
    }
  }
}

const extractDTeamJobClasses = planList => {
  let ret = []
  planList.forEach(e => {
    if (e.posJobClass && !ret.some(r=>r.name===e.posJobClass)) {
      ret.push({name: e.posJobClass, order: e.jobClassOrder})
    }
  })
  return ret
}

const filterDTeamPlans = (planList, selectedJobClasses, activeOnly) => {
  return planList.filter(p=>{
    let ret = true
    if (selectedJobClasses.length > 0) {
      ret = selectedJobClasses.some(j => j.name === p.posJobClass)
    }
    if (ret && activeOnly) {
      ret = p.history === 0
    }
    return ret
  })
}

