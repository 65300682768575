import { createSelector } from 'reselect'
import { 
   REVIEW_STEP_MGR_START, 
   REVIEW_STEP_MGR_PREV,
   REVIEW_STEP_IND_START,
   REVIEW_STEP_IND_MINE,
   REVIEW_STATUS_FINISH, 
   PLAN_STATUS_REVIEW_COMPLETE,
   PLAN_STATUS_REVIEW_YEAR_CLOSE } from 'services/constants'
import { getPlan, getPlanStage, getAccessMode, } from 'components/selectors'
import { getReviewReasonValues } from 'utils'
import { preTypeAll } from './reducer'

export const getReviewStep = createSelector(
   state => state.ui.review.step, 
   data => data
)
export const getActiveItems = createSelector(
   [
      state => state.ui.review.activeItems, 
      state => getDefaultReview(state),
   ],
   (data, defaultReview) => data.length === 0 ? [defaultReview] : data
)

export const getReviewDetail = createSelector(
   [
      state => state.ui.review.detail,
      state => getPlan(state),
      state => getPlanStage(state)
   ], 
   (data, plan, stage) => {
      const rd = {...data }
      // if(plan.type?.code === 'NO_GOAL' && stage.yearClose){
      //    rd.details = []
      //    // rd.finalThought = ''
      //    rd.onBehalf = 3
      // }
      
      rd.finalReviewReason = rd.finalReviewReason || getReviewReasonValues()[0]
      return rd
   }
)

export const getReviewDetailOrigin = createSelector(
   [
      state => state.ui.review.detailO, 
      state => getPlan(state)
   ],
   (data, plan) => ({...data, formula: plan.formula})
)

export const getDefaultReview = createSelector(
   state => getPreReviewList(state),
   data => data[data.length -1 ]
)

export const getReviewUpdateDetail = createSelector(
   [
      state => state.ui.review.updateDetail,
      state => getDefaultReview(state),
   ],
   (data, defaultReview) => !data.id ? defaultReview : data
)

export const getRatingList = createSelector(
   state => getPlan(state), 
   data => data.planRatingList
)

export const getReviewDocs = createSelector(
   [
      state => state.ui.review.reviewDocs, 
      state => getPlan(state), 
   ],
   (docs, plan) => docs || plan.reviewDocs
)

export const getReviewStarted = createSelector(
   [
      state => getAccessMode(state),
      state => getPlan(state),
      state => getReviewStep(state),
   ],
   (accessMode, plan, step) => {
      if(plan.type.code !== 'REVIEW' && !accessMode.noFinal){
         return false
      }

      if(plan.planReviewList.length > 0){
         return true
      }

      if(accessMode.ownerRating){
      }

      if(accessMode.managerRating){
      }
      
      return false
   }
)
export const getPanelShowing= createSelector(
   state => state.ui.review.panelShowing, 
   data => data
)
export const getReviewWarning= createSelector(
   state => state.ui.review.reviewWarning, 
   data => data
)

export const getReviewStepEnable = createSelector(
   [
      state => getPlan(state),
   ],
   (plan) => {
      // const reviewList = plan.planReviewList
      const stepEnable={}
      stepEnable[REVIEW_STEP_MGR_START] = plan.status?.code !== PLAN_STATUS_REVIEW_COMPLETE || plan.status?.code !== PLAN_STATUS_REVIEW_YEAR_CLOSE
      stepEnable[REVIEW_STEP_MGR_PREV] = true   // reviewList.filter(r=>r.status.code === REVIEW_STATUS_FINISH).length > 0

      stepEnable[REVIEW_STEP_IND_START] = plan.status?.code !== PLAN_STATUS_REVIEW_COMPLETE || plan.status?.code !== PLAN_STATUS_REVIEW_YEAR_CLOSE
      stepEnable[REVIEW_STEP_IND_MINE] = true   // reviewList.filter(r=>r.status.code === REVIEW_STATUS_FINISH).length > 0

      return stepEnable
   }
)

export const getReviewReject= createSelector(
   state => getPlan(state), 
   data => data.planReviewList.filter(e=>e.reject)[0],
)

const getFinishedReviews = plan => plan.planReviewList
   .map(r=>({...r, ind: plan.ind}))
   .filter(r=>r.status.code === REVIEW_STATUS_FINISH )
   .sort((a,b)=>a.submitAt - b.submitAt)

// export const getMyReviewList = createSelector(getPlan, getFinishedReviews)

export const getPreReviewList= createSelector(getPlan, getFinishedReviews)

export const getPreStep= createSelector(
   state => state.ui.review.preStep, 
   data => data
)

export const getIndPreStep= createSelector(
   state => state.ui.review.indPreStep, 
   data => data
)
export const getPreType= createSelector(
   [
      state => getPreReviewList(state), 
      state => getPreReviewTypes(state),
      state => state.ui.review.preType, 
   ],
   (list, types, type) => list.length === 1 ? types[0] : type
)

export const getPreReviewTypes= createSelector(
   state => getPreReviewList(state), 
   reviewList => {
      const _reviewList = [
         ...reviewList
         .map(r=>({...r.type, reviewId: r.id, reviewAt: r.reviewAt, submitAt: r.submitAt}))
      ]
      // return [preTypeAll, ..._reviewList]
      return _reviewList.length === 0 ? [] : _reviewList.length === 1 ? _reviewList : [preTypeAll, ..._reviewList]
   }
)