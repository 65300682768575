import React from 'react'
import { RedWarning } from 'utils'

class propsType{
  content?: any;
}
const InlineMessage = (props: propsType) =>{
  const { content } = props

  return (!!!content.msgCode? <span/>:<RedWarning>{
    content.msgCode === 'E0000'?
    `Your changes can't be saved because Mentorship is currently unavailable. Please check back again soon`
    :
    content.msg
  }</RedWarning>)
}
export default InlineMessage