import {useState, useRef} from 'react'
import './style.scss'
import { newId } from 'utils';
const DefaultExport = props => {
    const { children, labelName, labelledBy, describedBy, onChange=()=>{}, ...rest } = props
    // eslint-disable-next-line
    const selected = useRef({})
    // eslint-disable-next-line
    const [selectWidth, setSelectWidth] = useState(75)
    const _onChange = e => {
        onChange(e.target.selectedIndex)
        // selected.current.innerText = e.target.selectedOptions[0].label

        // setSelectWidth(selected.current.offsetWidth + 75)
        // console.log(e.target.selectedOptions[0])
        // children[]
    }
    const id=newId()
    return (<div className="ontario-form-group" {...rest}>
        {/* <span style={{position: 'fixed', visibility: 'hidden'}} ref={selected}/> */}
    <label className="ontario-label" role='dialog' aria-live='polite' htmlFor={id}>
        {labelName}<span className="ontario-label__flag"></span>
    </label>
    <select required className="ontario-input ontario-dropdown tms-select"
    id={id} name={id}
        onChange={_onChange}>
        {children}
    </select>
</div>
    )
};

export default DefaultExport;