import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react'
import {DropDownForm} from './dropdown'
import DatePicker from './date-picker'
import { Div } from './elements'
import { isValidDate, isValidDateStr, createDate } from 'utils/date-util'
import { loggerInfo, newId} from 'utils'

export default forwardRef((props: any, ref) => {
  const { style, menuStyle, children, onApply, format, startDate, endDate, minDate, maxDate, onClear }= props
  const [ from, setFrom] = useState<any>('')
  const [ to, setTo] = useState<any>('')
  const [ startDateId, setStartDateId] = useState<string>()
  const [ endDateId, setEndDateId ] = useState<string>()

  const [ toDisable, setToDisable ] = useState<any>()

  const [ message, setMessage] = useState('')
  const [ srMessage, setSrMessage ] = useState('')
  const modalRef: any = useRef({})
  const dpFromRef: any = useRef({})
  const dpToRef: any = useRef({})

  useEffect(()=> {
    setStartDateId(newId())
    setEndDateId(newId)
  }, [])

  useImperativeHandle(ref, () => ({
		init(){ clear() }
  }))
  
  const onClose = (e?: any) => modalRef.current.hideDropMenu(e)

  const onClickApply = () =>{
    if(setApply()) {
      onClose()
    }
  }

  const setApply = () =>{
    if (!dpFromRef.current.isValid()){
      setSrMessage("State date " + dpFromRef.current.getMessage())
      return false
    }
    
    if (!dpToRef.current.isValid()) {
      setSrMessage("End date " + dpToRef.current.getMessage())
      return false
    }
    setSrMessage('')
        
    const __from = dpFromRef.current.getValue()
    const __to = dpToRef.current.getValue()

    if ((__from && !isValidDateStr(__from)) || (__to && !isValidDateStr(__to))) {
      return false
    }

    setFrom(__from)
    setTo(__to)

    const _from = __from && createDate(__from)
    const _to = __to && createDate(__to)
    
    if(_from.getTime && minDate && minDate.getTime() > _from.getTime()) {
      setMessage('Start date is not valid.')
      return false
    }

    if(_to.getTime && maxDate && maxDate.getTime() < _to.getTime()) {
      setMessage('End date is not valid.')
      return false
    }

    const validFrom = isValidDate(_from)
    const validTo = isValidDate(_to) 

    if(!(validFrom && validTo && _to.getTime() < _from.getTime())) {
      onApply({ 
        from: validFrom ? _from : null, 
        to: validTo ? _to : null 
      })
      setMessage('')
      return true
    }else{
      setMessage('Not a valid date range.')
    }
  }
  const onClickClear = () => {
    clear()
    onClear()
  }
  
  const clear = () =>{
    dpFromRef.current.setValue('')
    dpToRef.current.setValue('')
    dpFromRef.current.setMessage('')
    dpToRef.current.setMessage('')
    setMessage('')
    setFrom('')
    setTo('')
    dpFromRef.current.focus()
  }

  const onDropMenu= (event: any) =>{
    if (!event) {
      clear()
    } else {
      setFrom(startDate)
      setTo(endDate)
    }
  }

  const onDayChangeStart = (value: any) =>{
    setMessage('')
    setToDisable(createDate(value))
  }

  const onDayChangeEnd = () =>{
    setMessage('')
  }

  const onShowStart = (value: any) =>{
    if(dpToRef.current.isShow() && value){
    dpToRef.current.toggleShow(false)
    }
  }

  const onShowEnd = (value: any) =>{
    if(dpFromRef.current.isShow() && value){
      dpFromRef.current.toggleShow(false)
    }
  }

  loggerInfo('dateRange.render')
  return (
    <span className='date-criteria'>
      <DropDownForm ref={modalRef} 
        menuRole="dialog"
        style={style} 
        menuStyle={{width: '310px', ...menuStyle}} 
        onDropMenu={onDropMenu}
        display={children}>
        <div className='simple-row'>
          <div className='col-sm-6'>
            <span id={startDateId} style={{'fontSize': '13px', 'marginBottom': '2px'}}>Start date <span className="sr-only">in format YYYY-MM-DD</span></span>
            <DatePicker id="dateRangeFrom" labelledBy={startDateId} value={from} ref={dpFromRef} format={format} onDayChange={onDayChangeStart} onShow={onShowStart}
              dayPickerProps={{ disabledDays: { before: minDate, after: maxDate}}}/>
          </div>
          <div className='col-sm-6'>
            <span id={endDateId} style={{'fontSize': '13px', 'marginBottom': '2px'}}>End date <span className="sr-only">in format YYYY-MM-DD</span></span>
            <DatePicker id="dateRangeTo" labelledBy={endDateId} value={to} ref={dpToRef} format={format} onDayChange={onDayChangeEnd} onShow={onShowEnd}
              dayPickerProps={{ disabledDays: { before: toDisable || minDate, after: maxDate}}}/>
          </div>
        </div>
        {message && 
          <div className='simple-row' style={{fontSize: '12px', color: 'red', marginTop: '5px', textAlign: 'center'}}><div role="alert" className='col-sm'>{message}</div></div>
        }
        {!!srMessage && <span role="alert" className="sr-only">{srMessage}</span>}
        <div className='simple-row' style={{marginTop: '10px'}}>
          <div className='col-sm'>
          <Div style={{width: '45px', float: 'left'}} className='clickable-div' onClick={onClickApply}>Save</Div>
          <Div style={{width: '45px', float: 'left', marginLeft: '10px'}} className='clickable-div' onClick={onClose}>Cancel</Div>
          {onClear &&
          <Div style={{width: '45px', float: 'right'}} className='clickable-div' onClick={onClickClear}>Clear</Div>}
          </div>
        </div>
      </DropDownForm>
    </span>
  )
})