import {
  MENTOR_PROFILE_REFRESH,
  MENTOR_PROFILE_O_REFRESH,
  MENTOR_STEP_REFRESH,
  MENTOR_WIZARD_ERROR_REFRESH,
  MENTOR_SEARCH_RESULT_REFRESH,
  MENTOR_MOST_SKILLS_REFRESH,
  MENTOR_SEARCH_REQ_REFRESH,
  MENTOR_SEARCH_REQ_CLEAR,
  MENTOR_PAGE_NO_REFRESH,
  MENTOR_SEARCH_LAST_REQ_REFRESH,
  MENTOR_SEARCH_SEARCH_RETURNED,
  MENTOR_RESULT_SORT_REFRESH,
  MENTOR_OPEN_SECTION_REFRESH,
  MENTOR_RELATED_REFRESH,
  MENTOR_DELETED_REFRESH,
  MENTOR_STATUS_COUNT_REFRESH,
  MENTOR_SETUP_REFRESH,
  MENTOR_SEND_REQUEST_REFRESH,
  MENTOR_SAVED_REFRESH,
  MENTOR_OPERATION_REFRESH,
  MENTOR_NOTIFY_REFRESH,
  MENTOR_FILTER_WARNING_REFRESH
} from 'services/action-types'
// import { VALUE_MT_SAVED_SECTION_RESULTS, VALUE_MT_SEARCH_SECTION } from 'services/constants';
import { MentorProfileDto, MentorSearchCriteriaJson, MentorSearchSortBy, MentorshipSearchResultDto, MentorshipStatus, MentorshipType, ValueListBase, ProfStatusCountDto, MentorshipCardDto } from 'types'
import { getMentorSearchPageSize } from 'utils'

// export const pageSize = 2
export const pageSize = getMentorSearchPageSize().value - 0

export class MentorSection{
  name: string='';
  type: MentorshipType=MentorshipType.SEARCH;
  status?: MentorshipStatus=MentorshipStatus.SEARCH;
  label: string='';
  hint?: any;
  sortValues:any[]=[];
}

export enum sectionName{
  search='search',
  saved='saved',
  pending='pending',
  accepted='accepted',
  completed='completed',
  cancelled='cancelled',
  notify='notify'
}

const searchSection: MentorSection = {
  name: sectionName.search, 
  type: MentorshipType.SEARCH,
  status: MentorshipStatus.SEARCH, 
  label: 'Search results', sortValues: [
    {sortDirection: 'DESC', sortBy: MentorSearchSortBy.MATCH_SKILLS, sortLabel: 'Matched skills (most-least)'},
    {sortDirection: 'DESC', sortBy: MentorSearchSortBy.SPOT_NUM, sortLabel: 'Available mentorship spots (most-least)'},
    {sortDirection: 'ASC', sortBy: MentorSearchSortBy.FIRST_NAME, sortLabel: 'First name (A-Z)'},
    {sortDirection: 'ASC', sortBy: MentorSearchSortBy.LAST_NAME, sortLabel: 'Last name (A-Z)'},
  ],
  //hint: {label:'Search results tips', code: VALUE_MT_SEARCH_SECTION}
}
const savedSection: MentorSection = {
  name: sectionName.saved, 
  type: MentorshipType.SAVED,
  status: MentorshipStatus.SAVED,
  label: 'Saved', sortValues: [
    {sortDirection: '', sortBy: '', sortLabel: 'Profile saved (newest-oldest)', sortFunc: ()=>(a:any, b: any)=> b.inSavedListTime - a.inSavedListTime},
    {sortDirection: '', sortBy: '', sortLabel: 'Matched skills (most-least)', sortFunc: ()=>(a:any, b: any)=> b.matchedSkill.length - a.matchedSkill.length},
    {sortDirection: '', sortBy: MentorSearchSortBy.SPOT_NUM, sortLabel: 'Available mentorship spots (most-least)', sortFunc: (a:any, b: any)=> b.spots - a.spots},
    {sortDirection: '', sortBy: '', sortLabel: 'First name (A-Z)', sortFunc: (a:any, b: any)=>a.ind.firstName?.localeCompare(b.ind.firstName)},
    {sortDirection: '', sortBy: '', sortLabel: 'Last name (A-Z)', sortFunc: (a:any, b: any)=>a.ind.lastName?.localeCompare(b.ind.lastName)},
  ],
  //hint: {label:'Saved tips', code: VALUE_MT_SAVED_SECTION_RESULTS}
}
const pendingSection: MentorSection = {
  name: sectionName.pending, 
  type: MentorshipType.MENTORSHIP,
  status: MentorshipStatus.PENDING,
  label: 'Pending', sortValues: []
}
const acceptedSection: MentorSection = {
  name: sectionName.accepted, 
  type: MentorshipType.MENTORSHIP,
  status: MentorshipStatus.ACCEPTED,
  label: 'Accepted', sortValues: []
}
const completedSection: MentorSection = {
  name: sectionName.completed, 
  type: MentorshipType.MENTORSHIP,
  status: MentorshipStatus.COMPLETED,
  label: 'Completed', sortValues: []
}
const cancelledSection: MentorSection = {
  name: sectionName.cancelled, 
  type: MentorshipType.MENTORSHIP,
  status: MentorshipStatus.CANCELLED,
  label: 'Canceled', sortValues: []
}
const notifySection: MentorSection = {
  name: sectionName.notify, 
  type: MentorshipType.NOTIFY,
  label: 'Notify', sortValues: []
}
export const MentorSections={
  [sectionName.search]: searchSection,
  [sectionName.saved]: savedSection,
  [sectionName.pending]: pendingSection,
  [sectionName.accepted]: acceptedSection,
  [sectionName.completed]: completedSection,
  [sectionName.cancelled]: cancelledSection,
  [sectionName.notify]: notifySection
}

const initSortValue = {
  [sectionName.search]: searchSection.sortValues[0],
  [sectionName.saved]: savedSection.sortValues[0],
}
export const initSearchReq: MentorSearchCriteriaJson = {
  ...new MentorSearchCriteriaJson(), 
  page: 0,
  size: pageSize,
  sortBy: initSortValue[sectionName.search].sortBy
}

const initialState = {
  step: 1,
  wizardError: false,
  profile: new MentorProfileDto(),
  profileO: new MentorProfileDto(),
  result: new MentorshipSearchResultDto(),
  mostSkills: [ValueListBase],
  searchReq: initSearchReq,
  lastSearchReq: {},
  searchReturned: {},

  page: 0,
  sort: initSortValue,
  activeSection: MentorSections[sectionName.search],

  related: {},
  deleted: [],
  totalRecords: {},
  statusCount: new ProfStatusCountDto(),
  setup: false,
  operation: {},
  filterWarning: false
}

export const mentorReducer =  (state = initialState, action: any) => {
  switch (action.type) {
  case MENTOR_PROFILE_REFRESH:
    return state = { 
      ...state, 
      profile: action.value,
    }
  case MENTOR_PROFILE_O_REFRESH:
    return state = { 
      ...state, 
      profile: action.value,
      profileO: action.value,
    }
  case MENTOR_STEP_REFRESH:
    return state = { 
      ...state, 
      step: action.value
    }
  case MENTOR_WIZARD_ERROR_REFRESH:
    displayWizardError(action.value)
    return state = { 
      ...state, 
      wizardError: action.value
    }    
  case MENTOR_SEARCH_RESULT_REFRESH:
    return state = { 
      ...state, 
      result: action.value,
      totalRecords: {...state.totalRecords, [sectionName.search]: action.value.totalRecords},
    }
  case MENTOR_SEND_REQUEST_REFRESH:
    return state = { 
      ...state, 
      result: replaceResult(state, action.value),
      related: replaceRelated(state, action.value),
    }
  case MENTOR_SAVED_REFRESH:
    return state = { 
      ...state, 
      result: replaceResult(state, action.value),
      related: replaceSaved(state, action.value),
    }
  case MENTOR_MOST_SKILLS_REFRESH:
    return state = { 
      ...state, 
      mostSkills: action.value,
    }
  case MENTOR_SEARCH_REQ_REFRESH:
    return state = { 
      ...state, 
      searchReq: action.value,
      filterWarning: false
    }
  case MENTOR_SEARCH_REQ_CLEAR:
    return state = { 
      ...state, 
      searchReq: {...state.searchReq, ...initSearchReq},
      filterWarning: false
    }
  case MENTOR_PAGE_NO_REFRESH:
    return state = {
      ...state,
      searchReq: {
        ...state.searchReq, 
        page: action.value,
      },
      filterWarning: false,
      page: action.value,
    }
  case MENTOR_SEARCH_LAST_REQ_REFRESH:
    return state = { 
      ...state, 
      lastSearchReq: action.value
    }
  case MENTOR_SEARCH_SEARCH_RETURNED:
    return state = { 
      ...state, 
      searchReturned: action.value,
    }
  case MENTOR_RESULT_SORT_REFRESH:
    return state = { 
      ...state,
      sort: {...state.sort, [action.value.section.name]: action.value.data},
    }
  case MENTOR_OPEN_SECTION_REFRESH:
    return state = { 
      ...state, 
      activeSection: action.value,
    }
  case MENTOR_RELATED_REFRESH:
    return state = { 
      ...state, 
      ...setRelated(state, action.value)
    }
  case MENTOR_DELETED_REFRESH:
    return state = { 
      ...state, 
      deleted: action.value,
    }
  case MENTOR_STATUS_COUNT_REFRESH:
    return state = { 
      ...state, 
      totalRecords:{...state.totalRecords, ...setStatusCount(action.value)},
    }
  case MENTOR_SETUP_REFRESH:
    return state = {
      ...state,
      setup: true
    }
  case MENTOR_OPERATION_REFRESH:
    return state = {
      ...state,
      operation: action.value,
    }
  case MENTOR_NOTIFY_REFRESH:
    return state = {
      ...state,
      ...setNotify(state, action.value)
    }
  case MENTOR_FILTER_WARNING_REFRESH:
    return state = {
      ...state,
      filterWarning: action.value,
    }
  default:
    return state
  }
}

const isScrolledIntoView = (el: any) => {
  var rect = el.getBoundingClientRect()
  var isVisible = (rect.top >= 0) && (rect.bottom <= window.innerHeight)
  return isVisible
}

const displayWizardError = (show: boolean) => show && setTimeout(() => {
  const warning:any = document.querySelector('.red-warning-bar')
  warning && !isScrolledIntoView(warning) && warning.scrollIntoView({ behavior: "auto", block: "center", inline: "center" })
}, 200)

const getSectionName = (code: string) => {
  const keys =Object.keys(MentorSections) as Array<keyof typeof MentorSections>
  const name:string = keys.filter(k=>MentorSections[k].status===code)[0]
  return name
}

const setStatusCount = (data: ProfStatusCountDto[]) => {
  const count:any = {[sectionName.pending]: 0, [sectionName.accepted]: 0, [sectionName.completed]: 0, [sectionName.saved]: 0}

  data.map((d:any)=>{
    count[getSectionName(d.status.code)] = d.count
    return d
  })
  return count
}

const setRelated =(state: any, value: any)=>{
  const _related = {...state.related }  
  if(value.section.name === sectionName.notify){
    const items = value.data.filter((n:any)=>n.events.type?.code === 'CANCELLED').sort((a:any,b:any)=>b.lastUpdateAt - a.lastUpdateAt) || []
    _related[sectionName.cancelled] = items.length > 1 ? [items[0]] : items
  }
  _related[value.section.name] = value.data

  const _totalRecords = { ...state.totalRecords }
  if(value.section.name === sectionName.saved){
    _totalRecords[value.section.name] = value.data.filter((d:MentorshipCardDto)=>d.mentorshipType?.code !== 'NOTIFY').length 
  }else{
    _totalRecords[value.section.name] = value.data.length
  }
  return {
    related: _related,
    totalRecords: _totalRecords
  }
}

const replaceResult = (state: any, card: { before: MentorshipCardDto, after: MentorshipCardDto}) => {
  const activeSectionName: string = state.activeSection.name
  const {after} = card
  if(activeSectionName === sectionName.search){
    const _result = {...state.result}
    const _items = [..._result.items]
    const index = _items.map((item: MentorshipCardDto)=>item.indId).indexOf(after.indId)
    _items[index] = after
    return {..._result, items: _items}
  }else{
    return state.result
  }
}

const replaceRelated = (state: any, card: { before: MentorshipCardDto, after: MentorshipCardDto}) => {
  const _related = {...state.related}
  const activeSectionName: string = state.activeSection.name
  const {before, after} = card

  if(before.mentorshipStatus?.code === 'CANCELLED' && before.mentorshipType?.code === 'NOTIFY'){
    _related[sectionName.cancelled] = []
  }else if(activeSectionName === sectionName.pending || activeSectionName === sectionName.accepted){
    _related[activeSectionName] = _related[activeSectionName].filter((r:any)=>r.mentorshipId !== after.mentorshipId)
  }else if(activeSectionName === sectionName.saved){
    const index = _related[activeSectionName]?.map((r:any)=>r.indId).indexOf(after.indId)
    if(index > -1){
      _related[activeSectionName][index] = after
    }
  }  
  return _related
}

const replaceSaved = (state: any, card: { before: MentorshipCardDto, after: MentorshipCardDto}) => {
  const _related = {...state.related}
  const activeSectionName: string = state.activeSection.name
  const {before, after} = card

  if(before.mentorshipStatus?.code === 'CANCELLED' && before.mentorshipType?.code === 'NOTIFY'){
    _related[sectionName.cancelled] = []
  }else if(activeSectionName === sectionName.saved){
    _related[activeSectionName] = _related[activeSectionName]?.filter((r:any)=>r.indId !==after.indId)
  }else{
    const index = _related[activeSectionName]?.map((r:any)=>r.indId).indexOf(after.indId)
    if(index > -1){
      _related[activeSectionName][index] = after
    }
  }
  return _related
}

const setNotify =(state: any, value: MentorshipCardDto)=>{  
  const _related = {...state.related }
  _related[sectionName.notify] = _related[sectionName.notify]?.filter((n:MentorshipCardDto)=>n.mentorshipId !== value.mentorshipId)

  return {
    related: _related,
  }
}
